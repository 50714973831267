import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Grid } from 'semantic-ui-react';
import { GRAY_8_5, DARK_BLUE, COMPUTER } from 'styles/variables';

// Row
const GridRowBJ = ({ pointer, hover, hoverColor, paddingBottom, paddingTop,
  paddingLeft, paddingRight, ...other }) => <Grid.Row {...other} />;

GridRowBJ.propTypes = {
  paddingTop: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingLeft: PropTypes.string,
  pointer: PropTypes.bool,
  hover: PropTypes.bool,
  hoverColor: PropTypes.string,
};

const StyledGridRow = styled(GridRowBJ)`
  ${(props) => props.paddingTop && css`
    padding-top: ${props.paddingTop} !important;
  `}
  ${(props) => props.paddingRight && css`
    padding-right: ${props.paddingRight} !important;
  `}
  ${(props) => props.paddingBottom && css`
    padding-bottom: ${props.paddingBottom} !important;
  `}
  ${(props) => props.paddingLeft && css`
    padding-left: ${props.paddingLeft} !important;
  `}
  ${(props) => props.pointer && css`
    cursor: pointer !important;
    ${props.hover && css`
      &:hover {
        background: ${() => props.hoverColor || GRAY_8_5} !important;
        .hovered {
          color: ${DARK_BLUE} !important;
        }
      }
    `}
  `}
`;

// Column
const GridColumnBJ = ({ noPaddingLeft, noPaddingRight, paddingTop, paddingRight, paddingBottom,
  paddingLeft, outlineColor, desktopFullHeight, ...other }) => <Grid.Column {...other} />;

GridColumnBJ.propTypes = {
  noPaddingLeft: PropTypes.bool,
  noPaddingRight: PropTypes.bool,
  paddingTop: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingLeft: PropTypes.string,
  outlineColor: PropTypes.string,
  desktopFullHeight: PropTypes.bool,
};

const StyledGridColumn = styled(GridColumnBJ)`
  ${(props) => props.noPaddingLeft && css`
    padding-left: 0 !important;
  `}
  ${(props) => props.noPaddingRight && css`
    padding-right: 0 !important;
  `}
  ${(props) => props.paddingTop && css`
    padding-top: ${props.paddingTop} !important;
  `}
  ${(props) => props.paddingRight && css`
    padding-right: ${props.paddingRight} !important;
  `}
  ${(props) => props.paddingBottom && css`
    padding-bottom: ${props.paddingBottom} !important;
  `}
  ${(props) => props.paddingLeft && css`
    padding-left: ${props.paddingLeft} !important;
  `}
  ${(props) => props.outlineColor && css`
    outline-color: ${props.outlineColor} !important;
    :focus {
      outline-color: ${props.outlineColor} !important;
    }
  `}
  ${(props) => props.desktopFullHeight && css`
    @media only screen and (min-width: ${COMPUTER.minWidth}px) {
      height: 100% !important;
    }
  `}
`;


const GridBJ = (props) => <Grid {...props} />;

GridBJ.Row = StyledGridRow;
GridBJ.Column = StyledGridColumn;


export default GridBJ;
