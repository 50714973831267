import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Segment } from 'semantic-ui-react';
import { BJLoader } from './BJLoader';

const StyledSegment = ({ color, padding, ...other }) => <Segment {...other} />;

StyledSegment.propTypes = {
  color: PropTypes.string,
  padding: PropTypes.string,
};

const NoPaddedSegment = styled(StyledSegment)`
  ${(props) => props.color && css`
    background-color: ${props.color} !important;
  `}
  box-shadow: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  ${(props) => props.padding && css`
    padding: ${props.padding} !important;
  `}

  // Loader styles
  &.loading:after {
    ${BJLoader}
  }
`;

const ContainerSegment = (props) => <NoPaddedSegment basic {...props} />;

export default ContainerSegment;
