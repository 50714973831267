import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Icon } from 'components/Semantic';

import { useEventTracking } from 'containers/EventTracking';

import ColorText from '../../ColorText';

import ModalMejorarFormato from './ModalMejorarFormato';

import messages from './messages';

const BottomToolbar = (props) => {
  const { text, enhanceParams, onReplace, intl } = props;

  const [open, setOpen] = useState(false);

  const { trackEvent } = useEventTracking();

  const handleTrackEvent = (event) => {
    trackEvent({
      FeatureName: enhanceParams.name,
      Uuid: enhanceParams.uuid,
      Event: event,
      Channel1: enhanceParams.chnl1,
      Channel2: enhanceParams.chnl2,
      Channel3: enhanceParams.chnl3,
    });
  };

  return (
    <>
      <div
        className="bj-toolbar"
        onMouseDown={(e) => {
          // This line is just to prevent firing onBlur in the editor
          e.preventDefault();
        }}
        style={{ textAlign: 'center', padding: '0.65em' }}
      >
        <ColorText light medium>{enhanceParams.description}</ColorText>
        <Button
          className="jazzy"
          onMouseDown={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
          title={intl.formatMessage(messages.embellecerButton)}
          icon
          toolbar
          type="button"
          disabled={enhanceParams.disabled}
          style={{ marginLeft: '10px' }}
        >
          <Icon name="magic" />
          <ColorText style={{ marginLeft: '5px' }}>
            <FormattedMessage {...messages.embellecerButton} />
          </ColorText>
        </Button>
      </div>

      {open ?
        <ModalMejorarFormato
          open={open}
          onClose={() => setOpen(false)}
          title={enhanceParams.title}
          text={text}
          endpoint={enhanceParams.endpoint}
          onReplace={onReplace}
          trackEvent={handleTrackEvent}
        />
      : null}
    </>
  );
};

BottomToolbar.propTypes = {
  text: PropTypes.string,
  enhanceParams: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    endpoint: PropTypes.string,
    uuid: PropTypes.string,
    chnl1: PropTypes.string,
    chnl2: PropTypes.string,
    chnl3: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  onReplace: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(BottomToolbar);
