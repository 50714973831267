
export const ROL_FUNCIONARIO_EMPRESA = 'FuncionarioEmpresa';
export const ROL_FUNCIONARIO_ADMINISTRADOR = 'FuncionarioAdministrador';
export const ROL_FUNCIONARIO_ANALISTA = 'FuncionarioAnalista';
export const ROL_FUNCIONARIO_ASISTENTE = 'FuncionarioAsistente';
export const ROL_EMPRESA_BUSQUEDA_LIBRE = 'EmpresaBusquedaLibre';
export const ROL_EMPRESA_MIS_CVS = 'EmpresaHistorialPostulaciones';
export const ROL_EMPRESA_RECOMENDADOS = 'EmpresaRecomendados';
export const ROL_EMPRESA_OFERTA_MODO_POSTULACION = 'EmpresaOfertaModoPostulacion';
export const ROL_EMPRESA_OFERTA_REFERENCIA_EXTERNA = 'EmpresaOfertaReferenciaExterna';
export const ROL_OPERADOR_AVANZADO = 'OperadorAvanzado';
export const ROL_OPERADOR_ADMINISTRADOR_PAGOS = 'AdministradorPagos';
export const ROL_OPERADOR_EDITAR_EMPRESA_PAQUETE = 'OperadorEditarEmpresaPaquete';
export const ROL_EMPRESA_PREMIUM = 'EmpresaPremium';
