import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Header } from 'semantic-ui-react';
import { GRAY_1, DARK_BLUE, BLUE_HOVER } from 'styles/variables';


// Header.Content

const HeaderContentBJ = ({ color, item, clickable, medium, small, secondary, ...other }) => <Header.Content {...other} />;

HeaderContentBJ.propTypes = {
  color: PropTypes.string,
  item: PropTypes.bool,
  clickable: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  tiny: PropTypes.bool,
  secondary: PropTypes.bool,
};

const StyledHeaderContent = styled(HeaderContentBJ)`
  // Por defecto el color del Header es GRAY_1
  color: ${(props) => props.color || GRAY_1} !important;
  .header-content {
    color: ${(props) => props.color || GRAY_1} !important;
  }
  padding: 0 !important;
  
  ${(props) => props.item && css`
    font-size: 16px !important;
  `}

  ${(props) => props.clickable && css`
    cursor: pointer !important;
    color: ${DARK_BLUE} !important;
    &:hover {
      color: ${BLUE_HOVER} !important;
    }
  `}

  ${(props) => props.medium && css`
    font-size: 16px !important;
  `}

  ${(props) => props.small && css`
    font-size: 14px !important;
  `}
  
    ${(props) => props.tiny && css`
    font-size: 12px !important;
  `}

  ${(props) => props.secondary && css`
    font-weight: normal !important;
    text-transform: uppercase !important;
    .sub {
      text-transform: none !important;
    }
  `}
`;


// Header.Subheader

const StyledSubheader = styled(Header.Subheader)`
  padding-top: 7px !important;
  font-size: 12px !important;
  i {
    font-size: 14px !important;
  }
`;


// Header

const HeaderBJ = ({ ...other }) => <Header {...other} />;

HeaderBJ.Content = StyledHeaderContent;
HeaderBJ.Subheader = StyledSubheader;

export default HeaderBJ;
