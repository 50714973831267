import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { GRAY_1 } from 'styles/variables';

const StrongBJ = ({ color, italic, underline, pointer, ...other }) => <strong {...other} />;

StrongBJ.propTypes = {
  color: PropTypes.string,
  italic: PropTypes.bool,
  underline: PropTypes.bool,
  pointer: PropTypes.bool,
};

const StyledStrong = styled(StrongBJ)`
  // Por defecto el color es GRAY_1
  color: ${(props) => props.color || GRAY_1};
  
  ${(props) => props.italic && css`
    font-style: italic;
  `}
  
  ${(props) => props.underline && css`
    text-decoration: underline;
  `}
  
  ${(props) => props.pointer && css`
    cursor: pointer;
  `}
`;

export default StyledStrong;
