import _ from 'lodash';
import { fromJS } from 'immutable';

function createReducer(initialState, reducerMap, assertionFn) {
  return (state = fromJS(initialState), action) => {
    if (_.isFunction(assertionFn) && assertionFn(state, action) === false) {
      return state;
    }

    const reducer = reducerMap[action.type];

    return reducer
      ? reducer(state, action)
      : state;
  };
}

export default createReducer;
