import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { GRAY_1 } from 'styles/variables';
import { lightColor } from 'styles/utils';

const SpanBJ = ({
  color,
  mini,
  tiny,
  small,
  medium,
  large,
  xlarge,
  xxlarge,
  light,
  uppercase,
  italic,
  bold,
  strong,
  fontFamily,
  fontSize,
  ...other
}) => <span {...other} />;

SpanBJ.propTypes = {
  color: PropTypes.string,
  mini: PropTypes.bool,
  tiny: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  xlarge: PropTypes.bool,
  xxlarge: PropTypes.bool,
  light: PropTypes.bool,
  italic: PropTypes.bool,
  bold: PropTypes.bool,
  strong: PropTypes.bool,
  uppercase: PropTypes.bool,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
};

const StyledSpan = styled(SpanBJ)`
  // Por defecto el color es GRAY_1
  color: ${(props) => props.color || GRAY_1} !important;

  i {
    font-size: 14px !important;
  }

  ${(props) => (props.fontSize !== undefined) && css`
    font-size: ${props.fontSize};
  `}

  ${(props) => props.fontFamily && css`
    font-family: ${props.fontFamily};
  `}


  ${(props) => props.italic && css`
    font-style: italic;
  `}

  ${(props) => props.bold && css`
    font-weight: bold;
  `}

  ${(props) => props.strong && css`
    font-weight: bold;
  `}

  ${(props) => props.mini && css`
    font-size: 10px;
  `}

  ${(props) => props.tiny && css`
    font-size: 11px;
  `}

  ${(props) => props.small && css`
    font-size: 12px;
  `}

  ${(props) => props.medium && css`
    font-size: 13px;
  `}

  ${(props) => props.large && css`
    font-size: 14px;
  `}

  ${(props) => props.xlarge && css`
    font-size: 15px;
  `}

  ${(props) => props.xxlarge && css`
    font-size: 16px;
  `}

  ${(props) => props.light && css`
    color: ${lightColor(props)} !important;
  `}

  ${(props) => props.uppercase && css`
    text-transform: uppercase !important;
  `}
`;

export default StyledSpan;
