import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Segment } from 'semantic-ui-react';
import { WHITE, GRAY_5, GRAY_10, DARK_BLUE } from 'styles/variables';
import { BJLoader } from 'components/BJComponents/BJLoader';


// Segment.Group

const SegmentGroupBJ = ({ noMarginBottom, noMarginTop, compact, shadow, divided, shadowColor,
  arrow, arrowColor, ...other }) => <Segment.Group {...other} />;

SegmentGroupBJ.propTypes = {
  noMarginBottom: PropTypes.bool,
  noMarginTop: PropTypes.bool,
  compact: PropTypes.bool,
  shadow: PropTypes.bool,
  shadowColor: PropTypes.string,
  divided: PropTypes.bool,
  arrow: PropTypes.bool,
  arrowColor: PropTypes.string,
};

const StyledSegmentGroup = styled(SegmentGroupBJ)`
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  width: 100% !important;
  margin: 1rem 0em !important;
  
  ${(props) => props.noMarginBottom && css`
    margin-bottom: 0px !important;
  `}
  
  ${(props) => props.noMarginTop && css`
    margin-top: 0px !important;
  `}
  
  ${(props) => props.compact && css`
    margin: 0 !important;
  `}
  
  ${(props) => props.shadow && css`
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3)  !important;
  `}

  ${(props) => props.divided && css`
    >.segment, >.segments {
      &:not(:first-child) {
        ${props.horizontal ?
          `border-left: 1px solid ${GRAY_10} !important;`
        :
          `border-top: 1px solid ${GRAY_10} !important;`
        }
      }
    }
  `}

  ${(props) => props.arrow && css`
    z-index: 1;
    :after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: ${props.arrowColor || WHITE};
      position: absolute;
      z-index: -1;
      top: 12px;
      right: -5px;
      transform: rotate(45deg);
      ${!!props.shadow &&
        `box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3)  !important;`
      }
    }
  `}
`;


// Segment

const SegmentBJ = ({ noPadding, noPaddingTop, noPaddingVertical, noPaddingHorizontal, color, compact, icon,
  transparent, scrollable, maxHeight, minHeight, border, borderColor, borderRadius, borderRadiusValue, comment,
  selected, shadow, shadowColor, arrow, relative, ...other }) => <Segment {...other} />;

SegmentBJ.Content = Segment.Content;
SegmentBJ.Group = Segment.Group;

SegmentBJ.propTypes = {
  color: PropTypes.string,
  compact: PropTypes.bool,
  icon: PropTypes.bool,
  transparent: PropTypes.bool,
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  noPaddingVertical: PropTypes.bool,
  noPaddingHorizontal: PropTypes.bool,
  noPadding: PropTypes.bool,
  scrollable: PropTypes.bool,
  maxHeight: PropTypes.number,
  border: PropTypes.bool,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.bool,
  borderRadiusValue: PropTypes.string,
  comment: PropTypes.bool,
  selected: PropTypes.bool,
  shadow: PropTypes.bool,
  shadowColor: PropTypes.string,
  minHeight: PropTypes.number,
  arrow: PropTypes.bool,
  relative: PropTypes.bool,
};

const StyledSegment = styled(SegmentBJ)`
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  
  // Por defecto el color del background es GRAY_5
  background-color: ${(props) => props.color || GRAY_5} !important;
  
  ${(props) => props.compact && css`
    padding: 8px !important;
  `}
  
  ${(props) => props.noPaddingTop && css`
    padding-top: 0px !important;
  `}
  
    ${(props) => props.noPaddingBottom && css`
    padding-bottom: 0px !important;
  `}
  
  ${(props) => props.noPaddingVertical && css`
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  `}

  ${(props) => props.noPaddingHorizontal && css`
    padding-left: 0px !important;
    padding-right: 0px !important;
  `}
  
  ${(props) => props.noPadding && css`
    padding: 0px !important;
  `}
  
    ${(props) => props.minHeight && css`
    min-height: ${props.minHeight}px !important;
  `}
  
  ${(props) => props.icon && css`
    max-width: 35px !important;
  `}
  
  ${(props) => props.transparent && css`
    background-color: transparent !important;
  `}
  
  ${(props) => props.scrollable && css`
    overflow: auto;
    max-height: ${props.maxHeight || 300}px;
  `}
  
  ${(props) => props.border && css`
    border: 1px solid ${props.borderColor || GRAY_10} !important;
  `}
  
  ${(props) => props.borderRadius && css`
    border-radius: ${props.borderRadiusValue ?? '3px'} !important;
  `}

  ${(props) => props.comment && css`
    border-left: 4px solid ${props.borderColor || GRAY_10} !important;
  `}

  ${(props) => props.selected && css`
    border-bottom: 3px solid ${DARK_BLUE} !important;
  `}

  ${(props) => props.shadow && css`
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3) !important;
  `}

  ${(props) => props.arrow && css`
    :after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: ${props.color || GRAY_5};
      position: absolute;
      z-index: 1;
      top: 50%;
      right: -5px;
      transform: translateY(-50%) rotate(45deg);
      ${!!props.border && css`
        border-top: 1px solid ${props.borderColor || GRAY_10};
        border-right: 1px solid ${props.borderColor || GRAY_10};
      `}
      ${!!props.shadow &&
        `box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3) !important;`
      }
    }
  `}

  ${(props) => props.relative && css`
    position: relative !important;
  `}

  // Loader styles
  &.loading {
    &:before {
      background-color: ${(props) => props.transparent ? 'transparent' : (props.color ?? GRAY_5)} !important;
      opacity: 0.8;
    }
    &:after {
      ${BJLoader}
    }
  }
`;

StyledSegment.Group = StyledSegmentGroup;

export default StyledSegment;
