import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Step } from 'semantic-ui-react';
import flechaOscura from 'images/icons/flechaOscura.svg';
import flecha from 'images/icons/flecha.svg';
import { MOBILE, WHITE, LIGHT_GRAY, GRAY_1, GRAY_13, GRAY_14, DARK_GREEN, DARK_BLUE } from 'styles/variables';


// Step.Group

const StepGroupBJ = ({ linear, ...other }) => <Step.Group {...other} />;

StepGroupBJ.propTypes = {
  linear: PropTypes.bool,
};

const StyledStepGroup = styled(StepGroupBJ)`
  border: none !important;

  ${(props) => props.linear && css`
    .step {
      flex-direction: column !important;
      justify-content: flex-start !important;
      &:not(:first-child) {
        &:before {
          content: '' !important;
          position: absolute !important;
          transform: translateY(-50%) translateX(-50%) !important;
          top: 32px !important;
          width: 100% !important;
          height: 5px !important;
          z-index: 0 !important;
          background-color: transparent !important;
        }
      }
      &:after {
        content: '' !important;
        position: absolute !important;
        transform: translateY(-50%) translateX(50%) !important;
        top: 32px !important;
        width: 100% !important;
        height: 5px !important;
        z-index: 0 !important;
        background-color: ${DARK_GREEN} !important;
      }
      .bj-icon-circle {
        border-radius: 100%;
        width: 35px;
        height: 35px;
        font-size: 20px;
        line-height: 20px;
        background-color: ${DARK_GREEN};
        z-index: 1;
        position: relative;
        span {
          position: absolute;
          transform: translateY(-50%) translateX(50%) !important;
          top: 50%;
          right: 50%;
          font-size: 1em;
          font-weight: bold;
          color: ${WHITE};
        }
      }
      .icon {
        color: ${WHITE} !important;
        margin: 0 !important;
        z-index: 1 !important;
        position: absolute !important;
        transform: translateY(-50%) translateX(50%) !important;
        top: 50% !important;
        right: 50% !important;
      }
      .description {
        color: ${GRAY_1} !important;
        text-align: center !important;
      }
      &.active {
        &:not(:first-child) {
          &:before {
            background-image: linear-gradient(to right, transparent, ${DARK_BLUE}) !important;
          }
        }
        &:after {
          background-color: ${GRAY_14} !important;
        }
        .bj-icon-circle {
          background-color: ${DARK_BLUE} !important;
        }
        .description {
          color: ${DARK_BLUE} !important;
        }
      }
      &.disabled {
        &:not(:first-child) {
          &:before {
            background-image: linear-gradient(to right, transparent, ${GRAY_14}) !important;
          }
        }
        &:after {
          content: '' !important;
          position: absolute !important;
          transform: translateY(-50%) translateX(50%) !important;
          top: 32px !important;
          width: 100% !important;
          height: 5px !important;
          z-index: 0 !important;
          background-color: ${GRAY_14} !important;
        }
        .bj-icon-circle {
          background-color: ${GRAY_14} !important;
        }
        .description {
          color: ${GRAY_13} !important;
        }
      }
    }
  `}
`;


// Step.Title

const StepBJTitle = ({ color, ...other }) => <Step.Title id="bj-step-title" {...other} />;

StepBJTitle.propTypes = {
  color: PropTypes.string,
};

const StyledStepTitle = styled(StepBJTitle)`
  ${(props) => props.color && css`
    &#bj-step-title {
      font-size: 14px !important;
      font-weight: bold !important;
      padding: 0 !important;
      border: none !important;
      color: ${props.color} !important;
      background-color: transparent !important;
    }
  `}
`;


// Step.Content

const StepBJContent = ({ ...other }) => <Step.Content id="bj-step-content" {...other} />;

const StyledContent = styled(StepBJContent)`
  &#bj-step-content {
    padding: 0 !important;
  }
`;


// Step

const StyledStep = styled(Step)`
  padding: 14px !important;
  border-right: none !important;
  background: transparent !important;
  &.disabled {
    i {
      color: ${LIGHT_GRAY} !important;
    }
  }
  &:after {
    border: none !important;
    border-width: none !important;
    width: unset !important;
    height: unset !important;
    background-color: transparent !important;
    content: url(${flechaOscura}) !important;
    transform: translateY(-50%) translateX(50%) !important;
  }
  &.disabled:after {
    border: none !important;
    border-width: none !important;
    width: unset !important;
    height: unset !important;
    background-color: transparent !important;
    content: url(${flecha}) !important;
    transform: translateY(-50%) translateX(50%) !important;
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: row !important;
    .icon {
      margin: 0 !important;
      margin-right: 7px !important;
    }
  }

  .icon {
    margin-right: 7px !important;
  }
`;

StyledStep.Content = StyledContent;
StyledStep.Description = Step.Description;
StyledStep.Group = StyledStepGroup;
StyledStep.Title = StyledStepTitle;

export default StyledStep;
