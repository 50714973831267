import styled from 'styled-components';
import { Message } from 'semantic-ui-react';
import { ORANGE_1, ORANGE_2, GRAY_1 } from 'styles/variables';

const StyledMessage = styled(Message)`
  border-radius: 0 !important;
  box-shadow: none !important;
  border: 1px solid !important;
  .icon {
    font-size: 1.5em !important;
  }
  
  &.warning {
    border-color: ${ORANGE_1} !important;
    background-color: ${ORANGE_2} !important;
    color: ${GRAY_1} !important;
    .header {
      color: ${ORANGE_1} !important;
    }
    .icon {
      color: ${ORANGE_1} !important;
    }
  }
`;

StyledMessage.Content = Message.Content;
StyledMessage.Header = Message.Header;
StyledMessage.Item = Message.Item;
StyledMessage.List = Message.List;

export default StyledMessage;
