import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import { startsWith } from 'lodash';
import { Responsive } from 'components/Semantic';
import { Link } from 'components/Navigation';
import settings from 'settings';

import logoDefault from 'images/logo-header.svg';
import logoDefaultFJ from 'images/logo-header-fj.svg';
import logoDefaultCJ from 'images/logo-header-cj.svg';
import logoMobile from 'images/logo/mobile.svg';

import messages from './messages';

import './header.css';

const Logo = (props) => {
  const { intl } = props;

  const pais = settings.name;

  let logoHeader = logoDefault;
  if (startsWith(settings.defaultLocale, 'en')) {
    logoHeader = logoDefaultFJ;
  } else if (startsWith(settings.defaultLocale, 'it')) {
    logoHeader = logoDefaultCJ;
  }
  
  if (pais) {
    // Indica si la fecha cae entre el 08/12 y el 08/01 para mostrar el logo navideño
    const today = moment();
    const firstDayOfXmas = moment().date(8).month(11).year(today.year());
    const lastDayOfXmas = moment().date(8).month(0).year(today.year());
    const isXmasTime = (today.month() === 11 && today.isAfter(firstDayOfXmas)) || (today.month() === 0 && today.isBefore(lastDayOfXmas));
  
    if (isXmasTime) {
      logoHeader = require(`images/logo/xmas/${pais}-xmas.svg`).default;
    } else {
      logoHeader = require(`images/logo/${pais}.svg`).default;
    }
  }

  return (
    <div className="headerLogo">
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Link external to="/" className="logo">
          <img
            src={logoHeader}
            alt={intl.formatMessage(messages.appBannerTitle)}
            width={277}
            height={70}
          />
        </Link>
      </Responsive>
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
        <Link external to="/" className="logo">
          <img
            src={logoMobile}
            alt={intl.formatMessage(messages.appBannerTitle)}
            width={55}
            height={55}
          />
        </Link>
      </Responsive>
    </div>
  );
};

Logo.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Logo);
