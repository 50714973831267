import { defineMessages } from 'react-intl';

export default defineMessages({
  embellecerButton: {
    id: 'app.components.BJComponents.RichTextEditor.Toolbar.embellecerButton',
    defaultMessage: 'Mejorar formato',
  },
  embellecerOmitir: {
    id: 'app.components.BJComponents.RichTextEditor.Toolbar.embellecerOmitir',
    defaultMessage: 'Mantener versión original',
  },
  embellecerGuardar: {
    id: 'app.components.BJComponents.RichTextEditor.Toolbar.embellecerGuardar',
    defaultMessage: 'Cambiar a versión mejorada',
  },
  embellecerLoadingMessage: {
    id: 'app.components.BJComponents.RichTextEditor.Toolbar.embellecerLoadingMessage',
    defaultMessage: 'Estamos aplicando la magia, por favor espera...',
  },
  embellecerOriginal: {
    id: 'app.components.BJComponents.RichTextEditor.Toolbar.embellecerOriginal',
    defaultMessage: 'Texto original',
  },
  embellecerNuevo: {
    id: 'app.components.BJComponents.RichTextEditor.Toolbar.embellecerNuevo',
    defaultMessage: 'Texto mejorado',
  },
  embellecerError: {
    id: 'app.components.BJComponents.RichTextEditor.Toolbar.embellecerError',
    defaultMessage: 'En este momento no podemos procesar su solicitud. Intente nuevamente más tarde.',
  },
  MAX_TOKENS_EXCEEDED: {
    id: 'app.components.BJComponents.RichTextEditor.Toolbar.embellecerError.MAX_TOKENS_EXCEEDED',
    defaultMessage: 'El texto es muy largo para poder ser procesado.',
  },
  embellecerReintentar: {
    id: 'app.components.BJComponents.RichTextEditor.Toolbar.embellecerReintentar',
    defaultMessage: 'Reintentar',
  },
});
