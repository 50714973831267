const settings = {
  all: {
    apiBaseUrl: 'https://api.buscojobs.com',
    audience: 'beta.buscojobs.com',
    apiVersion: 'v3',
    hotjar: {
      trackingId: '1333623',
      version: '6',
    },
    discapacidadesEmpresa: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
  },
  ar: {
    analytics: {
      trackingId: 'UA-7826427-1',
      measurementId: 'G-QRCH2HSPSV',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '5dc4bdad8ecf79b28acbb3e002280dcb48b19ff2d7f16407a47120f58d2db466276b7e24a9b1f5fc0c9d85cf7e3b0720',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  au: {
    analytics: {
      trackingId: 'UA-128690725-1',
      measurementId: 'G-VTZCPCYTVN',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '07fcb72ba9e5566ca237e2a002a1842bad42084e43c0409bb355af33a475429a',
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  bo: {
    analytics: {
      trackingId: 'UA-18261833-1',
      measurementId: 'G-CCV2C25T3J',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: 'c52b0c17655f98ef6cdcae851b25331b545c251276d0fff9520d4ffdcbbe97ce8c7055f6068d5a97f50da40475195e04',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  br: {
    analytics: {
      trackingId: 'UA-12302684-1',
      measurementId: 'G-E22FWT320S',
    },
    zohoChat: true,
    zohoChatCode: '5dc4bdad8ecf79b28acbb3e002280dcb12a8f4d796d77e6851ee7cf79e757fd4276b7e24a9b1f5fc0c9d85cf7e3b0720',
    danchiano: true,
    nuevosSubcanales: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  cl: {
    analytics: {
      trackingId: 'UA-18254989-1',
      measurementId: 'G-B7DZ7KQ3PV',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: 'c52b0c17655f98ef6cdcae851b25331b3edd06e0b3aa312e11d4f855854b8d368c7055f6068d5a97f50da40475195e04',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  co: {
    analytics: {
      trackingId: 'UA-17473751-1',
      measurementId: 'G-220C14XTTF',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '5dc4bdad8ecf79b28acbb3e002280dcb6e03d78d300ded482f1eb87eb451c417276b7e24a9b1f5fc0c9d85cf7e3b0720',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  cr: {
    analytics: {
      trackingId: 'UA-20681228-1',
      measurementId: 'G-BRDWDPCPQF',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: 'bcbe59cc1da4141efcb87a2d52a6d74162175c374f1febefaa5ba12bb5713249',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  do: {
    analytics: {
      trackingId: 'UA-22429209-1',
      measurementId: 'G-2HP7Z1QLGD',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: 'c52b0c17655f98ef6cdcae851b25331bfd53acd999031377e1548fd1d51ec2978c7055f6068d5a97f50da40475195e04',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  ec: {
    analytics: {
      trackingId: 'UA-16882560-1',
      measurementId: 'G-P0PDD4LBLM',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: 'fd870270809575b6517ca051d27cda3b85f9316f90ff9b31232a1853bd4b2cdc05110beaadb11b0767b11919796178e2',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    facebookPixelId: '508076406388098',
    extensionCVs: true,
    transferencias: {
      banco: "Produbanco",
      tipoCuenta: "Cuenta Corriente",
      moneda: "USD",
      nroCuenta: "2005262298",
      razonSocial: "Buscojobs Ecuador Cia Ltda",
      ruc: "1792521971001",
      mail: "ecuador@buscojobs.com"
    },
    blog: true,
    featuresGPT: true,
  },
  es: {
    analytics: {
      trackingId: 'UA-8499585-1',
      measurementId: 'G-DK6H9XFNLQ',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '5dc4bdad8ecf79b28acbb3e002280dcb35fa32c602cee7bd8292d6d3207af249276b7e24a9b1f5fc0c9d85cf7e3b0720',
    listadosCargos: true,
    apiBaseUrl: 'https://api-eu.buscojobs.com',
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    featuresGPT: true,
  },
  gh: {
    analytics: {
      trackingId: 'UA-149283263-1',
      measurementId: 'G-HRKEPJCYSC',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '07fcb72ba9e5566ca237e2a002a1842beeee157dcc12510fbcd0a16b999f59da',
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  gt: {
    analytics: {
      trackingId: 'UA-20680844-1',
      measurementId: 'G-KQYHSMMCSX',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '5dc4bdad8ecf79b28acbb3e002280dcb02bf8b0c425f3dcb25852a42481e1546276b7e24a9b1f5fc0c9d85cf7e3b0720',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  hn: {
    analytics: {
      trackingId: 'UA-20678767-1',
      measurementId: 'G-HSTGTM7Z1J',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '5dc4bdad8ecf79b28acbb3e002280dcb2b55a0cbb0e41de3e22e7b833718b958276b7e24a9b1f5fc0c9d85cf7e3b0720',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  id: {
    analytics: {
      trackingId: 'UA-149273972-1',
      measurementId: 'G-H09CGPC25K',
    },
    danchiano: true,
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  ie: {
    analytics: {
      measurementId: 'G-WZVWN0CL1C',
    },
    apiBaseUrl: 'https://api-eu.buscojobs.com',
    danchiano: true,
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  in: {
    apiBaseUrl: 'https://api-eu.buscojobs.com',
    analytics: {
      trackingId: 'UA-129084427-1',
      measurementId: 'G-J2BB7G34F4',
    },
    danchiano: true,
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  it: {
    analytics: {
      trackingId: 'UA-129274573-1',
      measurementId: 'G-YLRXCEGH7M',
    },
    apiBaseUrl: 'https://api-eu.buscojobs.com',
    danchiano: false,
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  jm: {
    analytics: {
      trackingId: 'UA-149273669-1',
      measurementId: 'G-2KS9SWB6KP',
    },
    danchiano: true,
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  ke: {
    analytics: {
      trackingId: 'UA-149275084-1',
      measurementId: 'G-THHVNNRSBR',
    },
    danchiano: true,
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  mx: {
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '5dc4bdad8ecf79b28acbb3e002280dcbe19cdd4a9ade70c4acbd4b6e56bceb71276b7e24a9b1f5fc0c9d85cf7e3b0720',
    listadosCargos: true,
    analytics: {
      trackingId: 'UA-16882558-1',
      measurementId: 'G-ETJ75Z6YRD',
    },
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    hasApp: false,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  my: {
    analytics: {
      trackingId: 'UA-149287817-1',
      measurementId: 'G-STTD134LSR',
    },
    danchiano: true,
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  ng: {
    analytics: {
      trackingId: 'UA-149281853-1',
      measurementId: 'G-5S5R3K1HQD',
    },
    danchiano: true,
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  ni: {
    analytics: {
      trackingId: 'UA-20681246-1',
      measurementId: 'G-Q9T6DF4X52',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: 'c52b0c17655f98ef6cdcae851b25331bd271e1653373afdebd57ae44dd83ad188c7055f6068d5a97f50da40475195e04',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  nz: {
    analytics: {
      trackingId: 'UA-128656229-1',
      measurementId: 'G-4KJB8DRCGR',
    },
    danchiano: true,
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  pa: {
    analytics: {
      trackingId: 'UA-20683508-1',
      measurementId: 'G-DN572HJEWQ',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: 'c52b0c17655f98ef6cdcae851b25331b0c5e9eeac92c7f20688bfcf6f88d4ef18c7055f6068d5a97f50da40475195e04',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    facebookPixelId: '485445977236006',
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  pe: {
    analytics: {
      trackingId: 'UA-15731998-1',
      measurementId: 'G-3LDSH05TB7',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: 'c52b0c17655f98ef6cdcae851b25331b8cdc7913c6132c514ed77ec1e65127f88c7055f6068d5a97f50da40475195e04',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  ph: {
    analytics: {
      trackingId: 'UA-129912844-1',
      measurementId: 'G-FB895J476D',
    },
    danchiano: true,
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  pr: {
    analytics: {
      trackingId: 'UA-22425859-1',
      measurementId: 'G-N173YRFX97',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: 'c52b0c17655f98ef6cdcae851b25331bcc8603b7c50de7ee3c1590cd04236c5f8c7055f6068d5a97f50da40475195e04',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  pt: {
    analytics: {
      trackingId: 'UA-106819881-1',
      measurementId: 'G-TH5NRE8WN9',
    },
    apiBaseUrl: 'https://api-eu.buscojobs.com',
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '07fcb72ba9e5566ca237e2a002a1842bc8edd5a6aacd05dbebc70bdca67e5858',
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  py: {
    analytics: {
      trackingId: 'UA-18221982-1',
      measurementId: 'G-SBS3JLXPR2',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: 'c52b0c17655f98ef6cdcae851b25331b170e02a203e35e782a4e0cb69cd8fdfd8c7055f6068d5a97f50da40475195e04',
    listadosCargos: true,
    facebookPixelId: '192144221200486',
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  sv: {
    analytics: {
      trackingId: 'UA-20680841-1',
      measurementId: 'G-EEM2MR3J8E',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '5dc4bdad8ecf79b28acbb3e002280dcbe1951b7c9aa0137ac001f79f1913a52c276b7e24a9b1f5fc0c9d85cf7e3b0720',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  uk: {
    analytics: {
      measurementId: 'G-YM0D4Q1L0K',
    },
    apiBaseUrl: 'https://api-eu.buscojobs.com',
    danchiano: true,
    nuevosSubcanales: true,
    featuresGPT: true,
  },
  us: {
    analytics: {
      trackingId: 'UA-48510926-1',
      measurementId: 'G-MKB97JLWZJ',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '07fcb72ba9e5566ca237e2a002a1842b78e2853c939cd867527916292295ad69',
    nuevosSubcanales: true,
    blog: true,
    featuresGPT: true,
  },
  uy: {
    danchiano: true,
    centralTest: false,
    centralTestComprar: false,
    analytics: {
      trackingId: 'UA-2070227-1',
      measurementId: 'G-24RF197EBJ',
    },
    hotjar: {
      trackingId: '1333623',
      version: '6',
    },
    zohoChat: true,
    zohoChatCode: 'b26b865877e09d905ba9acc0dd832d8ea2c34028a035918901fb0f90199d7b31',
    modalidadLiberados: true,
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    extensionCVs: true,
    transferencias: {
      banco: "Banco Itaú",
      tipoCuenta: "Cuenta Corriente",
      moneda: "$",
      nroCuenta: "2052133",
      razonSocial: "Tecnovens S.A.",
      mail: "informacion@buscojobs.com.uy"
    },
    blog: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    featuresGPT: true,
    mejorarServicio: true,
  },
  ve: {
    analytics: {
      trackingId: 'UA-18255692-1',
      measurementId: 'G-20HHXGJBBD',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '07fcb72ba9e5566ca237e2a002a1842ba9cb5d2b457c8f239e1047ae35dbb45c',
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    formacion: {
      enabled: false,
      url: "https://static.buscojobs.com/bj-recommender-front/latest/BJRecommender.js",
    },
    blog: true,
    featuresGPT: true,
  },
  za: {
    apiBaseUrl: 'https://api-eu.buscojobs.com',
    analytics: {
      trackingId: 'UA-127168084-1',
      measurementId: 'G-GZFDHRB8BH',
    },
    danchiano: true,
    zohoChat: true,
    zohoChatCode: '726eeb8cafc1025f228633f0cbf4b91eb6cf97961ae253cad5d288bfdc1a18f0',
    nuevosSubcanales: true,
    featuresGPT: true,
  },
};

export default settings;
