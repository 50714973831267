import { assign, isArray, isNil, mapValues, omitBy } from 'lodash';

export function mapDataIds(data) {
  if (isArray(data)) {
    return data.map((d) => parseInt(d.id, 10));
  } else if (!isNil(data)) {
    return parseInt(data.id, 10);
  }
  return null;
}

export function mapApiObject(datum) {
  const obj = {
    Id: datum.id,
    ...omitBy(datum.attributes, isNil),
  };

  if (datum.relationships) {
    const related = mapValues(datum.relationships, (rel) => mapDataIds(rel.data));
    assign(obj, omitBy(related, isNil));
  }

  if (datum.meta) {
    assign(obj, datum.meta);
  }
  return obj;
}
