import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Container, Icon, Dimmer } from 'semantic-ui-react';
import { BACKGROUND_GRAY, MOBILE, TABLET, COMPUTER, LARGE_SCREEN } from 'styles/variables';

import FaracoHandEOT from 'styles/fonts/Faraco_Hand.eot';
import FaracoHandTTF from 'styles/fonts/Faraco_Hand.ttf';

export const StyledSidebar = styled.div`
  margin-top: 68px !important;
`;

export const MenuIcon = styled(Icon)`
  float: left !important;
  margin: 0 1em 0 0 !important;
`;

export const StyledDimmable = styled(Dimmer.Dimmable)`
  background-color: ${BACKGROUND_GRAY};
  min-height: 100vh;
  display: flex !important;
  flex-direction: column !important;
`;

export const ErrorDiv = styled.div`
  width: 100%;
  z-index: 1000;
  margin-top: 70px;
  @media only screen and (min-width: ${TABLET.minWidth}px) {
    position: fixed;
  }
  @media only screen and (min-width: ${COMPUTER.minWidth}px) {
    position: fixed;
    margin-top: 81px;
  }
`;

const Wrapper = ({ wide, ...other }) => <Container {...other} />;

Wrapper.propTypes = {
  wide: PropTypes.bool,
};

export const WrapperContainer = styled(Wrapper)`
  // All browsers except IE
  @supports not (-ms-high-contrast: none) {
    flex: 1 !important;
  }
  margin-top: 14px;
  @media only screen and (min-width: ${TABLET.minWidth}px) {
    margin-top: 6em;
  }
  &#wrapper {
    @media only screen and (max-width: ${MOBILE.maxWidth}px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  &.wrapperWhite {
    #pageContainer {
      padding: 10px;
      background-color: white;
    }
  }
  ${(props) => props.wide && css`
    @media only screen and (min-width: ${LARGE_SCREEN.minWidth}px) {
      &#wrapper {
        width: 100%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  `}
`;

export const PageContainer = styled.div`
  flex: 1 0 auto;
  z-index: 1;

  @font-face {
    font-family: 'Faraco Hand';
    src: url(${FaracoHandEOT}); /* EOT file for IE */
  }

  @font-face {
    font-family: 'Faraco Hand';
    src: url(${FaracoHandTTF}); /* TTF file for CSS3 browsers */
  }
`;
