import * as variables from './variables';

export const hoverBackgroundColor = (props) => {
  const color = props.color || props.mainColor;
  switch (color) {
    case variables.DARK_GREEN:
      if (props.basic) {
        return variables.GREEN_3;
      }
      return variables.GREEN_HOVER;
    case variables.LIGHT_GRAY:
      return variables.WHITE;
    case variables.GRAY_8:
      return variables.GRAY_5;
    case variables.GRAY_7:
      return variables.GRAY_10;
    case variables.GRAY_1:
      return variables.DARK_GRAY;
    case variables.ORANGE_1:
      return variables.ORANGE_HOVER;
    case variables.DARK_BLUE:
      return variables.BLUE_HOVER;
    case variables.RED:
      return variables.RED_HOVER;
    case variables.WHITE:
      return variables.WHITE;
    case variables.DANCHIANO:
      return variables.DARK_DANCHIANO;
    case variables.GREEN_DANCHIANO:
      return variables.DANCHIANO;
    case variables.PROCESOS_SELECCION_LAVANDER:
      return variables.PROCESOS_SELECCION_LIGHT;
    case variables.GALANTE_MARTINS:
      return variables.GALANTE_MARTINS_HOVER;
    default:
      return variables.BLUE_HOVER;
  }
};

export const fontColor = (props) => {
  const color = props.color || props.mainColor;
  switch (color) {
    case variables.WHITE:
      return variables.DARK_GREEN;
    case variables.LIGHT_GRAY:
      return variables.DARK_BLUE;
    case variables.GRAY_7:
      return variables.DARK_BLUE;
    case variables.GRAY_8:
      return variables.DARK_GREEN;
    default:
      return variables.WHITE;
  }
};

export const lightColor = (props) => {
  const color = props.color || props.mainColor;
  switch (color) {
    case variables.GRAY_1:
      return variables.GRAY_4;
    default:
      return variables.GRAY_4;
  }
};

export const contrastColor = (hexColor) => {
  let hexColorCopy = hexColor;

  // If a leading # is provided, remove it
  if (hexColor.slice(0, 1) === '#') {
    hexColorCopy = hexColor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexColorCopy.length === 3) {
    hexColorCopy = hexColorCopy.split('').map((hex) => `${hex}${hex}`).join('');
  }

  // Convert to RGB value
  const r = parseInt(hexColorCopy.substr(0, 2), 16);
  const g = parseInt(hexColorCopy.substr(2, 2), 16);
  const b = parseInt(hexColorCopy.substr(4, 2), 16);

  // Get YIQ ratio
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  // Check contrast
  return (yiq >= 128) ? variables.BLACK : variables.WHITE;
};
