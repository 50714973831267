import React from 'react';
import styled, { css } from 'styled-components';
import { Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { BLACK_1, WHITE } from '../../styles/variables';

const PopupBJ = ({ color, opacity, noBorder, bjstyle, ...other }) => {
  if (other.disabled) {
    return other.trigger;
  }
  return <Popup {...other} />;
};

PopupBJ.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
  noBorder: PropTypes.bool,
  opacity: PropTypes.number,
  bjstyle: PropTypes.bool,
};

const StyledPopup = styled(PopupBJ)`
  ${(props) => props.bjstyle && css`
    background: ${BLACK_1} !important;
    color: ${WHITE} !important;
    font-size: 12px !important;
    opacity: 1 !important;
    border: none !important;
    border-radius: 0 !important;
    ::before {
      background: ${BLACK_1} !important;
      border-radius: 0 !important;
    }
  `}
  ${(props) => props.noBorder && css`
    border-radius: 0 !important;
  `}
  ${(props) => props.color && css`
    color: ${props.color} !important;
  `}
  ${(props) => props.background && css`
    background: ${props.background} !important;
    ::before {
      background: ${props.background} !important;
    }
  `}
  ${(props) => props.opacity && css`
    opacity: ${props.opacity} !important;
  `}
`;

StyledPopup.Content = Popup.Content;
StyledPopup.Header = Popup.Header;

export default StyledPopup;
