/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import settings from 'settings';
import { DEFAULT_LOCALE } from '../App/constants';

const LanguageProvider = ({ messages, children }) => (
  <IntlProvider
    defaultLocale={DEFAULT_LOCALE}
    locale={settings.defaultLocale}
    key={settings.defaultLocale}
    messages={messages[settings.defaultLocale]}
  >
    {React.Children.only(children)}
  </IntlProvider>
);

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default LanguageProvider;
