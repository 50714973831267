import { v4 as uuidv4 } from 'uuid';
import { startsWith } from 'lodash';
import { makePost } from 'containers/DataLoader';
import { TRACK_EVENT } from './constants';

export function trackEvent(event) {
  const uuid = uuidv4();
  const force = document.cookie.split('; ').find((c) => startsWith(c, 'force'))?.split('=')[1] === "true";

  return makePost(TRACK_EVENT, {
    url: "/eventsTrack",
    body: {
      ...event,
      Uuid: event.Uuid ?? uuid,
      ForcedAccess: force ? 1 : 0,
    },
  });
}
