import styled from 'styled-components';
import { Input } from 'semantic-ui-react';
import { GRAY_1 } from 'styles/variables';

export default styled(Input)`
  input {
    border-radius: 0 !important;
  }
  [readonly] {
    border-color: rgba(34, 36, 38, 0.15) !important;
  }
  &.action {
    [readonly] {
      border-right: none !important;
    }
  *::placeholder {
    color: ${GRAY_1} !important;
  }
`;
