import { takeEvery, call, put, select } from 'redux-saga/effects';
import { isArray, isNil } from 'lodash';
import qs from 'qs';

import request from 'utils/request';
import { paisSelector } from 'containers/App/selectors';
import { selectToken } from 'containers/Auth/selectors';
import { API_REST_URL, API_REQUEST } from './constants';
import { handleResponse, handleError } from './actions';

function extractDataIds(data) {
  if (isArray(data)) {
    return data.map((d) => parseInt(d.id, 10));
  } else if (!isNil(data)) {
    return parseInt(data.id, 10);
  }
  return null;
}

function processQuery(query) {
  const q = query;
  if (q.include) {
    q.include = q.include.join(',');
  }
  if (q.sort) {
    q.sort = q.sort.join(',');
  }
  return q;
}

export function* apiCall(action) {
  let { url, query, ...options } = action.payload;
  const { raw, query: customCallName, ...otherMeta } = action.meta;

  if (customCallName) {
    yield put({ type: `${customCallName}_REQUEST`, ...otherMeta });
  }

  const pais = yield select(paisSelector);

  url = `${API_REST_URL}/${pais}${url}`;

  if (query) {
    url += `?${qs.stringify(processQuery(query))}`;
  }

  try {
    // Si es necesario obtiene el access token del state
    if (options.auth) {
      const token = yield select(selectToken);
      options.headers.Authorization = `Bearer ${token}`;
    }

    const response = yield call(request, url, options);
    if (response.data) {
      yield put(handleResponse(response, raw));
    }
    if (customCallName) {
      const customContent = {};
      if (response.data) {
        customContent.payload = raw ? response : extractDataIds(response.data);
        customContent.meta = response.meta;
      }

      const queryAction = { type: `${customCallName}_SUCCESS`, ...customContent };
      yield put(queryAction);
    }
  } catch (err) {
    yield put(handleError(err));
    if (customCallName) {
      yield put({ type: `${customCallName}_FAILURE`, payload: err });
    }
  }
}

export function* apiWatcher() {
  yield takeEvery(API_REQUEST, apiCall);
}

export default [apiWatcher];
