import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import darken from 'polished/lib/color/darken';
import getLuminance from 'polished/lib/color/getLuminance';
import { Label } from 'semantic-ui-react';
import { DARK_BLUE, BLACK, WHITE } from 'styles/variables';

const RibbonLabel = ({ backgroundColor, textColor, ...other }) => (
  <Label
    {...other}
  />);
RibbonLabel.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
};

const getTextColor = (backgroundColor) => getLuminance(backgroundColor) > 0.5 ? BLACK : WHITE;

const StyledLabel = styled(RibbonLabel)`
  background-color: ${(props) => props.backgroundColor || DARK_BLUE} !important;
  border-color: ${(props) => props.backgroundColor ? darken(0.51, props.backgroundColor) : darken(0.51, DARK_BLUE)} !important;
  color: ${(props) => props.textColor ? props.textColor : getTextColor(props.backgroundColor || DARK_BLUE)} !important;
  font-weight: normal !important;
  padding: 0.4em 0.7em !important;
  i {
    font-size: 14px !important;
  }
  .icon {
    margin: 0em 0.45em 0em 0em !important;
  }
  &.ui.ribbon.label:after {
    border-width: 0em 0.7em 0.7em 0em !important;
  }
  &.ui[class*="right ribbon"].label:after {
    border-width: 0.7em 0.7em 0em 0em !important;
  }
`;
export default StyledLabel;
