import { fromJS } from 'immutable';
import createReducer from 'utils/createReducer';
import { REPO_QUERY_REQUEST, REPO_QUERY_SUCCESS, REPO_QUERY_FAILURE, REPO_QUERY_COUNT } from '../constants';

const initialState = {
  data: null,
  loaded: false,
  loading: false,
  error: null,
  count: 0,
  page: 0,
  pageSize: 0,
};

export function makePaginationReducer(queryName) {
  return createReducer(initialState, {
    [REPO_QUERY_REQUEST]: (state, action) =>
      state
        .set('loading', true)
        .set('page', action.payload.page)
        .set('pageSize', action.payload.pageSize),

    [REPO_QUERY_SUCCESS]: (state, action) =>
      state
        .set('loading', false)
        .set('loaded', true)
        .set('data', fromJS(action.payload.data.result))
        .set('error', null),

    [REPO_QUERY_FAILURE]: (state, action) =>
      state
        .set('loading', false)
        .set('error', fromJS(action.payload.error)),

    [REPO_QUERY_COUNT]: (state, action) =>
      state
        .set('count', action.payload.count),

  }, (state, action) => action.payload && action.payload.name === queryName);
}
