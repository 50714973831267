import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card } from 'semantic-ui-react';

const CardBJ = ({ basic, ...other }) => <Card {...other} />;

CardBJ.propTypes = {
  basic: PropTypes.bool,
};

const StyledCard = styled(CardBJ)`
    {
      transform: none !important;
    }`;

StyledCard.Content = Card.Content;
StyledCard.Description = Card.Description;
StyledCard.Group = Card.Group;
StyledCard.Header = Card.Header;
StyledCard.Meta = Card.Meta;

export default StyledCard;
