import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import { Segment } from 'components/Semantic';
import { GRAY_1, GRAY_5, GRAY_8, DARK_GREEN } from 'styles/variables';


// Dropdown.Item

const BJDropdownItem = ({ text, src, icon, color, ...other }) => (
  <Dropdown.Item {...other}>
    {src ?
      <img src={src} alt="" height="14px" style={{ marginTop: 0 }} />
    :
      <i className={`${icon} icon`} aria-hidden="true" />
    }
    <span className="text">{text}</span>
  </Dropdown.Item>
);

BJDropdownItem.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  src: PropTypes.string,
  color: PropTypes.string,
};

const StyledDropdownItem = styled(BJDropdownItem)`
  color: ${(props) => props.color ?? DARK_GREEN} !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  font-style: normal !important;
  
  &:hover {
    background-color: ${GRAY_5} !important;
  }
`;


// Dropdown.Menu

const StyledDropdownMenu = styled(Dropdown.Menu)`
  width: 270px !important;
  border: none !important;
  border-radius: 0 !important;
  background-color: ${GRAY_8} !important;
`;


// Dropdown

const StyledDropdown = styled(Dropdown)`
  width: 270px !important;
  height: 100% !important;
  border-radius: 0 !important;
  color: ${DARK_GREEN} !important;
  font-size: 1.1rem !important;
  font-weight: bold !important;
  font-style: italic !important;
  
  &:hover {
    color: ${GRAY_1} !important;
  }
  
  &.active {
    color: ${GRAY_1} !important;
  }
  
  .dropdown.icon {
    float: right !important;
    vertical-align: sub !important;
  }
`;

StyledDropdown.Divider = Dropdown.Divider;
StyledDropdown.Header = Dropdown.Header;
StyledDropdown.Item = StyledDropdownItem;
StyledDropdown.Menu = StyledDropdownMenu;
StyledDropdown.SearchInput = Dropdown.SearchInput;

const ActionsDropdown = (props) => {
  const { title, actions, defaultOpen } = props;

  return (
    <Segment as={StyledDropdown} color={GRAY_8} text={title} defaultOpen={defaultOpen}>
      <StyledDropdown.Menu>
        {actions.map((action) => action)}
      </StyledDropdown.Menu>
    </Segment>
  );
};

ActionsDropdown.Divider = StyledDropdown.Divider;
ActionsDropdown.Header = StyledDropdown.Header;
ActionsDropdown.Item = StyledDropdown.Item;
ActionsDropdown.Menu = StyledDropdown.Menu;
ActionsDropdown.SearchInput = StyledDropdown.SearchInput;

ActionsDropdown.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array,
  defaultOpen: PropTypes.bool,
};

export default ActionsDropdown;
