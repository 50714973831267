import { defineMessages } from 'react-intl';

export default defineMessages({
  updateSuccess: {
    id: 'app.components.Notification.updateSuccess',
    defaultMessage: 'Se realizaron los cambios con éxito.',
  },
  defaultError: {
    id: 'app.components.Notification.defaultError',
    defaultMessage: 'Ha ocurrido un error al realizar la consulta',
  },
});
