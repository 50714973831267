import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Input } from 'components/Semantic';
import { DARK_BLUE, DARK_GREEN, WHITE } from 'styles/variables';

import messages from './messages';

const CopyInput = ({ value, intl, ...props }) => {
  const [copied, setCopied] = useState(false);

  return (
    <Input
      value={value}
      readOnly
      onFocus={(e) => e.target.select()}
      action={{
        icon: copied ? 'check' : 'copy',
        onClick: () => {
          navigator.clipboard?.writeText(value);
          setCopied(true);
        },
        title: copied ? intl.formatMessage(messages.copiado) : intl.formatMessage(messages.copiar),
        style: {
          color: copied ? DARK_GREEN : DARK_BLUE,
          backgroundColor: WHITE,
          border: `1px solid ${copied ? DARK_GREEN : DARK_BLUE}`,
        },
      }}
      {...props}
    />
  );
};

CopyInput.propTypes = {
  value: PropTypes.string,
  intl: intlShape.isRequired,
};

export default injectIntl(CopyInput);
