import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';
import { Segment, Grid } from 'semantic-ui-react';
import { BJMessage } from 'components/BJComponents';
import { Button } from 'components/Semantic';
import messages from './messages';

const ContainerSegment = styled(Segment)`
  min-height: 300px;
`;

const ErrorPage = ({ status, message, code }) => {
  if (window.ReactNativeWebView) {
    return (
      <ContainerSegment basic>
        <Error status={status} message={message} code={code} />
      </ContainerSegment>
    );
  }

  return (
    <Error status={status} message={message} code={code} />
  );
};

ErrorPage.propTypes = {
  status: PropTypes.number.isRequired,
  message: PropTypes.string,
  code: PropTypes.string,
};

ErrorPage.defaultProps = {
  status: 500,
};

const Error = injectIntl(({ status, message, code, intl }) => {
  let messageText = message;
  if (code && `ERROR_CODE_${code}` in messages) {
    messageText = intl.formatMessage(messages[`ERROR_CODE_${code}`]);
  }

  let title;
  let text;

  switch (status) {
    case 401:
    case 403:
      title = intl.formatMessage(messages.titulo403);
      text = intl.formatMessage(messages.texto403);
      break;
    case 404:
      title = intl.formatMessage(messages.titulo404);
      text = intl.formatMessage(messages.texto404);
      break;
    case 408:
      title = intl.formatMessage(messages.titulo408);
      text = intl.formatMessage(messages.texto408);
      break;
    default:
      title = intl.formatMessage(messages.titulo500);
      text = intl.formatMessage(messages.texto500);
  }

  return (
    <BJMessage
      type="warning"
      big
      header={title}
      text={
        <Grid verticalAlign="middle">
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={status >= 500 ? 10 : 16}
              computer={status >= 500 ? 12 : 16}
              largeScreen={status >= 500 ? 13 : 16}
              widescreen={status >= 500 ? 13 : 16}
            >
              <span style={{ overflowWrap: 'anywhere' }}>{messageText ?? text}</span>
            </Grid.Column>
            {status >= 500 ?
              <>
                <Grid.Column width={16} only="mobile" style={{ padding: '10px' }} />
                <Grid.Column mobile={16} tablet={6} computer={4} largeScreen={3} widescreen={3}>
                  <Button
                    fluid
                    style={{ padding: '10px' }}
                    onClick={() => {
                      // eslint-disable-next-line no-restricted-globals
                      location.reload();
                    }}
                  >
                    {intl.formatMessage(messages.reintentar)}
                  </Button>
                </Grid.Column>
              </>
            : null}
          </Grid.Row>
        </Grid>
      }
    />
  );
});

Error.propTypes = {
  status: PropTypes.number.isRequired,
  message: PropTypes.string,
  code: PropTypes.string,
  intl: intlShape.isRequired,
};

Error.defaultProps = {
  status: 500,
};

export default ErrorPage;
