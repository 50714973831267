import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyRouterMiddleware, Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { useScroll as scrollMiddleware } from 'react-router-scroll';
import { CookiesProvider } from 'react-cookie';
import moment from 'moment';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { hotjar } from 'react-hotjar';
import Cookies from 'universal-cookie';

import 'sanitize.css/sanitize.css';
import 'semantic-ui-css/semantic.css';

import 'moment/locale/es';
import 'moment/locale/pt';

import settings from 'settings';
import { makeSelectLocationState } from 'containers/App/selectors';
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicons, the manifest.json file, the browserconfig.xml and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
// import 'favicons';
// import '!file-loader?name=[name].[ext]!./bj-manifest.json';
// import '!file-loader?name=[name].[ext]!./fj-manifest.json';
// import '!file-loader?name=[name].[ext]!./cj-manifest.json';
// import '!file-loader?name=[name].[ext]!./browserconfig.xml';
// import 'file-loader?name=[name].[ext]!./.htaccess';
/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './store';
import reportWebVitals from './reportWebVitals';
// Import i18n messages
import { loadTranslationsMessages } from './i18n';
// Import root routes
import createRoutes from './routes';

require('./images/favicon.ico');

moment.locale(settings.defaultLocale);

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const initialState = {};
const store = configureStore(initialState, browserHistory);

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: makeSelectLocationState(),
});

window.reactRouterHistory = history;
const rootRoute = createRoutes(store);

// Obtenemos cookies
const cookies = new Cookies();

const force = cookies.get('force') === 'true';

const measurementId = settings?.analytics?.measurementId;
const trackingId = settings?.analytics?.trackingId;
// Si es dev o se forzo acceso no se hace tracking con analytics
const gaOptions = (settings.isDev || force) ? { testMode: true } : { titleCase: false };
if (measurementId) {
  ReactGA4.initialize([{
    trackingId: measurementId,
    gaOptions: { ...gaOptions, name: "conutry" },
    gtagOptions: { send_page_view: false }
  }, {
    trackingId: "G-8BCPQP10NR",
    gaOptions: { ...gaOptions, name: "general" },
    gtagOptions: { send_page_view: false }
  }]);
}
if (trackingId) {
  ReactGA.initialize(trackingId, gaOptions);
}

if (settings && settings.facebookPixelId) {
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init(settings.facebookPixelId, {}, options);
}

// Hotjar
const hotjarOptions = settings.hotjar || {};
if (!force && hotjarOptions.trackingId && hotjarOptions.version) {
  hotjar.initialize(hotjarOptions.trackingId, hotjarOptions.version);
}

// Tracking page view
function fireTracking() {
  if (measurementId) {
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }
  ReactGA.pageview(window.location.pathname + window.location.search);
  ReactPixel.pageView();
}

const render = (messages) => {
  const root = createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <CookiesProvider>
          <Router
            onUpdate={fireTracking}
            history={history}
            routes={rootRoute}
            render={
              // Scroll to top when going to a new page, imitating default browser
              // behaviour
              applyRouterMiddleware(scrollMiddleware())
            }
          />
        </CookiesProvider>
      </LanguageProvider>
    </Provider>
  );
};

// Hot reloadable translation json files
if (module.hot) {
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept('./i18n', () => {
    loadTranslationsMessages().then((messages) => {
      render(messages);
    });
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  (new Promise((resolve) => {
    resolve(import('intl'));
  }))
    .then(() => Promise.all([
      import('intl/locale-data/jsonp/en.js'),
      import('intl/locale-data/jsonp/es.js'),
    ]))
    .then(() => loadTranslationsMessages())
    .then((messages) => render(messages))
    .catch((err) => {
      throw err;
    });
} else {
  loadTranslationsMessages().then((messages) => {
    render(messages);
  });
}

reportWebVitals();

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (settings.env.isProd) {
//   require('offline-plugin/runtime').install(); // eslint-disable-line global-require
// }
