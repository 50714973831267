import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Label } from 'semantic-ui-react';
import { Icon, Popup } from 'components/Semantic';
import { ORANGE_1 } from 'styles/variables';
import ColorText from './ColorText';

const InfoLabel = ({ noPaddingLeft, noPaddingRight, noMarginRight, src, icon, title, value, hint, color, disabled, ...other }) => {
  const label = (
    <Label {...other} >
      <ColorText>
        {src ?
          <img src={src} alt="" height="24px" />
        :
          <Icon name={icon} />
        }
        {title}
      </ColorText>
      <Label.Detail>
        {value}
      </Label.Detail>
    </Label>
  );

  if (hint) {
    return (
      <Popup
        trigger={label}
        wide
        content={hint}
        bjstyle
        position="bottom right"
      />
    );
  }

  return label;
};

InfoLabel.propTypes = {
  noPaddingLeft: PropTypes.bool,
  noPaddingRight: PropTypes.bool,
  noMarginRight: PropTypes.bool,
  src: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.object,
  value: PropTypes.object,
  hint: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
};

const StyledLabel = styled(InfoLabel)`
  border: none !important;
  background-color: transparent !important;
  margin: 0em 0.5em 0em 0em !important;
  font-weight: normal !important;
  i {
    font-size: 14px !important;
  }
  .detail {
    font-size: 0.9rem;
    color: ${(props) => props.color ?? ORANGE_1};
    vertical-align: unset !important;
  }
  ${(props) => props.noPaddingLeft && css`
    padding-left: 0px !important;
  `}
  ${(props) => props.noPaddingRight && css`
    padding-right: 0px !important;
  `}
  ${(props) => props.noMarginRight && css`
    margin-right: 0 !important;
  `}
`;

export default StyledLabel;
