import { values } from 'lodash';

export const HOTKEYS = {
  b: 'bold',
  i: 'italic',
};

export const PARAGRAPH = 'paragraph';
export const BULLETED_LIST = 'bulleted-list';
export const NUMBERED_LIST = 'numbered-list';
export const LIST_ITEM = 'list-item';

export const LIST_TYPES = [BULLETED_LIST, NUMBERED_LIST];

export const TEXT_FORMATS = {
  PLAIN_TEXT: 'plain-text',
  MARKDOWN: 'markdown',
};

export const TEXT_FORMATS_LIST = values(TEXT_FORMATS);
