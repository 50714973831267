import { API_MEDIA_TYPE, API_REQUEST, API_SUCCESS, API_FAILURE } from './constants';

function makeRequest({ url, method, query, headers, body, auth }, meta = {}) {
  const payload = {};

  payload.url = url;
  payload.method = method; // .toLowerCase();
  payload.headers = { Accept: API_MEDIA_TYPE, ...headers };
  payload.query = query;
  payload.auth = !!auth;

  if (payload.method === 'post' || payload.method === 'put' || payload.method === 'PATCH') {
    const contentType = payload.headers['Content-Type'];
    if (contentType === 'multipart/form-data') {
      // Si estamos subiendo un archivo hay que evitar setear el Content-Type o stringificar el body para que funcione bien
      delete payload.headers['Content-Type'];
      payload.body = body;
    } else {
      payload.headers['Content-Type'] = contentType || API_MEDIA_TYPE;
      payload.body = JSON.stringify(body);
    }
  }

  return { type: API_REQUEST, payload, meta };
}

export function handleResponse(res, raw = false) {
  return { type: API_SUCCESS, payload: res, meta: { raw } };
}

export function handleError(err) {
  return { type: API_FAILURE, payload: err };
}

export function makeGet({ url, query, headers, auth }, meta) {
  const method = 'get';
  return makeRequest({ url, method, query, headers, auth }, meta);
}

export function makePost({ url, query, headers, body, auth }, meta) {
  const method = 'post';
  return makeRequest({ url, method, query, headers, body, auth }, meta);
}

export function makePatch({ url, query, headers, body, auth }, meta) {
  const method = 'PATCH';
  return makeRequest({ url, method, query, headers, body, auth }, meta);
}

export function makeDelete({ url, headers, auth }, meta) {
  const method = 'delete';
  return makeRequest({ url, method, headers, auth }, meta);
}
