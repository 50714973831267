import React from 'react';
import PropTypes from 'prop-types';
import { startsWith } from 'lodash';
import * as url from 'url';
import { withRouter, Link } from 'react-router';
import { APPLICATION_PATH } from 'containers/App/constants';

// eslint-disable-next-line react/prefer-stateless-function
class BJLink extends React.Component {

  static propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    external: PropTypes.bool,
    location: PropTypes.object,
    children: PropTypes.node,
  }

  render() {
    const { to, external, children, ...otherProps } = this.props;

    if (external) {
      return <a href={to} {...otherProps}>{children}</a>;
    }

    if (!to) {
      return <span {...otherProps}>{children}</span>;
    }

    let dest = to;

    if (typeof dest === 'string') {
      const { pathname, search } = url.parse(dest);
      dest = `${APPLICATION_PATH}${(startsWith(pathname, '/') ? '' : '/')}${pathname}${(search || '')}`;
    }

    return <Link to={dest} {...otherProps}>{children}</Link>;
  }
}

export default withRouter(BJLink);
