import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Label, Icon } from 'semantic-ui-react';
import { omit } from 'lodash';
import * as variables from 'styles/variables';
import { hoverBackgroundColor, fontColor } from 'styles/utils';
import Popup from './Popup';

const Chip = ({ content, icon, color, simple, readOnly, detailPopup, disabled, onClick, ...other }) => {
  const chip = (
    <Chip.Label
      color={color}
      simple={simple}
      readOnly={readOnly}
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      {...other}
    >
      {content}
      {icon && !readOnly && !disabled &&
        <Chip.Icon
          name={icon}
          circular
          simple={simple}
          color={color}
        />
      }
    </Chip.Label>
  );

  if (detailPopup) {
    return (
      <Popup
        trigger={chip}
        content={detailPopup}
        inverted
        flowing
        position="bottom center"
        basic
        bjstyle
        disabled={disabled}
      />
    );
  }
  return chip;
};

Chip.propTypes = {
  content: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  color: PropTypes.string,
  simple: PropTypes.bool,
  readOnly: PropTypes.bool,
  detailPopup: PropTypes.object,
  disabled: PropTypes.bool,
};

Chip.defaultProps = {
  color: variables.GRAY_7,
};

const ChipLabel = (props) => <Label {...omit(props, ['color', 'simple'])} />;
const ChipIcon = (props) => <Icon {...omit(props, ['color', 'simple'])} />;

Chip.Label = styled(ChipLabel)`
  float: none !important;
  border-radius: 100px !important;
  background-color: ${(props) => props.color} !important;
  color: ${(props) => fontColor(props)} !important;
  margin: 2px !important;
  padding: 0.3em 0.3em 0.3em 0.833em !important;
  font-weight: normal !important;
  
  ${(props) => props.readOnly ? css`
    padding: 0.5em 0.833em 0.5em 0.833em !important;
    height: 25.19px !important;
  ` : css`
    cursor: pointer !important;
    :hover {
      background-color: ${hoverBackgroundColor(props)} !important;
    }
  `}  
  ${(props) => props.disabled && css`
    cursor: unset !important;
  `}
  ${(props) => props.simple && css`
    border-radius: 100px !important;
    color: ${variables.GRAY_1} !important;
    background-color: transparent !important;
    margin: 2px !important;
    padding: 0.3em 0.3em 0.3em 0.833em !important;
    font-weight: normal !important;
    cursor: pointer !important;
    ${!props.readOnly && css`
      :hover {
        background-color: ${variables.GRAY_7} !important;
      }
    `}
  `}`;

Chip.Icon = styled(ChipIcon)`
  background-color: ${(props) => fontColor(props)} !important;
  color: ${(props) => props.color} !important;
  opacity: 1 !important;
  
  &.circular.icon {
    width: 1.2em !important;
    height: 1.2em !important;
    font-size: 15px !important;
    padding: 0.1em 0.1em !important;
  }
  ${(props) => props.simple && css`
    background-color: ${variables.GRAY_1} !important;
    color: ${variables.WHITE} !important;
    opacity: 1 !important;
    
    &.circular.icon {
      width: 1.2em !important;
      height: 1.2em !important;
      font-size: 15px !important;
      padding: 0.1em 0.1em !important;
    }
    }
  `}
`;

export default Chip;
