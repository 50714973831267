import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/Navigation';
import { Icon, Responsive } from 'components/Semantic';

import '../header.css';

const Button = (props) => {
  const { href, icon, img, text, simple, btnClass } = props;

  return (
    <div className={`button ${!simple ? 'loginButton' : ''}`}>
      <Link external to={href} className={`baseButton ${btnClass}`}>
        {icon ? (
          <Icon name={icon} />
        ) : img ? (
          <img alt={text} src={img} height={20} width={20} />
        ) : null}
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
          <span className="buttonText">{text}</span>
        </Responsive>
      </Link>
    </div>
  );
};

Button.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string,
  img: PropTypes.string,
  text: PropTypes.string,
  simple: PropTypes.bool,
  btnClass: PropTypes.string,
};

export default Button;
