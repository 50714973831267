/*
 * NotFoundPage Messages
 *
 * This contains all the text for the NotFoundPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.RestrictedPage.header',
    defaultMessage: 'No tiene permisos para realizar esta acción',
  },
  text: {
    id: 'app.components.RestrictedPage.text',
    defaultMessage: 'El recurso que está intentando acceder requiere permisos que usted no posee.',
  },
});
