import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { Form } from 'semantic-ui-react';
import ColorText from 'components/BJComponents/ColorText';
import { BJLoader } from 'components/BJComponents/BJLoader';
import { WHITE, LIGHT_GRAY, GRAY_1, GRAY_4, GRAY_5, GRAY_8, ORANGE_1, DARK_BLUE, GREEN_3, DARK_GREEN, MOBILE } from 'styles/variables';
import Button from './Button';
import Responsive from './Responsive';

import messages from './messages';


// Resposive Form Element Wrapper

const wrappFormElement = (FormElement, focusable) => {
  class ResposiveFormElementWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        scrollableElem: window,
      };
    }

    componentDidMount() {
      const elementWithScroll = document.querySelector('.scrolling.content');
      if (elementWithScroll) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ scrollableElem: elementWithScroll });
      }
    }

    render() {
      const { mobile, tablet, computer, largeScreen, widescreen, ...other } = this.props;

      if (mobile || tablet || computer || largeScreen) {
        return [
          focusable && this.props.type !== 'file' ?
            <Responsive
              key="mobile-form-element"
              // eslint-disable-next-line react/no-find-dom-node
              onFocus={() => this.state.scrollableElem.scrollTo(0, ReactDOM.findDOMNode(this).offsetTop)}
              as={FormElement}
              maxWidth={Responsive.onlyMobile.maxWidth}
              width={mobile}
              {...other}
            />
          :
            <Responsive
              key="mobile-form-element"
              as={FormElement}
              maxWidth={Responsive.onlyMobile.maxWidth}
              width={mobile}
              {...other}
            />,
          <Responsive
            key="tablet-form-element"
            as={FormElement}
            minWidth={Responsive.onlyTablet.minWidth}
            maxWidth={Responsive.onlyTablet.maxWidth}
            width={tablet}
            {...other}
          />,
          <Responsive
            key="computer-form-element"
            as={FormElement}
            minWidth={Responsive.onlyComputer.minWidth}
            maxWidth={Responsive.onlyComputer.maxWidth}
            width={computer}
            {...other}
          />,
          <Responsive
            key="largescreen-form-element"
            as={FormElement}
            minWidth={Responsive.onlyLargeScreen.minWidth}
            maxWidth={Responsive.onlyLargeScreen.maxWidth}
            width={largeScreen}
            {...other}
          />,
          <Responsive
            key="widescreen-form-element"
            as={FormElement}
            minWidth={Responsive.onlyWidescreen.minWidth}
            width={widescreen}
            {...other}
          />,
        ];
      }

      if (focusable && this.props.type !== 'file') {
        return (
          <React.Fragment>
            <Responsive
              as={FormElement}
              maxWidth={Responsive.onlyMobile.maxWidth}
              // eslint-disable-next-line react/no-find-dom-node
              onFocus={() => this.state.scrollableElem.scrollTo(0, ReactDOM.findDOMNode(this).offsetTop)}
              {...other}
            />
            <Responsive
              as={FormElement}
              minWidth={Responsive.onlyTablet.minWidth}
              {...other}
            />
          </React.Fragment>
        );
      }
      return <FormElement {...other} />;
    }
  }

  ResposiveFormElementWrapper.propTypes = {
    mobile: PropTypes.number,
    tablet: PropTypes.number,
    computer: PropTypes.number,
    largeScreen: PropTypes.number,
    widescreen: PropTypes.number,
    type: PropTypes.string,
  };

  return ResposiveFormElementWrapper;
};


// Form.Field

const FormFieldBJ = ({ success, ...other }) => <Form.Field {...other} />;

FormFieldBJ.propTypes = {
  success: PropTypes.bool,
};

const StyledFormField = styled(FormFieldBJ)`
  .field {
    padding-right: 0 !important;
    padding-left: 0 !important;

    ${(props) => props.success && css`
      input {
        border-color: ${GREEN_3} !important;
      }
      .dropdown {
        border-color: ${GREEN_3} !important;
      }
      textarea {
        border-color: ${GREEN_3} !important;
      }
    `}
  }
  @media only screen and (max-width: ${MOBILE.maxWidth}px) {
    font-size: 16px !important;
  }
`;


// Form.Checkbox

const FormCheckboxBJ = ({ yesNo, ...other }) => <Form.Checkbox {...other} />;

FormCheckboxBJ.propTypes = {
  yesNo: PropTypes.bool,
};

const StyledFormCheckbox = styled(FormCheckboxBJ)`
  input:checked ~ label:after {
    color: ${DARK_GREEN} !important;
  }
  label:before {
    border-color: ${GRAY_4} !important;
  }

  ${(props) => props.readOnly && css`
    input {
      cursor: default !important;
    }
    label {
      cursor: default !important;
    }
  `}

  ${(props) => props.yesNo && css`
    &.field {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  `}

  ${(props) => props.toggle && css`
    min-width: 60px !important;
    height: 26px !important;
    &.field label {
      color: ${GRAY_4} !important;
      font-family: Arial !important;
      font-size: 0.9em !important;
      min-width: 60px !important;
      height: 26px !important;
      padding-top: 5px !important;
    }
    label:before {
      background-color: ${LIGHT_GRAY} !important;
      width: 60px !important;
      height: 26px !important;
    }
    label:after {
      width: 20px !important;
      height: 20px !important;
      left: 3px !important;
      top: 3px !important;
      box-shadow: none !important;
      background-color: ${WHITE} !important;
      background: ${WHITE} !important;
    }
    &.field {
      .ui.toggle.checkbox input:checked {
        ~ label {
          color: ${GRAY_4} !important;
          font-family: Arial !important;
          font-size: 0.9em !important;
        }
        ~ label:before {
          background-color: ${props.color || DARK_BLUE} !important;
          ${props.simple && css`
            background-color: ${LIGHT_GRAY} !important;
          `}
        }
        ~ label:after {
          left: 37px !important;
        }
      }
    }
  `}
`;

const SpecialCheckbox = ({ yesNo, onChange, ...other }) => {
  if (yesNo) {
    return (
      <div
        role="presentation"
        style={{ position: 'relative', display: 'inline' }}
        onClick={(e) => onChange(e, { ...other, checked: !other.checked })}
      >
        {other.checked ?
          <span
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: WHITE,
              position: 'absolute',
              transform: 'translate(-50%,-50%)',
              left: '-3px',
              zIndex: '10',
              cursor: 'pointer',
            }}
          >
            <FormattedMessage {...messages.si} />
          </span>
        :
          <span
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: WHITE,
              position: 'absolute',
              transform: 'translate(-50%,-50%)',
              left: '15px',
              zIndex: '10',
              cursor: 'pointer',
            }}
          >
            <FormattedMessage {...messages.no} />
          </span>
        }
        <StyledFormCheckbox yesNo={yesNo} {...other} />
      </div>
    );
  }
  return <StyledFormCheckbox onChange={onChange} {...other} />;
};


// Form.Radio

const StyledFormRadio = styled(Form.Radio)`
  label:before {
    border-color: ${GRAY_4} !important;
  }
  .radio.checked {
    label:after {
      background-color: ${DARK_GREEN} !important;
    }
  }

  ${(props) => props.readOnly && css`
    input {
      cursor: default !important;
    }
    label {
      cursor: default !important;
    }
  `}
`;


// Form.Input

const FormInputBJ = ({ hintLabel, required, ...other }) => <Form.Input {...other} />;

FormInputBJ.propTypes = {
  hintLabel: PropTypes.bool,
  required: PropTypes.bool,
};

const StyledFormInput = styled(FormInputBJ)`
  ${(props) => props.hintLabel && css`
    &.field.field label {
      color: ${GRAY_4} !important;
      text-align: right !important;
      height: 13px !important;
      &::after {
        content: '' !important;
      }
    }
  `}
  ${(props) => props.required && css`
    label::after {
      content: '*' !important;
      color: ${ORANGE_1} !important;
    }
  `}
  ${(props) => !props.label && css`
    label::after {
      content: '' !important;
    }
  `}

  ${(props) => props.readOnly && css`
    input {
      color: ${GRAY_1} !important;
      background-color: ${GRAY_8} !important;
      font-style: italic !important;
      border-color: ${LIGHT_GRAY} !important;
    }
  `}
`;


// Form.TextArea

const StyledTextArea = styled(Form.TextArea)`
  ${(props) => props.readOnly && css`
    textarea {
      border-color: ${LIGHT_GRAY} !important;
    }
  `}
`;

const FormTextAreaBJ = ({ message, label, required, ...other }) => {
  if (message) {
    return (
      <React.Fragment>
        <ColorText medium>{label}</ColorText>
        {required && <ColorText color={ORANGE_1}>*</ColorText>}
        {message}
        <StyledTextArea required={required} {...other} />
      </React.Fragment>
    );
  }
  return (
    <StyledTextArea label={label} required={required} {...other} />
  );
};

FormTextAreaBJ.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  message: PropTypes.node,
};


// Form.Group

const FormGroupBJ = ({ floated, noMarginBottom, ...other }) => <Form.Group {...other} />;

FormGroupBJ.propTypes = {
  floated: PropTypes.string,
  noMarginBottom: PropTypes.bool,
};

const StyledFormGroup = styled(FormGroupBJ)`
  float: ${(props) => props.floated || 'unset'} !important;
  ${(props) => props.noMarginBottom && css`
    margin-bottom: 0 !important;
  `}
`;


// Form

const FormBJ = ({ reload, ...other }) => {
  if (reload) {
    return null;
  }

  return (
    <Form {...other} />
  );
}

FormBJ.propTypes = {
  reload: PropTypes.bool,
};

const StyledForm = styled(FormBJ)`
  &.loading::before {
    // Por defecto el color del background es GRAY_5
    background-color: ${GRAY_5}80 !important;
  }
  .field {
    label {
      color: ${GRAY_1} !important;
      font-weight: normal !important;
      ::after {
        color: ${ORANGE_1} !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        font-style: italic !important;
      }
    }
    input, textarea, .dropdown {
      border-radius: 0 !important;
      .menu {
        border-radius: 0 !important;
      }
    }
    *::placeholder {
      color: ${GRAY_1} !important;
    }
  }

  // Loader styles
  &.loading:after {
    ${BJLoader}
  }
`;

StyledForm.Field = wrappFormElement(StyledFormField, false);
StyledForm.Button = wrappFormElement(Button, false);
StyledForm.Checkbox = wrappFormElement(SpecialCheckbox, false);
StyledForm.Dropdown = wrappFormElement(Form.Dropdown, false);
StyledForm.Group = wrappFormElement(StyledFormGroup, false);
StyledForm.Input = wrappFormElement(StyledFormInput, true);
StyledForm.Radio = wrappFormElement(StyledFormRadio, false);
StyledForm.Select = wrappFormElement(Form.Select, false);
StyledForm.TextArea = wrappFormElement(FormTextAreaBJ, true);

export default StyledForm;
