import _ from 'lodash';
import { List } from 'immutable';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { denormalize } from 'normalizr';
import { selectApiEntities } from 'containers/Api/selectors';

export function selectObject(state) {
  return state.get('data', null);
}

export function selectList(state) {
  const data = state.get('data', List());
  return data === null ? List() : data;
}

export function selectNumber(state, defaultValue = 0) {
  const data = state.get('data', defaultValue);
  return data === null ? defaultValue : data;
}

export function selectPage(state) {
  return state.get('page');
}

export function selectPageSize(state) {
  return state.get('pageSize');
}

export function selectCount(state) {
  return state.get('count');
}

export function selectMaxPage(state) {
  const size = selectPageSize(state);
  const count = selectCount(state);
  return Math.ceil(count / size);
}

export const selectDenormalized = (selector, schema) =>
  createSelector([selectApiEntities, selector], (entities, state) => {
    const data = _.isArray(schema) ? selectList(state) : selectObject(state);
    return state.set('data', denormalize(data, schema, entities));
  });

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, _.isEqual);

export const selectDeepDenormalized = (selector, schema) =>
  createDeepEqualSelector([selectApiEntities, selector], (entities, state) => {
    const data = _.isArray(schema) ? selectList(state) : selectObject(state);
    return state.set('data', denormalize(data, schema, entities));
  });
