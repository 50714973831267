import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { omit, keys, isFunction, castArray } from 'lodash';
import { PageLoader } from 'components/BJComponents';
import { DataLoaderShape } from '../constants';

class DataFetcher extends React.Component {

  static propTypes = {
    fetch: PropTypes.func,
    waitFor: PropTypes.oneOfType([DataLoaderShape, PropTypes.arrayOf(DataLoaderShape)]),
    throwFor: PropTypes.oneOfType([DataLoaderShape, PropTypes.arrayOf(DataLoaderShape)]),
    spinner: PropTypes.node,
    children: PropTypes.node,
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const { router } = props;

    // IMPORTANTE: Cuando el usuario cambia de página hay que dejar de propagar el error, ya que
    //             el mismo evita que se rendericen las rutas y el usuario queda atrapado en la página del error.
    router.listen(() => {
      if (this.state.error) {
        this.setState({ error: null });
      }
    });
  }

  state = {
    error: null, // Guardamos el error a propagar, si corresponde
  }

  componentDidMount() {
    const { fetch } = this.props;

    if (isFunction(fetch)) {
      // Hacemos las llamadas al Api especificadas en la prop `fetch`
      fetch(this.otherProps);
    }
  }

  // Cada vez que una prop de `throwFor` cambia y resulta en error, nos guardamos el mismo en el state.
  // Es necesario guardarlo en el state para poder resetearlo una vez que la ruta cambie.
  componentDidUpdate(prevProps) {
    if (this.props.throwFor && prevProps.throwFor) {
      const throwFor = castArray(this.props.throwFor);
      const prevThrowFor = castArray(prevProps.throwFor);

      const fetcher = throwFor.find((f, i) => f.error && prevThrowFor[i].loading);
      if (fetcher) {
        this.setState({ error: fetcher.error });
      }
    }
  }

  get otherProps() {
    return omit(this.props, keys(DataFetcher.propTypes));
  }

  render() {
    if (this.state.error) {
      throw this.state.error;
    }

    if (this.props.waitFor) {
      const waitFor = castArray(this.props.waitFor);
      const fetcher = waitFor.find((f) => f.loading || !f.loaded);
      if (fetcher) {
        return this.props.spinner || <PageLoader />;
      }
    }

    return this.props.children;
  }
}

export default withRouter(DataFetcher);
