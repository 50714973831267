import { defineMessages } from 'react-intl';

export default defineMessages({
  si: {
    id: 'app.components.Semantic.si',
    defaultMessage: 'SI',
  },
  no: {
    id: 'app.components.Semantic.no',
    defaultMessage: 'NO',
  },
});
