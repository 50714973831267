import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { BJMessage } from 'components/BJComponents';
import messages from './messages';

const Restricted = (props) => (
  <BJMessage
    type="warning"
    big
    header={props.intl.formatMessage(messages.header)}
    text={<FormattedMessage {...messages.text} />}
  />
);

Restricted.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Restricted);
