import React,{ useEffect }  from 'react';
import settings from 'settings';
import { withRouter } from 'react-router';


const useScript = (url, widgetCode, props) => {
  const { nombre, empresaId, email, location } = props;

  const pathname = location.pathname

  const script = document.createElement('script');
  script.type = 'text/javascript';

  const pais = settings.name.toUpperCase();

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute("type", "text/javascript");

    let code = `
      var $zoho = $zoho || {}; $zoho.salesiq = $zoho.salesiq || {
        widgetcode: "${widgetCode}",
        values: {}, ready: function () {
          if ("${pathname}".includes('/postulados'))
            window.$zoho.salesiq.floatbutton.visible("hide");
          else
            window.$zoho.salesiq.floatbutton.visible("show");
          $zoho.salesiq.visitor.email("${email}");
          $zoho.salesiq.visitor.info({"empresa": "${nombre} - ${empresaId}"});
          $zoho.salesiq.floatbutton.coin.hidetooltip();
        }
      }; 
      var d = document;
      s = d.createElement("script");
      s.type = "text/javascript";
      s.id = "zsiqscript";
      s.defer = true;
      s.src = "${url}";
      t = d.getElementsByTagName("script")[0];
      t.parentNode.insertBefore(s, t);
      d.innerHTML = "<div id='zsiqwidget'></div>";
    `;
    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);

    return () => {
    document.body.removeChild(script);
    }
  }, [email, empresaId, pathname, nombre, pais, url, widgetCode]);

  useEffect(() => {
    if (pathname.includes('/postulados'))
      window.$zoho?.salesiq?.floatbutton?.visible("hide");
    else
      window.$zoho?.salesiq?.floatbutton?.visible("show");
  }, [pathname])
};

const ZohoChat = (props) => {
  return (
      <React.Fragment>
          {useScript('https://salesiq.zoho.com/widget', settings.zohoChatCode, props)}
      </React.Fragment>
  );
}

export default withRouter(ZohoChat);


////