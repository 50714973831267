import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Message, Image } from 'semantic-ui-react';
import { Icon } from 'components/Semantic';
import { Link } from 'components/Navigation';
import { WHITE, GRAY_1, GRAY_10, DARK_BLUE, COMPUTER, TABLET, MOBILE } from 'styles/variables';
import ColorText from './ColorText';

const StyledMessage = styled(Message)`
  border-radius: 0 !important;
  box-shadow: none !important;
  color: ${GRAY_1} !important;
  background-color: ${WHITE} !important;
  border: 1px ${GRAY_10} solid !important;
  padding: 0.25rem 0.5rem !important;
  margin: 1em 0 !important;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column;
  }
`;

const Text = styled.div`
  width: 85%;
  padding: 0.5rem;

  @media only screen and (max-width: ${COMPUTER.maxWidth}px) {
    width: 80%;
  }

  @media only screen and (max-width: ${TABLET.maxWidth}px) {
    width: 75%;
  }

  @media only screen and (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
  }
`;

const Img = styled.div`
  width: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: ${COMPUTER.maxWidth}px) {
    width: 20%;
  }

  @media only screen and (max-width: ${TABLET.maxWidth}px) {
    width: 25%;
  }

  @media only screen and (max-width: ${MOBILE.maxWidth}px) {
    width: 100%;
    justify-content: space-around;
    padding: 0.5rem;
  }
`;

const Banner = (props) => {
  const { logo, text, url, ...other } = props;

  return (
    <Link to={url} external target="_blank">
      <StyledMessage
        content={
          <Content>
            <Text>
              <ColorText>{text}</ColorText>
            </Text>
            <Img>
              <Image src={logo} style={{ height: '30px' }} />
              <Icon
                name="chevron right"
                color={DARK_BLUE}
                size="large"
                style={{
                  margin: '0 0 0 7px',
                }}
              />
            </Img>
          </Content>
        }
        {...other}
      />
    </Link>
  );
};

Banner.propTypes = {
  logo: PropTypes.string,
  text: PropTypes.node,
  url: PropTypes.string,
};

export default Banner;
