import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { GRAY_1, GRAY_5, GRAY_8, WHITE, MOBILE } from 'styles/variables';


// Modal.Content

const ModalContentBJ = ({ backgroundColor, noPadding, maxHeight, ...other }) => <Modal.Content scrolling {...other} />;

ModalContentBJ.propTypes = {
  backgroundColor: PropTypes.string,
  noPadding: PropTypes.bool,
  maxHeight: PropTypes.string,
};

const StyledModalContent = styled(ModalContentBJ)`
  background-color: ${(props) => props.backgroundColor || WHITE} !important;
  border-radius: 0 !important;
  overflow-x: hidden !important;

  ${(props) => props.noPadding && css`
    padding: 0 !important;
  `}

  ${(props) => props.maxHeight && css`
    max-height: ${props.maxHeight} !important;
  `}
`;


// Modal.Header

const ModalHeaderBJ = ({ color, backgroundColor, ...other }) => <Modal.Header {...other} />;

ModalHeaderBJ.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

const StyledModalHeader = styled(ModalHeaderBJ)`
  background-color: ${(props) => props.backgroundColor || GRAY_5} !important;
  border: none !important;
  border-radius: 0 !important;
  font-size: 1.2rem !important;
  color: ${(props) => props.color || GRAY_1} !important;
`;


// Modal.Actions

const StyledModalActions = styled(Modal.Actions)`
  background-color: ${GRAY_8} !important;
  border: none !important;
  border-radius: 0 !important;
`;


// Modal

const SemanticModal = ({ mobileFriendly, vh, backgroundColor, onClose, closeIcon = true, ...props }) => {
  const [canClose, setCanClose] = useState(true);

  return (
    <Modal
      onClose={(e, d) => {
        if (canClose && onClose) {
          onClose(e, d);
        }
        setCanClose(true);
      }}
      onMouseDown={() => setCanClose(false)}
      onMouseUp={() => setCanClose(true)}
      closeIcon={closeIcon}
      {...props}
    />
  );
};

SemanticModal.propTypes = {
  mobileFriendly: PropTypes.bool,
  vh: PropTypes.number,
  unrestrictedHeight: PropTypes.bool,
  backgroundColor: PropTypes.string,
  onClose: PropTypes.func,
  closeIcon: PropTypes.bool,
};

const StyledModal = styled(SemanticModal)`
  border-radius: 0 !important;
  height: 0 !important;

  ${(props) => !props.unrestrictedHeight && css`
    &.ui.modal .scrolling.content {
      max-height: calc(60vh);
    }
  `}

  ${(props) => props.mobileFriendly && css`
    @media(max-width: ${MOBILE.maxWidth}px) {
      .modals.dimmer > &.ui.scrolling.modal {
        margin: 0 !important;
        width: 100% !important;
        .scrolling.content {
          height: calc(${props.vh}px - 108px) !important;
          max-height: none !important;
        }
      }
    }
  `}
`;

StyledModal.Content = StyledModalContent;
StyledModal.Header = StyledModalHeader;
StyledModal.Actions = StyledModalActions;
StyledModal.Description = Modal.Description;


// Component

class ModalBJ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vh: window.innerHeight,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions, false);
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions, false);
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    const windowHeight = window.innerHeight;
    this.setState({ vh: windowHeight });
  }

  render() {
    return <StyledModal className="scrolling" {...this.props} vh={this.state.vh} />;
  }
}

ModalBJ.Content = StyledModal.Content;
ModalBJ.Header = StyledModal.Header;
ModalBJ.Actions = StyledModal.Actions;
ModalBJ.Description = StyledModal.Description;

export default ModalBJ;
