import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// eslint-disable-next-line jsx-a11y/anchor-has-content
const LinkBJ = ({ small, uppercase, color, hoverColor, underline, ...other }) => <span{...other} />;

LinkBJ.propTypes = {
  small: PropTypes.bool,
  color: PropTypes.string.isRequired,
  hoverColor: PropTypes.string,
  underline: PropTypes.bool,
  uppercase: PropTypes.bool,
};

const StyledLink = styled(LinkBJ)`
  ${(props) => props.small && css`
    font-size: 12px;
  `}
  ${(props) => props.underline && css`
    text-decoration: underline;
  `}
  ${(props) => props.hoverColor && css`
    &:hover {
      color: ${props.hoverColor} !important;
    } 
  `}
  ${(props) => props.color && css`
    color: ${props.color} !important;
  `}
  ${(props) => props.uppercase && css`
    text-transform: uppercase !important;
  `}
  
`;

export default StyledLink;
