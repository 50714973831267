import settings from 'settings';
import { getAsyncInjectors } from 'utils/asyncInjectors';
import { loadModules, renderNetworkError } from 'utils/moduleLoader';

export default function (store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store); // eslint-disable-line no-unused-vars

  const panelRoute = {
    name: 'empresa-panel',
    path: 'empresa/panel',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/MiBuscojobs'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const empresaRoute = {
    name: 'empresa-ver',
    path: 'empresa(/:seccion)',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/MiEmpresa'),
        import('components/MiCuenta'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const ofertasRoute = {
    name: 'empresa-oferta-todas',
    path: 'empresa/ofertas(/:seccion)',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/MisOfertas'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const publicarOfertaRoute = {
    name: 'empresa-oferta-publicar',
    path: 'empresa/publicar',
    getComponent(nextState, cb) {
      loadModules([
        import('containers/Empresa/PublicarOferta'),
        import('containers/Empresa/PublicarOferta/reducer'),
        import('containers/Empresa/PublicarOferta/sagas'),
        import('containers/Empresa/SelectorPaquetes/reducer'),
        import('containers/Empresa/SelectorPaquetes/sagas'),
        import('containers/Empresa/OfertaIngresar/reducer'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component, reducer, sagas, reducerSelector, sagasSelector, reducerIngresoOferta] = modules;
        injectReducer(this.name, reducer.default);
        injectReducer('selector-paquetes', reducerSelector.default);
        injectReducer('oferta-ingresar', reducerIngresoOferta.default);
        injectSagas(sagas.default);
        injectSagas(sagasSelector.default);
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const ofertaPostuladosRoute = {
    name: 'empresa-oferta-postulados',
    path: 'empresa/oferta-:nroOferta/postulados',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/OfertaPostulados'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const postulacionRoute = {
    name: 'empresa-postulacion',
    path: 'empresa/oferta-:idOferta/postulados/postulacion-:idPostulacion(/:seccion)',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/Postulacion'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const verResultadoTestCTRoute = {
    name: 'empresa-oferta-postulantes-ver-resultado-ct',
    path: 'empresa-:idEmpresa/oferta-:nroOferta/postulacion-:idPostulacion/postulante-test-:idPostulanteTest/:crearVisualizacion',
    getComponent(nextState, cb) {
      loadModules([
        import('containers/Empresa/VerResultadoTestCT'),
        import('containers/Empresa/TestsPsicometricos/reducer'),
        import('containers/Empresa/TestsPsicometricos/sagas'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component, reducer, sagas] = modules;

          injectReducer('empresa-tests-psicometricos', reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const verComparacionDARoute = {
    name: 'empresa-oferta-postulantes-ver-comparacion-da',
    path: 'empresa/oferta-:nroOferta/postulacion-:idPostulacion/ver-comparacion',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/VerComparacionDA'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const paginaPreOfertaDanchianoRoute = {
    name: 'empresa-pre-oferta-danchiano',
    path: 'empresa/oferta',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/PreOfertaDanchiano'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const paginaOfertaRoute = {
    name: 'empresa-oferta-ver',
    path: 'empresa/oferta-:nroOferta',
    getComponent(nextState, cb) {
      loadModules([
        import('containers/Empresa/Oferta'),
        import('containers/Empresa/Oferta/reducer'),
        import('containers/Empresa/Oferta/sagas'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component, reducerOferta, sagasOferta] = modules;

          injectReducer(this.name, reducerOferta.default);
          injectSagas(sagasOferta.default);
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const editarOfertaRoute = {
    name: 'empresa-oferta-editar',
    path: 'empresa/oferta-:nroOferta/editar',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/OfertaEditar'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const estadoCuentaRoute = {
    name: 'empresa-estado-cuenta',
    path: 'empresa/estado-cuenta',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/EstadoCuenta'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const transferenciaBancariaRoute = {
    name: 'transferencia-bancaria',
    path: 'empresa/pago-:idPago/transferencia',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/TransferenciaBancaria'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const funcionariosRoute = {
    name: 'empresa-funcionarios',
    path: 'empresa/funcionarios',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/Funcionarios'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const invitacionFuncionariosRoute = {
    name: 'empresa-funcionarios-invitacion',
    path: 'empresa/funcionarios/invitacion',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/InvitacionFuncionario'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const busquedaLibreRoute = {
    name: 'empresa-busqueda-libre',
    path: 'empresa/busqueda-libre',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/BusquedaBD'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const candidatoBusquedaLibreRoute = {
    name: 'empresa-candidato-busqueda-libre',
    path: 'empresa/busqueda-libre/postulante-:idPostulante',
    getComponent(nextState, cb) {
      loadModules([
        import('containers/Empresa/PostulanteBusquedaLibre'),
        import('containers/Empresa/PostulanteBusquedaLibre/reducer'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component, reducerPostulante] = modules;

          injectReducer(this.name, reducerPostulante.default);
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const historialPostulacionesRoute = {
    name: 'empresa-historial-postulaciones',
    path: 'empresa/mis-cvs',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/MisCurriculums'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const postulanteMisCVsRoute = {
    name: 'empresa-candidato-mis-cvs',
    path: 'empresa/mis-cvs/postulante-:idPostulante',
    getComponent(nextState, cb) {
      loadModules([
        import('containers/Empresa/PostulanteMisCVs'),
        import('containers/Empresa/PostulanteMisCVs/reducer'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component, reducerPostulante] = modules;

          injectReducer(this.name, reducerPostulante.default);
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const ofertasFuncionarioRoute = {
    name: 'empresa-ofertas-funcionario',
    path: 'empresa/funcionario-:idFuncionario/ofertas(/:seccion)',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/OfertasFuncionario'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const paginaPaqueteRoute = {
    name: 'empresa-paquete',
    path: 'empresa/paquete-:nroPaquete',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/Paquete'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const postulantesAdministradosRoute = {
    name: 'empresa-postulantes-administrados',
    path: 'empresa/busqueda-postulantes',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/CandidatosAdministrados'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const postulanteAdministradoRoute = {
    name: 'empresa-postulante-administrado',
    path: 'empresa/busqueda-postulantes/postulante-:idPostulante',
    getComponent(nextState, cb) {
      loadModules([
        import('containers/Empresa/PostulanteAdministrado'),
        import('containers/Empresa/PostulanteAdministrado/reducer'),
        import('containers/Empresa/PostulanteAdministrado/sagas'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component, reducerPostulante, sagasPostulante] = modules;

          injectReducer(this.name, reducerPostulante.default);
          injectSagas(sagasPostulante.default);
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const ofertaInvitarRoute = settings.invitarCandidatos ? {
    name: 'empresa-oferta-invitar',
    path: 'empresa/oferta-:nroOferta/invitar',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/OfertaInvitaciones'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  } : undefined;

  //

  const postulanteInvitacionRoute = {
    name: 'empresa-postulante-invitacion',
    path: 'empresa/oferta-:nroOferta/invitar/postulante-:idPostulante',
    getComponent(nextState, cb) {
      loadModules([
        import('containers/Empresa/PostulanteInvitacion'),
        import('containers/Empresa/PostulanteInvitacion/reducer'),
        import('containers/Empresa/PostulanteInvitacion/sagas'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component, reducer, sagas] = modules;

          injectReducer(this.name, reducer.default);
          injectSagas(sagas.default);
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const empresaConfiguracionRoute = {
    name: 'empresa-configuracion',
    path: 'empresa/configuracion(/:seccion)',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Empresa/Configuracion'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  return {
    childRoutes: [
      panelRoute,
      ofertaPostuladosRoute,
      postulacionRoute,
      publicarOfertaRoute,
      editarOfertaRoute,
      verResultadoTestCTRoute,
      verComparacionDARoute,
      paginaPreOfertaDanchianoRoute,
      paginaOfertaRoute,
      funcionariosRoute,
      invitacionFuncionariosRoute,
      busquedaLibreRoute,
      candidatoBusquedaLibreRoute,
      historialPostulacionesRoute,
      postulanteMisCVsRoute,
      ofertasFuncionarioRoute,
      estadoCuentaRoute,
      paginaPaqueteRoute,
      transferenciaBancariaRoute,
      postulantesAdministradosRoute,
      postulanteAdministradoRoute,
      ofertaInvitarRoute,
      postulanteInvitacionRoute,
      empresaConfiguracionRoute,
      ofertasRoute, // Esta ruta debe ir penúltima
      empresaRoute, // Esta ruta debe ir al final
    ].filter((r) => r),
  };
}
