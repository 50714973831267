import { schema } from 'normalizr';

export const entities = {};

const makeEntity = (type, idAttribute) => {
  const entity = new schema.Entity(type, {}, { idAttribute });
  entities[type] = entity;
  return entity;
};

export const Usuario = makeEntity('usuario', 'id');
export const Rol = makeEntity('rol', 'id');
export const Permiso = makeEntity('permiso', 'IdPermiso');
export const Empresa = makeEntity('empresa', 'IdEmpresa');
export const Postulante = makeEntity('postulante', 'IdPostulante');
export const Funcionario = makeEntity('funcionario', 'IdFuncionario');
export const Operador = makeEntity('operador', 'IdOperador');
export const Oferta = makeEntity('empresa_oferta', 'IdOferta');
export const Postulacion = makeEntity('postulacion', 'IdPostulacion');

export const EmpresaPaquete = makeEntity('empresa_paquete', 'IdEmpresaPaquete');
export const Paquete = makeEntity('paquete', 'IdPaquete');
export const Servicio = makeEntity('servicio', 'IdServicio');
export const PaqueteServicio = makeEntity('paquete_servicio', (value) => `${value.IdPaquete}-${value.IdServicio}`);
export const Pago = makeEntity('pago', 'IdPago');

export const Ambito = makeEntity('ambito', 'IdAmbito');
export const Rubro = makeEntity('rubro', 'IdRubro');
export const SubRubro = makeEntity('subrubro', 'IdSubRubro');
export const EstadoPostulacion = makeEntity('estado_postulacion', 'IdEstadoPostulacion');
export const Conocimiento = makeEntity('conocimiento', 'IdConocimiento');
export const Curso = makeEntity('curso', 'IdCurso');
export const Idioma = makeEntity('idioma', 'IdIdioma');
export const NivelIdioma = makeEntity('nivel_idioma', 'IdNivelIdioma');
export const NivelJerarquico = makeEntity('nivel_jerarquico', 'IdNivelJerarquico');
export const Pais = makeEntity('pais', 'IdPais');
export const Departamento = makeEntity('departamento', 'IdDepartamento');
export const Ciudad = makeEntity('ciudad', 'IdCiudad');
export const Destino = makeEntity('destino', 'IdDestino');
export const MetodoPago = makeEntity('metodo_pago', 'Nombre');
export const ModoPostulacion = makeEntity('modo_postulacion', 'IdModoPostulacion');
export const Cargo = makeEntity('cargo', 'IdCargo');

export const TestExterno = makeEntity('test_externo', 'IdTest');
export const SaldoTest = makeEntity('saldo_test', 'IdEmpresa');

export const OfertaPostulanteInvitacion = makeEntity('oferta_postulante_invitacion', (value) => `${value.IdOferta}-${value.IdPostulante}`);

export const GradoEstudio = makeEntity('grado_estudio', 'IdGradoEstudio');

export const AreaEstudio = makeEntity('area_estudio', 'IdAreaEstudio');

export const Institucion = makeEntity('institucion', 'IdInstitucion');

export const EstadoCivil = makeEntity('estado_civil', 'IdEstadoCivil');

export const TipoDocumento = makeEntity('tipo_documento', 'IdTipoDocumento');

export const Canal = makeEntity('canal', 'IdCanal');

export const SubCanal = makeEntity('subCanal', 'IdSubCanal');

export const Contrato = makeEntity('contrato', 'IdContrato');

export const JornadaLaboral = makeEntity('jornada_laboral', 'IdJornadaLaboral');

export const DisponibilidadCambioResidencia = makeEntity('disponibilidad_cambio_residencia', 'IdDisponibilidadCambioResidencia');

export const DisponibilidadViaje = makeEntity('disponibilidad_viaje', 'IdDisponibilidadViaje');

export const TipoPermisoConducir = makeEntity('tipo_permiso_conducir', 'IdTipoPermisoConducir');

export const TipoAyuda = makeEntity('tipo_ayuda', 'IdTipoAyuda');
export const DiscapacidadAyuda = makeEntity('discapacidad_ayuda', 'IdDiscapacidadAyuda');

export const TipoPublicacion = makeEntity('tipo_publicacion', 'clave');

export const PostulanteConocimiento = makeEntity('postulante_conocimiento', 'IdPostulanteConocimiento');
export const PostulanteExperiencia = makeEntity('postulante_experiencia', 'IdPostulanteExperiencia');
export const PostulanteEstudio = makeEntity('postulante_estudio', 'IdPostulanteEstudio');
export const PostulanteIdioma = makeEntity('postulante_idioma', 'IdPostulanteIdioma');
export const PostulanteReferencia = makeEntity('postulante_referencia', 'IdPostulanteReferencia');
export const PostulanteTestExterno = makeEntity('postulante_test_externo', 'IdPostulanteTestExterno');
export const PostulanteAspiracion = makeEntity('postulante_aspiracion', 'IdPostulanteAspiracion');
export const PostulanteDiscapacidad = makeEntity('postulante_discapacidad', 'IdPostulanteDiscapacidad');

export const EmpresaPostulanteAdministrado = makeEntity('empresa_postulante_administrado', 'IdPostulante');
export const FuncionarioInvitacion = makeEntity('funcionario_invitacion', 'IdInvitacion');

export const Propiedad = makeEntity('propiedad', 'NroPropiedad');

Usuario.define({
  Empresa,
  Postulante,
  Funcionario,
  Operador,
  Roles: [Rol],
});

Rol.define({
  Permisos: [Permiso],
});

Empresa.define({
  Usuario,
  OfertasActivas: [Oferta],
  OfertasPublicadas: [Oferta],
  OfertasVigentes: [Oferta],
  OfertasCaducadas: [Oferta],
  OfertasPendientes: [Oferta],
  EmpresaPaquete,
  Funcionarios: [Funcionario],
  Pagos: [Pago],
  EmpresaPaquetes: [EmpresaPaquete],
  PostulantesAdministrados: [Postulante],
  BusquedaPostulantes: [Postulante],
  BusquedaLibre: [Postulante],
  Postulantes: [Postulante],
  TiposPublicacion: [TipoPublicacion],
  FuncionarioInvitaciones: [FuncionarioInvitacion],
});

Postulante.define({
  Usuario,
  Ciudad,
  Departamento,
  Pais,
  EstadoCivil,
  Curriculum: Postulante,
  MisOfertasPostulado: [Oferta],
  EmpresasAdministrados: [Empresa],
  Postulaciones: [Postulacion],
  Invitaciones: [OfertaPostulanteInvitacion],
  PostulacionesActivas: [Postulacion],
  Conocimientos: [PostulanteConocimiento],
  Experiencias: [PostulanteExperiencia],
  Estudios: [PostulanteEstudio],
  Idiomas: [PostulanteIdioma],
  Referencias: [PostulanteReferencia],
  Aspiraciones: [PostulanteAspiracion],
  TestsExternos: [PostulanteTestExterno],
  OfertasRecomendadas: [Oferta],
});

Funcionario.define({
  Usuario,
  Empresa,
  OfertasPropias: [Oferta],
  OfertasAsociadas: [Oferta],
});

Operador.define({
  Usuario,
  BusquedaPostulantes: [Postulante],
});

Oferta.define({
  Empresa,
  Paquete: EmpresaPaquete,
  Postulaciones: [Postulacion],
  Invitaciones: [OfertaPostulanteInvitacion],
});

OfertaPostulanteInvitacion.define({
  Oferta,
});

Postulacion.define({
  Postulante,
  Oferta,
  Estado: EstadoPostulacion,
});

EmpresaPaquete.define({
  Paquete,
  Pago,
  Pagos: [Pago],
  Ofertas: [Oferta],
});

Paquete.define({
  Servicios: [Servicio],
  PaqueteServicios: [PaqueteServicio],
});

PaqueteServicio.define({ Paquete, Servicio });

Pais.define({ Departamento });
Departamento.define({
  Ciudades: [Ciudad],
});

DiscapacidadAyuda.define({
  TipoAyuda,
});

PostulanteConocimiento.define({
  Conocimiento,
});

PostulanteExperiencia.define({
  Conocimientos: [PostulanteConocimiento],
  Rubro,
  SubRubro,
  Cargo,
  Ambito,
  NivelJerarquico,
  // Sueldo,
  Ciudad,
  Departamento,
  Pais,
});

PostulanteEstudio.define({
  Curso,
  Ciudad,
  Departamento,
  Pais,
  AreaEstudio,
  GradoEstudio,
  Institucion,
});

PostulanteIdioma.define({
  Idioma,
  NivelEscrito: NivelIdioma,
  NivelHablado: NivelIdioma,
  NivelLeido: NivelIdioma,
});

PostulanteAspiracion.define({
  Conocimientos: [Conocimiento],
  Ambitos: [Ambito],
  // Cargo,
  DisponibilidadCambioResidencia,
  DisponibilidadViaje,
  // Sueldo,
  Ciudad,
  Departamento,
  Pais,
});


PostulanteTestExterno.define({
  TestExterno,
});

PostulanteDiscapacidad.define({
  Ayudas: [DiscapacidadAyuda],
});

EmpresaPostulanteAdministrado.define({
  Postulante,
});
