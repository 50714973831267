import React from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { Button, Icon } from 'components/Semantic';
import { isMarkActive, toggleMark } from '../utils/helpers';

const MarkButton = (props) => {
  const { format, icon, hotkey } = props;

  const editor = useSlate();

  return (
    <Button
      onMouseDown={(e) => {
        e.preventDefault();
        toggleMark(editor, format);
      }}
      title={hotkey}
      icon
      toolbar
      toggle
      active={isMarkActive(editor, format)}
      type="button"
    >
      <Icon name={icon} />
    </Button>
  );
};

MarkButton.propTypes = {
  format: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  hotkey: PropTypes.string,
};

export default MarkButton;
