import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Divider, Grid } from 'semantic-ui-react';
import { Modal, Segment, Button } from 'components/Semantic';
import { WHITE, GRAY_7, DARK_BLUE, DARK_GREEN } from 'styles/variables';

import { useMutation } from 'containers/Api/hooks';

import ColorText from '../../../ColorText';
import MarkdownRenderer from '../../../MarkdownRenderer';

import messages from '../messages';

import { API_CALL_EVENT, API_SUCCESS_EVENT, API_ERROR_EVENT,
  CHANGE_TEXT_EVENT, KEEP_ORIGINAL_EVENT } from '../constants';

import Progreso from './Progreso';
import ErrorMessage from './ErrorMessage';

const ModalMejorarFormato = (props) => {
  const { open, onClose, title, text, endpoint, onReplace, trackEvent } = props;

  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(null);

  // Data Fetching
  const [enhanceText, { loading, loaded, data }] = useMutation(() => endpoint, { method: 'post' });

  useEffect(() => {
    if (open) {
      enhance(enhanceText({ values: { text, fullMode: true } }));
    }
  }, [open]);

  useEffect(() => {
    const show = () => {
      trackEvent(API_SUCCESS_EVENT);
      setVisible(true);
    }

    if (loaded) {
      setTimeout(show, 500);
    }
  }, [loaded]);

  const handleClose = () => {
    trackEvent(KEEP_ORIGINAL_EVENT);
    onClose();
  };

  const enhance = async (promise) => {
    setError(null);
    trackEvent(API_CALL_EVENT);
    try {
      await promise;
    } catch (err) {
      trackEvent(API_ERROR_EVENT);
      setError(err.error ?? err);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} size="large">
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content style={{ position: 'relative' }}>
        {error ?
          <Segment color={WHITE}>
            <ErrorMessage
              error={error}
              onRetry={() => {
                enhance(enhanceText({ values: { text, fullMode: true } }));
              }}
            />
          </Segment>
        : !visible ?
          <Segment color={WHITE} style={{ textAlign: 'center', margin: 0, padding: '8em 1em' }}>
            <ColorText light>
              <FormattedMessage {...messages.embellecerLoadingMessage} />
            </ColorText>
            <Progreso full={loaded} />
          </Segment>
        :
          <Segment color={WHITE}>
            <Grid>
              <Grid.Row style={{ padding: 0 }}>
                <Grid.Column mobile={16} tablet={8} coputer={8} largeScreen={8} widescreen={8}>
                  <Segment color={WHITE}>
                    <ColorText light bold uppercase>
                      <FormattedMessage {...messages.embellecerOriginal} />
                    </ColorText>
                    <Divider hidden style={{ margin: '0.5rem' }} />
                    <MarkdownRenderer>{text}</MarkdownRenderer>
                  </Segment>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} coputer={8} largeScreen={8} widescreen={8}>
                  <Segment color={WHITE} border>
                    <ColorText light bold uppercase>
                      <FormattedMessage {...messages.embellecerNuevo} />
                    </ColorText>
                    <Divider hidden style={{ margin: '0.5rem' }} />
                    <MarkdownRenderer>{data}</MarkdownRenderer>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          floated="right"
          color={DARK_GREEN}
          type="button"
          disabled={loading || !!error}
          onClick={() => {
            onReplace(data);
            trackEvent(CHANGE_TEXT_EVENT);
            onClose(); // Don't use handleClose to avoid tracking close event
          }}
        >
          <FormattedMessage {...messages.embellecerGuardar} />
        </Button>
        <Button
          floated="right"
          color={GRAY_7}
          textColor={DARK_BLUE}
          type="button"
          onClick={handleClose}
        >
          <FormattedMessage {...messages.embellecerOmitir} />
        </Button>
        <Divider fitted hidden clearing />
      </Modal.Actions>
    </Modal>
  );
};

ModalMejorarFormato.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  endpoint: PropTypes.string,
  onReplace: PropTypes.func,
  trackEvent: PropTypes.func,
};

export default injectIntl(ModalMejorarFormato);
