import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Divider } from 'semantic-ui-react';
import { WHITE, GRAY_1, GRAY_5, GRAY_8, DARK_BLUE } from 'styles/variables';
import { Segment, Header, Icon, Responsive } from 'components/Semantic';
import ContainerSegment from './ContainerSegment';

const CenteredContent = styled.span`
  color: ${WHITE};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  i {
    backface-visibility: unset;
  }
`;

const Section = (props) => {
  const { idName, basic, simple, title, icon, src, mainColor, backgroundColor, children, loading, expandable,
    defaultOpen, detailLabel, hoverDetail, multipleContent, bigTitle, bigDetail, onTitleClick, childrenArray, childrenKeys,
    compact, headerBackground, shadow, shadowColor, arrow, divided, padded } = props;

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [hover, setHover] = useState(false);

  const handleClick = () => setIsOpen(!isOpen);

  const handleMouseEnter = () => {
    if (hoverDetail) {
      setHover(true);
    }
  };

  const handleMouseLeave = () => {
    if (hoverDetail) {
      setHover(false);
    }
  };

  const color = mainColor || DARK_BLUE;
  const background = backgroundColor || GRAY_5;
  // eslint-disable-next-line no-nested-ternary
  let headerBackgroundColor = !headerBackground ?
    (backgroundColor || simple) ? background : GRAY_8
  : headerBackground;
  if (hoverDetail && hover) {
    headerBackgroundColor = simple ? WHITE : GRAY_5;
  }

  let headerWidth = 16;
  if (detailLabel || expandable) {
    headerWidth = 11;
    if (bigTitle) {
      headerWidth = 12;
    } else if (bigDetail) {
      headerWidth = 8;
    }
  }
  let detailWidth = 5;
  if (bigTitle) {
    detailWidth = 4;
  } else if (bigDetail) {
    detailWidth = 8;
  }

  const header = (
    <Grid verticalAlign="middle">
      <Grid.Row>
        <Grid.Column width={headerWidth}>
          {bigTitle ?
            <Header floated="left" style={{ marginRight: 0 }}>
              <Header.Content color={GRAY_1}>{title}</Header.Content>
            </Header>
          :
            <Header as="h4" floated="left">
              <Header.Content color={GRAY_1}>{title}</Header.Content>
            </Header>
          }
        </Grid.Column>
        {detailLabel &&
          <Grid.Column width={detailWidth} textAlign="right" verticalAlign="middle">
            <Responsive minWidth={Responsive.onlyComputer.minWidth}>
              {(!hoverDetail || hover) && detailLabel}
            </Responsive>
            <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
              {detailLabel}
            </Responsive>
          </Grid.Column>
        }
        {expandable &&
          <Grid.Column width={detailWidth} textAlign="right" verticalAlign="middle">
            <Icon
              name={isOpen ? 'chevron up' : 'chevron down'}
              onClick={handleClick}
              style={{ color: `${color}`, cursor: 'pointer' }}
            />
          </Grid.Column>
        }
      </Grid.Row>
    </Grid>
  );

  const childrenCount = childrenArray ? childrenArray.length : 0;

  const arrowColor = basic ? headerBackground || background : headerBackgroundColor;

  return (
    <Segment.Group
      compact
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      shadow={shadow}
      shadowColor={shadowColor}
      arrow={arrow}
      arrowColor={arrowColor}
    >
      {!!title &&
        <ContainerSegment onClick={onTitleClick}>
          {basic ?
            <Segment border={!simple} color={headerBackground || background} compact={!padded}>
              {header}
            </Segment>
          :
            <Segment.Group compact horizontal arrow={arrow}>
              <Segment
                color={simple ? headerBackgroundColor : color}
                icon
                style={hoverDetail && hover ? { cursor: 'pointer', minWidth: '35px' } : { minWidth: '35px' }}
              >
                <CenteredContent style={{ marginLeft: bigTitle && '5px' }}>
                  {src ?
                    <img src={src} alt={title} height="24px" />
                  :
                    <Icon
                      id={`${idName}-${icon}`}
                      name={icon}
                      color={simple ? color : WHITE}
                      size="large"
                      fitted
                      style={{ backgroundColor: headerBackground }}
                    />
                  }
                </CenteredContent>
              </Segment>
              <Segment color={headerBackgroundColor} compact style={hoverDetail && hover ? { cursor: 'pointer' } : {}}>
                {header}
              </Segment>
            </Segment.Group>
          }
        </ContainerSegment>
      }
      {divided ?
        <Divider style={{ margin: 0, borderBottom: 'none' }} />
      : null}
      {(!expandable || isOpen) &&
        <div>
          {multipleContent && childrenArray ?
            <Segment.Group compact>
              {childrenArray.map((child, index) => (
                <Segment color={background} loading={loading} key={childrenKeys[index]}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <div>
                          {child}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    {!(childrenCount === index + 1) && (
                      <Grid.Row style={{ padding: 0 }}>
                        <Grid.Column>
                          <Divider style={{ marginTop: compact ? 0 : '7px', marginBottom: compact ? 0 : '7px' }} />
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </Grid>
                </Segment>
              ))}
            </Segment.Group>
          :
            <Segment color={basic && !simple ? WHITE : background} loading={loading} border={basic && !simple}>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <div>
                      {children}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          }
        </div>
      }
    </Segment.Group>
  );
};

Section.propTypes = {
  idName: PropTypes.string,
  basic: PropTypes.bool,
  simple: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  icon: PropTypes.string,
  src: PropTypes.string,
  mainColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  headerBackground: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  expandable: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  detailLabel: PropTypes.object,
  hoverDetail: PropTypes.bool,
  multipleContent: PropTypes.bool,
  bigTitle: PropTypes.bool,
  bigDetail: PropTypes.bool,
  onTitleClick: PropTypes.func,
  childrenArray: PropTypes.array,
  childrenKeys: PropTypes.array,
  compact: PropTypes.bool,
  shadow: PropTypes.bool,
  shadowColor: PropTypes.string,
  arrow: PropTypes.bool,
  divided: PropTypes.bool,
  padded: PropTypes.bool,
};

export default Section;
