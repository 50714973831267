import settings from 'settings';
const TAG = 'react';
const TOKEN = '2c33da69-3288-4d03-9333-4c01ecba44c3';

const LOGGLY_ENDPOINT = `https://logs-01.loggly.com/inputs/${TOKEN}/tag/${TAG}`;

export function logMessage(json) {
  if (settings.isDev) {
    return;
  }

  fetch(LOGGLY_ENDPOINT, {
    method: 'POST',
    mode: 'no-cors',
    headers: { 'Content-Type': 'text/plain' },
    body: JSON.stringify({ ...json }),
  });
}
