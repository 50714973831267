import React from 'react';
import Helmet from 'react-helmet';
import { startsWith } from 'lodash';
import settings from 'settings';

export default function AppHelmet() {
  let helmetTitle = 'Buscojobs';
  let manifestFile = '/app/bj-manifest.json';
  if (startsWith(settings.defaultLocale, 'en')) {
    helmetTitle = 'Findojobs';
    manifestFile = '/app/fj-manifest.json';
  } else if (startsWith(settings.defaultLocale, 'it')) {
    helmetTitle = 'Cercojobs';
    manifestFile = '/app/cj-manifest.json';
  }

  return (
    <Helmet
      titleTemplate={`%s - ${helmetTitle}`}
      defaultTitle={settings.helmet.title}
      meta={[
        {
          name: 'description',
          content: settings.helmet.title,
        },
        {
          name: 'msapplication-TileColor',
          content: '#da532c',
        },
        {
          name: 'msapplication-TileImage',
          content: '/app/mstile-144x144.png',
        },
        {
          name: 'msapplication-config',
          content: '/app/browserconfig.xml',
        },
      ]}
      link={[
        {
          rel: 'shortcut icon',
          href: '/app/favicon.ico',
        },
        {
          rel: 'icon',
          sizes: '16x16',
          href: '/app/favicon-16x16.png',
        },
        {
          rel: 'icon',
          sizes: '32x32',
          href: '/app/favicon-32x32.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/app/apple-touch-icon.png',
        },
        {
          rel: 'mask-icon',
          href: '/app/safari-pinned-tab.svg',
          color: '#ffffff',
        },
        {
          rel: 'manifest',
          href: manifestFile,
        },
      ]}
      htmlAttributes={{ lang: settings.defaultLocale }}
    />
  );
}
