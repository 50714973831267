import { connect } from 'react-redux';

import { authSelector, userSelector } from 'containers/App/selectors';
import { authenticate, logout } from 'containers/Auth/actions';
import toJS from 'utils/propsToJS';
import Header from 'components/Header';

function mapStateToProps(state) {
  return {
    auth: authSelector(state),
    user: userSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authenticate: (username, password) => dispatch(authenticate(username, password)),
    logout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Header));
