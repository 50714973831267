import { createSelector } from 'reselect';
import { keyIn } from 'utils/immutable';

const selectApp = (state) => state.get('app');

const selectAuth = (state) => state.get('auth');

export const paisSelector = createSelector(selectApp, (app) => app.get('pais'));

export const idPaisSelector = createSelector(selectApp, (app) => app.get('idPais'));

export const apiVersionSelector = createSelector(selectApp, (app) => app.get('apiVersion'));

export const authSelector = createSelector(selectAuth, (auth) => auth.filterNot(keyIn('user')));

export const userSelector = createSelector(selectAuth, (auth) => auth.get('user'));


// makeSelectLocationState expects a plain JS object for the routing state
export const makeSelectLocationState = () => {
  let prevRoutingState;
  let prevRoutingStateJS;

  return (state) => {
    const routingState = state.get('route'); // or state.route

    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState;
      prevRoutingStateJS = routingState.toJS();
    }

    return prevRoutingStateJS;
  };
};
