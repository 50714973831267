import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Menu } from 'semantic-ui-react';
import { GRAY_1, GRAY_5, GRAY_6, GRAY_8, WHITE, DARK_BLUE, BLUE_HOVER, MOBILE, COMPUTER } from 'styles/variables';


// Menu.Item

const MenuItemBJ = ({ color, ...other }) => <Menu.Item {...other} />;

MenuItemBJ.propTypes = {
  color: PropTypes.string,
};

const StyledMenuItem = styled(MenuItemBJ)`
   border-radius: 0 !important;
   background-color: ${(props) => props.color || 'none'} !important;
   color: ${GRAY_1} !important;
   
   &:before {
      background: transparent !important;
   }
`;


// Menu.Header

const MenuHeaderBJ = ({ color, ...other }) => <Menu.Header {...other} />;

MenuHeaderBJ.propTypes = {
  color: PropTypes.string,
};

const StyledMenuHeader = styled(MenuHeaderBJ)`
   color: ${(props) => props.color || GRAY_1} !important;
   font-weight: 700;
`;


// Menu

const MenuBJ = ({ color, navbar, basic, ...other }) => <Menu {...other} />;

MenuBJ.propTypes = {
  navbar: PropTypes.bool,
  basic: PropTypes.bool,
  color: PropTypes.string,
};

MenuBJ.Header = StyledMenuHeader;
MenuBJ.Item = StyledMenuItem;
MenuBJ.Menu = Menu.Menu;

const StyledMenu = styled(MenuBJ)`
  border-radius: 0 !important;
  
  &.vertical {
    background-color: ${(props) => props.color || GRAY_5} !important;
    border: none !important;
    margin-bottom: 10px !important;
    &:not(.sidebar) {
      .item {
        .menu {
          .item {
            font-size: 12px !important;
          }
        }
      }
    }
    &.sidebar {
      margin-bottom: 0 !important;
      .item {
        color: ${DARK_BLUE} !important;
        :hover {
          background-color: ${DARK_BLUE} !important;
          color: ${WHITE} !important;
        }
        &::before {
          background-color: transparent !important;
        }
      }
    }
  }
  
  &.pagination {
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    .item {
      color: ${DARK_BLUE} !important;
      &.active {
        background-color: ${GRAY_8} !important;
        color: ${DARK_BLUE} !important;
        font-weight: 700 !important;
      }
      :hover {
        background-color: ${GRAY_5} !important;
        color: ${DARK_BLUE} !important;
      }
      &.disabled {
        color: ${GRAY_6} !important;
        &:hover {
          background-color: unset !important;
          color: ${GRAY_6} !important;
        }
      }
      ::before {
        background-color: transparent !important;
      }
    }
  }

  ${(props) => props.basic && css`
    background-color: transparent !important;
    border: 0 !important;
    min-height: unset !important;
    &:hover {
      background-color: transparent !important;
    }
  `}

  ${(props) => props.navbar && css`
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    transition: background-color 1s, box-shadow 1s;
    min-height: 70px !important;
    font-family: 'Roboto', 'Arial', sans-serif !important;
    @media only screen and (max-width: ${MOBILE.maxWidth}px) {
      position: absolute !important;
    }
    .item {
      background-color: transparent !important;
      color: ${DARK_BLUE} !important;
      font-size: 15px !important;
      font-weight: 700 !important;
      line-height: 20px !important;
      text-transform: uppercase !important;
      border-radius: 3px !important;

      a.item.navbar-item:first-child,
      div.item.navbar-item:last-child,
      a.item.navbar-item:last-child {
        border-radius: 3px !important;
      }

      &.navbar-item {
        @media only screen and (max-width: ${COMPUTER.maxWidth}px) {
          padding-left: 0.6em;
          padding-right: 0.6em;
        }
        .divider {
          margin: 0;
        }
        &:hover {
          color: ${BLUE_HOVER} !important;
        }
      }

      &::before {
        background: unset !important;
      }
    }
  `}
`;

StyledMenu.Header = MenuBJ.Header;
StyledMenu.Item = MenuBJ.Item;
StyledMenu.Menu = MenuBJ.Menu;

export default StyledMenu;
