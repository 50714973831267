import ojo from 'images/ojo-loader.svg';
import ojoSmall from 'images/ojo-loader-small.svg';

const BJLoader = `
  content: url(${ojo}) !important;
  animation-duration: 1s !important;
  border: none !important;
  width: 40px !important;
  height: 40px !important;
`;

const BJLoaderSmall = `
  content: url(${ojoSmall}) !important;
  animation-duration: 1s !important;
  border: none !important;
  width: 18px !important;
  height: 18px !important;
`;

export { BJLoaderSmall, BJLoader };
