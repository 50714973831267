import React from 'react';
import PropTypes from 'prop-types';
import { Virtual, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import './CarouselStyles.css';

// Import Swiper styles
import 'swiper/swiper.css';

const Carousel = (props) => {
  const { slides, animationSpeed, autoplay, dots } = props;

  return (
    <Swiper
      modules={[Virtual, Autoplay, Pagination]}
      speed={animationSpeed}
      slidesPerView={1}
      autoplay={autoplay ? {
        delay: autoplay,
        disableOnInteraction: false,
      } : false}
      pagination={dots ? {
        clickable: true,
        bulletElement: 'div',
      } : false}
      virtual
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={slide} virtualIndex={index}>
          <div>
            {slide}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

Carousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
  animationSpeed: PropTypes.number,
  autoplay: PropTypes.number,
  dots: PropTypes.bool,
};

export default Carousel;
