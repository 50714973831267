import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import moment from 'moment';
import settings from 'settings';
import { Divider } from 'semantic-ui-react';
import { Input, Form, Responsive, Modal, Button } from 'components/Semantic';
import { GRAY_1, GRAY_4, GRAY_7, GRAY_8, GRAY_10, DARK_BLUE, BLUE_HOVER } from 'styles/variables';
import { hoverBackgroundColor, fontColor } from 'styles/utils';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import messages from './messages';

const ReactDatetime = ({ pickerPosition, viewDate, value, ...props }) => {
  const date =
    (value || viewDate) &&
    moment(value || viewDate, props.dateFormat).locale(settings.defaultLocale);
  return (<Datetime viewDate={date} value={date} {...props} />);
};

ReactDatetime.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pickerPosition: PropTypes.oneOf(['left', 'right']),
  viewDate: PropTypes.object,
  dateFormat: PropTypes.string,
};

const StyledDatetime = styled(ReactDatetime)`
  .rdtPicker {
    ${(props) => props.alwaysVisible ? css`
      width: 100% !important;
    ` : css`
      margin-top: 0.5em !important;
      border: 1px solid ${GRAY_10} !important;
      box-shadow: 0px 2px 3px 0px ${GRAY_10} !important;

      ${props.pickerPosition === 'right' && css`
        right: 0 !important;
      `}
    `}
  }

  .rdtSwitch, .rdtPrev, .rdtNext, .rdtYear, .rdtMonth, .rdtDay {
    color: ${GRAY_1} !important;
    &:hover {
      background-color: ${GRAY_8} !important;
    }
  }

  .rdtOld, .rdtNew {
    color: ${GRAY_4} !important;
  }

  .rdtDisabled {
    color: ${GRAY_4} !important;
    &:hover {
      background: none !important;
    }
  }

  .dow {
    color: ${GRAY_1} !important;
  }

  .rdtSwitch, .rdtPrev, .rdtNext {
    color: ${DARK_BLUE} !important;
    &:hover {
      color: ${BLUE_HOVER} !important;
    }
  }

  .rdtActive {
    font-weight: bold !important;
    background-color: ${(props) => props.mainColor || DARK_BLUE} !important;
    color: ${(props) => fontColor(props)} !important;
    &:hover {
      background-color: ${(props) => hoverBackgroundColor(props)} !important;
    }
  }
`;

const DateInput = (props) => {
  const { useFormInput, name, placeholder, disabled, ...other } = props;
  if (useFormInput) {
    return (<Form.Input name={name} placeholder={placeholder} disabled={disabled} readOnly {...other} />);
  }
  return (<Input placeholder={placeholder} disabled={disabled} readOnly {...other} />);
};

DateInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  useFormInput: PropTypes.bool,
  disabled: PropTypes.bool,
};

class DatePicker extends React.Component {

  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    mainColor: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.number,
    required: PropTypes.bool,
    dateFormat: PropTypes.string,
    error: PropTypes.bool,
    errorLabel: PropTypes.node,
    disabled: PropTypes.bool,
    useFormInput: PropTypes.bool,
    onChange: PropTypes.func,
    disableMobileModal: PropTypes.bool,
    beforeDate: PropTypes.object,
    afterDate: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }
  getSelectableDate = (current) => {
    const { beforeDate, afterDate } = this.props;
    return (!beforeDate || moment(current).isSameOrBefore(beforeDate)) && (!afterDate || moment(current).isAfter(afterDate));
  };

  openModal = () => this.setState({ modalOpen: true });

  handleOnCloseModal = () => this.setState({ modalOpen: false });

  handleOnChange = (value) => {
    const { onChange } = this.props;
    onChange(value);
    this.setState({ modalOpen: false });
  };


  renderInput = (props, openCalendar) => {
    const { useFormInput, name, placeholder, disabled } = this.props;
    const { modalOpen } = this.state;

    return (
      <DateInput
        useFormInput={useFormInput}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onClick={openCalendar}
        autoFocus={modalOpen}
        {...props}
      />
    );
  };

  render() {
    const { name, label, width, required, error, errorLabel, onChange, ...other } = this.props;

    const { modalOpen } = this.state;

    const color = this.props.mainColor || DARK_BLUE;

    const dateFormat = this.props.dateFormat || settings.dateFormat;

    let className = 'field';
    switch (width) {
      case 1:
        className = `one wide ${className}`;
        break;
      case 2:
        className = `two wide ${className}`;
        break;
      case 3:
        className = `three wide ${className}`;
        break;
      case 4:
        className = `four wide ${className}`;
        break;
      case 5:
        className = `five wide ${className}`;
        break;
      case 6:
        className = `six wide ${className}`;
        break;
      case 7:
        className = `seven wide ${className}`;
        break;
      case 8:
        className = `eight wide ${className}`;
        break;
      case 9:
        className = `nine wide ${className}`;
        break;
      case 10:
        className = `ten wide ${className}`;
        break;
      case 11:
        className = `eleven wide ${className}`;
        break;
      case 12:
        className = `twelve wide ${className}`;
        break;
      case 13:
        className = `thirteen wide ${className}`;
        break;
      case 14:
        className = `fourteen wide ${className}`;
        break;
      case 15:
        className = `fifteen wide ${className}`;
        break;
      case 16:
        className = `sixteen wide ${className}`;
        break;
      default:
        className = 'field';
    }
    if (required) {
      className = `required ${className}`;
    }
    if (error) {
      className = `error ${className}`;
    }

    const inputPicker = (
      <div className={className}>
        <label htmlFor={name}>{label}</label>
        <StyledDatetime
          id={name}
          locale={settings.defaultLocale}
          renderInput={this.renderInput}
          closeOnSelect
          onChange={onChange}
          isValidDate={this.getSelectableDate}
          dateFormat={dateFormat}
          {...other}
        />
        {error && errorLabel}
      </div>
    );

    // Same component behavior in all resolutions
    if (this.props.disableMobileModal) {
      return inputPicker;
    }

    return (
      <React.Fragment>
        <Responsive as={React.Fragment} maxWidth={Responsive.onlyMobile.maxWidth}>
          <div className={className}>
            <label htmlFor={name}>{label}</label>
            <DateInput
              type="text"
              value={this.props.value ? moment(this.props.value, dateFormat).format(dateFormat) : ''}
              useFormInput={this.props.useFormInput}
              name={name}
              placeholder={this.props.placeholder}
              disabled={this.props.disabled}
              onFocus={this.openModal}
              isValidDate={this.getSelectableDate}
            />
            {error && errorLabel}
          </div>
          <Modal open={modalOpen} onClose={this.handleOnCloseModal} mobileFriendly>
            <Modal.Header>{label}</Modal.Header>
            <Modal.Content>
              <div className={className}>
                <StyledDatetime
                  id={name}
                  locale={settings.defaultLocale}
                  input={false}
                  alwaysVisible
                  onChange={this.handleOnChange}
                  isValidDate={this.getSelectableDate}
                  dateFormat={dateFormat}
                  {...other}
                />
                {error && errorLabel}
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                type="button"
                floated="right"
                color={GRAY_7}
                textColor={color}
                onClick={this.handleOnCloseModal}
                autoFocus
              >
                <FormattedMessage {...messages.cerrar} />
              </Button>
              <Divider fitted hidden clearing />
            </Modal.Actions>
          </Modal>
        </Responsive>
        <Responsive as={React.Fragment} minWidth={Responsive.onlyTablet.minWidth}>{inputPicker}</Responsive>
      </React.Fragment>
    );
  }
}

export default DatePicker;
