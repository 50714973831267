import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { Checkbox } from 'semantic-ui-react';
import { DARK_GREEN, WHITE, GRAY_4, LIGHT_GRAY, DARK_BLUE } from 'styles/variables';

import messages from './messages';

const CheckboxBJ = ({ color, labelColor, simple, ...other }) => <Checkbox {...other} />;

CheckboxBJ.propTypes = {
  color: PropTypes.string,
  labelColor: PropTypes.string,
  simple: PropTypes.bool,
};

const StyledChackbox = styled(CheckboxBJ)`
  input:checked ~ label:after {
    color: ${DARK_GREEN} !important;
  }
  &.radio input:checked ~ label:after {
    background-color: ${DARK_GREEN} !important;
  }
  label {
    color: ${(props) => props.labelColor || 'rgba(0, 0, 0, 0.87)'} !important;
    &:before {
      border-color: ${GRAY_4} !important;
    }
  }
  ${(props) => props.simple && !props.toggle && css`
    width: 100% !important;
    label:before {
      border: none !important;
    }
  `}
  ${(props) => props.toggle && css`
    min-width: 60px !important;
    height: 26px !important;
    label {
      color: ${GRAY_4} !important;
      font-family: Arial !important;
      font-size: 0.9em !important;
      min-width: 60px !important;
      height: 26px !important;
      padding-top: 5px !important;
    }
    label:before {
      background-color: ${LIGHT_GRAY} !important;
      width: 60px !important;
      height: 26px !important;
    }
    label:after {
      width: 20px !important;
      height: 20px !important;
      left: 3px !important;
      top: 3px !important;
      box-shadow: none !important;
      background-color: ${WHITE} !important;
      background: ${WHITE} !important;
      ${props.simple && css`
        background: ${props.color || DARK_BLUE} !important;
      `}
    }
    &.ui.toggle.checkbox input:checked {
      ~ label {
        color: ${GRAY_4} !important;
        font-family: Arial !important;
        font-size: 0.9em !important;
      }
      ~ label:before {
        background-color: ${props.color || DARK_BLUE} !important;
        ${props.simple && css`
          background-color: ${LIGHT_GRAY} !important;
        `}
      }
      ~ label:after {
        left: 37px !important;
      }
    }
  `}
`;

const SpecialCheckbox = ({ yesNo, onChange, ...other }) => {
  if (yesNo) {
    return (
      <div
        role="presentation"
        style={{ position: 'relative', display: 'inline' }}
        onClick={(e) => onChange(e, other)}
      >
        {other.checked ?
          <span
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: WHITE,
              position: 'absolute',
              transform: 'translate(-50%,-50%)',
              left: '21px',
              top: '1px',
              zIndex: '10',
              cursor: 'pointer',
            }}
          >
            <FormattedMessage {...messages.si} />
          </span>
        :
          <span
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: WHITE,
              position: 'absolute',
              transform: 'translate(-50%,-50%)',
              left: '40px',
              top: '1px',
              zIndex: '10',
              cursor: 'pointer',
            }}
          >
            <FormattedMessage {...messages.no} />
          </span>
        }
        <StyledChackbox {...other} />
      </div>
    );
  }
  return <StyledChackbox onChange={onChange} {...other} />;
};

SpecialCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  yesNo: PropTypes.bool,
};

export default SpecialCheckbox;
