import { NOTIFICATION_SEND, NOTIFICATION_DISMISS } from './constants';

export function sendUpdateNotification() {
  return {
    type: NOTIFICATION_SEND,
    payload: {
      error: false,
    },
  };
}

export function sendSuccessNotification(message) {
  return {
    type: NOTIFICATION_SEND,
    payload: {
      error: false,
      message,
    },
  };
}

export function sendErrorNotification(err) {
  return {
    type: NOTIFICATION_SEND,
    payload: {
      error: true,
      title: err.name,
      message: err.message,
    },
  };
}

export function dismissNotification() {
  return { type: NOTIFICATION_DISMISS };
}
