import _ from 'lodash';
import { normalize } from 'normalizr';
import { call, takeEvery, put, select, all } from 'redux-saga/effects';

import { API_BASE_URL } from 'containers/Api';
import { sendUpdateNotification } from 'containers/Notification/actions';
import { paisSelector, apiVersionSelector } from 'containers/App/selectors';
import { handleError as handleAuthError } from 'containers/Auth/actions';
import { selectToken } from 'containers/Auth/selectors';
import { fetchToken } from 'containers/Auth/sagas';
import request from 'utils/request';
import { checkToken } from 'utils/jwt';
import { entities } from 'schema';
import settings from 'settings';

import { REPO_QUERY_REQUEST, Operations } from './constants';
import { handleSuccess, handleFailure, handleTerminated, handleCount } from './actions';

function* queryRepo(action) {
  const { name, operation, req, schema } = action.payload;
  const meta = action.meta || {};
  meta.operation = operation;

  try {
    let response;

    // LIST: Intentamos contar los resultados (si existe el endpoint)
    if (operation === Operations.LIST) {
      let count;
      [response, count] = yield all([
        call(makeRequest, req),
        call(countList, req),
      ]);
      yield put(handleCount(name, count));
    } else {
      response = yield* makeRequest(req);
    }

    // COUNT: Nos quedamos solamente con la cantidad
    if (operation === Operations.COUNT) {
      response = { result: response.count };
    }

    // Si tiene un schema asociado desnormalizamos la respuesta
    if (schema) {
      if (_.isArray(schema)) {
        response = normalize(response, [entities[schema[0]]]);
      } else {
        response = normalize(response, entities[schema]);
      }
    }

    // Despachamos la respuesta para que la procese el reducer
    yield put(handleSuccess(name, response, meta));

    // Si corresponde enviamos una notificacion
    if ((operation === Operations.UPDATE || operation === Operations.CREATE) && meta.notify) {
      yield put(sendUpdateNotification());
    }
  } catch (err) {
    if (err.error && err.error.statusCode === 401) {
      yield put(handleAuthError(err.error));
    } else {
      yield put(handleFailure(name, err.error, meta));
    }
  } finally {
    yield put(handleTerminated(name, meta));
  }
}

function* countList(req) {
  const countReq = {
    url: `${req.url}/count`,
    method: 'get',
    filter: _.omit(req.filter, ['limit', 'skip']),
  };

  try {
    const response = yield* makeRequest(countReq);
    return response.count;
  } catch (err) {
    return 0;
  }
}

function* makeRequest(params) {
  let { url, filter, method, ...options } = params;

  const pais = yield select(paisSelector);
  const version = yield select(apiVersionSelector);
  const locale = settings.defaultLocale;
  let token = yield select(selectToken);

  if (!checkToken(token) && !settings.isDev) { // Chequeo que el token siga vivo
    yield fetchToken();
    token = yield select(selectToken);
  }

  url = `${API_BASE_URL}/${version}/${pais}/api${url}`;

  // Soporte para el parametro filter de Loopback
  const isCount = url.endsWith('/count');
  const isFacet = url.endsWith('/facets');

  if ((isCount || isFacet) && filter && !_.isEmpty(filter.where)) {
    url += `?where=${encodeURI(JSON.stringify(filter.where))}`;
  } else if (!_.isEmpty(filter)) {
    url += `?filter=${encodeURI(JSON.stringify(filter))}`;
  }

  options.headers = { ...options.headers };

  const today = new Date();
  options.headers['x-timezone-offset'] = today.getTimezoneOffset();

  // Si estoy logueado mando el token en el header Authorization
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }

  if (window?.appMobile?.version) {
    options.headers['appmobile-version'] = window?.appMobile?.version;
  }

  if (locale) {
    const lang = locale.substr(0, 2).toLowerCase();
    options.headers['Accept-Language'] = lang;
  }

  options.method = method.toUpperCase();
  options.mode = 'cors';

  // Mandamos el ASP.NET_SessionId en un header para que el api sincronice las sesiones
  const sessionCookie = document.cookie.split('; ').find((c) => _.startsWith(c, 'ASP.NET_SessionId'));
  if (sessionCookie) {
    options.headers.SessionId = decodeURIComponent(sessionCookie.split('=')[1]);
  }

  if (method === 'post' || method === 'put' || method === 'patch') {
    if (options.body instanceof FormData) { // Si estamos subiendo un archivo
      delete options.headers['Content-Type'];
    } else {
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(options.body);
    }
  }

  const response = yield call(request, url, options);
  return response;
}

function* repoWatcher() {
  yield takeEvery(REPO_QUERY_REQUEST, queryRepo);
}

export default [repoWatcher];
