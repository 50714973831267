import { Responsive } from 'semantic-ui-react';


/* Colors */

export const BACKGROUND_GRAY = '#e1e1e1';
export const LIGHT_GRAY = '#d4d4d5';
export const GRAY_9 = '#f0f3e7';
export const GRAY_1 = '#555555';
export const GRAY_0 = '#292929';
export const DARK_GRAY = '#808080';
export const GRAY_EMPTY_LIST = 'rgba(0, 0, 0, 0.25)';
export const GRAY_4 = '#999999';
export const GRAY_6 = '#a4a4a4';
export const GRAY_7 = '#ededed';
export const GRAY_8 = '#eeeeee';
export const GRAY_5 = '#f9f9f9';
export const GRAY_8_5 = '#e6e1e133';
export const GRAY_10 = '#dfdfdf';
export const GRAY_11 = '#e8e5e5';
export const GRAY_12 = '#f4f4f4';
export const GRAY_13 = '#b1b1b1';
export const GRAY_14 = '#c6c6c6';

export const DARK_GREEN = '#7a9403';
export const GREEN_HOVER = '#698002';
export const GREEN_2 = '#688816';
export const GREEN_3 = '#B2C488';
export const LIGHT_GREEN = '#eff1e4';
export const LIGHT_GREEN_2 = '#ECF3DA';

export const ORANGE_1 = '#ff8000';
export const ORANGE_HOVER = '#c66400';
export const ORANGE_2 = '#ffecd9';
export const ORANGE_3 = '#fff2e5';
export const ORANGE_4 = '#ffb579';
export const LIGHT_YELLOW = '#fada82';
export const WARNING_YELLOW_1 = '#f9cc02';
export const WARNING_YELLOW_2 = '#fdf7d6';

export const LIGHT_ORANGE_1 = '#ffaa00';
export const LIGHT_ORANGE_2 = '#ffd072';
export const LIGHT_ORANGE_3 = '#fff6e5';

export const DARK_BLUE = '#366ba1';
export const BLUE_HOVER = '#1f6bb9';
export const BLUE_2 = '#98b0cc';
export const LIGHT_BLUE = '#CCE6F4';

export const RED = '#e41411';
export const RED_2 = '#941411';
export const LIGHT_RED = '#feedee';
export const RED_HOVER = '#ff0000';

export const WHITE = '#ffffff';
export const WHITE_HOVER = GRAY_9;
export const BLACK = '#000000';
export const BLACK_1 = '#333333';

export const NEUTRAL_FIELD_BORDER = 'rgba(34, 36, 38, 0.15)';
export const FOCUS_FIELD_BORDER = '#85B7D9';
export const ERROR_FIELD_BACKGROUND = '#FFF6F6';
export const ERROR_FIELD_COLOR = '#9F3A38';
export const ERROR_FIELD_BORDER = '#E0B4B4';

export const CHAT = '#238DE9';

export const DANCHIANO = '#4bbaa2';
export const DARK_DANCHIANO = '#296c64';
export const GREEN_DANCHIANO = '#32CCAA';
export const LIGHT_GREEN_DANCHIANO = '#E2F9F5';
export const LIGHT_GREEN_DANCHIANO_2 = '#C2E9D4'

export const GALANTE_MARTINS = '#B3232D';
export const GALANTE_MARTINS_HOVER = '#D11F28';

export const BLUE_FBL = '#1F7CB7';

export const LINKEDIN = '#1f88be';
export const FACEBOOK = '#1877F2';
export const TWITTER = '#1DA1F2';
export const SKYPE = '#00AFF0';
export const SKYPE_LIGHT = '#E0F3FC';
export const WHATSAPP = '#25D366';
export const WHATSAPP_LIGHT = '#E9F5EA';
export const EXCEL = '#21A366';

export const SAPPHIRE_1 = '#356aa0';
export const SAPPHIRE_2 = '#8fadca';
export const SAPPHIRE_3 = '#eaf0f5';
export const GREEN_SUSCRIPCIONES = '#f1f4e5';

export const TURQUOISE_1 = '#4dcce9'

export const TRANSITION_BLUE = '#1a344e';
export const TRANSITION_BLUE_HOVER_1 = '#122436';
export const TRANSITION_BLUE_HOVER_2 = '#2b639c';
export const TRANSITION_ORANGE = '#fdb529';
export const TRANSITION_ORANGE_HOVER_1 = '#e57606';
export const TRANSITION_ORANGE_HOVER_2 = '#f8ad1c';

export const PROCESOS_SELECCION_DARK = '#2c3358';
export const PROCESOS_SELECCION_LIGHT = '#475490';
export const PROCESOS_SELECCION_SECONDARY = '#B7BEDC';
export const PROCESOS_SELECCION_GRAY = '#edeef3';
export const PROCESOS_SELECCION_LAVANDER = '#9f7cbb';

/* Breakpoints */

/* 320 - 767 */
export const MOBILE = { minWidth: Responsive.onlyMobile.minWidth, maxWidth: Responsive.onlyMobile.maxWidth };
/* 768 - 991 */
export const TABLET = { minWidth: Responsive.onlyTablet.minWidth, maxWidth: Responsive.onlyTablet.maxWidth };
/* 992 - 1199 */
export const COMPUTER = { minWidth: Responsive.onlyComputer.minWidth, maxWidth: Responsive.onlyLargeScreen.minWidth - 1 };
/* 1200 - 1919 */
export const LARGE_SCREEN = { minWidth: Responsive.onlyLargeScreen.minWidth, maxWidth: Responsive.onlyLargeScreen.maxWidth };
/* 1920 - ... */
export const WIDE_SCREEN = { minWidth: Responsive.onlyWidescreen.minWidth };
