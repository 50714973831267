import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { LIGHT_GRAY, ORANGE_1, GRAY_10 } from 'styles/variables';

const SliderBJ = (props) => {
  const dottedStyle = props.dotted
    ? { bottom: '-6px', width: '13px', height: '13px', borderColor: LIGHT_GRAY, backgroundColor: LIGHT_GRAY }
    : { display: 'none' };
  return (
    <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
      <Slider
        style={{ marginTop: '10px', marginBottom: '10px' }}
        trackStyle={{
          backgroundColor: GRAY_10,
          border: 'none',
          height: '8px',
        }}
        handleStyle={{
          backgroundColor: ORANGE_1,
          boxShadow: 'none',
          border: 'none',
          height: '18px',
          width: '18px',
          borderRadius: '100%',
          top: '35%',
        }}
        railStyle={{
          backgroundColor: GRAY_10,
          height: '8px',
        }}
        dotStyle={dottedStyle}
        {...props}
      />
    </div>
  );
};
SliderBJ.propTypes = {
  dotted: PropTypes.bool,
};
export default SliderBJ;
