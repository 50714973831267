import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { ORANGE_1, DARK_GRAY } from 'styles/variables';

const SemanticProgress = ({ full, ...other }) => <Progress {...other} />;

SemanticProgress.propTypes = {
  full: PropTypes.bool,
};

const StyledProgress = styled(SemanticProgress)`
  max-width: 400px !important;
  margin: 30px auto 0 !important;

  .bar {
    height: 8px !important;
    background: ${ORANGE_1} !important;
    transition: width 3s ease !important;
    ${(props) => props.full && css`
      transition: width 0.1s ease !important;
    `}

    :after {
      background: ${DARK_GRAY} !important;
      animation: progress-active-bj 2s ease infinite !important;

      @keyframes progress-active-bj {
        0%   {opacity: 0.7; width: 0%;}
        100%   {opacity: 0; width: 100%;}
      }
    }
  }
`;

const Progreso = (props) => {
  const { full } = props;

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const updateProgress = (amount) => setPercent(amount);

    setTimeout(() => updateProgress(15), 5000);
    setTimeout(() => updateProgress(30), 10000);
    setTimeout(() => updateProgress(45), 15000);
    setTimeout(() => updateProgress(60), 20000);
    setTimeout(() => updateProgress(75), 25000);
    setTimeout(() => updateProgress(90), 30000);

    return () => setPercent(0);
  }, []);

  return (
    <StyledProgress
      active
      percent={full ? 100 : percent}
      size="tiny"
      full={full}
    />
  );
};

Progreso.propTypes = {
  full: PropTypes.bool,
};

export default Progreso;
