import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Message } from 'semantic-ui-react';
import { GRAY_1, GRAY_4, GRAY_8, RED, LIGHT_RED, DARK_GREEN, LIGHT_GREEN, WARNING_YELLOW_1, WARNING_YELLOW_2,
  ORANGE_1, ORANGE_2, DARK_BLUE, LIGHT_BLUE } from 'styles/variables';

const SemanticMessage = ({ border, borderLeft, mainColor, headerColor, baseColor, big, simple, textAlign,
  marginTop, marginBottom, vertical, ...other }) => (
    <Message {...other} />
);

SemanticMessage.propTypes = {
  mainColor: PropTypes.string,
  baseColor: PropTypes.string,
  border: PropTypes.bool,
  borderLeft: PropTypes.bool,
  big: PropTypes.bool,
  headerColor: PropTypes.string,
  simple: PropTypes.bool,
  textAlign: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  vertical: PropTypes.bool,
};

const StyledMessage = styled(SemanticMessage)`
  border-radius: 0 !important;
  box-shadow: none !important;
  color: ${GRAY_1} !important;
  margin: 0 !important;
  ${(props) => props.border && css`
    border: 1px ${props.mainColor || GRAY_4} solid !important;
  `}
  ${(props) => props.borderLeft && css`
    border: 3px ${props.mainColor || GRAY_4} solid !important;
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
  `}
  background-color: ${(props) => props.simple ? 'transparent' : (props.baseColor || GRAY_8)} !important;
  ${(props) => props.marginTop && css`
    margin-top: ${props.marginTop} !important;
  `}
  ${(props) => props.marginBottom && css`
    margin-bottom: ${props.marginBottom} !important;
  `}
  ${(props) => props.simple && css`
    padding: 0 !important;
  `}
  ${(props) => props.vertical && css`
    flex-direction: column !important;
    text-align: center !important;
    > .icon {
      margin: 0 !important;
      margin-bottom: 20px !important;
      &.close {
        margin: 0 !important;
      }
    }
  `}
  .header {
    color: ${(props) => props.headerColor || props.mainColor || GRAY_4} !important;
    ${(props) => props.big && css`
      font-size: 20px !important;
      margin-bottom: 7px !important;
    `}
  }
  > .icon {
    font-size: ${(props) => props.big ? '2.5em' : '1.5em'} !important;
    opacity: 1 !important;
    color: ${(props) => props.mainColor || GRAY_4} !important;
    &.close {
      font-size: 1.1em !important;
    }
  }
  .content {
    ${(props) => props.textAlign && css`
      text-align: ${props.textAlign} !important;
    `}
  }
  .message-text {
    font-style: italic !important;
    ${(props) => props.big && css`
      font-style: normal !important;
      font-size: 16px !important;
      font-weight: bold !important;
    `}
  }
  ${(props) => props.size === "massive" && css`
    padding: 1.5em !important;
    .header {
      font-size: 36px !important;
      margin-bottom: 7px !important;
    }
    > .icon {
      font-size: 4em !important;
      &.close {
        font-size: 1.1em !important;
      }
    }
    .message-text {
      font-style: normal !important;
      font-size: 24px !important;
      font-weight: bold !important;
    }
  `}
`;

const BJMessage = (props) => {
  const { type, headerColor, header, text, onDismiss, border, borderLeft, big, simple, textAlign, marginTop,
    marginBottom, icon, primaryColor, secondaryColor, vertical, ...other } = props;

  let mainColor;
  let baseColor;
  // let headerText;
  let iconName;

  switch (type) {
    case 'info':
      mainColor = GRAY_4;
      baseColor = GRAY_8;
      // headerText = 'Información:';
      iconName = 'info circle';
      break;
    case 'success':
      mainColor = DARK_GREEN;
      baseColor = LIGHT_GREEN;
      // headerText = 'Éxito:';
      iconName = 'check circle';
      break;
    case 'warning':
      mainColor = simple ? WARNING_YELLOW_1 : ORANGE_1;
      baseColor = simple ? WARNING_YELLOW_2 : ORANGE_2;
      // headerText = 'Advertencia:';
      iconName = 'warning sign';
      break;
    case 'error':
      mainColor = RED;
      baseColor = LIGHT_RED;
      // headerText = 'Error:';
      iconName = 'remove circle';
      break;
    case 'suggestion':
      mainColor = DARK_BLUE;
      baseColor = LIGHT_BLUE;
      iconName = 'idea';
      break;
    case 'custom':
      mainColor = primaryColor;
      baseColor = secondaryColor;
      iconName = icon;
      break;
    default:
      mainColor = GRAY_4;
      baseColor = GRAY_8;
      // headerText = 'Información:';
      iconName = 'info circle';
  }

  return (
    <StyledMessage
      mainColor={mainColor}
      baseColor={baseColor}
      icon={iconName}
      border={border}
      borderLeft={borderLeft}
      header={header}
      headerColor={headerColor}
      content={<span className="message-text">{text}</span>}
      onDismiss={onDismiss}
      textAlign={textAlign}
      big={big}
      simple={simple}
      marginTop={marginTop}
      marginBottom={marginBottom}
      vertical={vertical}
      {...other}
    />
  );
};

BJMessage.propTypes = {
  type: PropTypes.string.isRequired,
  header: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onDismiss: PropTypes.func,
  border: PropTypes.bool,
  borderLeft: PropTypes.bool,
  big: PropTypes.bool,
  headerColor: PropTypes.string,
  simple: PropTypes.bool,
  textAlign: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  icon: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  vertical: PropTypes.bool,
};

export default BJMessage;
