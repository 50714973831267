import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { fontColor } from 'styles/utils';

const IconBJ = ({ color, inverted, noShadow, ...other }) => <Icon {...other} />;

IconBJ.propTypes = {
  color: PropTypes.string,
  inverted: PropTypes.bool,
  noShadow: PropTypes.bool,
};

const StyledIcon = styled(IconBJ)`
  ${(props) => props.color && css`
    ${props.inverted ? 'background-color' : 'color'}: ${props.color} !important;
  `}
  ${(props) => props.inverted && props.color && css`
    color: ${fontColor(props.color)} !important;
  `}
  ${(props) => props.noShadow && css`
    box-shadow: none !important;
  `}
`;

export default StyledIcon;
