import { put, call, takeLatest, select } from 'redux-saga/effects';
import request from 'utils/request';
import { startsWith } from 'lodash';
import { API_BASE_URL } from 'containers/Api/constants';
import { paisSelector, apiVersionSelector } from 'containers/App/selectors';
import { AUTH_REQUEST, AUTH_TOKEN, AUTH_LOGOUT } from './constants';
import { handleAuthentication, handleError } from './actions';

function* makeAuth(url, options) {
  try {
    const response = yield call(request, url, options);
    const token = response.id;

    if (!token) {
      // evitamos loop react -> node -> react ...
      yield window.location.replace('/app/logout');
    } else {
      yield put(handleAuthentication(token));
    }
  } catch (err) {
    yield put(handleError(err));
  }
}

function* makeLogin(action) {
  const pais = yield select(paisSelector);
  const version = yield select(apiVersionSelector);

  const url = `${API_BASE_URL}/${version}/${pais}/api/usuarios/login`;
  const { username, password } = action.payload;

  const options = {
    method: 'post',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  };

  yield makeAuth(url, options);
}

// Intercambia el ASP.NET_SessionId por un JWT
export function* fetchToken() {
  const pais = yield select(paisSelector);
  const version = yield select(apiVersionSelector);

  const url = `${API_BASE_URL}/${version}/${pais}/api/usuarios/token`;

  const sessionCookie = document.cookie.split('; ').find((c) => startsWith(c, 'ASP.NET_SessionId'));
  if (sessionCookie) {
    const sessionId = decodeURIComponent(sessionCookie.split('=')[1]);

    const options = {
      method: 'post',
      headers: {
        Accept: 'application/json',
        SessionId: sessionId,
      },
    };

    yield makeAuth(url, options);
  } else {
    yield put(handleError({ title: 'No se puede obtener un nuevo token' }));
  }
}

// function* makeRefresh(action) {
//   const url = `${API_REST_URL}/auth/refresh`;
//   const options = {
//     method: 'post',
//     headers: { Accept: 'application/json', Authorization: `bearer ${action.payload.token}` },
//   };
//   yield makeAuth(url, options);
// }

function* makeLogout() {
  yield localStorage.removeItem('token');
}

export function* authWatcher() {
  yield takeLatest(AUTH_REQUEST, makeLogin);
  yield takeLatest(AUTH_TOKEN, fetchToken);
  // yield takeLatest(AUTH_EXPIRED, makeRefresh);
  yield takeLatest(AUTH_LOGOUT, makeLogout);
}

export default [authWatcher];
