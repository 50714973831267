import { Editor, Element, Transforms } from 'slate';
import { HOTKEYS, PARAGRAPH, BULLETED_LIST, NUMBERED_LIST, LIST_ITEM, LIST_TYPES } from '../constants';

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
}

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

export const isBlockActive = (editor, format) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
    })
  );

  return !!match;
}

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  });
  Transforms.setNodes(editor, { type: isActive ? PARAGRAPH : isList ? LIST_ITEM : format });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
}

export const isMac = () => navigator.userAgent.indexOf("Mac") !== -1;

export const handleOnKeyDown = (e, editor) => {
  // Handles pressing 'Enter' or 'Backspace' on empty list items
  if (e.key === 'Enter' || e.key === 'Backspace') {
    const isBulletedListActive = isBlockActive(editor, BULLETED_LIST);
    const isNumberedListActive = isBlockActive(editor, NUMBERED_LIST);

    // If I'm on a list
    if (isBulletedListActive || isNumberedListActive) {
      // Get actual path
      const [, path] = Editor.first(editor, editor.selection);
      // Get list item node
      const [node,] = Editor.above(editor, { at: path });
      if (node.children?.length > 0) {
        // Collapse list item text to check if it is empty
        const listItemText = node.children.map((n) => n.text).join('');
        if (listItemText === '') {
          e.preventDefault();
          // Just remove list block
          toggleBlock(editor, isBulletedListActive ? BULLETED_LIST : NUMBERED_LIST);
          return;
        }
      }
    }
  }

  // Handles hotkeys
  if ((isMac() && !e.metaKey) || !e.ctrlKey) {
    return;
  }
  const mark = HOTKEYS[e.key];
  if (mark) {
    e.preventDefault();
    toggleMark(editor, mark);
  }
}

export const replaceText = (editor, text) => {
  // Set the selection to the entire contents of the editor
  Transforms.select(editor, {
    anchor: Editor.start(editor, []),
    focus: Editor.end(editor, []),
  });
  // Remove nodes in the selection
  Transforms.removeNodes(editor);
  // Inserts nodes at the current selection
  Transforms.insertNodes(editor, text);
}
