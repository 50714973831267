import { getAsyncInjectors } from 'utils/asyncInjectors';
import { loadModules, renderNetworkError } from 'utils/moduleLoader';

export default function (store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store); // eslint-disable-line no-unused-vars

  const operadorPanel = {
    name: 'operador-panel',
    path: 'operador',
    getComponent(nextState, cb) {
      loadModules([
        import('containers/Operador/Panel'),
        import('containers/Operador/Panel/reducer'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component, reducer] = modules;

        injectReducer(this.name, reducer.default);
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const listadoEmpresasRoute = {
    name: 'operador-empresas',
    path: 'operador/empresas',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Empresa/ListadoEmpresas'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const adminEmpresaRoute = {
    name: 'operador-empresa',
    path: 'operador/empresas/:id',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Empresa/AdminEmpresa'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const listadoOfertasRoute = {
    name: 'operador-ofertas',
    path: 'operador/ofertas',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Oferta/ListadoOfertas'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const adminOfertaRoute = {
    name: 'operador-oferta',
    path: 'operador/ofertas/:id',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Oferta/AdminOferta'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const listadoFuncionariosRoute = {
    name: 'operador-funcionarios',
    path: 'operador/funcionarios',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Funcionarios/ListadoFuncionarios'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const adminFuncionarioRoute = {
    name: 'operador-funcionario',
    path: 'operador/funcionarios/:id',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Funcionarios/AdminFuncionario'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const adminEmpresaPaqueteRoute = {
    name: 'operador-empresapaquete',
    path: 'operador/empresapaquetes/:id',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/EmpresaPaquete/AdminEmpresaPaquete'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const listadoPaqueteRoute = {
    name: 'operador-paquetes',
    path: 'operador/paquetes',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Paquetes/ListadoPaquetes'),
      ], cb)
            .then(([modules, renderRoute]) => {
              const [component] = modules;
              renderRoute(component);
            })
            .catch(err => renderNetworkError(err, cb));
    },
  };
  const adminPaqueteRoute = {
    name: 'operador-paquete',
    path: 'operador/paquetes/:id',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Paquetes/FormPaquete'),
      ], cb)
            .then(([modules, renderRoute]) => {
              const [component] = modules;
              renderRoute(component);
            })
            .catch(err => renderNetworkError(err, cb));
    },
  };

  const adminPaqueteCrearRoute = {
    name: 'operador-paquetes-crear',
    path: 'operador/paquetes/crear',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Paquetes/FormPaquete'),
      ], cb)
            .then(([modules, renderRoute]) => {
              const [component] = modules;
              renderRoute(component);
            })
            .catch(err => renderNetworkError(err, cb));
    },
  };

  const adminPaqueteAsignarRoute = {
    name: 'operador-paquetes-asignar',
    path: 'operador/paquetes/asignar',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Paquetes/AsignarPaquete'),
      ], cb)
            .then(([modules, renderRoute]) => {
              const [component] = modules;
              renderRoute(component);
            })
            .catch(err => renderNetworkError(err, cb));
    },
  };

  const listadoEmpresaPaqueteRoute = {
    name: 'operador-empresapaquete',
    path: 'operador/empresapaquetes',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/EmpresaPaquete/ListadoEmpresaPaquetes'),
      ], cb)
            .then(([modules, renderRoute]) => {
              const [component] = modules;
              renderRoute(component);
            });
    },
  };

  const listadoPostulantesRoute = {
    name: 'operador-postulantes',
    path: 'operador/postulantes',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Postulante/ListadoPostulantes'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const adminPostulanteRoute = {
    name: 'operador-postulante',
    path: 'operador/postulantes/:id',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Postulante/AdminPostulante'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const adminUsuarioRoute = {
    name: 'operador-usuario',
    path: 'operador/usuarios/:id',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Usuario/AdminUsuario'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const listadoPagosRoute = {
    name: 'operador-pagos',
    path: 'operador/pagos',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Pagos/ListadoPagos'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const adminPagoRoute = {
    name: 'operador-pago',
    path: 'operador/pagos/:id',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Pagos/AdminPago'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const operadorBusquedaLibrePostulantesRoute = {
    name: 'operador-buscar-postulantes',
    path: 'operador/buscar-postulantes',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/BusquedaLibrePostulantes'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const candidatoRoute = {
    name: 'operador-candidato',
    path: 'operador/busqueda-libre/postulante-:idPostulante',
    getComponent(nextState, cb) {
      loadModules([
        import('containers/Operador/Candidato'),
        import('containers/Operador/Candidato/reducer'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component, reducerCandidato] = modules;

          injectReducer(this.name, reducerCandidato.default);
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const listadoFeedsRoute = {
    name: 'operador-feeds',
    path: 'operador/feeds',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Scraping/Feeds/ListadoFeeds'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;

          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const agregarFeedRoute = {
    name: 'operador-feed-agregar',
    path: 'operador/feeds/agregar',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Scraping/Feeds/AgregarFeed'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;

          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const editarFeedRoute = {
    name: 'operador-feed-editar',
    path: 'operador/feeds/:id',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Scraping/Feeds/EditarFeed'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;

          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const listadoBannersRoute = {
    name: 'operador-banners',
    path: 'operador/banners',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Banners/ListadoBanners'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;

          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const adminBannerRoute = {
    name: 'operador-banners-agregar',
    path: 'operador/banners/agregar',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Banners/AgregarBanner'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;

          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const editarBannerRoute = {
    name: 'operador-banners-editar',
    path: 'operador/banners/:id',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Panel/Banners/EditarBanner'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;

          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const adminImgsS3Route = {
    name: 'operador-imgs-s3',
    path: 'operador/imgs-s3',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/ImgsS3'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;

          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const validacionListadosRoute = {
    name: 'operador-validacion-listados',
    path: 'operador/listas/validacion',
    getComponent(nextState, cb) {
      loadModules([
        import('containers/Operador/ValidacionListados'),
        import('containers/Operador/ValidacionListados/reducer'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component, reducer] = modules;

          injectReducer(this.name, reducer.default);
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const enriquecerDatosPostulantesRoute = {
    name: 'operador-enriquecer-datos-postulantes',
    path: 'operador/enriquecer-datos-postulantes',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/Reportes/EnriquecerDatosPostulantes'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;

          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const planillaPostulantesLiberadosRoute = {
    name: 'operador-planilla-postulantes-liberados',
    path: 'operador/postulantes-liberados',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Operador/PlanillaLiberados'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;

          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  return {
    childRoutes: [
      operadorPanel,
      listadoEmpresasRoute,
      adminEmpresaRoute,
      listadoOfertasRoute,
      adminOfertaRoute,
      adminEmpresaPaqueteRoute,
      listadoEmpresaPaqueteRoute,
      listadoPaqueteRoute,
      adminPaqueteCrearRoute,
      adminPaqueteAsignarRoute,
      adminPaqueteRoute,
      listadoPostulantesRoute,
      adminPostulanteRoute,
      adminUsuarioRoute,
      listadoPagosRoute,
      adminPagoRoute,
      operadorBusquedaLibrePostulantesRoute,
      candidatoRoute,
      listadoFeedsRoute,
      agregarFeedRoute,
      editarFeedRoute,
      listadoBannersRoute,
      adminBannerRoute,
      editarBannerRoute,
      adminImgsS3Route,
      validacionListadosRoute,
      enriquecerDatosPostulantesRoute,
      listadoFuncionariosRoute,
      adminFuncionarioRoute,
      planillaPostulantesLiberadosRoute,
    ].filter((r) => r),
  };
}
