
const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
  throw err;
};

const loadModule = (cb) => (componentModule) => {
  cb(null, componentModule.default);
};

function componentLoader(lazyComponent, attempt) {
  return new Promise((resolve, reject) => {
    Promise.resolve(lazyComponent)
      .then(resolve)
      .catch((error) => {
        console.error(error);
        console.log('Reintentando cargar el componente. Reintento:', attempt);
        setTimeout(() => {
          if (attempt >= 3) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attempt + 1).then(resolve, reject);
        }, 1500);
      });
  });
}

export function loadModules(promises, cb) {
  const modules = Promise
    .all(promises.map(p => componentLoader(p, 1)))
    .catch(errorLoading);
  const renderRoute = loadModule(cb);

  return Promise.all([modules, renderRoute]);
}

export function renderNetworkError(err, cb) {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({
      event: 'chunk-load-error',
    }));
  }

  cb(null, () => {
    // eslint-disable-next-line no-throw-literal
    throw { statusCode: 408 };
  });
}