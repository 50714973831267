import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Label } from 'semantic-ui-react';
import { GRAY_1, GRAY_4, GRAY_8, DARK_BLUE } from 'styles/variables';
import { fontColor } from 'styles/utils';

const LabelBJ = ({ color, transparent, fieldInfo, bordered, ...other }) => <Label {...other} />;

LabelBJ.propTypes = {
  color: PropTypes.string,
  transparent: PropTypes.bool,
  fieldInfo: PropTypes.bool,
  bordered: PropTypes.bool,
};

LabelBJ.Detail = Label.Detail;
LabelBJ.Group = Label.Group;

const StyledLabel = styled(LabelBJ)`
  background-color: ${(props) => props.color || DARK_BLUE} !important;
  color: ${(props) => fontColor(props)} !important;
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
  margin-left: 7px !important;
  &.pointing {
    border-radius: 0 !important;
    padding: 0.5em !important;
  }
  
  ${(props) => props.transparent && css`
    background-color: transparent !important;
    color: ${props.color || GRAY_4} !important;
  `}
    
  ${(props) => props.bordered && css`
    border: 1px solid ${props.color || GRAY_4} !important;
  `}
  
  ${(props) => props.fieldInfo && css`
    background-color: ${GRAY_8} !important;
    color: ${GRAY_1} !important;
    border-radius: 0 !important;
    font-weight: normal !important;
    font-style: italic !important;
    padding: 13px !important;
    margin-left: 0 !important;
    margin-bottom: 7px !important;
    
    &.pointing {
      padding: 13px !important;
    }

    &.left {
      margin-bottom: 0 !important;
    }
  `}
  
  ${(props) => props.circular && css`
    &.ui.circular.label {
      min-width: 1.5em !important;
      min-height: 1.5em !important;
      padding: 3px !important;
    }
  `}
`;

StyledLabel.Detail = LabelBJ.Detail;
StyledLabel.Group = LabelBJ.Group;

export default StyledLabel;
