import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { Button } from 'components/Semantic';
import { BJMessage } from 'components/BJComponents';

import messages from '../messages';

const ErrorMessage = (props) => {
  const { error, onRetry, intl } = props;

  const unrecoverableErrors = ["MAX_TOKENS_EXCEEDED"];

  const retry = !unrecoverableErrors.includes(error?.code);

  const errorText = retry ?
    intl.formatMessage(messages.embellecerError)
  :
    intl.formatMessage(messages[error.code]);

  return (
    <BJMessage
      type="error"
      text={
        <Grid verticalAlign="middle">
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={retry ? 10 : 16}
              computer={retry ? 12 : 16}
              largeScreen={retry ? 13 : 16}
              widescreen={retry ? 13 : 16}
            >
              <span style={{ overflowWrap: 'anywhere' }}>{errorText}</span>
            </Grid.Column>
            {retry ? (
              <>
                <Grid.Column width={16} only="mobile" style={{ padding: '10px' }} />
                <Grid.Column mobile={16} tablet={6} computer={4} largeScreen={3} widescreen={3}>
                  <Button
                    fluid
                    style={{ padding: '10px' }}
                    onClick={onRetry}
                  >
                    {intl.formatMessage(messages.embellecerReintentar)}
                  </Button>
                </Grid.Column>
              </>
            ) : null}
          </Grid.Row>
        </Grid>
      }
    />
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.object,
  onRetry: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(ErrorMessage);
