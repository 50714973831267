import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import * as variables from 'styles/variables';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${variables.NEUTRAL_FIELD_BORDER};

  .bj-toolbar {
    padding: 0.42em;
    background-color: ${variables.GRAY_5};
  
    .jazzy {
      background-color: ${variables.WHITE} !important;
      border-radius: 50px !important;
      padding: 6px 12px !important;
      box-shadow: 0px 2px 3px -1px ${variables.GRAY_4};

      &:hover {
        box-shadow: 0px 2px 3px -1px #bbbbbb;

        span {
          color: ${variables.GRAY_4} !important;
        }
      }

      i {
        font-size: 18px !important;
        animation: rainbow 1500ms infinite;

        @keyframes rainbow {
          0%   {color: ${variables.DARK_GRAY}; }
          50%  {color: ${variables.ORANGE_1}; }
          100% {color: ${variables.DARK_GRAY}; }
        }
      }

      &.disabled {
        i {
          animation: none;
        }
      }
    }
  }

  .bj-editable {
    height: ${(props) => props.rows * 20 + 22}px;
    min-height: 42px;
    background-color: ${variables.WHITE};
    overflow: auto;
    resize: vertical;
    div[role="textbox"] {
      height: 100%;
      padding: 11px 14px;
      cursor: text;
      span[data-slate-placeholder="true"] {
        width: unset !important;
      }
    }
  }

  ${(props) => props.success && css`
    border: 1px solid ${variables.GREEN_3};
  `}

  ${(props) => props.focus && css`
    border: 1px solid ${variables.FOCUS_FIELD_BORDER};
  `}

  ${(props) => props.error && css`
    border: 1px solid ${variables.ERROR_FIELD_BORDER};
    color: ${variables.ERROR_FIELD_COLOR};

    .bj-editable {
      background-color: ${variables.ERROR_FIELD_BACKGROUND};
    }
  `}
`;

Container.propTypes = {
  rows: PropTypes.number,
  success: PropTypes.bool,
  focus: PropTypes.bool,
  error: PropTypes.bool,
};
