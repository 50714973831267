import PropTypes from 'prop-types';

const REPO_QUERY = 'DataLoader/REPO_QUERY';
export const REPO_QUERY_REQUEST = `${REPO_QUERY}_REQUEST`;
export const REPO_QUERY_SUCCESS = `${REPO_QUERY}_SUCCESS`;
export const REPO_QUERY_FAILURE = `${REPO_QUERY}_FAILURE`;
export const REPO_QUERY_TERMINATED = `${REPO_QUERY}_TERMINATED`;
export const REPO_QUERY_COUNT = `${REPO_QUERY}_COUNT`;

export const Operations = {
  GET: 'get',
  LIST: 'list',
  CREATE: 'create',
  UPDATE: 'update',
  COUNT: 'count',
  DELETE: 'delete',
};

export const DataLoaderShape = PropTypes.shape({
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number]),
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  page: PropTypes.number,
  pageSize: PropTypes.number,
  count: PropTypes.number,
  hasMore: PropTypes.bool,
});
