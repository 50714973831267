import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Search } from 'semantic-ui-react';
import { LIGHT_GRAY, GRAY_1, GRAY_4, GRAY_8, WHITE, BLACK, TABLET } from 'styles/variables';
import { BJLoaderSmall } from 'components/BJComponents/BJLoader';
import Icon from './Icon';

const SearchBJ = ({ simpleCategory, simpleResult, ...other }) => <Search {...other} />;

SearchBJ.Category = Search.Category;
SearchBJ.Result = Search.Result;
SearchBJ.Results = Search.Results;

SearchBJ.propTypes = {
  simpleCategory: PropTypes.bool,
  simpleResult: PropTypes.bool,
};

const StyledSearch = styled(SearchBJ)`
  input {
    border-radius: 0 !important;
  }
  *::placeholder {
    color: ${GRAY_1} !important;
  }

  .ui.icon.input {
    width: 100%;
  }

  .results {
    margin-top: 5px !important;
    border-radius: 0!important;

    &.transition.visible {
      max-height: 250px;
      overflow: auto;
    }

    .result {
      padding: 9px 16px !important;
      ${(props) => props.simpleResult && css`
        border: none !important;
      `}

      &:hover {
        background-color: ${GRAY_8} !important;
      }

      &.active {
        background-color: ${GRAY_8} !important;
      }
    }
  }

  ${(props) => props.simpleCategory && css`
    .results {

      .category {
        background-color: ${WHITE} !important;

        div {
          border-bottom: none !important;
          font-weight: normal !important;

          :hover {
            font-weight: bold !important;
            background-color: ${WHITE} !important;
          }

          .content {
            padding: 0 !important;

            .title {
              background-color: ${WHITE} !important;
              color: ${BLACK} !important;
              border-top: none !important;
              padding: 0 !important;
            }
          }
        }

        .name {
          padding: 0 !important;
          width: 0 !important;
        }
      }
    }
  `}

  // Loader styles
  &.loading .input > i.icon:before {
    border: none !important;
  }
  &.loading .input > i.icon:after {
    ${BJLoaderSmall}
  }

  ${(props) => props.readOnly && css`
    input {
      color: ${GRAY_1} !important;
      background-color: ${GRAY_8} !important;
      font-style: italic !important;
      border-color: ${LIGHT_GRAY} !important;
    }
  `}
`;

StyledSearch.Category = SearchBJ.Category;
StyledSearch.Result = SearchBJ.Result;
StyledSearch.Results = SearchBJ.Results;

const ClearableSearch = styled.div`
  position: relative;
  .bj-clear-button {
    display: none !important;
    @media(max-width: ${TABLET.maxWidth}px) {
      display: inline-block !important;
    }
  }
  &:hover {
    .bj-clear-button {
      display: inline-block !important;
    }
  }
`;

const ClearButton = styled(Icon)`
  cursor: pointer !important;
  position: absolute !important;
  top: 10px;
  right: 7px;
`;

const SpecialSearch = ({ onClear, ...other }) => {
  if (onClear) {
    return (

      <ClearableSearch>
        <StyledSearch {...other} />
        {!other.disabled && !other.readOnly &&
          <ClearButton className="bj-clear-button" name="delete" color={GRAY_4} onClick={onClear} />
        }
      </ClearableSearch>
    );
  }

  return (<StyledSearch {...other} />);
};

SpecialSearch.propTypes = {
  onClear: PropTypes.func,
};

SpecialSearch.Category = StyledSearch.Category;
SpecialSearch.Result = StyledSearch.Result;
SpecialSearch.Results = StyledSearch.Results;

export default SpecialSearch;
