import React from 'react';
import PropTypes from 'prop-types';
import { PARAGRAPH, BULLETED_LIST, NUMBERED_LIST, LIST_ITEM } from './constants';

const BlockElement = (props) => {
  const { element, attributes, children } = props;

  switch (element.type) {
    case LIST_ITEM:
      return (
        <li {...attributes}>
          {children}
        </li>
      );
    case NUMBERED_LIST:
      return (
        <ol {...attributes}>
          {children}
        </ol>
      );
    case BULLETED_LIST:
      return (
        <ul {...attributes}>
          {children}
        </ul>
      );
    case PARAGRAPH:
      return (
        <p {...attributes}>
          {children}
        </p>
      );
    default:
      return (
        <p {...attributes}>
          {props.children}
        </p>
      );
  }
};

BlockElement.propTypes = {
  element: PropTypes.object.isRequired,
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default BlockElement;
