import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, Segment } from 'components/Semantic';
import { DARK_GREEN, GRAY_7 } from 'styles/variables';

import ColorText from './ColorText';

import messages from './messages';


const CautionModal = (props) => {
  const { action, trigger, children, open, loading, close, no, yes } = props;
  return (
    <Modal trigger={trigger} open={open} size="mini" onClose={close}>
      <Modal.Header>{children[0]}</Modal.Header>
      <Modal.Content>
        <Segment loading={loading} transparent noPadding>
          <ColorText>{children[1]}</ColorText>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button color={GRAY_7} onClick={close} disabled={loading}>
          {no || <FormattedMessage {...messages.no} />}
        </Button>
        <Button color={DARK_GREEN} onClick={action} autoFocus={open} disabled={loading}>
          {yes || <FormattedMessage {...messages.si} />}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

CautionModal.propTypes = {
  action: PropTypes.func,
  trigger: PropTypes.node,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  close: PropTypes.func,
  children: PropTypes.node,
  no: PropTypes.string,
  yes: PropTypes.string,
};
export default CautionModal;
