import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import settings from 'settings';

import blog from 'images/icons/blog.svg';

import Button from './Button';

import messages from '../messages';

import '../header.css';

const LoginButtons = (props) => {
  const { intl } = props;

  return (
    <div className="loginButtons">
      {/* Blog Button */}
      {settings.blog ? (
        <Button
          simple
          href="/blog/"
          img={blog}
          text="Blog"
        />
      ) : null}
      {/* Company Button */}
      <Button
        btnClass="companyButton"
        href="/empresas"
        icon="briefcase"
        text={intl.formatMessage(messages.publicarOferta)}
      />
      {/* Applicants Button */}
      <Button
        btnClass="applicantButton"
        href="/login"
        icon="user circle"
        text={intl.formatMessage(messages.ingresoCandidatos)}
      />
    </div>
  );
};

LoginButtons.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LoginButtons);
