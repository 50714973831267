import React from 'react';
import PropTypes from 'prop-types';
import styledC, { css } from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import { BLACK_1, GRAY_1, GRAY_4, GRAY_6, DARK_GRAY, DARK_BLUE, BLUE_HOVER, WHITE,
  TRANSITION_BLUE, TRANSITION_BLUE_HOVER_1, TRANSITION_BLUE_HOVER_2, TRANSITION_ORANGE, ORANGE_1,
  TRANSITION_ORANGE_HOVER_2, TRANSITION_ORANGE_HOVER_1 } from 'styles/variables';
import { hoverBackgroundColor, fontColor } from 'styles/utils';


// Dropdown.Item

const BJDropdownItem = ({ color, bold, ...other }) => <Dropdown.Item {...other} />;

BJDropdownItem.propTypes = {
  color: PropTypes.string,
  bold: PropTypes.bool,
};

const StyledDropdownItem = styledC(BJDropdownItem)`
  ${(props) => props.color && css`
    .text {
      color: ${props.color} !important;
    }
  `}
  ${(props) => props.bold && css`
    .text {
      font-weight: bold;
    }
  `}
`;


// Dropdown

const DropdownBJ = ({ search, color, navbar, styled, userType, ...other }) => (
  <Dropdown
    {...other}
    search={search ?
      (options, query) => {
        const search = query.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

        return options.filter((o) => {
          const option = typeof o.text === "string" ? o.text : o.fullname;

          return option.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(search) >= 0;
        });
      }
    : false}
    selectOnBlur={false}
  />
);

DropdownBJ.Divider = Dropdown.Divider;
DropdownBJ.Header = Dropdown.Header;
DropdownBJ.Item = StyledDropdownItem;
DropdownBJ.Menu = Dropdown.Menu;
DropdownBJ.SearchInput = Dropdown.SearchInput;

DropdownBJ.propTypes = {
  search: PropTypes.bool,
  color: PropTypes.string,
  navbar: PropTypes.bool,
  styled: PropTypes.bool,
  basic: PropTypes.bool,
  userType: PropTypes.oneOf(['Postulante', 'Empresa', 'Funcionario', 'Operador']),
};

const StyledDropdown = styledC(DropdownBJ)`
  border-radius: 0 !important;

  *::placeholder {
    color: ${GRAY_1} !important;
  }

  .menu {
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  ${(props) => props.basic && css`
    background-color: transparent !important;
    color: ${props.color} !important;
    &:hover {
      background-color: transparent !important;
      color: ${hoverBackgroundColor(props)} !important;
    }
  `}

  ${(props) => props.button && css`
    border-radius: 3px !important;
    padding-left: 1em !important;
    padding-right: 2.5em !important;
    border: ${props.color || DARK_BLUE} 1px solid !important;
    background-color: ${WHITE} !important;
    color: ${GRAY_1} !important;

    i {
      background-color: ${props.color || DARK_BLUE} !important;
      opacity: 1 !important;
      color: ${fontColor(props)} !important;
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
      height: 100% !important;
      width: 2.57142857em !important;
      margin: 0 !important;
      ::before {
        display: block !important;
        position: absolute !important;
        width: 100% !important;
        top: 50% !important;
        text-align: center !important;
        transform: translateY(-50%) !important;
      }
    }

    .menu {
      border-top-color: ${hoverBackgroundColor(props) || BLUE_HOVER} !important;
      ${props.navbar && css`
        box-shadow: 1px 1px 5px -1px #000000 !important;
        border-top-color: unset !important;
      `}
    }

    &:hover {
      border: ${hoverBackgroundColor(props) || BLUE_HOVER} 1px solid !important;
      i {
        background-color: ${hoverBackgroundColor(props) || BLUE_HOVER} !important;
      }
    }

    &.active {
      border: ${hoverBackgroundColor(props) || BLUE_HOVER} 1px solid !important;
      i {
        background-color: ${hoverBackgroundColor(props) || BLUE_HOVER} !important;
      }
    }
  `}

  ${(props) => props.navbar && css`
    background-image: linear-gradient(to right, ${props.userType === 'Postulante' ? ORANGE_1 : props.userType === 'Operador' ? GRAY_1 : TRANSITION_BLUE}, ${props.userType === 'Postulante' ? TRANSITION_ORANGE : props.userType === 'Operador' ? GRAY_6 : DARK_BLUE}) !important;
    color: ${fontColor(props)} !important;
    padding-top: 12px !important;
    padding-bottom: 10px !important;
    font-family: 'Roboto', 'Arial', sans-serif !important;
    font-size: 14px !important;
    line-height: 20px !important;
    height: 44px !important;
    border: 0 !important;
    box-shadow: 1px 1px 5px -1px #000000 !important;

    i {
      background: transparent !important;
    }

    .button {
      .text {
        text-transofrm: uppercase !important;
      }
    }

    .divider {
      margin: 0 !important;
    }

    &:hover {
      background-image: linear-gradient(to right, ${props.userType === 'Postulante' ? TRANSITION_ORANGE_HOVER_1 : props.userType === 'Operador' ? BLACK_1 : TRANSITION_BLUE_HOVER_1}, ${props.userType === 'Postulante' ? TRANSITION_ORANGE_HOVER_2 : props.userType === 'Operador' ? GRAY_4 : TRANSITION_BLUE_HOVER_2}) !important;
      border: 0 !important;
      i {
        background: transparent !important;
      }
    }

    &.active {
      background-image: linear-gradient(to right, ${props.userType === 'Postulante' ? TRANSITION_ORANGE_HOVER_1 : props.userType === 'Operador' ? BLACK_1 : TRANSITION_BLUE_HOVER_1}, ${props.userType === 'Postulante' ? TRANSITION_ORANGE_HOVER_2 : props.userType === 'Operador' ? GRAY_4 : TRANSITION_BLUE_HOVER_2}) !important;
      border: 0 !important;
      i {
        background: transparent !important;
      }
    }
  `}
  ${(props) => props.styled && !props.fluid && css`
    width: 145px !important;
  `}
  ${(props) => props.styled && css`
    background-color: transparent !important;
    color: ${DARK_GRAY} !important;
    border: ${DARK_GRAY} 1px solid !important;
    i {
      background-color: transparent !important;
      color: ${DARK_GRAY} !important;
    }

    &:hover {
      background-color: ${props.color || DARK_BLUE} !important;
      color: ${fontColor(props)} !important;
      border: ${props.color || DARK_BLUE} 1px solid !important;
      i {
        background-color: ${props.color || DARK_BLUE} !important;
        color: ${fontColor(props)} !important;
      }
    }

    &.active {
      background-color: ${props.color || DARK_BLUE} !important;
      color: ${fontColor(props)} !important;
      border: ${props.color || DARK_BLUE} 1px solid !important;
      i {
        background-color: ${props.color || DARK_BLUE} !important;
        color: ${fontColor(props)} !important;
      }
    }
  `}
`;

StyledDropdown.Divider = DropdownBJ.Divider;
StyledDropdown.Header = DropdownBJ.Header;
StyledDropdown.Item = DropdownBJ.Item;
StyledDropdown.Menu = DropdownBJ.Menu;
StyledDropdown.SearchInput = DropdownBJ.SearchInput;

export default StyledDropdown;
