import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends React.Component {

  static propTypes = {
    onCatch: PropTypes.func,
    children: PropTypes.node,
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const { router } = this.props;

    // Cuando la ruta actual cambia hay que resetear el error del state.
    router.listen(() => {
      if (this.state.error) {
        this.setState({ error: null });
      }
    });
  }

  state = {
    error: null,
  };

  componentDidCatch(err, info) {
    let error;

    if (err instanceof Error) {
      error = { statusCode: 500 };
    } else {
      error = err;
    }

    this.setState({ error });

    if (this.props.onCatch) {
      this.props.onCatch(error, info);
    }

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        event: 'error',
        error,
      }));
    }

  }

  render() {
    const { error } = this.state;

    if (error) {
      return <ErrorPage status={error.statusCode} message={error.message} code={error?.code} />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
