import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Menu, Segment, Accordion } from 'semantic-ui-react';
import { Responsive } from 'components/Semantic';
import { GRAY_1, GRAY_5, GRAY_8, GRAY_10, DARK_BLUE, WHITE } from 'styles/variables';
import ContainerSegment from './ContainerSegment';

const DetailContainer = styled.div`
  text-align: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const BJSegment = ({ relaxed, ...other }) => <Segment {...other} />;

BJSegment.propTypes = {
  relaxed: PropTypes.bool,
};

const DetailSegment = styled(BJSegment)`
  text-align: right !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  margin: 0 !important;
  ${(props) => props.relaxed && css`
    margin-top: 1rem !important;
  `}
`;

const BJMenu = ({ backgroundColor, compactTabs, relaxed, ...other }) => <Menu {...other} />;

BJMenu.propTypes = {
  backgroundColor: PropTypes.string,
  compactTabs: PropTypes.bool,
  relaxed: PropTypes.bool,
};

const StyledMenu = styled(BJMenu)`
  position: relative;
  border-bottom-color: ${GRAY_10} !important;
  background-color: transparent !important;
  ${(props) => props.backgroundColor && css`
    background-color: ${props.backgroundColor || 'transparent'} !important;
  `}
  ${(props) => props.relaxed && css`
    margin-top: 1rem !important;
  `}
  
  .item {
    border-radius: 0 !important;
    background-color: ${GRAY_8} !important;
    color: ${DARK_BLUE} !important;
    text-transform: uppercase !important;
    ${(props) => props.compactTabs && css`
      padding-left: 0.8em !important;
      padding-right: 0.8em !important;
    `}
    :hover {
      background-color: ${GRAY_5} !important;
    }
  }
  
  .active-item {
    border-radius: 0 !important;
    border-width: 1px !important;
    border-color: ${GRAY_10} !important;
    border-bottom-style: solid !important;
    border-bottom-color: ${WHITE} !important;
    background-color: ${WHITE} !important;
    color: ${GRAY_1} !important;
    font-weight: 700 !important;
    margin-bottom: -1px !important;
    :hover {
      background-color: ${WHITE} !important;
    }
  }
`;

const StyledSegment = styled(BJSegment)`
  box-shadow: none !important;
  border-radius: 0 !important;
  border-color: ${GRAY_10} !important;
  
  // Por defecto el color del background es WHITE
  background-color: ${WHITE} !important;

  ${(props) => props.relaxed && css`
    margin-bottom: 1rem !important;
  `}
`;

const BJAccordion = ({ relaxedTop, relaxedBottom, ...other }) => <Accordion {...other} />;

BJAccordion.propTypes = {
  relaxedTop: PropTypes.bool,
  relaxedBottom: PropTypes.bool,
};

const StyledAccordion = styled(BJAccordion)`
  border-radius: 0 !important;

  // Por defecto el color del background es WHITE
  background-color: ${WHITE} !important;
  
  &.accordion > .title {
    background-color: ${GRAY_8} !important;
    color: ${DARK_BLUE} !important;
    font-weight: unset !important;
    text-transform: uppercase !important;
    :hover {
      background-color: ${GRAY_5} !important;
    }
    &.active {
      background-color: ${WHITE} !important;
      color: ${GRAY_1} !important;
      font-weight: 700 !important;
    }
  }
  ${(props) => props.relaxedTop && css`
    margin-top: 1rem !important;
  `}
  ${(props) => props.relaxedBottom && css`
    margin-bottom: 1rem !important;
  `}
`;

const getDefaultIndex = (activeItem, defaultActiveItem, panels) => {
  if (activeItem) {
    return getPanelIndex(activeItem, panels);
  } else if (defaultActiveItem) {
    return getPanelIndex(defaultActiveItem, panels);
  }
  return 0;
};

const getPanelIndex = (name, panels) => {
  const items = panels.map((panel) => panel.title.key);
  return Math.max(0, items.indexOf(name));
};

const TabSegment = (props) => {
  const { panels, activeItem, defaultActiveItem, onItemChange, detail, backgroundColor, exclusive,
    compactTabs, relaxed, loading } = props;

  const [activeIndex, setActiveIndex] = useState(getDefaultIndex(activeItem, defaultActiveItem, panels));

  useEffect(() => {
    if (activeItem) {
      setActiveIndex(getDefaultIndex(activeItem, defaultActiveItem, panels));
    }
  }, [activeItem]);

  // On tab change handler
  const handleItemClick = (e, { name }) => {
    if (onItemChange) {
      onItemChange(name);
    }
    setActiveIndex(getPanelIndex(name, panels));
  };

  // On accordion change handler
  const handleIndexChange = (e, { index }) => {
    if (onItemChange) {
      onItemChange(panels[index].title.key);
    }
    setActiveIndex(index);
  };

  const defaultActiveIndex = getDefaultIndex(activeItem, defaultActiveItem, panels);
  const activePanel = panels[activeIndex];

  return (
    <ContainerSegment loading={loading}>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <StyledMenu
          attached="top"
          tabular
          backgroundColor={backgroundColor}
          compactTabs={compactTabs}
          relaxed={relaxed}
        >
          {!!panels && panels.map((panel, index) => (
            <Menu.Item
              key={panel.title.key}
              name={panel.title.key}
              className={activeIndex === index ? 'active-item' : ''}
              onClick={handleItemClick}
            >
              {panel.title.content}
            </Menu.Item>
          ))}
          {!!detail && <DetailContainer>{detail}</DetailContainer>}
        </StyledMenu>
        <StyledSegment attached="bottom" relaxed={relaxed}>
          {!!activePanel &&
            <div key={activePanel.content.key}>{activePanel.content.content}</div>
          }
        </StyledSegment>
      </Responsive>
      <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
        {!!detail && <DetailSegment relaxed={relaxed}>{detail}</DetailSegment>}
        <StyledAccordion
          styled
          fluid
          exclusive={exclusive}
          defaultActiveIndex={exclusive ? defaultActiveIndex : [defaultActiveIndex]}
          panels={panels}
          onTitleClick={handleIndexChange}
          relaxedTop={relaxed && !detail}
          relaxedBottom={relaxed}
        />
      </Responsive>
    </ContainerSegment>
  );
};

TabSegment.propTypes = {
  defaultActiveItem: PropTypes.string,
  activeItem: PropTypes.string,
  onItemChange: PropTypes.func,
  panels: PropTypes.array,
  detail: PropTypes.object,
  backgroundColor: PropTypes.string,
  exclusive: PropTypes.bool,
  compactTabs: PropTypes.bool,
  relaxed: PropTypes.bool,
  loading: PropTypes.bool,
};

TabSegment.defaultProps = {
  exclusive: false,
};

export default TabSegment;
