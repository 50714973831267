import React from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { Button, Icon } from 'components/Semantic';
import { isBlockActive, toggleBlock } from '../utils/helpers';

const BlockButton = (props) => {
  const { format, icon } = props;

  const editor = useSlate();

  return (
    <Button
      onMouseDown={(e) => {
        e.preventDefault();
        toggleBlock(editor, format);
      }}
      icon
      toolbar
      toggle
      active={isBlockActive(editor, format)}
      type="button"
    >
      <Icon name={icon} />
    </Button>
  );
};

BlockButton.propTypes = {
  format: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default BlockButton;
