import React from 'react';
import PropTypes from 'prop-types';
import { BJMessage } from 'components/BJComponents';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const NotificationShape = PropTypes.shape({
  message: PropTypes.string,
  error: PropTypes.bool,
  title: PropTypes.string,
});

const Notification = ({ notification, dismissNotification }) => {
  if (notification) {
    let { error, message } = notification;

    if (!error && !message) {
      message = <FormattedMessage {...messages.updateSuccess} />;
    } else if (error && !message) {
      message = <FormattedMessage {...messages.defaultError} />;
    }

    return (
      <BJMessage
        type={error ? 'error' : 'success'}
        attached="bottom"
        text={<span>{message}</span>}
        onDismiss={() => dismissNotification()}
      />
    );
  }
  return null;
};

Notification.propTypes = {
  notification: NotificationShape,
  dismissNotification: PropTypes.func.isRequired,
};

export default Notification;
