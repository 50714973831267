import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { startsWith } from 'lodash';
import { Link } from 'components/Navigation';
import { Header, Button, Dropdown } from 'components/Semantic';
import styled from 'styled-components';
import settings from 'settings';
import { Container, Grid, List, Image, Tab, Menu, Icon } from 'semantic-ui-react';
import moment from 'moment';

import { DARK_BLUE, ORANGE_1, WHITE, MOBILE } from 'styles/variables';

import logoFooter from 'images/logo-bj-blanco.svg';
import logoFooterFJ from 'images/logo-fj-blanco.svg';
import logoFooterCJ from 'images/logo-cj-blanco.svg';
import logoLinkedin from 'images/icons/linkedin.svg';
import logoFacebook from 'images/icons/facebook.svg';
import waveFooter from 'images/DivisorPie.svg';

import messages from './messages';


const Wave = (props) => (<Image src={waveFooter} {...props} />);

const StyledWaveFooter = styled(Wave)`
  margin-bottom: -1px;
  width: 100%;
`;

const AppFooter = styled(Grid)`
  //background-color: ${WHITE} !important;
  border: 0px !important;
  margin-top: 20px !important;
  padding-bottom: 14px !important;
`;

const GradientContainer = styled(Container)`
  background-color: ${WHITE} !important;

  @media (max-width: ${MOBILE.maxWidth}px) {
    padding 0 !important;
    min-width: 90% !important;
  }
`;

const BlueGradientContainer = styled(Grid.Row)`{
  margin-top: 20px;
  min-height: 70px;
  background-image: linear-gradient(to right, #1a344e, #366ba1);
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  width: 100% !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}`;

const WhiteContainer = styled(Grid)`
  background-color: ${WHITE} !important;
  width: 100%;
  padding: 0 !important;
  justify-content: center !important;
`;

const FooterContent = styled(Grid)`
  margin-top: 0px !important;
`;

const SectionText = styled(Grid.Column)`
  border: 0px !important;
  margin-top: -5px;
  padding-left: 50px !important;
  padding-top: 30px !important;
`;

const SectionOrangeText = styled(Grid.Column)`{
  padding-left: 50px !important;
  align-self: center !important;
}`;

const SpacedRow = styled(Grid.Row)`
  padding-top: 30px !important;
`;

const GreyList = styled(List.Item)`
  color: #999999 !important;
  &:hover {
    color: #1E70BF !important;
  }
`;

const SmallText = styled.small`{
  font-size: 89%;
}`;

const CountryText = styled.small`{
  color: white;
  font-size: 14px;
  margin-bottom: 3px;
}`;

const OrangeHeaderText = styled.h1`{
  color: #FF8000;
  font-weight: bold;
  font-size: 14px;
}`;

const BjTab = styled(Tab)`{

  .pointing {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    border-bottom-width: 0px !important;
    width: 100% !important;
      a {
        width: 33% !important;
        border: 0px !important;
        justify-content: center !important;
        color: ${DARK_BLUE} !important;
        font-weight: bold !important;

        i {
          margin-left: 5px !important;

          &.down {
            display: block !important
          }

          &.up {
            display: none !important;
          }
        }

        &.active {
          i {
            &.down {
              display: none !important
            }
  
            &.up {
              display: block !important;
            }
          }
        }
    }
  }

  .segment {
    padding-top: 10px !important;
    border: 0 !important;
    box-shadow: unset !important;
    border-radius: 0 !important;
    border-top: 1px solid #cccccc !important;
    transition: display 1s;

    &.general {
      &::before {
        left: 15% !important;
      }
    }

    &.candidatos {
      &::before {
        left: 50% !important;
      }
    }

    &.empresas {
      &::before {
        left: 85% !important;
      }
    }

    &::before {
      left: 15%;
      content: '' !important;
      min-width: 10px !important;
      width: 10px !important;
      min-height: 10px !important;
      height: 10px !important;
      background-color: ${WHITE} !important;
      border-left: 1px solid #cccccc !important;
      border-top: 1px solid #cccccc !important;
      top: -6px !important;
      position: absolute !important;
      transform: rotate(45deg) !important;
    }
  }

}`;

const Footer = (props) => {
  const { intl } = props;

  const partners = settings.partners;

  let logo = logoFooter;
  if (startsWith(settings.defaultLocale, 'en')) {
    logo = logoFooterFJ;
  } else if (startsWith(settings.defaultLocale, 'it')) {
    logo = logoFooterCJ;
  }

  const paises = ['internacional', 'divider', 'ar', 'bo', 'br', 'cl', 'co', 'ec', 'py', 'pe', 'uy', 've', 'divider', 'us', 'mx', 'divider', 'cr', 'sv', 'gt', 'hn', 'jm', 'ni', 'pa', 'pr', 'do', 'divider', 'es', 'pt', 'ie', 'it', 'uk', 'divider', 'gh', 'ke', 'ng', 'za', 'divider', 'au', 'nz', 'divider', 'in', 'id', 'ph', 'my'];
  const urls = {
    internacional: 'https://www.buscojobs.com',
    ar: 'https://www.buscojobs.com.ar',
    bo: 'https://www.buscojobs.com.bo',
    br: 'https://br.buscojobs.com',
    cl: 'https://www.buscojobs.cl',
    co: 'https://www.buscojobs.com.co',
    ec: 'https://www.buscojobs.com.ec',
    py: 'https://www.buscojobs.com.py',
    pe: 'https://www.buscojobs.pe',
    uy: 'https://www.buscojobs.com.uy',
    ve: 'https://ve.buscojobs.com',
    us: 'https://www.findojobs.us',
    mx: 'https://www.buscojobs.mx',
    cr: 'https://www.buscojobs.cr',
    sv: 'https://www.buscojobs.com.sv',
    gt: 'https://www.buscojobs.com.gt',
    hn: 'https://www.buscojobs.hn',
    jm: 'https://jm.findojobs.com',
    ni: 'https://www.buscojobs.com.ni',
    pa: 'https://www.buscojobs.com.pa',
    pr: 'https://www.buscojobs.com.pr',
    do: 'https://www.buscojobs.com.do',
    es: 'https://www.buscojobs.com.es',
    pt: 'https://www.buscojobs.pt/',
    ie: 'https://ie.findojobs.com',
    it: 'https://www.cercojobs.it',
    uk: 'https://www.findojobs.co.uk',
    gh: 'https://www.findojobs.com.gh',
    ke: 'https://www.findojobs.ke',
    ng: 'https://www.findojobs.ng',
    za: 'https://www.findojobs.co.za',
    au: 'https://au.findojobs.com',
    nz: 'https://www.findojobs.co.nz',
    in: 'https://www.findojobs.in',
    id: 'https://www.findojobs.id',
    ph: 'https://www.findojobs.ph',
    my: 'https://www.findojobs.my',
  };
  /* eslint-disable react/no-array-index-key */
  const opcionesPaises = paises.map((pais, index) => pais !== 'divider' ?
    <Dropdown.Item
      as={Link}
      key={pais}
      text={<FormattedMessage {...messages[pais]} />}
      external
      to={urls[pais]}
    />
  :
    <Dropdown.Divider key={`divider-${index}`} />
  );
  /* eslint-enable */
  const panes = [
    {
      menuItem: (
        <Menu.Item key="general">
          <FormattedMessage {...messages.general} /><Icon className="down" name="chevron down" /><Icon className="up" name="chevron up" />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane className="general" attached={false}>
          <List size="tiny">
            <GreyList as={Link} external to={intl.formatMessage(messages.urlMisionVision)}>
              <FormattedMessage {...messages.misionVision} />
            </GreyList>
            <GreyList as={Link} external to={intl.formatMessage(messages.urlQuienesSomos)}>
              <FormattedMessage {...messages.quienesSomos} />
            </GreyList>
            <GreyList as={Link} external to={intl.formatMessage(messages.urlTerminos)}>
              <FormattedMessage {...messages.terminos} />
            </GreyList>
            <GreyList as={Link} external to={intl.formatMessage(messages.urlPrivacidad)}>
              <FormattedMessage {...messages.privacidad} />
            </GreyList>
            {!settings.hideBuscojobsOffice &&
              <GreyList as={Link} external to={intl.formatMessage(messages.urlBJOffice)}>
                <FormattedMessage {...messages.bjOffice} />
              </GreyList>
            }
            {!settings.hideBuscojobsEmprende &&
              <GreyList as={Link} external to={intl.formatMessage(messages.urlEmprende)}>
                <FormattedMessage {...messages.emprende} />
              </GreyList>
            }
          </List>
        </Tab.Pane>),
    },
    {
      menuItem: (
        <Menu.Item key="candidatos">
          <FormattedMessage {...messages.candidatos} /><Icon className="down" name="chevron down" /><Icon className="up" name="chevron up" />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane className="candidatos" attached={false}>
          <List size="tiny">
            <GreyList as={Link} external to={intl.formatMessage(messages.urlAgregarCV)}>
              <FormattedMessage {...messages.agregarCV} />
            </GreyList>
            <GreyList as={Link} external to={intl.formatMessage(messages.urlDejarCV)}>
              <FormattedMessage {...messages.dejarCV} />
            </GreyList>
            <GreyList as={Link} external to={intl.formatMessage(messages.urlOfertasArea)}>
              {settings.nuevosSubcanales ?
                <FormattedMessage {...messages.ofertasSubArea} />
              :
                <FormattedMessage {...messages.ofertasArea} />
              }
            </GreyList>
            <GreyList as={Link} external to={intl.formatMessage(messages.urlOfertasLoc)}>
              <FormattedMessage {...messages.ofertasLoc} />
            </GreyList>
            <GreyList as={Link} external to="/paginas/faq-postulantes">
              <FormattedMessage {...messages.faqPostulantes} />
            </GreyList>
            {settings.blog ? (
              <GreyList
                as={Link}
                external
                to={intl.formatMessage(messages.blogCandidatosUrl)}
                target="_blank"
              >
                <FormattedMessage {...messages.blogCandidatos} />
              </GreyList>
            ) : null}
          </List>
        </Tab.Pane>),
    },
    {
      menuItem: (
        <Menu.Item key="empresas">
          <FormattedMessage {...messages.empresas} /><Icon className="down" name="chevron down" /><Icon className="up" name="chevron up" />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane className="empresas" attached={false} >
          <List size="tiny">
            <GreyList as={Link} external to={intl.formatMessage(messages.urlPublicar)}>
              <FormattedMessage {...messages.publicar} />
            </GreyList>
            <GreyList as={Link} external to={intl.formatMessage(messages.urlTarifas)}>
              <FormattedMessage {...messages.tarifas} />
            </GreyList>
            <GreyList as={Link} external to="/paginas/faq-empresas">
              <FormattedMessage {...messages.faqEmpresas} />
            </GreyList>
            {settings.blog ? (
              <GreyList
                as={Link}
                external
                to={intl.formatMessage(messages.blogEmpresasUrl)}
                target="_blank"
              >
                <FormattedMessage {...messages.blogEmpresas} />
              </GreyList>
            ) : null}
          </List>
        </Tab.Pane>),
    },
  ];

  return (
    <AppFooter columns={16}>
      <Grid.Column width={16}>
        <StyledWaveFooter />
      </Grid.Column>
      <WhiteContainer>
        <GradientContainer>
          <Grid.Row>
            <FooterContent>
              <SectionText mobile={16} tablet={16} only="mobile tablet">
                <BjTab menu={{ secondary: true, pointing: true }} panes={panes} />
                <Container>
                  <Button as={Link} color={WHITE} textColor={DARK_BLUE} contacto external to={intl.formatMessage(messages.urlContacto)}><FormattedMessage {...messages.contactoBtn} /></Button>
                </Container>
              </SectionText>
              <SectionText computer={5} only="computer">
                <Container style={{ textAlign: 'center' }}>
                  <Header as="h4"><Header.Content color={DARK_BLUE}><FormattedMessage {...messages.contactoHeader} /></Header.Content></Header>
                  <Button as={Link} color={WHITE} textColor={DARK_BLUE} contacto external to={intl.formatMessage(messages.urlContacto)}><FormattedMessage {...messages.contactoBtn} /></Button>
                  <SmallText><FormattedMessage {...messages.contactoFooter} /></SmallText>
                </Container>
              </SectionText>
              <SectionText computer={5} only="computer">
                <Container>
                  <Header as="h4"><Header.Content color={ORANGE_1}><FormattedMessage {...messages.general} /></Header.Content></Header>
                  <List size="tiny">
                    <GreyList as={Link} external to={intl.formatMessage(messages.urlMisionVision)}>
                      <FormattedMessage {...messages.misionVision} />
                    </GreyList>
                    <GreyList as={Link} external to={intl.formatMessage(messages.urlQuienesSomos)}>
                      <FormattedMessage {...messages.quienesSomos} />
                    </GreyList>
                    <GreyList as={Link} external to={intl.formatMessage(messages.urlTerminos)}>
                      <FormattedMessage {...messages.terminos} />
                    </GreyList>
                    <GreyList as={Link} external to={intl.formatMessage(messages.urlPrivacidad)}>
                      <FormattedMessage {...messages.privacidad} />
                    </GreyList>
                    {!settings.hideBuscojobsOffice &&
                      <GreyList as={Link} external to={intl.formatMessage(messages.urlBJOffice)}>
                        <FormattedMessage {...messages.bjOffice} />
                      </GreyList>
                    }
                    {!settings.hideBuscojobsEmprende &&
                      <GreyList as={Link} external to={intl.formatMessage(messages.urlEmprende)}>
                        <FormattedMessage {...messages.emprende} />
                      </GreyList>
                    }
                  </List>
                  {partners &&
                  <Header as="h4">
                    <Header.Content color={ORANGE_1}><FormattedMessage {...messages.partners} /></Header.Content>
                  </Header>
                  }
                  <List size="tiny">
                    {partners && partners.map((partner) => (
                      <GreyList key={partner.url} as={Link} external target="_blank" to={partner.url}>
                        {partner.name}
                      </GreyList>
                    ))}
                  </List>
                </Container>
              </SectionText>
              <SectionText computer={3} only="computer">
                <Header as="h4"><Header.Content color={ORANGE_1}><FormattedMessage {...messages.candidatos} /></Header.Content></Header>
                <List size="tiny">
                  <GreyList as={Link} external to={intl.formatMessage(messages.urlAgregarCV)}>
                    <FormattedMessage {...messages.agregarCV} />
                  </GreyList>
                  <GreyList as={Link} external to={intl.formatMessage(messages.urlDejarCV)}>
                    <FormattedMessage {...messages.dejarCV} />
                  </GreyList>
                  <GreyList as={Link} external to={intl.formatMessage(messages.urlOfertasArea)}>
                    {settings.nuevosSubcanales ?
                      <FormattedMessage {...messages.ofertasSubArea} />
                    :
                      <FormattedMessage {...messages.ofertasArea} />
                    }
                  </GreyList>
                  <GreyList as={Link} external to={intl.formatMessage(messages.urlOfertasLoc)}>
                    <FormattedMessage {...messages.ofertasLoc} />
                  </GreyList>
                  <GreyList as={Link} external to="/paginas/faq-postulantes">
                    <FormattedMessage {...messages.faqPostulantes} />
                  </GreyList>
                  {settings.blog ? (
                    <GreyList
                      as={Link}
                      external
                      to={intl.formatMessage(messages.blogCandidatosUrl)}
                      target="_blank"
                    >
                      <FormattedMessage {...messages.blogCandidatos} />
                    </GreyList>
                  ) : null}
                </List>
              </SectionText>
              <SectionText computer={3} only="computer">
                <Header as="h4"><Header.Content color={ORANGE_1}><FormattedMessage {...messages.empresas} /></Header.Content></Header>
                <List size="tiny">
                  <GreyList as={Link} external to={intl.formatMessage(messages.urlPublicar)}>
                    <FormattedMessage {...messages.publicar} />
                  </GreyList>
                  <GreyList as={Link} external to={intl.formatMessage(messages.urlTarifas)}>
                    <FormattedMessage {...messages.tarifas} />
                  </GreyList>
                  <GreyList as={Link} external to="/paginas/faq-empresas">
                    <FormattedMessage {...messages.faqEmpresas} />
                  </GreyList>
                  {settings.blog ? (
                    <GreyList
                      as={Link}
                      external
                      to={intl.formatMessage(messages.blogEmpresasUrl)}
                      target="_blank"
                    >
                      <FormattedMessage {...messages.blogEmpresas} />
                    </GreyList>
                  ) : null}
                </List>
              </SectionText>
            </FooterContent>
          </Grid.Row>
          <SpacedRow>
            <Grid columns={2} reversed="mobile vertically">
              <Grid.Column computer={10} tablet={8} mobile={16}>
                <Grid columns={2}>
                  <SectionOrangeText computer={3} tablet={8} mobile={6}>
                    <OrangeHeaderText><FormattedMessage {...messages.siguenos} /></OrangeHeaderText>
                  </SectionOrangeText>
                  <Grid.Column computer={13} tablet={8} mobile={10}>
                    <Link external to="https://www.facebook.com/buscojobs/"><Image src={logoFacebook} floated="left" style={{ marginBottom: 0 }} /></Link>
                    <Link external to="https://www.linkedin.com/company/buscojobs"><Image src={logoLinkedin} floated="left" style={{ marginBottom: 0 }} /></Link>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column computer={6} tablet={8} mobile={16}>
                <Grid columns={2}>
                  <SectionOrangeText computer={4} tablet={8} mobile={6}>
                    <OrangeHeaderText><FormattedMessage {...messages.pais} /></OrangeHeaderText>
                  </SectionOrangeText>
                  <Grid.Column computer={12} tablet={8} mobile={10}>
                    <Dropdown
                      scrolling
                      fluid
                      size="small"
                      styled
                      color={DARK_BLUE}
                      button
                      text={settings.paisNombre}
                    >
                      <Dropdown.Menu>
                        {opcionesPaises}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </SpacedRow>
        </GradientContainer>
        <BlueGradientContainer>
          <Image src={logo} style={{ marginBottom: 0, height: '37.5px', marginRight: '20px' }} />
          <CountryText>{settings.paisNombre} © {moment().year()}</CountryText>
        </BlueGradientContainer>
      </WhiteContainer>
    </AppFooter>
  );
};

Footer.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
