const settings = {
  all: {
    apiBaseUrl: 'https://apisandbox.buscojobs.com',
    audience: 'development.buscojobs.com',
    apiVersion: 'v3',
    facebookAppId: '263596141675683',
    formacion: {
      enabled: false,
      url: "http://localhost:9000/BJRecommender.js",
    },
  },
  ar: {
    danchiano: true,
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
  },
  bo: {
    danchiano: true,
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    blog: true,
    featuresGPT: true,
  },
  ec: {
    danchiano: true,
    extensionCVs: true,
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    blog: true,
    featuresGPT: true,
  },
  pa: {
    danchiano: true,
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    blog: true,
    featuresGPT: true,
  },
  py: {
    danchiano: true,
    listadosCargos: true,
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    blog: true,
    featuresGPT: true,
  },
  us: {
    danchiano: true,
  },
  uy: {
    danchiano: true,
    isDanchianoFree: true,
    centralTest: false,
    centralTestComprar: false,
    zohoChat: false,
    zohoChatCode: 'b26b865877e09d905ba9acc0dd832d8ea2c34028a035918901fb0f90199d7b31',
    discapacidadesEmpresa: true,
    modalidadLiberados: true,
    hasApp: false,
    listadosCargos: true,
    extensionCVs: true,
    formacion: {
      enabled: false,
      url: "https://d259nu5gg6b6ku.cloudfront.net/bj-recommender-front/latest/BJRecommender.js",
    },
    nuevosSubcanales: true,
    funcionesLaboralesComputadas: true,
    featuresGPT: true,
    mejorarServicio: true,
    blog: true,
  },
};

export default settings;
