/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withCookies } from 'react-cookie';
import { handleAuthentication, getToken } from 'containers/Auth/actions';
import { PageStylesProvider } from 'components/PageStyles';
import toJS from 'utils/propsToJS';
import AppComponent from 'components/App';
import { userSelector, authSelector } from './selectors';

export const UserContext = React.createContext(null);

export const injectUser = (Component) => (props) => {
  const user = useContext(UserContext);

  return <Component {...props} user={user} />;
};

export function useAuth() {
  const user = useContext(UserContext);
  return user;
}

function AppContainer(props) {
  const [appReady, setAppReady] = useState(false);
  const [loadApp, setLoadApp] = useState(false);

  if (window.ReactNativeWebView) {
    window.setloadApp = setLoadApp;
    if(!appReady) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        event: 'appReady',
      }));
      setAppReady(true);
      setTimeout(function(){ setLoadApp(true); }, 200);
    }

    if(!loadApp) {
      return (<div></div>);
    }
  }

  return (
    <UserContext.Provider value={props.user}>
      <PageStylesProvider>
        <AppComponent {...props} />
      </PageStylesProvider>
    </UserContext.Provider>
  );
}

AppContainer.propTypes = {
  user: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    globalState: state,
    user: userSelector(state),
    auth: authSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    handleAuthentication,
    getToken,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(toJS(AppContainer)));
