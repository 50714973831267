import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List } from 'semantic-ui-react';
import * as variables from 'styles/variables';

const ListBJ = ({ color, ...other }) => <List {...other} />;

ListBJ.propTypes = {
  color: PropTypes.string,
};

const StyledList = styled(ListBJ)`
  .item::before {
    color: ${(props) => props.color ?? variables.DARK_BLUE} !important;
  }

  .item > i.icon {
    color: ${(props) => props.color ?? variables.DARK_BLUE} !important;
  }
`;

StyledList.Content = List.Content;
StyledList.Description = List.Description;
StyledList.Header = List.Header;
StyledList.Icon = List.Icon;
StyledList.Item = List.Item;
StyledList.List = List.List;

export default StyledList;
