import { fromJS } from 'immutable';
import createReducer from 'utils/createReducer';
import { NOTIFICATION_SEND, NOTIFICATION_DISMISS } from './constants';

const initialState = {
  notification: null,
};

const reducer = createReducer(initialState, {

  [NOTIFICATION_SEND]: (state, action) =>
    state.set('notification', fromJS({ ...action.payload })),

  [NOTIFICATION_DISMISS]: (state) =>
    state.set('notification', null),

});

export default reducer;
