import { assign, castArray, isArray, isNil } from 'lodash';
import { fromJS, List } from 'immutable';
import createReducer from 'utils/createReducer';
import { API_REQUEST, API_SUCCESS, API_FAILURE } from './constants';
import { mapApiObject } from './utils';

const initialState = {
  loadingCount: 0,
  errors: [],
  entities: {},
};

const reducer = createReducer(initialState, {

  [API_REQUEST]: (state) =>
    state
      .update('loadingCount', (value) => value + 1)
      .set('errors', List()),

  [API_SUCCESS]: (originalState, action) => {
    let state = originalState;

    if (action.meta && action.meta.raw) {
      return state;
    }

    let data = castArray(action.payload.data);
    const included = action.payload.included || [];

    if (!isArray(action.payload.data) && action.payload.meta) {
      const meta = action.payload.meta || {};
      data = castArray(assign({}, action.payload.data, { meta }));
    }

    state = state.update('loadingCount', (value) => value - 1);

    if (data.length === 0 || isNil(data[0])) {
      return state;
    }

    const entityName = data[0].type;

    data.forEach((datum) => {
      state = state.mergeIn(['entities', entityName, datum.id], fromJS(mapApiObject(datum)));
    });

    included.forEach((inc) => {
      state = state.mergeIn(['entities', inc.type, inc.id], fromJS(mapApiObject(inc)));
    });

    return state;
  },

  [API_FAILURE]: (state, action) => {
    const errors = action.payload.errors || castArray(action.payload.error);

    return state
      .update('loadingCount', (value) => value - 1)
      .setIn(['errors'], state.getIn(['errors']).concat(fromJS(errors)));
  },

});

export default reducer;
