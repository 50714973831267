import { fromJS } from 'immutable';
import { each } from 'lodash';
import createReducer from 'utils/createReducer';
import { REPO_QUERY_SUCCESS } from 'containers/DataLoader';
import { OPTIMISTIC_UPDATE } from './constants';

const initialState = {
  entities: {},
};

const reducer = createReducer(initialState, {

  [REPO_QUERY_SUCCESS]: (state, action) => {
    if (action.payload && action.payload.data && action.payload.data.entities) {
      let newState = state;
      each(action.payload.data.entities, (entityRepo, entityName) => {
        each(entityRepo, (instance, id) => {
          newState = newState.mergeIn(['entities', entityName, id], fromJS(instance));
        });
      });

      return newState;
      // return state.set('entities', state.get('entities').mergeDeep(action.payload.data.entities));
    }
    return state;
  },

  [OPTIMISTIC_UPDATE]: (state, action) => {
    const { entity, id, updates } = action.payload;
    return state.mergeIn(['entities', entity, id.toString()], fromJS(updates));
  },

});

export default reducer;
