import React from 'react';

let browserLocale = 'es';
// Chequeo si está corriendo en el browser, ya que la global `window` no existe en node.js
if (typeof window !== 'undefined') {
  browserLocale = window.navigator.userLanguage || window.navigator.language;
}

const settings = {

  all: {
    defaultLocale: browserLocale,
    unionPaises: false,
    dateFormat: 'DD/MM/YYYY',
    funcionarios: true,
    invitarCandidatos: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
    blog: false,
    extensionCVs: true,
    isDanchianoFree: true,
    contacto: {
      mail: 'ventas@buscojobs.com',
    },
    helmet: {
      title: 'Bolsa de trabajo con ofertas de empleo actualizadas en Argentina – Buscojobs',
      description: 'Buscojobs es una web de búsqueda de empleo, con ofertas de trabajo actualizadas. Ingresá tu curriculum vitae gratis y encontrá trabajos en Argentina.',
    },
    educacion: {
      basica: [1, 2],
      avanzada: [4, 5, 6],
      academica: [1, 2, 4, 5, 6],
      cursos: [3],
      conTitulo: [2, 3, 4, 5, 6],
      conArea: [3, 4, 5, 6],
    },
    cv: {
      completitud: {
        datosPersonales: 5,
        experiencias: 15,
        educacion: 15,
        preferenciasLaborales: 10,
        perfilPsicografico: {
          testCompetencias: 5,
          testValores: 5,
          testPersonalidad: 5,
        },
        foto: 10,
        idiomas: 10,
        conocimientos: 10,
        resumen: 5,
        referencias: 5,
      },
    },
    companyUsernameIsMail: true,
    iva: false,
    cuotas: false,
    empresaScraping: 6,
    listadosCargos: false,
    facebookAppId: '1232093363833938',
    linkedInAppId: '7815kbqqy8i3ig',
    widgetEmpresas: true,
    exportarDatosBasicosPostulante: false,
    publicarEnTodoElPais: true,
    deel: false,
  },
  ar: {
    name: 'ar',
    timezone: 'America/Argentina/Buenos_Aires',
    defaultLocale: 'es',
    paisId: 2,
    paisNombre: 'Argentina',
    empresaScraping: 9944,
    helmet: {
      title: 'Bolsa de trabajo con ofertas de empleo actualizadas en Argentina – Buscojobs',
      description: 'Buscojobs es una web de búsqueda de empleo, con ofertas de trabajo actualizadas. Ingresá tu curriculum vitae gratis y encontrá trabajos en Argentina.',
    },
    postulantes: {
      docObligatorio: true,
      docNacional: [1, 3, 4],
      docFormat: '(Sólo dígitos)',
    },
    moneda: {
      iso: 'ARS',
      simbolo: '$',
      nombre: 'Pesos Argentinos',
    },
    contacto: {
      mail: 'ventas@buscojobs.com',
      horario: { desde: 9, hasta: 17, timezone: 'America/Montevideo' },
    },
    companyUsernameIsMail: false,
  },
  au: {
    name: 'au',
    defaultLocale: 'en',
    paisId: 35,
    paisNombre: 'Australia',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in Australia, Job Opportunities in Australia | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs Australia: Customer Service in Australia, Administrative Assistant in Australia,  Commercial - Sales in Australia, and many more',
    },
    moneda: {
      iso: 'AUD',
      simbolo: '$',
      nombre: 'Australian Dollars',
    },
    partners: [{
      name: 'Jobsora',
      url: 'https://au.jobsora.com',
    }],
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  bo: {
    name: 'bo',
    paisId: 3,
    paisNombre: 'Bolivia',
    timezone: 'America/La_Paz',
    defaultLocale: 'es',
    helmet: {
      title: 'Bolsa de trabajo y empleos en Bolivia - Buscojobs',
      description: 'Bolsa de trabajo con ofertas de empleo en Bolivia. Ingresa tu currículum y encuentra empleos en Buscojobs.',
    },
    moneda: {
      iso: 'BOB',
      simbolo: 'Bs',
      nombre: 'Bolivianos',
    },
    contacto: {
      mail: 'bolivia@buscojobs.com',
      horario: { desde: 9, hasta: 17, timezone: 'America/La_Paz' },
    },
    postulantes: {
      docObligatorio: true,
    },
  },
  br: {
    name: 'br',
    paisId: 4,
    timezone: 'America/Sao_Paulo',
    defaultLocale: 'pt',
    paisNombre: 'Brasil',
    empresaScraping: 2,
    helmet: {
      title: 'Emprego Grátis no Brasil - Vagas de Emprego no Brasil | BuscoJobs',
      description: 'Encontre milhares de vagas de emprego em Buscojobs Brasil: Atendimento ao Cliente em Brasil, Administração em Brasil,  Comercial & Vendas em Brasil, e muitos mais',
    },
    moneda: {
      iso: 'BRL',
      simbolo: 'R$',
      nombre: 'Reais',
    },
    postulantes: {
      docObligatorio: true,
    },
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  cl: {
    name: 'cl',
    paisId: 5,
    paisNombre: 'Chile',
    timezone: 'America/Santiago',
    defaultLocale: 'es-CL',
    helmet: {
      title: 'Bolsa de trabajo, ofertas de empleo y oportunidades de trabajo en Chile – Buscojobs',
      description: 'Bolsa de trabajo con oportunidades laborales en Chile. Ingresa tu currículum vitae para encontrar empleo.',
    },
    postulantes: {
      docObligatorio: true,
      docNacional: [5],
      docFormat: '(Sin puntos ni guiones)',
    },
    moneda: {
      iso: 'CLP',
      simbolo: '$',
      nombre: 'Pesos Chilenos',
    },
    contacto: {
      mail: 'ventas@buscojobs.com',
      horario: { desde: 8, hasta: 16, timezone: 'America/Santiago' },
    },
    partners: [
      {
        name: 'Jobsora',
        url: 'https://cl.jobsora.com/',
      },
    ],
  },
  co: {
    name: 'co',
    paisId: 6,
    paisNombre: 'Colombia',
    timezone: 'America/Bogota',
    defaultLocale: 'es-CO',
    helmet: {
      title: 'Ofertas de Trabajo, Buscar Empleo | BuscoJobs Colombia',
      description: 'Encuentra miles de ofertas de empleo en BuscoJobs Colombia: Administración - Oficina en Colombia, Ventas en Colombia,  Marketing - Publicidad en Colombia, y muchos más.',
    },
    postulantes: {
      docObligatorio: true,
      docNacional: [1, 2, 3, 6, 7],
      docFormat: '(Sólo dígitos)',
    },
    moneda: {
      iso: 'COP',
      simbolo: '$',
      nombre: 'Pesos Colombianos',
    },
  },
  cr: {
    name: 'cr',
    timezone: 'America/Costa_Rica',
    defaultLocale: 'es',
    paisId: 7,
    paisNombre: 'Costa Rica',
    helmet: {
      title: 'Ofertas de trabajo y oferta de empleos en Costa Rica - Buscojobs',
      description: 'Bolsa de trabajo con miles de empleos en Costa Rica. Registra tu currículum vitae y postulate.',
    },
    moneda: {
      iso: 'CRC',
      simbolo: '₡',
      nombre: 'Colones Costarricenses',
    },
    postulantes: {
      docObligatorio: true,
    },
  },
  do: {
    name: 'do',
    paisId: 21,
    paisNombre: 'República Dominicana',
    timezone: 'America/Santo_Domingo',
    defaultLocale: 'es',
    helmet: {
      title: 'Ofertas de trabajo y empleos en República Dominicana - Buscojobs',
      description: 'Bolsa de trabajo con ofertas de empleo en Dominicana. Ingresa tu curriculum vitae y postulate',
    },
    moneda: {
      iso: 'DOP',
      simbolo: 'RD$',
      nombre: 'Pesos Dominicanos',
    },
    contacto: {
      mail: 'ventas@buscojobs.com',
      horario: { desde: 8, hasta: 16, timezone: 'America/Santo_Domingo' },
    },
    postulantes: {
      docObligatorio: true,
    },
  },
  ec: {
    name: 'ec',
    paisId: 9,
    paisNombre: 'Ecuador',
    timezone: 'America/Guayaquil',
    defaultLocale: 'es-EC',
    empresaScraping: 7,
    helmet: {
      title: 'Ofertas de trabajo y vacantes de empleos en Ecuador – Buscojobs',
      description: 'Bolsa de trabajo en Ecuador. Ingresa tu currículum vitae y encuentra vacantes de empleo.',
    },
    moneda: {
      iso: 'USD',
      simbolo: '$',
      nombre: 'Dólares Estadounidenses',
    },
    contacto: {
      mail: 'ventas@buscojobs.com',
      horario: { desde: 9, hasta: 17, timezone: 'America/Guayaquil' },
    },
    postulantes: {
      docObligatorio: true,
    },
    buscotalent: true,
    widgetEmpresas: true,
    exportarDatosBasicosPostulante: true,
  },
  es: {
    name: 'es',
    paisId: 11,
    paisNombre: 'España',
    timezone: 'Europe/Madrid',
    defaultLocale: 'es',
    empresaScraping: 9944,
    helmet: {
      title: 'Pagina de anuncios de trabajo y empleo en España - Buscojobs',
      description: 'Página web de empleo con ofertas de consultoras de trabajo y otras empresas.',
    },
    postulantes: {
      terminosUE: true,
    },
    moneda: {
      iso: 'EUR',
      simbolo: '€',
      nombre: 'Euros',
    },
    partners: [
      {
        name: 'Jooble',
        url: 'https://es.jooble.org',
      },
    ],
  },
  gh: {
    name: 'gh',
    paisId: 86,
    paisNombre: 'Ghana',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in Ghana, Job Opportunities in Ghana | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs Ghana: Customer Service in Ghana, Administrative Assistant in Ghana, Commercial - Sales in Ghana, and many more',
    },
    moneda: {
      iso: 'GHS',
      simbolo: 'GH₵',
      nombre: 'Ghanaian Cedis',
    },
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  gt: {
    name: 'gt',
    paisId: 13,
    paisNombre: 'Guatemala',
    timezone: 'America/Guatemala',
    defaultLocale: 'es',
    helmet: {
      title: 'Empleos y trabajos en Guatemala - Buscojobs',
      description: 'Bolsa de trabajo con ofertas de empleo en Guatemala. Registra gratis tu curriculum vitae en Buscojobs',
    },
    moneda: {
      iso: 'GTQ',
      simbolo: 'Q',
      nombre: 'Quetzales',
    },
    postulantes: {
      docObligatorio: true,
    },
  },
  hn: {
    name: 'hn',
    timezone: 'America/Tegucigalpa',
    defaultLocale: 'es',
    paisId: 14,
    paisNombre: 'Honduras',
    helmet: {
      title: 'Ofertas de trabajo y empleo en Honduras – Buscojobs',
      description: 'Bolsa de trabajo con empleos en Honduras. Ingresa tu curriculum vitae gratis y encuentra una vacante',
    },
    moneda: {
      iso: 'HNL',
      simbolo: 'L',
      nombre: 'Lempiras',
    },
    postulantes: {
      docObligatorio: true,
    },
  },
  id: {
    name: 'id',
    paisId: 100,
    paisNombre: 'Indonesia',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in Indonesia, Job Opportunities in Indonesia | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs Indonesia: Customer Service in Indonesia, Administrative Assistant in Indonesia, Commercial - Sales in Indonesia, and many more',
    },
    moneda: {
      iso: 'IDR',
      simbolo: 'Rp',
      nombre: 'Indonesian rupiah',
    },
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  ie: {
    name: 'ie',
    paisId: 103,
    paisNombre: 'Ireland',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in Ireland, Job Opportunities in Ireland | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs Ireland: Customer Service in Ireland, Administrative Assistant in Ireland, Commercial - Sales in Ireland, and many more',
    },
    moneda: {
      iso: 'EUR',
      simbolo: '€',
      nombre: 'Euros',
    },
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  in: {
    name: 'in',
    paisId: 99,
    paisNombre: 'India',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in India, Job Opportunities in India | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs India: Customer Service in India, Administrative Assistant in India, Commercial - Sales in India, and many more',
    },
    moneda: {
      iso: 'INR',
      simbolo: '₹',
      nombre: 'Indian Rupees',
    },
    partners: [
      {
        name: 'Jobsora',
        url: 'https://in.jobsora.com',
      },
    ],
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  it: {
    name: 'it',
    paisId: 110,
    paisNombre: 'Italia',
    defaultLocale: 'it',
    empresaScraping: 2,
    helmet: {
      title: 'Borsa di Lavoro in Italia, Offerte di impiego in Italia | CercoJobs',
      description: 'Trova mille di offerte di Lavoro in CercoJobs Italia: Servizio Clienti in Italia, Impiegato Amministrativo in Italia,  Commerciale - Adetto Vendite in Italia, e tanti altri ancora',
    },
    moneda: {
      iso: 'EUR',
      simbolo: '€',
      nombre: 'Euros',
    },
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  jm: {
    name: 'jm',
    paisId: 111,
    paisNombre: 'Jamaica',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in Jamaica, Job Opportunities in Jamaica | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs Jamaica: Customer Service in Jamaica, Administrative Assistant in Jamaica, Commercial - Sales in Jamaica, and many more',
    },
    moneda: {
      iso: 'JMD',
      simbolo: '$',
      nombre: 'Jamaican Dollars',
    },
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  ke: {
    name: 'ke',
    paisId: 115,
    paisNombre: 'Kenya',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in Kenya, Job Opportunities in Kenya | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs Kenya: Customer Service in Kenya, Administrative Assistant in Kenya, Commercial - Sales in Kenya, and many more',
    },
    moneda: {
      iso: 'KES',
      simbolo: 'KSh',
      nombre: 'Kenyan Shillings',
    },
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  mx: {
    name: 'mx',
    timezone: 'America/Mexico_City',
    defaultLocale: 'es',
    paisId: 15,
    paisNombre: 'México',
    helmet: {
      title: 'Bolsa de empleo para buscar trabajo en México – Ingresa tu currículum - Buscojobs',
      description: 'Bolsa de trabajo donde puedes inscribir tu curriculum, buscar trabajo y publicar vacantes de empleos en México.',
    },
    moneda: {
      iso: 'MXN',
      simbolo: '$',
      nombre: 'Pesos Mexicanos',
    },
    partners: [
      {
        name: 'Jobsora',
        url: 'https://mx.jobsora.com/',
      },
      {
        name: 'Jooble',
        url: 'https://mx.jooble.org/',
      },
    ],
    postulantes: {
      docObligatorio: true,
    },
  },
  my: {
    name: 'my',
    paisId: 131,
    paisNombre: 'Malaysia',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in Malaysia, Job Opportunities in Malaysia | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs Malaysia: Customer Service in Malaysia, Administrative Assistant in Malaysia, Commercial - Sales in Malaysia, and many more',
    },
    moneda: {
      iso: 'MYR',
      simbolo: 'RM',
      nombre: 'Malaysian Ringgit',
    },
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  ng: {
    name: 'ng',
    paisId: 151,
    paisNombre: 'Nigeria',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in Nigeria, Job Opportunities in Nigeria | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs Nigeria: Customer Service in Nigeria, Administrative Assistant in Nigeria, Commercial - Sales in Nigeria, and many more',
    },
    moneda: {
      iso: 'NGN',
      simbolo: '₦',
      nombre: 'Nigerian Naira',
    },
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  ni: {
    name: 'ni',
    paisNombre: 'Nicaragua',
    defaultLocale: 'es',
    timezone: 'America/Managua',
    paisId: 16,
    helmet: {
      title: 'Oportunidades de empleo, trabajos y trabajo temporal en Nicaragua - Buscojobs',
      description: 'Bolsa de empleo con trabajos disponibles en Nicaragua. Encuentra oportunidades de empleo actualizadas.',
    },
    moneda: {
      iso: 'NIO',
      simbolo: 'C$',
      nombre: 'Córdobas',
    },
    postulantes: {
      docObligatorio: true,
    },
  },
  nz: {
    name: 'nz',
    paisId: 153,
    paisNombre: 'New Zealand',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in New Zealand, Job Opportunities in New Zealand | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs New Zealand: Customer Service in New Zealand, Administrative Assistant in New Zealand, Commercial - Sales in New Zealand, and many more',
    },
    moneda: {
      iso: 'NZD',
      simbolo: '$',
      nombre: 'New Zealand Dollars',
    },
    partners: [{
      name: 'Jobsora',
      url: 'https://nz.jobsora.com',
    }],
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  pa: {
    name: 'pa',
    timezone: 'America/Panama',
    defaultLocale: 'es-PA',
    paisId: 17,
    paisNombre: 'Panamá',
    helmet: {
      title: 'Ofertas de empleo y trabajo en Panamá - Buscojobs',
      description: 'Bolsa de trabajo con ofertas de empleo en Panamá. Registra tu curriculum vitae gratis.',
    },
    moneda: {
      iso: 'PAB',
      simbolo: 'B/.',
      nombre: 'Balboas',
    },
    postulantes: {
      docObligatorio: true,
    },
    iva: true,
    widgetEmpresas: true,
    exportarDatosBasicosPostulante: true,
  },
  pe: {
    name: 'pe',
    paisId: 19,
    paisNombre: 'Perú',
    timezone: 'America/Lima',
    defaultLocale: 'es',
    helmet: {
      title: 'Avisos de trabajo y empleo y trabajo temporal en Perú – Buscojobs',
      description: 'Bolsa de trabajo con avisos de empleo actualizados. Ingresa tu curriculum vitae gratis',
    },
    moneda: {
      iso: 'PEN',
      simbolo: 'S/.',
      nombre: 'Soles',
    },
    contacto: {
      mail: 'ventas@buscojobs.com',
      horario: { desde: 8, hasta: 16, timezone: 'America/Santiago' },
    },
    postulantes: {
      docObligatorio: true,
    },
  },
  ph: {
    name: 'ph',
    paisId: 79,
    paisNombre: 'Philippines',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in Philippines, Job Opportunities in Philippines | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs Philippines: Customer Service in Philippines, Administrative Assistant in Philippines, Commercial - Sales in Philippines, and many more',
    },
    moneda: {
      iso: 'PHP',
      simbolo: '₱',
      nombre: 'Philippine Pesos',
    },
    partners: [{
      name: 'Jobsora',
      url: 'https://ph.jobsora.com',
    },
    {
      name: 'Jooble',
      url: 'https://ph.jooble.org',
    }],
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  pr: {
    name: 'pr',
    paisId: 20,
    paisNombre: 'Puerto Rico',
    timezone: 'America/Puerto_Rico',
    defaultLocale: 'es',
    helmet: {
      title: 'Empleos Puerto Rico | Buscojobs Puerto Rico',
      description: 'Bolsa de empleo donde buscar trabajo por internet en Puerto Rico. Para enviar tu resumen y encontrar una oportunidad de trabajo.',
    },
    moneda: {
      iso: 'USD',
      simbolo: '$',
      nombre: 'Dólares Estadounidenses',
    },
    contacto: {
      mail: 'ventas@buscojobs.com',
      horario: { desde: 8, hasta: 16, timezone: 'America/Santo_Domingo' },
    },
    postulantes: {
      docObligatorio: true,
    },
  },
  pt: {
    name: 'pt',
    paisId: 26,
    paisNombre: 'Portugal',
    timezone: 'Europe/Lisbon',
    defaultLocale: 'pt-PT',
    empresaScraping: 2,
    helmet: {
      title: 'Buscojobs - Vagas de emprego e estágio em Portugal! Encontre seu futuro trabalho aqui!',
      description: 'Encontre milhares de vagas de emprego em Buscojobs Portugal: Atendimento ao Cliente em Portugal, Administração em Portugal,  Comercial & Vendas em Portugal, e muitos mais',
    },
    postulantes: {
      terminosUE: true,
    },
    moneda: {
      iso: 'EUR',
      simbolo: '€',
      nombre: 'Euros',
    },
    partners: [{
      name: 'Jooble',
      url: 'https://pt.jooble.org',
    }],
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  py: {
    name: 'py',
    timezone: 'America/Asuncion',
    defaultLocale: 'es-PY',
    paisId: 18,
    paisNombre: 'Paraguay',
    helmet: {
      title: 'Ofertas de trabajo y empleos en Paraguay - Buscojobs',
      description: 'Bolsa de trabajo con vacantes de empleo actualizadas. Registra tu curriculum vitae gratis y encuentra tu futuro empleo en Buscojobs Paraguay.',
    },
    moneda: {
      iso: 'PYG',
      simbolo: '₲',
      nombre: 'Guaraníes',
    },
    postulantes: {
      docObligatorio: true,
    },
    widgetEmpresas: true,
  },
  sv: {
    name: 'sv',
    timezone: 'America/El_Salvador',
    defaultLocale: 'es',
    paisId: 10,
    paisNombre: 'El Salvador',
    helmet: {
      title: 'Ofertas de trabajo y empleo en El Salvador - Buscojobs',
      description: 'Bolsa de trabajo con ofertas de empleo en El Salvador. Registra tu curriculum vitae en Buscojobs.',
    },
    moneda: {
      iso: 'USD',
      simbolo: '$',
      nombre: 'Dólares Estadounidenses',
    },
    postulantes: {
      docObligatorio: true,
    },
  },
  uk: {
    name: 'uk',
    paisId: 165,
    paisNombre: 'United Kingdom',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in United Kingdom, Job Opportunities in United Kingdom | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs United Kingdom: Customer Service in United Kingdom, Administrative Assistant in United Kingdom, Commercial - Sales in United Kingdom, and many more',
    },
    moneda: {
      iso: 'GBP',
      simbolo: '£',
      nombre: 'Pounds Sterling',
    },
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  us: {
    name: 'us',
    paisId: 12,
    paisNombre: 'United States',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in United States, Job Opportunities in United States | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs United States: Customer Service in United States, Administrative Assistant in United States, Commercial - Sales in United States, and many more',
    },
    moneda: {
      iso: 'USD',
      simbolo: '$',
      nombre: 'United States Dollars',
    },
    partners: [
      {
        name: 'Jobsora',
        url: 'https://us.jobsora.com/',
      },
    ],
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
  uy: {
    name: 'uy',
    paisId: 22,
    paisNombre: 'Uruguay',
    timezone: 'America/Montevideo',
    defaultLocale: 'es-UY',
    empresaScraping: 1,
    helmet: {
      title: 'Ofertas de trabajo y vacantes de empleos en Uruguay - Buscojobs',
      description: 'Portal de empleo y bolsa de trabajo con vacantes actualizadas. Registra tu curriculum y encontra una oportunidad hoy',
    },
    postulantes: {
      docObligatorio: true,
      docNacional: [1],
      docFormat: '(CI sin puntos ni guiones)',
    },
    moneda: {
      iso: 'UYU',
      simbolo: '$',
      nombre: 'Pesos Uruguayos',
    },
    contacto: {
      telefono: '(+598) 2916 2030',
      horario: { desde: 9, hasta: 17, timezone: 'America/Montevideo' },
    },
    companyUsernameIsMail: false,
    iva: true,
    cuotas: true,
    ticketPago: true,
    logoCUTI: true,
    discapacidades: {
      info: {
        texto1: <span>Según lo dispuesto en la <strong>ley Nº 19.691</strong> se debe estar inscripto en el registro nacional de personas con discapacidad del Ministerio de Desarrollo Social para poder ser contemplado dentro de los cupos que destinen las empresas en sus ofertas de empleo.</span>,
        texto2: '¿Necesitas más información sobre el trámite para inscribirte?',
        texto3: 'Ingresar al Registro Nacional de Personas con Discapacidad',
        texto4: 'Visitar web de la Fundación Bensadoun-Laurent',
        link1: 'https://www.gub.uy/tramites/registro-nacional-personas-discapacidad',
        link2: 'https://fundacionbl.org/',
      },
      fbl: false,
    },
    chatGalanteMartins: false,
    widgetEmpresas: true,
    exportarDatosBasicosPostulante: true,
    publicarEnTodoElPais: false,
  },
  ve: {
    name: 've',
    timezone: 'America/Caracas',
    defaultLocale: 'es',
    paisId: 23,
    paisNombre: 'Venezuela',
    helmet: {
      title: 'Oportunidades laborales, trabajos y empleos en Venezuela - Buscojobs',
      description: 'Bolsa de trabajo con ofertas de empleo actualizadas. Registra gratis tu curriculum vitae y encuentra oportunidades laborales.',
    },
    moneda: {
      iso: 'VES',
      simbolo: 'Bs',
      nombre: 'Bolívares',
    },
    postulantes: {
      docObligatorio: true,
    },
  },
  za: {
    name: 'za',
    paisId: 190,
    paisNombre: 'South Africa',
    defaultLocale: 'en',
    empresaScraping: 2,
    helmet: {
      title: 'Job Search in South Africa, Job Opportunities in South Africa | FindoJobs',
      description: 'Find thousands of job opportunities in Findojobs South Africa: Customer Service in South Africa, Administrative Assistant in South Africa, Commercial - Sales in South Africa, and many more',
    },
    moneda: {
      iso: 'ZAR',
      simbolo: 'R',
      nombre: 'South African Rand',
    },
    partners: [{
      name: 'Jobsora',
      url: 'https://za.jobsora.com',
    }],
    applicantsGoogleSignIn: true,
    hideBuscojobsOffice: true,
    hideBuscojobsEmprende: true,
  },
};

export default settings;
