import React from 'react';
import PropTypes from 'prop-types';
import styledC, { css } from 'styled-components';
import { Segment } from 'semantic-ui-react';
import { WHITE, DARK_GRAY, GRAY_1, GRAY_5, GRAY_8, GRAY_10, GREEN_3, DARK_BLUE, BLUE_HOVER,
  TABLET, COMPUTER } from 'styles/variables';
import { hoverBackgroundColor, fontColor } from 'styles/utils';


// Segment

const SegmentBJ = ({ disabled, dummy, styled, simple, color, backgroundColor, large, huge, noHover, wide, asButton,
                     leftBorder, noBorder, relaxed, multiple, ...other }) => <Segment {...other} />;

SegmentBJ.propTypes = {
  disabled: PropTypes.bool,
  dummy: PropTypes.bool,
  styled: PropTypes.bool,
  simple: PropTypes.bool,
  color: PropTypes.string,
  large: PropTypes.bool,
  huge: PropTypes.bool,
  noHover: PropTypes.bool,
  wide: PropTypes.bool,
  pro: PropTypes.bool,
  backgroundColor: PropTypes.string,
  asButton: PropTypes.bool,
  leftBorder: PropTypes.bool,
  noBorder: PropTypes.bool,
  relaxed: PropTypes.bool,
  multiple: PropTypes.bool,
};

const StyledSegment = styledC(SegmentBJ)`
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  background-color: ${GRAY_8} !important;
  text-align: center !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dropdown {
    color: ${DARK_BLUE};
    &:hover, &.active {
      background-color: ${GRAY_10};
    }
    i {
      margin: 5px 5px 7px;
    }
    .item {
      .text {
        color: ${GRAY_1};
      }
    }
  }

  ${(props) => props.backgroundColor && css`
    background-color: ${props.backgroundColor} !important;
  `}
  ${(props) => !props.asButton && css`
    &.button {
      background-color: ${GRAY_8} !important;
      &:hover {
        background-color: ${GRAY_8} !important;
      }
    }
  `}
    
  ${(props) => props.dummy && css`
    background-color: ${GRAY_5} !important;
  `}
  
  ${(props) => props.simple && css`
    background-color: ${WHITE} !important;
  `}
  ${(props) => props.pro && css`
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
      &:after {
        position: absolute !important;
        z-index: 2 !important;
        content: '' !important;
        top: 0 !important;
        right: -15% !important;
        background-color: transparent !important;
        width: 0 !important;
        height: 0 !important;
        border-top: 2.5em solid transparent !important;
        border-bottom: 2.5em solid transparent !important;
        border-left: 1em solid ${GRAY_8} !important;
        ${() => props.simple && css`
           border-left: 1em solid ${WHITE} !important;
        `}
        ${() => props.backgroundColor && css `
          border-left: 1em solid ${props.backgroundColor} !important;
        `}
        ${() => props.dummy && css`
           border-left: 1em solid ${GRAY_5} !important;
        `}
        ${() => props.huge && css `
          right: -11% !important;
        `}
        ${() => props.large && css `
          right: -12% !important;
        `}
    }
  `}  
  ${(props) => !props.noHover && css`
    &:hover {
      span {
        color: ${BLUE_HOVER};
      }
    }
    ${() => props.styled && css`
      &:hover {
        p {
          color: ${GREEN_3};
        }
        span {
          color: ${DARK_BLUE};
        }
      }
    `}
  `}

  ${(props) => !props.noBorder && css`
    // Por defecto el color del borde es GREEN_3
    ${props.leftBorder ? css`
      @media(max-width: ${TABLET.maxWidth}px) {
        border-left: 5px solid ${props.color || GREEN_3} !important;
      }
    ` : css`
      border-right: 5px solid ${props.color || GREEN_3} !important;
    `}
  `}
  
  @media(max-width: ${TABLET.maxWidth}px) {
    width: 100% !important;
    padding-top: ${(props) => props.relaxed ? '30px' : '20px'} !important;
    padding-bottom: ${(props) => props.relaxed ? '30px' : '20px'} !important;
  }
  
  @media(min-width: ${COMPUTER.minWidth}px) {
    max-width: 135px !important;
    min-width: 135px !important;
  }
  ${(props) => props.large && css`
    @media(min-width: ${COMPUTER.minWidth}px) {
      max-width: 150px !important;
      min-width: 150px !important;
    }
  `}
  ${(props) => props.huge && css `
    @media(min-width: ${COMPUTER.minWidth}px) {
      max-width: 170px !important;
      min-width: 170px !important;
    }
  `}
  ${(props) => props.asButton && css`
    border: none !important;
    background-color: ${props.color || DARK_BLUE} !important;
    :hover {
      background-color: ${hoverBackgroundColor(props)} !important;
      p {
        color: ${fontColor(props)} !important;
      }
      span {
        color: ${fontColor(props)} !important;
      }
    }
  `}
`;


// Text

const TextBJ = ({ styled, wide, disabled, asButton, color, ...other }) => <span {...other} />;

TextBJ.propTypes = {
  styled: PropTypes.bool,
  wide: PropTypes.bool,
  disabled: PropTypes.bool,
  asButton: PropTypes.bool,
  color: PropTypes.string,
};

const StyledText = styledC(TextBJ)`
  color: ${DARK_BLUE};
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  i {
    backface-visibility: unset;
  }
  
  ${(props) => props.styled && css`
    color: ${DARK_BLUE};
    span {
      display: flex;
    }
  `}
  
  ${(props) => props.disabled && css`
    color: ${DARK_GRAY};
    text-align: center;
    span {
      display: flex;

      // Only for IE browsers
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: inline;
      }
    }
  `}
  
  ${(props) => props.wide && css`
    width: 100%;
  `}

  ${(props) => props.asButton && css`
    color: ${fontColor(props)} !important;
  `}
`;

const ItemActionSegment = (props) => (
  <StyledSegment {...props}>
    <StyledText disabled={props.disabled} styled={props.styled} wide={props.wide} asButton={props.asButton} color={props.color}>
      {props.multiple ? props.children[0] : props.children}
    </StyledText>
    {!!props.multiple && props.children[1]}
  </StyledSegment>
);

ItemActionSegment.propTypes = {
  color: PropTypes.string,
  styled: PropTypes.bool,
  disabled: PropTypes.bool,
  wide: PropTypes.bool,
  asButton: PropTypes.bool,
  multiple: PropTypes.bool,
  children: PropTypes.node,
};

export default ItemActionSegment;
