import React from 'react';
import { ColorStrong } from 'components/BJComponents';
import { ORANGE_1 } from 'styles/variables';

function Asterisco(props) {
  return (
    <ColorStrong color={ORANGE_1} style={{ fontSize: '16px' }} {...props}>*</ColorStrong>
  )
}

export default Asterisco;
