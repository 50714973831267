import common from './common';
import dev from './dev';
import prod from './prod';

export function getCountry() {
  const host = window.location.host;
  const match = host.match(/\.(\w{2})$/) || host.match(/^(\w{2})\./);
  return match ? match[1] : 'uy';
}

function getConfig(env, country) {
  const settings = (env === 'production') ? prod : dev;
  return {
    ...common.all,
    ...common[country],
    ...settings.all,
    ...settings[country],
    env,
    country,
    isDev: env === 'development',
    isProd: env === 'production',
  };
}

const env = process.env.NODE_ENV || 'development';
const country = getCountry();

const config = getConfig(env, country);

export default config;
