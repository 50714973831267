import React from 'react';
import PropTypes from 'prop-types';

const MarkElement = (props) => {
  const { leaf, attributes, children } = props;

  let text = children;

  if (leaf.bold) {
    text = <b>{text}</b>;
  }
  if (leaf.italic) {
    text = <em>{text}</em>;
  }

  return (
    <span {...attributes}>{text}</span>
  );
};

MarkElement.propTypes = {
  leaf: PropTypes.object.isRequired,
  attributes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default MarkElement;
