import React, { useState, useEffect } from 'react';
import { ORANGE_1, GRAY_10 } from 'styles/variables';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PropTypes from 'prop-types';

const ProgressProvider = ({ valueStart, valueEnd, children }) => {
  const [value, setValue] = useState(valueStart);

  useEffect(() => {
    setValue(valueEnd);
  }, [valueEnd]);

  return children(value);
};

const CircularProgress = (props) => {
  const { percentage } = props;

  return (
    <ProgressProvider valueStart={1} valueEnd={percentage}>
      {(value) => (
        <CircularProgressbar
        value={value}
        text={`${value}%`}
        counterClockwise
        styles={{
          // Customize the root svg element
          root: {},
          // Customize the path, i.e. the part that's "complete"
          path: {
            // Tweak path color:
            stroke: ORANGE_1,
            // Tweak path to use flat or rounded ends:
            strokeLinecap: 'butt',
            // Tweak transition animation:
            transition: 'stroke-dashoffset 3s ease 0.2s',
          },
          // Customize the circle behind the path
          trail: {
            // Tweak the trail color:
            stroke: GRAY_10,
          },
          // Customize the text
          text: {
            // Tweak text color:
            fill: ORANGE_1,
            // Tweak text size:
            fontSize: '25px',
          },
        }}
      />
      )}
    </ProgressProvider>
  );
};

CircularProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default CircularProgress;
