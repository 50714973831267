import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import NotificationComponent from 'components/Notification';
import toJS from 'utils/propsToJS';
import { dismissNotification } from './actions';

const mapStateToProps = (state) => ({
  notification: state.getIn(['notification', 'notification']),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  dismissNotification,
}, dispatch);

const Notification = toJS(NotificationComponent);

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
