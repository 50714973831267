import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Segment } from 'semantic-ui-react';
import { GRAY_5, WHITE, GREEN_3, TABLET } from 'styles/variables';

const SegmentBJ = ({ simple, color, backgroundColor, fixed, noBorder, leftBorder, ...other }) => <Segment {...other} />;

SegmentBJ.propTypes = {
  simple: PropTypes.bool,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  fixed: PropTypes.bool,
  noBorder: PropTypes.bool,
  leftBorder: PropTypes.bool,
};

export default styled(SegmentBJ)`
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  background-color: ${(props) => props.backgroundColor || GRAY_5} !important;

  ${(props) => props.simple && css`
    background-color: ${WHITE} !important;
  `}
  
  ${(props) => !props.noBorder && css`
    // Por defecto el color del borde es GREEN_3
    ${props.leftBorder ? css`
      border-left: 5px solid ${props.color || GREEN_3} !important;
    ` : css`
      @media(max-width: ${TABLET.maxWidth}px) {
        border-right: 5px solid ${props.color || GREEN_3} !important;
      }
      ${props.fixed && css`
        border-right: 5px solid ${props.color || GREEN_3} !important;
      `}
    `}
  `}
`;
