import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Sidebar } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Menu, Icon } from 'components/Semantic';
import { Link } from 'components/Navigation';
import menuMessages from 'components/Header/messages';
import { userHasPermission } from 'utils/authWrappers';
import { TESTS, ESTADO_CUENTA_VER } from 'constants/permisos';
import settings from 'settings';
import { BLACK } from 'styles/variables';

import { StyledSidebar, MenuIcon } from './styledComponents';
import messages from "../Header/messages";

export default function AppSidebar({ user, visible, toggleVisibility, showAppBanner }) {
  const [operadorAdminMenu, setOperadorAdminMenu] = useState(false);
  const [operadorReportesMenu, setOperadorReportesMenu] = useState(false);
  const [operadorListasMenu, setOperadorListasMenu] = useState(false);
  const [operadorEstudiosMenu, setOperadorEstudiosMenu] = useState(false);
  const [operadorProductosMenu, setOperadorProductosMenu] = useState(false);

  const toggleOperadorAdmin = () => setOperadorAdminMenu(!operadorAdminMenu);
  const toggleOperadorReportes = () => setOperadorReportesMenu(!operadorReportesMenu);
  const toggleOperadorListas = () => setOperadorListasMenu(!operadorListasMenu);
  const toggleOperadorEstudios = () => setOperadorEstudiosMenu(!operadorEstudiosMenu);
  const toggleOperadorProductos = () => setOperadorProductosMenu(!operadorProductosMenu);

  let menuOptions = null;
  if (user != null && user.tipo === 'Postulante') {
    menuOptions = (
      <StyledSidebar>
        <Menu.Item as={Link} to="/postulante/panel" onClick={toggleVisibility}>
          <MenuIcon name="home" /><FormattedMessage {...menuMessages.menuPostulanteMiPanel} />
        </Menu.Item>
        <Menu.Item as={Link} to="/postulante/mi-cv" onClick={toggleVisibility}>
          <MenuIcon name="address card" /><FormattedMessage {...menuMessages.menuPostulanteMiCurriculum} />
        </Menu.Item>
        <Menu.Item as={Link} to="/postulante/mis-ofertas" onClick={toggleVisibility}>
          <MenuIcon name="file text" /><FormattedMessage {...menuMessages.menuPostulanteOfertas} />
        </Menu.Item>
        {settings.danchiano ? (
          <Menu.Item as={Link} to="/postulante/test-danchiano" onClick={toggleVisibility}>
            <MenuIcon name="bullseye" /><FormattedMessage {...menuMessages.menuPostulanteMisTests} />
          </Menu.Item>
        ) : null}
      </StyledSidebar>
    );
  } else if (user != null && (user.tipo === 'Empresa' || user.tipo === 'Funcionario')) {
    const MisTests = userHasPermission([TESTS])(() => [
      <Menu.Item key="mis-tests" as={Link} external to="/empresa-tests/mis-tests" onClick={toggleVisibility}>
        <MenuIcon name="pie graph" /><FormattedMessage {...menuMessages.menuEmpresaMisTests} />
      </Menu.Item>,
      settings.centralTestComprar && (
        <Menu.Item key="comprar-tests" style={{ paddingLeft: '45px' }} as={Link} external to="/empresa-comprar-tests" onClick={toggleVisibility}>
          <MenuIcon name="dollar" /><FormattedMessage {...menuMessages.menuEmpresaMisTestsComprar} />
        </Menu.Item>
      ),
      <Menu.Item key="cuenta-corriente-tests" style={{ paddingLeft: '45px' }} as={Link} external to="/empresa-tests/mis-tests" onClick={toggleVisibility}>
        <MenuIcon name="star" /><FormattedMessage {...menuMessages.menuEmpresaMisTestsCuentaCorrente} />
      </Menu.Item>,
      <Menu.Item key="historial-tests" style={{ paddingLeft: '45px' }} as={Link} external to="/empresa-tests/historial" onClick={toggleVisibility}>
        <MenuIcon name="history" /><FormattedMessage {...menuMessages.menuEmpresaMisTestsHistorial} />
      </Menu.Item>,
      <Menu.Item key="info-tests" style={{ paddingLeft: '45px' }} as={Link} external to="/empresa-tests/landing" onClick={toggleVisibility}>
        <MenuIcon name="info circle" /><FormattedMessage {...menuMessages.menuEmpresaMisTestsInformacion} />
      </Menu.Item>,
    ]);

    const EstadoCuenta = userHasPermission([ESTADO_CUENTA_VER])(() => (
      <Menu.Item as={Link} to="/empresa/estado-cuenta" onClick={toggleVisibility}>
        <MenuIcon name="star" /><FormattedMessage {...menuMessages.menuEmpresaEstadoCuenta} />
      </Menu.Item>
    ));

    menuOptions = (
      <StyledSidebar>
        <Menu.Item as={Link} to="/empresa/panel" onClick={toggleVisibility}>
          <MenuIcon name="building" /><FormattedMessage {...menuMessages.menuEmpresaMiEmpresa} />
        </Menu.Item>
        <Menu.Item as={Link} to="/empresa/ofertas" onClick={toggleVisibility}>
          <MenuIcon name="file text" /><FormattedMessage {...menuMessages.menuEmpresaMisOfertas} />
        </Menu.Item>
        {settings.centralTest && <MisTests />}
        <EstadoCuenta />
      </StyledSidebar>
    );
  }
  else if (user != null && (user.tipo === 'Operador')) {
    menuOptions = (
      <StyledSidebar>
        <Menu.Item onClick={toggleOperadorAdmin}>
          Admin.
          <Icon name="chevron right" color={BLACK} />
        </Menu.Item>
        {!!operadorAdminMenu &&
          <>
            <Menu.Item as={Link} to="/operador" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorAdministracionPanel} />
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-administracion-avanzada" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorAdministracionAvanzada} />
            </Menu.Item>
            <Menu.Item as={Link} to="/operador/empresas" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorAdministracionEmpresas} />
            </Menu.Item>
            <Menu.Item as={Link} to="/operador/ofertas" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorAdministracionOfertas} />
            </Menu.Item>
            <Menu.Item as={Link} to="/operador/funcionarios" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Administración de Funcionarios
            </Menu.Item>
            <Menu.Item as={Link} to="/operador/postulantes" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorAdministracionPostulantes} />
            </Menu.Item>
            <Menu.Item as={Link} to="/operador/pagos" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorAdministracionPagos} />
            </Menu.Item>
            <Menu.Item as={Link} to="/operador/empresapaquetes" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorAdministracionEmpresaPaquetes} />
            </Menu.Item>
            <Menu.Item as={Link} to="/operador/feeds" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Administración de Feeds
            </Menu.Item>
            <Menu.Item as={Link} to="/operador/banners" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Administración de Banners Alertas
            </Menu.Item>
            <Menu.Item as={Link} to="/operador/postulantes-liberados" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Actualizar Postulantes Liberados
            </Menu.Item>
            <Menu.Item as={Link} to="/operador/imgs-s3" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Subir imágenes a S3
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-pagos" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorPagos} />
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-forzar-acceso" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorForzarAcceso} />
            </Menu.Item>
          </>
        }
        <Menu.Item onClick={toggleOperadorReportes}>
          <FormattedMessage {...menuMessages.menuOperadorReportes} />
          <Icon name="chevron right" color={BLACK} />
        </Menu.Item>
        {!!operadorReportesMenu &&
          <>
            <Menu.Item as={Link} external to="/operador-reportes-hdl" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorReportesHDL} />
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador/estadisticas-danchiano" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorReportesDA} />
            </Menu.Item>
            <Menu.Item as={Link} to="/operador/enriquecer-datos-postulantes" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Enriquecer Datos de Postulantes
            </Menu.Item>
          </>
        }
        <Menu.Item onClick={toggleOperadorListas}>
          <FormattedMessage {...menuMessages.menuOperadorListas} />
          <Icon name="chevron right" color={BLACK} />
        </Menu.Item>
        {!!operadorListasMenu &&
          <>
            <Menu.Item as={Link} external to="/operador-conocimientos" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Áreas, subáreas y conocimientos
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-paises" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Países, provincias y ciudades
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-canales" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Canales y subcanales
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-regiones" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Regiones y ubicaciones
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-como-nos-conociste" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Cómo nos conociste
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-contratos" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Contratos
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-destinos" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Destinos
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-estados-civil" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Estados civiles
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-disponibilidades-cambiar-residencia" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Disponibilidades para cambiar de residencia
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-disponibilidades-viaje" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Disponibilidades para viajar
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-idiomas" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Idiomas
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-niveles-idioma" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Niveles de idioma
            </Menu.Item>
            <Menu.Item  style={{ paddingLeft: '45px' }} onClick={toggleOperadorEstudios}>
              Estudios
              <Icon name="chevron right" color={BLACK} />
            </Menu.Item>
            {!!operadorEstudiosMenu &&
              <>
                <Menu.Item as={Link} external to="/operador-tecnicos" style={{ paddingLeft: '65px' }} onClick={toggleVisibility}>
                  Técnicos
                </Menu.Item>
                <Menu.Item as={Link} external to="/operador-titulos" style={{ paddingLeft: '65px' }} onClick={toggleVisibility}>
                  Titulos
                </Menu.Item>
                <Menu.Item as={Link} external to="/operador-cursos" style={{ paddingLeft: '65px' }} onClick={toggleVisibility}>
                  Cursos
                </Menu.Item>
                <Menu.Item as={Link} external to="/operador-postgrados" style={{ paddingLeft: '65px' }} onClick={toggleVisibility}>
                  Postgrados
                </Menu.Item>
              </>
            }
            <Menu.Item as={Link} external to="/operador-tipos-documento" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Tipos de documento
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-tipos-permiso-conducir" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Tipos de permiso de conducir
            </Menu.Item>
            <Menu.Item as={Link} external to="/operador-propiedades" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              Propiedades
            </Menu.Item>
          </>
        }
        <Menu.Item onClick={toggleOperadorProductos}>
          <FormattedMessage {...menuMessages.menuOperadorProductos} />
          <Icon name="chevron right" color={BLACK} />
        </Menu.Item>
        {!!operadorProductosMenu &&
          <>
            <Menu.Item as={Link} to="/operador/paquetes" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorAdminPaquete} />
            </Menu.Item>
            <Menu.Item as={Link} to="/operador/paquetes/crear" style={{ paddingLeft: '45px' }} onClick={toggleVisibility}>
              <FormattedMessage {...messages.menuOperadorCrearPaquete} />
            </Menu.Item>
          </>
        }
      </StyledSidebar>
    );
  }

  return (
    <Sidebar as={Menu} animation="overlay" width="wide" visible={visible} vertical>
      {!!settings && !!settings.hasApp && !!showAppBanner &&
        <div style={{ marginTop: '165px', width: '100%' }}></div>
      }
      {menuOptions}
    </Sidebar>
  );
}

AppSidebar.propTypes = {
  user: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  showAppBanner: PropTypes.bool,
};
