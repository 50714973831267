import { Node, Text } from 'slate';
import { unified } from 'unified';
import markdown from 'remark-parse';
import { PARAGRAPH, BULLETED_LIST, NUMBERED_LIST, LIST_ITEM, TEXT_FORMATS } from '../constants';

const plainTextSerializer = (value) => {
  return value.map((node) => {
    switch (node.type) {
      case NUMBERED_LIST:
        return node.children.map((n, i) => `${i+1}. ${Node.string(n)}`).join('\n');
      case BULLETED_LIST:
        return node.children.map((n) => `• ${Node.string(n)}`).join('\n');
      default:
        return Node.string(node);
    }
  }).join('\n');
}

const markdownSerializer = (value) => {
  return value.map((node) => {
    if (Text.isText(node)) {
      let text = node.text.replace(/([\\`*_{}[\]<>()#+\-.!|])/g, '\\$1'); // Escape special characters
      const trimmedText = text.trim();
      const spaces = text.split(trimmedText);
      if (node.bold && node.italic) {
        text = trimmedText ? `${spaces[0] ?? ''}___${trimmedText}___${spaces[1] ?? ''}` : '';
      } else if (node.bold) {
        text = trimmedText ? `${spaces[0] ?? ''}**${trimmedText}**${spaces[1] ?? ''}` : '';
      } else if (node.italic) {
        text = trimmedText ? `${spaces[0] ?? ''}*${trimmedText}*${spaces[1] ?? ''}` : '';
      }
      return text;
    }

    const children = markdownSerializer(node.children).trim();

    switch (node.type) {
      case LIST_ITEM:
        return children;
      case NUMBERED_LIST:
        return `${node.children.map((n, i) => `${i+1}. ${markdownSerializer([n]).trim()}`).join('\n')}\n\n`;
      case BULLETED_LIST:
        return `${node.children.map((n) => `- ${markdownSerializer([n]).trim()}`).join('\n')}\n\n`;
      default:
        return `${children}\n\n`;
    }
  }).join('');
}

export const serialize = (value, textFormat) => {
  switch (textFormat) {
    case TEXT_FORMATS.PLAIN_TEXT:
      return plainTextSerializer(value);
    case TEXT_FORMATS.MARKDOWN:
      return markdownSerializer(value);
    default:
      return markdownSerializer(value);
  }
}

async function plainTextDeserializer(string) {
  return string.split('\n').map((line) => ({
    type: PARAGRAPH,
    children: [{ text: line }],
  }));
}

const processNode = (node) => {
  let leaf = {};
  switch (node.type) {
    case 'emphasis':
      leaf.italic = true;
      leaf.text = '';
      break;
    case 'strong':
      leaf.bold = true;
      leaf.text = '';
      break;
    default:
      leaf.text = node.value ? node.value.replace(/\\([\\`*_{}[\]<>()#+\-.!|])/g, '$1') : '';
      break;
  }
  if (node.children?.length > 0) {
    node.children.forEach((child) => {
      leaf = { ...leaf, ...processNode(child) };
    });
  }
  return leaf;
}

const processBlocks = (blocks) => {
  let leaves = [];
  blocks.forEach((block) => {
    if (block.children?.length > 0) {
      leaves = [...leaves, ...block.children.map((node) => processNode(node))];
    } else if (block.value) {
      leaves.push({ text: block.value.replace(/\\([\\`*_{}[\]<>()#+\-.!|])/g, '$1') });
    }
  });
  return leaves;
}

const processList = (listItems) => {
  return listItems.map((listItem) => {
    return {
      type: LIST_ITEM,
      children: listItem.children?.length > 0 ?
        processBlocks(listItem.children)
      :
        [{ text: '' }],
    };
  });
}

async function markdownDeserializer(string) {
  const mdast = await unified().use(markdown).parse(string);

  let ast = [];
  mdast.children.forEach((node) => {
    if (node.type === 'list') {
      if (node.ordered) {
        ast.push({
          type: NUMBERED_LIST,
          children: processList(node.children),
        });
      } else {
        ast.push({
          type: BULLETED_LIST,
          children: processList(node.children),
        });
      }
    } else if (node.children?.length > 0) {
      ast.push({
        type: PARAGRAPH,
        children: node.children.map((node) => processNode(node)),
      });
    } else if (node.value) {
      ast.push({
        type: PARAGRAPH,
        children: [{ text: node.value.replace(/\\([\\`*_{}[\]<>()#+\-.!|])/g, '$1') }],
      });
    }
  });

  return ast;
}

export const deserialize = (string, textFormat) => {
  switch (textFormat) {
    case TEXT_FORMATS.PLAIN_TEXT:
      return plainTextDeserializer(string);
    case TEXT_FORMATS.MARKDOWN:
      return markdownDeserializer(string);
    default:
      return plainTextDeserializer(string);
  }
}
