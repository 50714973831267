import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Timeline as TLine, TimelineItem } from 'vertical-timeline-component-for-react';
import { LIGHT_GRAY, DARK_GRAY, GRAY_13, ORANGE_1, MOBILE, TABLET, COMPUTER, LARGE_SCREEN } from 'styles/variables';

// TimelineItem

const TimelineItemBJ = ({ dateText, dateComplement, ...props }) => (
  <TimelineItem
    dateInnerStyle={{ background: 'transparent', color: ORANGE_1, fontSize: '13px' }}
    dateText={dateText}
    dateComponent={dateComplement ?
      <span className="timeline-item-date">
        <time
          className="timeline-item-dateinner"
          title={dateText}
          style={{ background: 'transparent', color: ORANGE_1, fontSize: '13px' }}
        >
          {dateText}
        </time>
        <br />
        <span className="timeline-item-date-complement">{dateComplement}</span>
      </span>
    :
      null
    }
    {...props}
  />
);

TimelineItemBJ.propTypes = {
  dateText: PropTypes.string,
  dateComplement: PropTypes.string,
};


// Timeline

const TimelineWrapper = styled.div`
  .timeline--wrapper {
    width: 100% !important;
  }
`;

const TimelineBJ = ({ ...props }) => (
  <TimelineWrapper><TLine lineColor={LIGHT_GRAY} animate={false} {...props} /></TimelineWrapper>
);

const Timeline = styled(TimelineBJ)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  .title {
    border: none !important;
    margin-bottom: 0.5em !important;
    color: ${LIGHT_GRAY} !important;
    transition: none !important;
    cursor: default !important;
    @media only screen and (max-width: ${MOBILE.maxWidth}px) {
      margin-bottom: 30px !important;
    }
    @media only screen and (min-width: ${TABLET.minWidth}px) {
      width: 20% !important;
    }
    @media only screen and (min-width: ${COMPUTER.minWidth}px) {
      width: 23% !important;
    }
    @media only screen and (min-width: ${LARGE_SCREEN.minWidth}px) {
      width: 17% !important;
    }
    &::before {
      background-color: ${DARK_GRAY} !important;
      @media only screen and (max-width: ${MOBILE.maxWidth}px) {
        left: calc(1% + 1px) !important;
      }
      @media only screen and (min-width: ${TABLET.minWidth}px) {
        right: -9px !important;
      }
      @media only screen and (min-width: ${COMPUTER.minWidth}px) {
        right: -11px !important;
      }
      @media only screen and (min-width: ${LARGE_SCREEN.minWidth}px) {
        right: -8px !important;
      }
    }
    .timeline-item-date {
      background-color: transparent;
      text-align: right;
      margin-top: 12px;
      -webkit-clip-path: none !important;
      clip-path: none !important;
      @media only screen and (max-width: ${MOBILE.maxWidth}px) {
        text-align: left;
      }
      .timeline-item-dateinner {
        -webkit-clip-path: none !important;
        clip-path: none !important;
        line-height: 24px !important;
        white-space: pre-wrap;
        display: inline;
      }
      .timeline-item-date-complement {
        color: ${GRAY_13};
        font-size: 12px;
      }
    }
  }
  .body {
    @media only screen and (min-width: ${TABLET.minWidth}px) {
      width: 80% !important;
    }
    @media only screen and (min-width: ${COMPUTER.minWidth}px) {
      width: 77% !important;
    }
    @media only screen and (min-width: ${LARGE_SCREEN.minWidth}px) {
      width: 83% !important;
    }
  }
  &::before {
    @media only screen and (min-width: ${TABLET.minWidth}px) {
      left: calc(19% + 6px) !important;
    }
    @media only screen and (min-width: ${COMPUTER.minWidth}px) {
      left: calc(22% + 6px) !important;
    }
    @media only screen and (min-width: ${LARGE_SCREEN.minWidth}px) {
      left: calc(16% + 6px) !important;
    }
  }
`;

Timeline.Item = TimelineItemBJ;

export default Timeline;
