import React from 'react';

import { NUMBERED_LIST, BULLETED_LIST } from '../constants';
import { isMac } from '../utils/helpers';

import MarkButton from './MarkButton';
import BlockButton from './BlockButton';
import Divider from './Divider';

const Toolbar = () => (
  <div
    className="bj-toolbar"
    onMouseDown={(e) => {
      // This line is just to prevent firing onBlur in the editor
      e.preventDefault();
    }}>
    <MarkButton format="bold" icon="bold" hotkey={isMac() ? 'Cmd + B' : 'Ctrl + B'} />
    <MarkButton format="italic" icon="italic" hotkey={isMac() ? 'Cmd + I' : 'Ctrl + I'} />
    <Divider />
    <BlockButton format={NUMBERED_LIST} icon="ordered list" />
    <BlockButton format={BULLETED_LIST} icon="unordered list" />
  </div>
);

export default Toolbar;
