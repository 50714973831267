import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { LIGHT_GRAY, GRAY_1, ORANGE_1, WHITE } from 'styles/variables';
import ColorText from './ColorText';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

class RangeSlider extends React.Component {

  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    defaultValue: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onAfterChange: PropTypes.func,
    mainColor: PropTypes.string,
  };

  handleChange = (value) => {
    this.props.onChange(value);
  };

  handleAfterChange = (value) => {
    this.props.onAfterChange(value);
  };

  render() {
    const { min, max, defaultValue, value, mainColor } = this.props;

    const color = mainColor || ORANGE_1;

    return (
      <Grid style={{ minWidth: '100%' }}>
        <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Grid.Column width={16}>
            <Range
              min={min}
              max={max}
              defaultValue={defaultValue}
              value={value}
              allowCross={false}
              tipFormatter={(val) => `${val}`}
              onChange={this.handleChange}
              onAfterChange={this.handleAfterChange}
              style={{ marginTop: '20px', marginBottom: '10px' }}
              trackStyle={[
                {
                  backgroundColor: color,
                  height: '8px',
                }]}
              handleStyle={[
                {
                  backgroundColor: WHITE,
                  boxShadow: `0px 1px 8px -1px ${GRAY_1}`,
                  border: 'none',
                  height: '28px',
                  width: '11px',
                  borderRadius: '4px',
                  top: '0%',
                },
                {
                  backgroundColor: WHITE,
                  boxShadow: `0px 1px 8px -1px ${GRAY_1}`,
                  border: 'none',
                  height: '28px',
                  width: '11px',
                  borderRadius: '4px',
                  top: '0%',
                }]}
              railStyle={{
                backgroundColor: LIGHT_GRAY,
                height: '8px',
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} style={{ paddingTop: 0 }}>
          <Grid.Column>
            <ColorText light>{min}</ColorText>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <ColorText light>{max}</ColorText>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default RangeSlider;
