import { fromJS } from 'immutable';
import { isNil } from 'lodash';
import createReducer from 'utils/createReducer';
import { REPO_QUERY_REQUEST, REPO_QUERY_SUCCESS, REPO_QUERY_FAILURE } from '../constants';

const initialGetState = {
  data: null,
  loaded: false,
  loading: false,
  error: null,
};

export function makeReducer(queryName) {
  return createReducer(initialGetState, {
    [REPO_QUERY_REQUEST]: (state) =>
      state.set('loading', true),

    [REPO_QUERY_SUCCESS]: (state, action) =>
      state
        .set('loading', false)
        .set('loaded', true)
        .set('data', isNil(action.payload.data.result) ? fromJS(action.payload.data) : fromJS(action.payload.data.result))
        .set('error', null),

    [REPO_QUERY_FAILURE]: (state, action) =>
      state
        .set('loading', false)
        .set('error', fromJS(action.payload.error)),

  }, (state, action) => action.payload && action.payload.name === queryName);
}
