import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb as BreadcrumbComponent, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import { Link } from 'components/Navigation';
import logo from 'images/ojo.svg';

const BreadcrumbIcon = styled(Image)`
  width: 14px;
  height: 14px;
`;

const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const BreadcrumbContext = React.createContext({
  sections: [],
  setSections: () => {},
});

export const BreadcrumbProvider = ({ children }) => {
  const [sections, setSections] = React.useState([]);

  return (
    <BreadcrumbContext.Provider value={{ sections, setSections }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

BreadcrumbProvider.propTypes = {
  children: PropTypes.node,
};

export const BreadcrumbContainer = () => {
  const { sections } = React.useContext(BreadcrumbContext);

  if (sections.length === 0) { return null; }

  const finalSections = [
    { key: 'home', content: <BreadcrumbIcon src={logo} />, href: '/' },
    ...sections.map((section) =>
      section.href ? <Link key={section.key} to={section.href} external={!!section.external}>{section.content}</Link> : section
    ),
  ];

  return (
    <Container>
      <BreadcrumbComponent size="small" icon="right angle" sections={finalSections} />
    </Container>
  );
};

export const Breadcrumbs = ({ sections }) => {
  const { setSections } = React.useContext(BreadcrumbContext);

  React.useEffect(() => {
    setSections(sections);
  }, [sections]);

  return null;
};

Breadcrumbs.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.node.isRequired,
    href: PropTypes.string,
    external: PropTypes.bool,
    active: PropTypes.bool,
  })),
};
