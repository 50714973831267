import React from 'react';
import { Responsive } from 'semantic-ui-react';
import { MOBILE, TABLET, COMPUTER, LARGE_SCREEN, WIDE_SCREEN } from 'styles/variables';

const ResponsiveBJ = ({ ...other }) => <Responsive {...other} />;

ResponsiveBJ.onlyMobile = { minWidth: MOBILE.minWidth, maxWidth: MOBILE.maxWidth };
ResponsiveBJ.onlyTablet = { minWidth: TABLET.minWidth, maxWidth: TABLET.maxWidth };
ResponsiveBJ.onlyComputer = { minWidth: COMPUTER.minWidth, maxWidth: COMPUTER.maxWidth };
ResponsiveBJ.onlyLargeScreen = { minWidth: LARGE_SCREEN.minWidth, maxWidth: LARGE_SCREEN.maxWidth };
ResponsiveBJ.onlyWidescreen = { minWidth: WIDE_SCREEN.minWidth };

export default ResponsiveBJ;
