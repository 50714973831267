import React, { useState } from 'react';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import PropTypes from 'prop-types';
import { Sidebar, Dimmer } from 'semantic-ui-react';
import { Responsive } from 'components/Semantic';
import { BreadcrumbContainer, BreadcrumbProvider } from 'components/BJComponents';
import MainHeader from 'containers/Header';
import Notification from 'containers/Notification';
import Footer from 'components/Footer';
import { usePageStyles } from 'components/PageStyles';
import ZohoChat from 'containers/ZohoChat';
import settings from 'settings';

import AppSidebar from './Sidebar';
import { StyledDimmable, ErrorDiv, WrapperContainer } from './styledComponents';

export default function AppLayout({ user, visible, toggleVisibility, children }) {
  const isAndroidDevice = navigator.userAgent.indexOf('Android') >= 0;
  
  const [showAppBanner, setShowAppBanner] = useState(isAndroidDevice && sessionStorage.getItem('hide_app_banner') !== '1');

  const { pageWidth, pageBackground } = usePageStyles();

  const closeAppBanner = () => {
    sessionStorage.setItem('hide_app_banner', 1);
    if (settings?.analytics?.measurementId) {
      ReactGA4.event({ category: 'usuario', action: 'app-banner-cerrar' });
    }
    ReactGA.event({ category: 'usuario', action: 'app-banner-cerrar' });
    setShowAppBanner(false);
  };

  return (
    <React.Fragment>
      <MainHeader
        toggleSidebarVisibility={toggleVisibility}
        showAppBanner={showAppBanner}
        closeAppBanner={closeAppBanner}
      />

      <Responsive as={ErrorDiv} minWidth={Responsive.onlyComputer.minWidth}>
        <Notification />
      </Responsive>

      {/* Spacer para desplazar el contenido de la página */}
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <Notification />
      </Responsive>
      {/* Spacer para desplazar el contenido de la página */}

      <Sidebar.Pushable style={{ transform: 'unset' }}>
        <AppSidebar
          user={user}
          visible={visible}
          toggleVisibility={toggleVisibility}
          showAppBanner={showAppBanner}
        />
        <Sidebar.Pusher>
          <StyledDimmable dimmed={visible}>
            <Dimmer active={visible} onClickOutside={toggleVisibility} />

            <Responsive as={ErrorDiv} maxWidth={Responsive.onlyTablet.maxWidth}>
              <Notification />
            </Responsive>

            {/* Spacer para desplazar el contenido de la página */}
            {!!settings && !!settings.hasApp && !!showAppBanner &&
              <div style={{ marginTop: '96px', width: '100%' }}></div>
            }
            <Responsive maxWidth={Responsive.onlyTablet.maxWidth} minWidth={Responsive.onlyTablet.minWidth}>
              <Notification />
            </Responsive>
            {/* Spacer para desplazar el contenido de la página */}

            <WrapperContainer
              id="wrapper"
              className={pageBackground === 'white' ? 'wrapperWhite' : ''}
              wide={pageWidth === 'wide'}
            >
              <BreadcrumbProvider>
                <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                  <BreadcrumbContainer />
                </Responsive>
                {children}
              </BreadcrumbProvider>
            </WrapperContainer>
            <Footer />
          </StyledDimmable>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      {(settings.zohoChat && !!settings.zohoChatCode && !!user && (user.tipo === 'Empresa' || user.tipo === 'Funcionario')) &&
        <ZohoChat
          nombre={user.tipo === 'Empresa' ?
            user.Empresa.Nombre.replaceAll('"', '')
          :
            `${user.Funcionario.PrimerNombre.replaceAll('"', '')} ${user.Funcionario.PrimerApellido.replaceAll('"', '')}`
          }
          empresaId={user.empresaId}
          email={user.tipo === 'Empresa' ? user.Empresa.Email : user.Funcionario.Email}
        />
      }
    </React.Fragment>
  );
}

AppLayout.propTypes = {
  user: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  children: PropTypes.node,
};
