import { routerActions } from 'react-router-redux';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history3/locationHelper';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import settings from 'settings';
import _ from 'lodash';
import { logMessage } from 'logger';

import { ROL_FUNCIONARIO_EMPRESA, ROL_FUNCIONARIO_ADMINISTRADOR,
  ROL_FUNCIONARIO_ANALISTA, ROL_FUNCIONARIO_ASISTENTE } from 'constants/roles';
import Restricted from 'containers/RestrictedPage';

/**
 * User types
 */
const ADMINISTRADOR = 'operador';
const EMPRESA = 'empresa';
const FUNCIONARIO = 'funcionario';
const POSTULANTE = 'postulante';

const locationHelper = locationHelperBuilder({});

const getUserType = (state) => {
  const tipo = state.getIn(['auth', 'user', 'tipo']);
  return tipo && tipo.toLowerCase();
};

const checkUserType = (state, type) => getUserType(state) === type;

/**
 * Se controla que el usuario este autenticado
 * En caso de estarlo se hace redirect al lugar indicado o en su defecto a '/'
 */
export const userIsNotAuthenticated = connectedReduxRedirect({
  authenticatedSelector: (state) => state.getIn(['auth', 'user']) === null,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || `/app/${getUserType(state)}`,
  redirectAction: routerActions.replace,
  allowRedirectBack: false,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

/**
 * Se controla que el usuario este autenticado
 * En caso contrario se hace redirect al login
 */
export const userIsAuthenticated = connectedReduxRedirect({
  authenticatedSelector: (state) => {
    const user = state.getIn(['auth', 'user']);
    const token = state.getIn(['auth', 'token']);
    const windowToken = window.token || null;
    const location = window.location;

    if (window.ReactNativeWebView && (!user || !token)) {
      logMessage({ type: "appmobile", user, token, location, windowToken });
    }

    return !!user;
  },
  redirectPath: '/app/login?expired=1',
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated',
});

/**
 * Se controla que el usuario tenga alguno de los permisos indicados
 *
 * @param userPermissions       Permisos del Usuario
 * @param permissions           Permisos que se desean verificar
 */
export const userHasPermissionFn = (userPermissions, permissions) =>
  userPermissions.some((p) => _.includes(permissions, p));

/**
 * Se controla que el usuario tenga alguno de los permisos indicados
 *
 * @param permissions           Permisos que se desean verificar
 * @param alternate             En caso de no tener el permiso mostrar este componente
 */
export const userHasPermission = (permissions, alternate = () => null) => connectedAuthWrapper({
  authenticatedSelector: (state) => {
    const userPermissions = state.getIn(['auth', 'user']).get('permisos').toArray();
    return userHasPermissionFn(userPermissions, permissions);
  },
  FailureComponent: alternate,
  wrapperDisplayName: 'UserHasPermission',
});

/**
 * Se controla que el usuario tenga alguno de los roles indicados
 *
 * @param userRoles       Roles del Usuario
 * @param roles           Roles que se desean verificar
 */
export const userHasRolesFn = (userRoles, roles) =>
  userRoles.some((r) => _.includes(roles, r));

/**
 * Se controla que el usuario tenga alguno de los roles indicados
 *
 * @param roles           Roles que se desean verificar
 * @param alternate             En caso de no tener el permiso mostrar este componente
 */
export const userHasRoles = (roles, alternate = () => null) => connectedAuthWrapper({
  authenticatedSelector: (state) => {
    const userRoles = state.getIn(['auth', 'user', 'roles']).toArray();
    return userHasRolesFn(userRoles, roles);
  },
  FailureComponent: alternate,
  wrapperDisplayName: 'UserHasRole',
});

/**
 * Se controla que el usuario pueda eliminar un comentario.
 * Si es Empresa o Administrador puede eliminar cualquier comentario.
 * Si es Analista o Asistente solo puede eliminar si la creó.
 *
 * @param userId                Id del usuario
 * @param userRol               Rol del usuario
 * @param comentario                Comentario a controlar si el usuario puede eliminarlo
 */
export const userCanDeleteComentarioFn = (userId, userRol, comentario) => {
  switch (userRol) {
    case ROL_FUNCIONARIO_ASISTENTE:
    case ROL_FUNCIONARIO_ANALISTA: {
      const idFuncionarioCreador = comentario.IdFuncionario;
      return idFuncionarioCreador && idFuncionarioCreador === userId;
    }
    case ROL_FUNCIONARIO_ADMINISTRADOR: {
      return true;
    }
    case ROL_FUNCIONARIO_EMPRESA: {
      return true;
    }
    default: {
      return false;
    }
  }
};
/**
 * Se controla que el usuario pueda  editar un comentario.
 * Si es Empresa o Administrador puede editar si la creó comentario.
 * Si es Analista o Asistente solo puede editar si la creó.
 *
 * @param userId                Id del usuario
 * @param userRol               Rol del usuario
 * @param comentario                Comentario a controlar si el usuario puede eliminarlo
 */
export const userCanEditComentarioFn = (userId, userRol, comentario) => {
  switch (userRol) {
    case ROL_FUNCIONARIO_ADMINISTRADOR:
    case ROL_FUNCIONARIO_EMPRESA:
    case ROL_FUNCIONARIO_ASISTENTE:
    case ROL_FUNCIONARIO_ANALISTA: {
      const idFuncionarioCreador = comentario.IdFuncionario || comentario.IdEmpresa;
      return idFuncionarioCreador && idFuncionarioCreador === userId;
    }
    default: {
      return false;
    }
  }
};

/**
 * Se controla que el usuario pueda editar una oferta.
 * Si es Empresa o Administrador puede editar cualquier oferta.
 * Si es Analista solo puede editar si la creó.
 * Si es Asistente no puede editar ninguna oferta.
 *
 * @param userId                Id del usuario
 * @param userRol               Rol del usuario
 * @param oferta                Oferta a controlar si el usuario puede editarla
 */
export const userCanEditOfertaFn = (userId, userRol, oferta) => {
  switch (userRol) {
    case ROL_FUNCIONARIO_ASISTENTE: {
      return false;
    }
    case ROL_FUNCIONARIO_ANALISTA: {
      const idFuncionarioCreador = oferta.Funcionario.length && oferta.Funcionario[0].IdFuncionario;
      return idFuncionarioCreador && idFuncionarioCreador === userId;
    }
    case ROL_FUNCIONARIO_ADMINISTRADOR: {
      return true;
    }
    case ROL_FUNCIONARIO_EMPRESA: {
      return true;
    }
    default: {
      return false;
    }
  }
};

/**
 * Se controla que el usuario pueda editar una oferta.
 * Si es Empresa o Administrador puede editar cualquier oferta.
 * Si es Analista solo puede editar si la creó.
 * Si es Asistente no puede editar ninguna oferta.
 *
 * @param oferta                Oferta a controlar si el usuario puede editarla
 * @param alternate             En caso de no tener el permiso mostrar este componente
 */
export const userCanEditOferta = (oferta, alternate = () => null) => connectedAuthWrapper({
  authenticatedSelector: (state) => {
    const user = state.getIn(['auth', 'user']);
    const userId = user.get('id');
    const userRol = user
      .get('roles')
      .filter((rol) => {
        const re = new RegExp('\\bFuncionario.+');
        return re.test(rol);
      })
      .last();
    return userCanEditOfertaFn(userId, userRol, oferta);
  },
  FailureComponent: alternate,
  wrapperDisplayName: 'UserCanEditOferta',
});

/**
 * Se controla que el usuario pueda asignar una oferta a otro funcionario.
 * Si es Empresa o Administrador puede asignar a cualquier Analista u Asistente
 * Si es Analista solo puede asignar si la creó.
 * Si es Asistente no puede asignar ninguna oferta.
 *
 * @param userId                Id del usuario
 * @param userRol               Rol del usuario
 * @param oferta                Oferta a controlar si el usuario puede asignarla
 */
export const userCanAssignOfertaFn = (userId, userRol, oferta) => {
  const funcionarioOferta = oferta.Funcionario && oferta.Funcionario[0];
  const idUsuarioCreador = funcionarioOferta ? funcionarioOferta.IdFuncionario : oferta.IdEmpresa;

  switch (userRol) {
    case ROL_FUNCIONARIO_ASISTENTE: {
      return false;
    }
    case ROL_FUNCIONARIO_ADMINISTRADOR:
    case ROL_FUNCIONARIO_EMPRESA: {
      return true;
    }
    case ROL_FUNCIONARIO_ANALISTA: {
      return idUsuarioCreador === userId;
    }
    default: {
      return false;
    }
  }
};

/**
 * Se controla que el usuario pueda asignar una oferta a otro funcionario.
 * Si es Empresa, Administrador o Analista solo puede asignar si la creó.
 * Si es Asistente no puede asignar ninguna oferta.
 *
 * @param oferta                Oferta a controlar si el usuario puede asignarla
 * @param alternate             En caso de no tener el permiso mostrar este componente
 *
 * Obs: Si el usuario tiene más de un rol Funcionario,
 */
export const userCanAssignOferta = (oferta, alternate = () => null) => connectedAuthWrapper({
  authenticatedSelector: (state) => {
    const user = state.getIn(['auth', 'user']);
    const userId = user.get('id');
    const userRol = user
      .get('roles')
      .filter((rol) => {
        const re = new RegExp('\\bFuncionario.+');
        return re.test(rol);
      })
      .last();
    return userCanAssignOfertaFn(userId, userRol, oferta);
  },
  FailureComponent: alternate,
  wrapperDisplayName: 'UserCanAssignOferta',
});

/**
 * Se controla que el usuario tenga mayor rango que el funcionario indicado
 *
 * @param funcionario           Funcionario a comparar con el usuario
 * @param alternate             En caso de no tener el permiso mostrar este componente
 */
export const userCanEditFuncionario = (funcionario, alternate = () => null) => connectedAuthWrapper({
  authenticatedSelector: (state) => {
    const userRoles = state.getIn(['auth', 'user'])
      .get('roles')
      .filter((rol) => {
        const re = new RegExp('\\bFuncionario.+');
        return re.test(rol);
      });
    let userRol;
    if (userRoles.size > 1 && userRoles.includes(ROL_FUNCIONARIO_EMPRESA)) {
      userRol = ROL_FUNCIONARIO_EMPRESA;
    } else {
      userRol = userRoles.first();
    }
    switch (userRol) {
      case ROL_FUNCIONARIO_ASISTENTE: {
        return false;
      }
      case ROL_FUNCIONARIO_ANALISTA: {
        const funcionarioRol1 = funcionario.Usuario.Roles.filter((rol) =>
          rol.name === ROL_FUNCIONARIO_ADMINISTRADOR || rol.name === ROL_FUNCIONARIO_ANALISTA || rol.name === ROL_FUNCIONARIO_ASISTENTE)[0];
        return funcionarioRol1 && funcionarioRol1.name === ROL_FUNCIONARIO_ASISTENTE;
      }
      case ROL_FUNCIONARIO_EMPRESA: {
        return true;
      }
      case ROL_FUNCIONARIO_ADMINISTRADOR: {
        const funcionarioRol2 = funcionario.Usuario.Roles.filter((rol) =>
          rol.name === ROL_FUNCIONARIO_ADMINISTRADOR || rol.name === ROL_FUNCIONARIO_ANALISTA || rol.name === ROL_FUNCIONARIO_ASISTENTE)[0];
        return funcionarioRol2 && (funcionarioRol2.name === ROL_FUNCIONARIO_ASISTENTE || funcionarioRol2.name === ROL_FUNCIONARIO_ANALISTA);
      }
      default: {
        return false;
      }
    }
  },
  FailureComponent: alternate,
  wrapperDisplayName: 'UserCanEditFuncionario',
});

/**
 * Se controla que el usuario tenga mayor rango que el funcionario de la invitacion indicada
 *
 * @param invitacion            Invitacion del Funcionario a comparar con el usuario
 * @param alternate             En caso de no tener el permiso mostrar este componente
 */
export const userCanEditInvitacionFuncionario = (invitacion, alternate = () => null) => connectedAuthWrapper({
  authenticatedSelector: (state) => {
    const userRoles = state.getIn(['auth', 'user'])
      .get('roles')
      .filter((rol) => {
        const re = new RegExp('\\bFuncionario.+');
        return re.test(rol);
      });
    let userRol;
    if (userRoles.size > 1 && userRoles.includes(ROL_FUNCIONARIO_EMPRESA)) {
      userRol = ROL_FUNCIONARIO_EMPRESA;
    } else {
      userRol = userRoles.first();
    }
    switch (userRol) {
      case ROL_FUNCIONARIO_ASISTENTE: {
        return false;
      }
      case ROL_FUNCIONARIO_ANALISTA: {
        const funcionarioRol1 = invitacion.Rol;
        return funcionarioRol1 && funcionarioRol1.name === ROL_FUNCIONARIO_ASISTENTE;
      }
      case ROL_FUNCIONARIO_EMPRESA: {
        return true;
      }
      case ROL_FUNCIONARIO_ADMINISTRADOR: {
        const funcionarioRol2 = invitacion.Rol;
        return funcionarioRol2 && (funcionarioRol2.name === ROL_FUNCIONARIO_ASISTENTE || funcionarioRol2.name === ROL_FUNCIONARIO_ANALISTA);
      }
      default: {
        return false;
      }
    }
  },
  FailureComponent: alternate,
  wrapperDisplayName: 'UserCanEditInvitacionFuncionario',
});

/**
 * Se controla que el usuario tenga completo el proceso de registro
 * En caso de tener el registro completo se hace redirect al lugar indicado o en su defecto a '/{tipoUsuario}/registro'
 */
export const userIsComplete = connectedReduxRedirect({
  authenticatedSelector: (state) => {
    if (getUserType(state) === 'postulante') {
      const user = state.getIn(['auth', 'user']);
      const postulante = user.get('Postulante');
      const perfilCompleto = postulante.get('PerfilCompleto');
      return perfilCompleto && !debeAceptarTerminosUE(user);
    }
    return true;
  },
  redirectPath: (state) => {
    const user = state.getIn(['auth', 'user']);
    if (debeAceptarTerminosUE(user)) {
      return '/app/confirmar-terminos';
    }

    return `/app/${getUserType(state)}/registro`;
  },
  redirectAction: routerActions.replace,
  allowRedirectBack: false,
  wrapperDisplayName: 'UserIsComplete',
});

/**
 * Se controla que el usuario tenga completo el proceso de registro
 */
export const userIsNotComplete = connectedReduxRedirect({
  authenticatedSelector: (state) => {
    if (getUserType(state) === 'postulante') {
      const user = state.getIn(['auth', 'user']);
      const postulante = user.get('Postulante');
      const perfilCompleto = postulante.get('PerfilCompleto');
      return !perfilCompleto;
    }
    return false;
  },
  redirectPath: () => '/app/postulante/panel',
  redirectAction: routerActions.replace,
  allowRedirectBack: false,
  wrapperDisplayName: 'UserIsNotComplete',
});

// Si estamos en un pais de la UE el postulante debe aceptar los terminos y condiciones para ser considerado usuario completo
function debeAceptarTerminosUE(user) {
  const esPaisUE = settings.postulantes && settings.postulantes.terminosUE;
  const aceptoTerminosUE = user.get('terminosUEAceptados');

  return esPaisUE && !aceptoTerminosUE;
}

/**
 * Controla si las funcionalidades de Funcionarios están activadas
 */
export const tieneFuncionariosActivados = connectedAuthWrapper({
  authenticatedSelector: () => !!settings && !!settings.funcionarios,
  FailureComponent: Restricted,
  wrapperDisplayName: 'TieneFuncionariosActivados',
});

/**
 * Controla si las funcionalidades de Danchiano están activadas
 */
export const tieneDanchianoActivado = connectedAuthWrapper({
  authenticatedSelector: () => !!settings && !!settings.danchiano,
  FailureComponent: Restricted,
  wrapperDisplayName: 'TieneDanchianoActivado',
});

/**
 * Controla si las funciones laborales no son computadas
 */
export const nonComputedJobFunctions = connectedAuthWrapper({
  authenticatedSelector: () => !settings?.funcionesLaboralesComputadas,
  FailureComponent: Restricted,
  wrapperDisplayName: 'NonComputedJobFunctions',
});

/**
 * Controla si se puede ver la sección 'Situación de Discapacidad' en el CV
 */
export const canSeeSituacionDiscapacidadFn = (user, oferta, postulante) => {
  if (userIsPostulanteFn(user)) {
    return true;
  } else if (oferta) {
    return !!postulante && !!postulante.data &&
      !!postulante.data.PostulanteDiscapacidad && !!postulante.data.PostulanteDiscapacidad.EnSituacionDiscapacidad &&
      !!oferta.PermiteDiscapacidad;
  }
  return false;
};

/**
 * Controla si se puede ver la sección 'Personas Liberadas' en el CV
 */
export const canSeePersonasLiberadasFn = (user, oferta, postulante) => {
  if (settings.modalidadLiberados) {
    if (userIsPostulanteFn(user)) {
      return true;
    } else if (oferta) {
      return !!postulante?.data?.EnProgramaLiberado && !!oferta?.PermiteLiberado;
    }
  }
  return false;
};

/**
 * Controla si la feature ATS pasada por param esta activada
 */
export const isFeatureATSActiveFn = (ats, feature) => !!ats && !!ats.data && !!ats.data.FeaturesAts && !!ats.data.FeaturesAts[feature];

/**
 * Controla si se puede utilizar la feature ATS pasada por param
 */
export const isFeatureATSEnabledFn = (ats, feature) => {
  const empresaEnabled = !!ats && !!ats.data && !!ats.data.FeaturesAts && !!ats.data.FeaturesAts[feature] && !!ats.data.EmpresaPremium;
  if (_.has(ats.data, 'OfertaPremium')) {
    return empresaEnabled && !!ats.data.OfertaPremium;
  }
  return empresaEnabled;
};

/**
 * Se controla que el usuario sea del tipo indicado
 * En caso contrario se muestra el componente Restricted
 */
// #region userIsType
export const userIsAdministrador = connectedAuthWrapper({
  authenticatedSelector: (state) => checkUserType(state, ADMINISTRADOR),
  FailureComponent: Restricted,
  wrapperDisplayName: 'UserIsAdministrador',
});

export const userIsEmpresa = connectedAuthWrapper({
  authenticatedSelector: (state) => checkUserType(state, EMPRESA),
  FailureComponent: Restricted,
  wrapperDisplayName: 'UserIsEmpresa',
});

export const userIsFuncionarioFn = (user) => user?.tipo?.toLowerCase() === FUNCIONARIO;

export const userIsFuncionario = connectedAuthWrapper({
  authenticatedSelector: (state) => checkUserType(state, FUNCIONARIO),
  FailureComponent: Restricted,
  wrapperDisplayName: 'UserIsFuncionario',
});

export const userIsPostulanteFn = (user) => user && user.tipo && user.tipo.toLowerCase() === POSTULANTE;

export const userIsPostulante = connectedAuthWrapper({
  authenticatedSelector: (state) => checkUserType(state, POSTULANTE),
  FailureComponent: Restricted,
  wrapperDisplayName: 'UserIsPostulante',
});

export const userIsPostulanteAlt = (alternate = () => null) => connectedAuthWrapper({
  authenticatedSelector: (state) => checkUserType(state, POSTULANTE),
  FailureComponent: alternate,
  wrapperDisplayName: 'UserIsPostulanteAlt',
});
// #endregion
