import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'components/Semantic';
import { DARK_GREEN, GRAY_7 } from 'styles/variables';

import ColorText from './ColorText';

import messages from './messages';

/**
 * Alternativa a <CautionModal> implementada usando hooks.
 */
const ConfirmModal = ({ trigger, title, text, onConfirm, onCancel, yes, no, closeOnConfirm }) => {
  const [isOpen, setOpen] = React.useState(false);

  const open = () => setOpen(true);

  const handleConfirm = () => {
    onConfirm();
    if (closeOnConfirm) {
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const triggered = trigger(open);

  return (
    <Modal trigger={triggered} open={isOpen} size="mini" onClose={handleCancel}>
      { title && <Modal.Header>{title}</Modal.Header> }
      <Modal.Content>
        <ColorText>{text}</ColorText>
      </Modal.Content>
      <Modal.Actions>
        <Button color={GRAY_7} onClick={handleCancel}>
          {no || <FormattedMessage {...messages.no} />}
        </Button>
        <Button color={DARK_GREEN} onClick={handleConfirm} autoFocus={isOpen}>
          {yes || <FormattedMessage {...messages.si} />}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  trigger: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  yes: PropTypes.string,
  no: PropTypes.string,
  closeOnConfirm: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  closeOnConfirm: true,
};

export default ConfirmModal;
