export function truncUserName(userName) {
  if (userName.length <= 11) {
    return userName.toUpperCase();
  }
  return `${userName.toUpperCase().slice(0, 10)}...`;
}

export function initialLetter(userName) {
  return userName.toUpperCase().slice(0, 1);
}
