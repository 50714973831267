import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const PageStylesContext = React.createContext(null);

export function PageStylesProvider({ children }) {
  const [pageWidth, setPageWidth] = useState('normal');
  const [pageBackground, setPageBackground] = useState('white');

  return (
    <PageStylesContext.Provider
      value={{
        pageWidth,
        setPageWidth,
        pageBackground,
        setPageBackground,
      }}
    >
      {children}
    </PageStylesContext.Provider>
  );
}

PageStylesProvider.propTypes = {
  children: PropTypes.node,
};

export function usePageStyles() {
  return useContext(PageStylesContext);
}
