import { defineMessages } from 'react-intl';

export default defineMessages({
  titulo403: {
    id: 'app.components.ErrorPage.titulo403',
    defaultMessage: 'Prohibido',
  },
  texto403: {
    id: 'app.components.ErrorPage.texto403',
    defaultMessage: 'No tiene permisos para acceder a esta página.',
  },
  titulo404: {
    id: 'app.components.ErrorPage.titulo404',
    defaultMessage: 'No se ha podido encontrar la página',
  },
  texto404: {
    id: 'app.components.ErrorPage.texto404',
    defaultMessage: 'La página que está intentando acceder no existe o no se encuentra disponible.',
  },
  titulo408: {
    id: 'app.components.ErrorPage.titulo408',
    defaultMessage: 'No se ha podido cargar la página',
  },
  texto408: {
    id: 'app.components.ErrorPage.texto408',
    defaultMessage: 'Uno o más recursos de la página no se han cargado correctamente. Verifique que su conexión sea estable.',
  },
  titulo500: {
    id: 'app.components.ErrorPage.titulo500',
    defaultMessage: 'Un error inesperado ha ocurrido',
  },
  texto500: {
    id: 'app.components.ErrorPage.texto500',
    defaultMessage: 'Por favor, vuelva a intentarlo nuevamente en unos minutos. Si el error persiste, póngase en contacto con nosotros.',
  },
  reintentar: {
    id: 'app.components.ErrorPage.reintentar',
    defaultMessage: 'Reintentar',
  },
  reload: {
    id: 'app.components.ErrorPage.reload',
    defaultMessage: 'Recargar en nueva tab',
  },
  ERROR_CODE_EMPRESA_BLOQUEADA: {
    id: 'app.components.ErrorMessage.errorCode.EMPRESA_BLOQUEADA',
    defaultMessage: 'Su cuenta se encuentra temporalmente bloqueada por tener servicios pendientes de pago. Sus ofertas seguirán activas en Buscojobs, pero el acceso para ver los curriculums de los postulantes será restringido.',
  },
  ERROR_CODE_EMPRESA_VER_POSTULANTE_PRIVADO: {
    id: 'app.components.ErrorMessage.errorCode.EMPRESA_VER_POSTULANTE_PRIVADO',
    defaultMessage: 'El postulante que intenta visualizar marcó su perfil como privado.',
  },
  ERROR_CODE_FORBIDDEN: {
    id: 'app.components.ErrorMessage.errorCode.FORBIDDEN',
    defaultMessage: 'No tiene permisos para realizar esta acción.',
  },
  ERROR_CODE_INVITACION_POSTULANTE_TEST_EXTERNO_EXISTENTE: {
    id: 'app.components.ErrorMessage.errorCode.INVITACION_POSTULANTE_TEST_EXTERNO_EXISTENTE',
    defaultMessage: 'Ya ha invitado a este candidato.',
  },
  ERROR_CODE_INVITACION_EXISTENTE: {
    id: 'app.components.ErrorMessage.errorCode.INVITACION_EXISTENTE',
    defaultMessage: 'La invitación ya existe.',
  },
  ERROR_CODE_INVITACION_NO_EXISTENTE: {
    id: 'app.components.ErrorMessage.errorCode.INVITACION_NO_EXISTENTE',
    defaultMessage: 'La invitación no existe.',
  },
  ERROR_CODE_OFERTA_SELECCION_CADUCADA: {
    id: 'app.components.ErrorMessage.errorCode.OFERTA_SELECCION_CADUCADA',
    defaultMessage: 'El proceso de selección ha caducado.',
  },
  ERROR_CODE_POSTULANTE_DOCUMENTO_INVALIDO: {
    id: 'app.components.ErrorMessage.errorCode.POSTULANTE_DOCUMENTO_INVALIDO',
    defaultMessage: 'El documento ingresado no es válido.',
  },
  ERROR_CODE_POSTULANTE_EDAD_MINIMA_INVALIDA: {
    id: 'app.components.ErrorMessage.errorCode.POSTULANTE_EDAD_MINIMA_INVALIDA',
    defaultMessage: 'No cuentas con la edad mínima requerida para utilizar Buscojobs.',
  },
  ERROR_CODE_POSTULANTE_ESTUDIO_INSTITUCION_REQUIRED: {
    id: 'app.components.ErrorMessage.errorCode.POSTULANTE_ESTUDIO_INSTITUCION_REQUIRED',
    defaultMessage: 'Debe ingresar una Institución.',
  },
  ERROR_CODE_POSTULANTE_EXPERIENCIA_FECHA_FIN: {
    id: 'app.components.ErrorMessage.errorCode.POSTULANTE_EXPERIENCIA_FECHA_FIN',
    defaultMessage: 'Ingrese la fecha de finalización del período trabajado, o bien indique que continúa trabajando actualmente.',
  },
  ERROR_CODE_POSTULANTE_NO_ENCONTRADO: {
    id: 'app.components.ErrorMessage.errorCode.POSTULANTE_NO_ENCONTRADO',
    defaultMessage: 'No se encontró el postulante.',
  },
  ERROR_CODE_POSTULANTE_VISIBILIDAD_INVALIDA: {
    id: 'app.components.ErrorMessage.errorCode.POSTULANTE_VISIBILIDAD_INVALIDA',
    defaultMessage: 'La visibilidad indicada no es válida.',
  },
  ERROR_CODE_ROL_FUNCIONARIO_INCORRECTO: {
    id: 'app.components.ErrorMessage.errorCode.ROL_FUNCIONARIO_INCORRECTO',
    defaultMessage: 'El rol indicado no es válido.',
  },
  ERROR_CODE_USUARIO_EXISTENTE: {
    id: 'app.components.ErrorMessage.errorCode.USUARIO_EXISTENTE',
    defaultMessage: 'Ya existe un usuario registrado con ese mail en Buscojobs.',
  },
  ERROR_CODE_USUARIO_PASSWORD_INVALIDA: {
    id: 'app.components.ErrorMessage.errorCode.USUARIO_PASSWORD_INVALIDA',
    defaultMessage: 'Contraseña inválida.',
  },
  ERROR_CODE_USUARIO_USERNAME_INVALIDO: {
    id: 'app.components.ErrorMessage.errorCode.USUARIO_USERNAME_INVALIDO',
    defaultMessage: 'Nombre de usuario inválido.',
  },
  ERROR_CODE_POSTULACION_DATOS_INCOMPLETOS: {
    id: 'app.components.ErrorMessage.errorCode.POSTULACION_DATOS_INCOMPLETOS',
    defaultMessage: 'No tiene suficientes datos ingresados en su CV.',
  },
  ERROR_CODE_POSTULACION_EXISTENTE: {
    id: 'app.components.ErrorMessage.errorCode.POSTULACION_EXISTENTE',
    defaultMessage: 'Ya está postulado a esta oferta.',
  },
  ERROR_CODE_POSTULACION_OFERTA_NO_DISPONIBLE: {
    id: 'app.components.ErrorMessage.errorCode.POSTULACION_OFERTA_NO_DISPONIBLE',
    defaultMessage: 'No se puede postular a esta oferta.',
  },
  ERROR_CODE_POSTULACION_LIMITE_MENSUAL: {
    id: 'app.components.ErrorMessage.errorCode.POSTULACION_LIMITE_MENSUAL',
    defaultMessage: 'Has llegado al límite de postulaciones permitidas por mes.',
  },
  ERROR_CODE_FUNCIONALIDAD_ATS_NO_DISPONIBLE: {
    id: 'app.components.ErrorMessage.errorCode.FUNCIONALIDAD_ATS_NO_DISPONIBLE',
    defaultMessage: 'La funcionalidad que quiere utilizar no está disponible.',
  },
  ERROR_CODE_OFERTA_ACTIVA_ERROR: {
    id: 'app.components.ErrorMessage.errorCode.OFERTA_ACTIVA_ERROR',
    defaultMessage: 'La oferta ya se encuentra activada.',
  },
  ERROR_CODE_OFERTA_NO_ENCONTRADA: {
    id: 'app.components.ErrorMessage.errorCode.OFERTA_NO_ENCONTRADA',
    defaultMessage: 'Lo sentimos, la oferta ya no está disponible.',
  },
  ERROR_CODE_OFERTA_MODIFICADA_ERROR: {
    id: 'app.components.ErrorMessage.errorCode.OFERTA_MODIFICADA_ERROR',
    defaultMessage: 'La oferta que estás intentando editar ha sido modificada desde que comenzaste a realizar los cambios. Puedes intentarlo nuevamente recargando la página, o abriendo el formulario en una nueva pestaña con el botón, para comparar los datos que cambiaron con los que tú estabas editando.',
  },
  ERROR_CODE_PAQUETE_NO_ENCONTRADO: {
    id: 'app.components.ErrorMessage.errorCode.PAQUETE_NO_ENCONTRADO',
    defaultMessage: 'No se encontró el paquete.',
  },
  ERROR_CODE_PAQUETE_TIPO_REPETIDO: {
    id: 'app.components.ErrorMessage.errorCode.PAQUETE_TIPO_REPETIDO',
    defaultMessage: 'Está intentando adquirir más de un paquete del mismo tipo.',
  },
  ERROR_CODE_PAQUETE_NO_APLICABLE_OFERTA: {
    id: 'app.components.ErrorMessage.errorCode.PAQUETE_NO_APLICABLE_OFERTA',
    defaultMessage: 'El paquete no es aplicable para la oferta.',
  },
  ERROR_CODE_EMPRESA_PAQUETE_PAGO_ESTADO_PENDIENTE: {
    id: 'app.components.ErrorMessage.errorCode.EMPRESA_PAQUETE_PAGO_ESTADO_PENDIENTE',
    defaultMessage: 'Ya tienes un pago pendiente para este servicio.',
  },
  MAILER_SUSCRIPCION_NO_ENCONTRADO: {
    id: 'app.components.ErrorMessage.errorCode.POSTULANTE_ALERTA_NO_ENCONTRADO',
    defaultMessage: 'No cuentas con una configuración de alertas.',
  },
  MAILER_FAILURE: {
    id: 'app.components.ErrorMessage.errorCode.MAILER_FAILURE',
    defaultMessage: 'El servicio Mailer no está disponible. Por favor, vuelva a intentarlo nuevamente en unos minutos. Si el error persiste, póngase en contacto con nosotros.',
  },
  ERROR_CODE_FEED_OFERTA_NOMBRE_REQUIRED: {
    id: 'app.components.ErrorMessage.errorCode.FEED_OFERTA_NOMBRE_REQUIRED',
    defaultMessage: 'Debe ingresar un nombre para el feed.',
  },
  ERROR_CODE_FEED_OFERTA_NOMBRE_EXISTENTE: {
    id: 'app.components.ErrorMessage.errorCode.FEED_OFERTA_NOMBRE_EXISTENTE',
    defaultMessage: 'El nombre del feed ya se encuentra en uso.',
  },
  ERROR_CODE_FEED_OFERTA_NOMBRE_FORMATO_INCORRECTO: {
    id: 'app.components.ErrorMessage.errorCode.FEED_OFERTA_NOMBRE_FORMATO_INCORRECTO',
    defaultMessage: 'El nombre del feed no debe contener puntos o espacios.',
  },
  ERROR_CODE_FEED_OFERTA_NOMBRE_MAX_LENGTH: {
    id: 'app.components.ErrorMessage.errorCode.FEED_OFERTA_NOMBRE_MAX_LENGTH',
    defaultMessage: 'El nombre del feed supera el tamaño máximo permitido.',
  },
  ERROR_CODE_FEED_OFERTA_CARACTERISTICAS_REQUIRED: {
    id: 'app.components.ErrorMessage.errorCode.FEED_OFERTA_CARACTERISTICAS_REQUIRED',
    defaultMessage: 'Caracteristicas requeridas.',
  },
  ERROR_CODE_FEED_CARACTERISTICA_MAIL_ID_INVALIDO: {
    id: 'app.components.ErrorMessage.errorCode.FEED_CARACTERISTICA_MAIL_ID_INVALIDO',
    defaultMessage: 'El feed contiene fuentes inválidas.',
  },
  ERROR_CODE_FEED_CARACTERISTICA_URL_ID_INVALIDO: {
    id: 'app.components.ErrorMessage.errorCode.FEED_CARACTERISTICA_URL_ID_INVALIDO',
    defaultMessage: 'El feed contiene fuentes inválidas.',
  },
  ERROR_CODE_FEED_CARACTERISTICA_CPC_MIN_INVALIDO: {
    id: 'app.components.ErrorMessage.errorCode.FEED_CARACTERISTICA_CPC_MIN_INVALIDO',
    defaultMessage: 'El feed contiene un CPC inválido.',
  },
  ERROR_CODE_FEED_CARACTERISTICA_EMPRESAS_EXCLUIDAS_INVALIDO: {
    id: 'app.components.ErrorMessage.errorCode.FEED_CARACTERISTICA_EMPRESAS_EXCLUIDAS_INVALIDO',
    defaultMessage: 'El feed contiene Ids de empresas excluidas inválidos.',
  },
  ERROR_CODE_BANNER_ALERTA_POSTULANTE_POR_DEFECTO: {
    id: 'app.components.ErrorMessage.errorCode.BANNER_ALERTA_POSTULANTE_POR_DEFECTO',
    defaultMessage: 'No puedes eliminar el banner por defecto o quitarle ese valor, primero debes subir un nuevo banner por defecto o editar uno existente para que pase a serlo.',
  },
});
