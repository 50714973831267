import React from 'react';
import styled from 'styled-components';
import { Segment } from 'components/Semantic';

const StyledSegment = styled(Segment)`
  min-height: 600px;
`;

const BJLoader = () => <StyledSegment loading />;

export default BJLoader;
