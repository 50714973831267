import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Link } from 'components/Navigation';
import { Grid } from 'semantic-ui-react';
import { BJMessage } from 'components/BJComponents';
import { Button } from 'components/Semantic';
import { GRAY_1 } from 'styles/variables';
import messages from './messages';

const ErrorMessage = (props) => {
  const { error, type, defaultText, list, intl, ...other } = props;

  let text = defaultText;
  if (error && error.code && `ERROR_CODE_${error.code}` in messages) {
    text = intl.formatMessage(messages[`ERROR_CODE_${error.code}`]);
  }

  if (list && list.length > 0) {
    return (
      <BJMessage
        type={type || 'error'}
        header={text}
        headerColor={GRAY_1}
        list={list}
        {...other}
      />
    );
  }

  return (
    <BJMessage
      type={type || 'error'}
      //text={text}
      text={error.statusCode === 409 ? (
        <Grid verticalAlign="middle">
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={10}
              computer={11}
              largeScreen={12}
              widescreen={13}
            >
              <span style={{ overflowWrap: 'anywhere' }}>{text ?? text}</span>
            </Grid.Column>
            <Grid.Column width={16} only="mobile" style={{ padding: '10px' }} />
            <Grid.Column mobile={16} tablet={6} computer={5} largeScreen={4} widescreen={3}>
              <Button
                as={Link}
                external
                // eslint-disable-next-line no-restricted-globals
                to={location.href}
                target="_blank"
                fluid
                style={{ padding: '10px' }}
              >
                {intl.formatMessage(messages.reload)}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : text}
      {...other}
    />
  );
};

ErrorMessage.propTypes = {
  type: PropTypes.string,
  error: PropTypes.object,
  defaultText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  list: PropTypes.array,
  intl: intlShape.isRequired,
};

export default injectIntl(ErrorMessage);
