import React from 'react';
import ReactMarkdown from 'react-markdown';

const MarkdownRenderer = ({ children }) => (
  <ReactMarkdown
    allowedElements={["p", "em", "strong", "ul", "ol", "li"]}
    unwrapDisallowed
  >
    {children}
  </ReactMarkdown>
);

export default MarkdownRenderer;
