import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Progress } from 'semantic-ui-react';
import { WHITE, GRAY_4, ORANGE_1, TABLET } from 'styles/variables';

const ProgressBJ = ({ color, noBottomMargin, marginTop, marginBottom, pointer,
  rectangle, vertical, customSize, topLabel, popup, ...other }) => <Progress {...other} />;

ProgressBJ.propTypes = {
  color: PropTypes.string,
  rectangle: PropTypes.bool,
  vertical: PropTypes.bool,
  pointer: PropTypes.bool,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  noBottomMargin: PropTypes.bool,
  customSize: PropTypes.number,
  topLabel: PropTypes.bool,
  popup: PropTypes.bool,
};

const StyledProgress = styled(ProgressBJ)`
  ${(props) => props.marginTop && css`
    margin-top: ${props.marginTop}px !important;
  `}

  ${(props) => props.marginBottom && css`
    margin-bottom: ${props.marginBottom}px !important;
  `}

  ${(props) => props.noBottomMargin && css`
    margin-bottom: 0px !important;
  `}
  
  ${(props) => props.pointer && css`
    cursor: pointer;
  `}

  ${(props) => props.vertical && css`
    transform: rotate(-90deg) !important;
  `}

  ${(props) => props.rectangle && css`
    border-radius: 0 !important;
  `}

  .label {
    color: ${(props) => props.color || ORANGE_1} !important;
    font-size: 0.8em !important;
    font-weight: normal !important;
    text-align: left !important;
    ${(props) => props.topLabel && css`
      top: auto !important;
      bottom: 100% !important;
    `}
  }

  .bar {
    ${(props) => props.customSize && css`
      height: ${props.customSize}em !important;`
    }
    min-width: unset !important;
    background-color: ${(props) => props.color || ORANGE_1} !important;
    ${(props) => props.rectangle && css`
      border-radius: 0 !important;
    `}
    .progress {
      right: unset !important;
    }
  }

  ${(props) => props.popup && css`
    margin: 0 0 35px !important;
    @media only screen and (min-width: ${TABLET.minWidth}px) {
      margin: 40px 20px 25px !important;
    }

    .label {
      color: ${GRAY_4} !important;
      font-size: 13px !important;
      margin-top: 5px !important;
    }

    .bar {
      transition: width 3s ease, background-color 3s ease !important;

      .progress {
        display: none;
      }

      @media only screen and (min-width: ${TABLET.minWidth}px) {
        .progress {
          display: block;
          min-width: 36px;
          text-align: center !important;
          transform: translateX(50%);
          right: 0 !important;
          top: -30px !important;
          color: ${WHITE} !important;
          background-color: ${(props) => props.color || ORANGE_1};
          padding: 5px;
          border-radius: 3px;
          transition: background-color 3s ease !important;
  
          &::after {
            content: '';
            border: 5px solid transparent;
            border-top: 5px solid ${(props) => props.color || ORANGE_1};
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            bottom: -10px;
            transition: border-color 3s ease !important;
          }
        }
      }
    }
  `}
`;
export default StyledProgress;
