import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import request from 'utils/request';
import _ from 'lodash';
import { paisSelector, apiVersionSelector } from 'containers/App/selectors';
import { selectToken } from 'containers/Auth/selectors';
import settings from 'settings';
import { API_BASE_URL } from './constants';

export function useApi(initialState = null, throwError = false) {
  const pais = useSelector(paisSelector);
  const version = useSelector(apiVersionSelector);
  const token = useSelector(selectToken);
  const locale = settings.defaultLocale;

  const [state, setState] = useState({
    data: initialState,
    loading: false,
    loaded: false,
    error: null,
  });

  useEffect(() => {
    if (throwError && state.error) {
      throw state.error;
    }
  }, [throwError, state.error]);

  const call = (urlPath, options = {}) => {
    // Si hay una request anterior corriendo, la cancelamos
    if (state.loading && !state.controller?.signal.aborted) {
      state.controller.abort();
    }

    let controller;
    try {
      controller = new AbortController();
    } catch(err) {
      console.log(err.message);
    }
    setState({ ...state, loading: true, controller });

    if (!_.isNil(controller)) {
      options.signal = controller.signal;
    }

    return callApi(urlPath, options, { pais, version, token, locale })
      .then((resp) => {
        setState({
          data: resp,
          loading: false,
          loaded: true,
          error: null,
        });

        return resp;
      })
      .catch((err) => {
        if (err.name === 'AbortError') {
          console.log('Fetch aborted', urlPath);
          return;
        }

        setState({
          ...state,
          loading: false,
          error: err.error || err,
        });

        // Tirar esta excepción puede traer problemas, pero es necesario para conocer en los event handlers cuando una mutación nos da error
        // y de esa forma poder reaccionar de forma acorde. De otra forma, habría que esperar que el componente haga re-render para leer el resultado
        // del atributo `error` que se guarda en el estado de la mutación.
        throw err;
      });
  };

  return [call, state];
}

export function useQuery(urlPath, options = {}, initialState = null, throwError = false) {
  const [fetchApi, state] = useApi(initialState, throwError);

  const refetch = (opts = {}) =>
    fetchApi(urlPath, { ...options, ...opts });

  useEffect(() => {
    fetchApi(urlPath, options);
  }, []);

  return { ...state, refetch };
}

export function useLazyQuery(urlPathOrFn, options = {}, initialState = null, throwError = false) {
  const [fetchApi, state] = useApi(initialState, throwError);

  const fetch = ({ params, ...opts } = {}) => {
    const urlPath = _.isFunction(urlPathOrFn) ? urlPathOrFn(params) : urlPathOrFn;
    return fetchApi(urlPath, { ...options, ...opts });
  };

  return [fetch, state];
}

export function useMutation(urlPathOrFn, options = {}, initialState = null) {
  const [fetchApi, state] = useApi(initialState);

  const mutate = ({ values, params } = {}) => {
    const urlPath = _.isFunction(urlPathOrFn) ? urlPathOrFn(params) : urlPathOrFn;
    return fetchApi(urlPath, { ...options, body: values });
  };

  return [mutate, state];
}

export function useListQuery(urlPath, options = {}, throwError = false) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(options.defaultPageSize || 10);

  const getOptions = (opts) => {
    const mergedOptions = { ...options, ...opts };
    return { ...mergedOptions, filter: { ...mergedOptions.filter, page, pageSize } };
  };

  const [fetchList, listState] = useApi([], throwError);
  const [fetchCount, countState] = useApi(0);

  const refetch = (opts = {}) => Promise.all([
    fetchList(urlPath, getOptions(opts)),
    fetchCount(`${urlPath}/count`, getOptions(opts)),
  ]);

  useEffect(() => {
    refetch();
  }, [page, pageSize]);

  const count = countState?.data ?? 0;

  return {
    data: listState.data,
    count,
    loading: listState.loading || countState.loading,
    loaded: listState.loaded && countState.loaded,
    error: listState.error || countState.error,
    refetch,
    page,
    pageSize,
    maxPage: countState ? Math.ceil(count / pageSize) : 0,
    onPageChange: setPage,
    onPageSizeChange: setPageSize,
  };
}

async function callApi(urlPath, options = {}, { pais, version, token, locale }) {
  let { filter, method = 'get', headers = {}, body, ...rest } = options;

  let url = `${API_BASE_URL}/${version}/${pais}/api${urlPath}`;

  // Soporte para el parametro filter de Loopback
  const isCount = url.endsWith('/count');
  const isFacet = url.endsWith('/facets');

  if (filter && filter.page && filter.pageSize) {
    filter.limit = filter.pageSize;
    filter.skip = (filter.page - 1) * filter.pageSize;
    delete filter.page;
    delete filter.pageSize;
  }

  if ((isCount || isFacet) && filter) {
    if (!_.isEmpty(filter.where)) {
      url += `?where=${encodeURIComponent(JSON.stringify(filter.where))}`;
    }
  } else if (!_.isEmpty(filter)) {
    url += `?filter=${encodeURIComponent(JSON.stringify(filter))}`;
  }

  const today = new Date();
  headers['x-timezone-offset'] = today.getTimezoneOffset();

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (window?.appMobile?.version) {
    headers['appmobile-version'] = window?.appMobile?.version;
  }

  if (locale) {
    const lang = locale.substr(0, 2).toLowerCase();
    headers['Accept-Language'] = lang;
  }

  // Mandamos el ASP.NET_SessionId en un header para que el api sincronice las sesiones
  const sessionCookie = document.cookie.split('; ').find((c) => _.startsWith(c, 'ASP.NET_SessionId'));
  if (sessionCookie) {
    headers.SessionId = decodeURIComponent(sessionCookie.split('=')[1]);
  }

  if (method === 'post' || method === 'put' || method === 'patch' || method === 'delete') {
    if (body instanceof FormData) { // Si estamos subiendo un archivo
      delete headers['Content-Type'];
    } else {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify(body);
    }
  }

  const reqOptions = {
    ...rest,
    method: method.toUpperCase(),
    mode: 'cors',
    headers,
    body,
  };

  const resp = await request(url, reqOptions);

  if (isCount) {
    if (_.isNumber(resp)) {
      return resp;
    } else if (_.isNumber(resp.count)) {
      return resp.count;
    }
  }

  return resp;
}
