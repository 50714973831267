import { defineMessages } from 'react-intl';

export default defineMessages({
  contactoHeader: {
    id: 'app.components.Footer.secciones.general.items.contactoHeader',
    defaultMessage: '¿Necesitas ayuda o tienes alguna sugerencia para hacernos?',
  },
  contactoBtn: {
    id: 'app.components.Footer.secciones.general.items.contactoBtn',
    defaultMessage: 'CONTÁCTANOS',
  },
  contactoFooter: {
    id: 'app.components.Footer.secciones.general.items.contactoFooter',
    defaultMessage: 'Encantados de atenderte',
  },
  general: {
    id: 'app.components.Footer.secciones.general.header',
    defaultMessage: 'General',
  },
  contacto: {
    id: 'app.components.Footer.secciones.general.items.contacto',
    defaultMessage: 'Contacto',
  },
  urlContacto: {
    id: 'app.components.Footer.secciones.general.items.urlContacto',
    defaultMessage: '/contacto',
  },
  faqPostulantes: {
    id: 'app.components.Footer.secciones.general.items.faqPostulantes',
    defaultMessage: 'Preguntas Frecuentas para Postulantes',
  },
  faqEmpresas: {
    id: 'app.components.Footer.secciones.general.items.faqEmpresas',
    defaultMessage: 'Preguntas Frecuentas para Empresas',
  },
  bjOffice: {
    id: 'app.components.Footer.secciones.general.items.bjOffice',
    defaultMessage: 'Buscojobs Office',
  },
  urlBJOffice: {
    id: 'app.components.Footer.secciones.general.items.urlBJOffice',
    defaultMessage: 'https://www.buscojobsoffice.com',
  },
  emprende: {
    id: 'app.components.Footer.secciones.general.items.emprende',
    defaultMessage: 'Buscojobs Emprende',
  },
  urlEmprende: {
    id: 'app.components.Footer.secciones.general.items.urlEmprende',
    defaultMessage: 'http://emprende.jobs',
  },
  blogCandidatos: {
    id: "app.components.Footer.secciones.general.items.blogCandidatos",
    defaultMessage: "Blog Candidatos",
  },
  blogCandidatosUrl: {
    id: "app.components.Footer.secciones.general.items.blogCandidatosUrl",
    defaultMessage: "/blog/publicaciones-candidatos/",
  },
  blogEmpresas: {
    id: "app.components.Footer.secciones.general.items.blogEmpresas",
    defaultMessage: "Blog Empresas",
  },
  blogEmpresasUrl: {
    id: "app.components.Footer.secciones.general.items.blogEmpresasUrl",
    defaultMessage: "/blog/publicaciones-empresas/",
  },
  tarifas: {
    id: 'app.components.Footer.secciones.general.items.tarifas',
    defaultMessage: 'Tarifas',
  },
  urlTarifas: {
    id: 'app.components.Footer.secciones.general.items.urlTarifas',
    defaultMessage: '/tarifas',
  },
  partners: {
    id: 'app.components.Footer.secciones.nuestraEmpresa.partners',
    defaultMessage: 'Partners',
  },
  nuestraEmpresa: {
    id: 'app.components.Footer.secciones.nuestraEmpresa.header',
    defaultMessage: 'Nuestra Empresa',
  },
  quienesSomos: {
    id: 'app.components.Footer.secciones.nuestraEmpresa.items.quienesSomos',
    defaultMessage: 'Quienes Somos',
  },
  urlQuienesSomos: {
    id: 'app.components.Footer.secciones.nuestraEmpresa.items.urlQuienesSomos',
    defaultMessage: '/paginas/quienes-somos',
  },
  misionVision: {
    id: 'app.components.Footer.secciones.nuestraEmpresa.items.misionVision',
    defaultMessage: 'Mision y Vision',
  },
  urlMisionVision: {
    id: 'app.components.Footer.secciones.nuestraEmpresa.items.urlMisionVision',
    defaultMessage: '/paginas/mision-vision',
  },
  listados: {
    id: 'app.components.Footer.secciones.listados.header',
    defaultMessage: 'Listados',
  },
  regiones: {
    id: 'app.components.Footer.secciones.listados.items.regiones',
    defaultMessage: 'Regiones',
  },
  areasEmpleo: {
    id: 'app.components.Footer.secciones.listados.items.areasEmpleo',
    defaultMessage: 'Áreas de Empleo',
  },
  sitios: {
    id: 'app.components.Footer.secciones.sitios.header',
    defaultMessage: 'Sitios',
  },
  internacional: {
    id: 'app.components.Footer.secciones.sitios.items.internacional',
    defaultMessage: 'Internacional',
  },
  ar: {
    id: 'app.components.Footer.secciones.sitios.items.ar',
    defaultMessage: 'Argentina',
  },
  au: {
    id: 'app.components.Footer.secciones.sitios.items.au',
    defaultMessage: 'Australia',
  },
  bo: {
    id: 'app.components.Footer.secciones.sitios.items.bo',
    defaultMessage: 'Bolivia',
  },
  br: {
    id: 'app.components.Footer.secciones.sitios.items.br',
    defaultMessage: 'Brasil',
  },
  cl: {
    id: 'app.components.Footer.secciones.sitios.items.cl',
    defaultMessage: 'Chile',
  },
  co: {
    id: 'app.components.Footer.secciones.sitios.items.co',
    defaultMessage: 'Colombia',
  },
  cr: {
    id: 'app.components.Footer.secciones.sitios.items.cr',
    defaultMessage: 'Costa Rica',
  },
  ec: {
    id: 'app.components.Footer.secciones.sitios.items.ec',
    defaultMessage: 'Ecuador',
  },
  sv: {
    id: 'app.components.Footer.secciones.sitios.items.sv',
    defaultMessage: 'El Salvador',
  },
  es: {
    id: 'app.components.Footer.secciones.sitios.items.es',
    defaultMessage: 'España',
  },
  us: {
    id: 'app.components.Footer.secciones.sitios.items.us',
    defaultMessage: 'Estados Unidos',
  },
  gh: {
    id: 'app.components.Footer.secciones.sitios.items.gh',
    defaultMessage: 'Ghana',
  },
  gt: {
    id: 'app.components.Footer.secciones.sitios.items.gt',
    defaultMessage: 'Guatemala',
  },
  hn: {
    id: 'app.components.Footer.secciones.sitios.items.hn',
    defaultMessage: 'Honduras',
  },
  id: {
    id: 'app.components.Footer.secciones.sitios.items.id',
    defaultMessage: 'Indonesia',
  },
  ie: {
    id: 'app.components.Footer.secciones.sitios.items.ie',
    defaultMessage: 'Irlanda',
  },
  in: {
    id: 'app.components.Footer.secciones.sitios.items.in',
    defaultMessage: 'India',
  },
  it: {
    id: 'app.components.Footer.secciones.sitios.items.it',
    defaultMessage: 'Italia',
  },
  jm: {
    id: 'app.components.Footer.secciones.sitios.items.jm',
    defaultMessage: 'Jamaica',
  },
  ke: {
    id: 'app.components.Footer.secciones.sitios.items.ke',
    defaultMessage: 'Kenia',
  },
  mx: {
    id: 'app.components.Footer.secciones.sitios.items.mx',
    defaultMessage: 'México',
  },
  my: {
    id: 'app.components.Footer.secciones.sitios.items.my',
    defaultMessage: 'Malasia',
  },
  ng: {
    id: 'app.components.Footer.secciones.sitios.items.ng',
    defaultMessage: 'Nigeria',
  },
  ni: {
    id: 'app.components.Footer.secciones.sitios.items.ni',
    defaultMessage: 'Nicaragua',
  },
  nz: {
    id: 'app.components.Footer.secciones.sitios.items.nz',
    defaultMessage: 'Nueva Zelanda',
  },
  pa: {
    id: 'app.components.Footer.secciones.sitios.items.pa',
    defaultMessage: 'Panamá',
  },
  py: {
    id: 'app.components.Footer.secciones.sitios.items.py',
    defaultMessage: 'Paraguay',
  },
  pe: {
    id: 'app.components.Footer.secciones.sitios.items.pe',
    defaultMessage: 'Perú',
  },
  ph: {
    id: 'app.components.Footer.secciones.sitios.items.ph',
    defaultMessage: 'Filipinas',
  },
  pr: {
    id: 'app.components.Footer.secciones.sitios.items.pr',
    defaultMessage: 'Puerto Rico',
  },
  pt: {
    id: 'app.components.Footer.secciones.sitios.items.pt',
    defaultMessage: 'Portugal',
  },
  do: {
    id: 'app.components.Footer.secciones.sitios.items.do',
    defaultMessage: 'Rep. Dominicana',
  },
  uk: {
    id: 'app.components.Footer.secciones.sitios.items.uk',
    defaultMessage: 'Reino Unido',
  },
  uy: {
    id: 'app.components.Footer.secciones.sitios.items.uy',
    defaultMessage: 'Uruguay',
  },
  ve: {
    id: 'app.components.Footer.secciones.sitios.items.ve',
    defaultMessage: 'Venezuela',
  },
  za: {
    id: 'app.components.Footer.secciones.sitios.items.za',
    defaultMessage: 'Sudáfrica',
  },
  terminos: {
    id: 'app.components.Footer.general.terminos',
    defaultMessage: 'Términos',
  },
  urlTerminos: {
    id: 'app.components.Footer.general.urlTerminos',
    defaultMessage: '/paginas/terminos',
  },
  privacidad: {
    id: 'app.components.Footer.general.privacidad',
    defaultMessage: 'Privacidad',
  },
  urlPrivacidad: {
    id: 'app.components.Footer.general.urlPrivacidad',
    defaultMessage: '/paginas/privacidad',
  },
  candidatos: {
    id: 'app.components.Footer.general.candidatos',
    defaultMessage: 'Candidatos',
  },
  agregarCV: {
    id: 'app.components.Footer.general.agregarCV',
    defaultMessage: 'Añade tu CV',
  },
  urlAgregarCV: {
    id: 'app.components.Footer.general.urlAgregarCV',
    defaultMessage: '/registro-postulante',
  },
  dejarCV: {
    id: 'app.components.Footer.general.dejarCV',
    defaultMessage: 'Dejar tu CV a las Empresas',
  },
  urlDejarCV: {
    id: 'app.components.Footer.general.urlDejarCV',
    defaultMessage: '/empresas/dejar-curriculum',
  },
  ofertasArea: {
    id: 'app.components.Footer.general.ofertasArea',
    defaultMessage: 'Ofertas por Área',
  },
  ofertasSubArea: {
    id: 'app.components.Footer.general.ofertasSubArea',
    defaultMessage: 'Ofertas por Función Laboral',
  },
  ofertasLoc: {
    id: 'app.components.Footer.general.ofertasLoc',
    defaultMessage: 'Ofertas por Localización',
  },
  urlOfertasArea: {
    id: 'app.components.Footer.general.urlOfertasArea',
    defaultMessage: '/listados/categorias',
  },
  urlOfertasLoc: {
    id: 'app.components.Footer.general.urlOfertasLoc',
    defaultMessage: '/listados/regiones',
  },
  empresas: {
    id: 'app.components.Footer.general.empresas',
    defaultMessage: 'Empresas',
  },
  publicar: {
    id: 'app.components.Footer.general.publicar',
    defaultMessage: 'Publica tu oferta',
  },
  urlPublicar: {
    id: 'app.components.Footer.general.urlPublicar',
    defaultMessage: '/login?redirect=%2Fapp%2Fempresa%2Fpublicar',
  },
  siguenos: {
    id: 'app.components.Footer.general.siguenos',
    defaultMessage: 'Síguenos',
  },
  pais: {
    id: 'app.components.Footer.general.pais',
    defaultMessage: 'País',
  },
});
