import React from 'react';
import PropTypes from 'prop-types';
import { userHasPermission } from 'utils/authWrappers';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { TESTS, ESTADO_CUENTA_VER } from 'constants/permisos';
import settings from 'settings';
import { Link } from 'components/Navigation';
import { Menu, Dropdown } from 'components/Semantic';
import messages from './messages';

const MisTests = userHasPermission([TESTS])((props) => (
  <Dropdown className="navbar-item" item pointing text={props.intl.formatMessage(messages.menuEmpresaMisTests)}>
    <Dropdown.Menu>
      {settings.centralTestComprar &&
        <Dropdown.Item as={Link} external to="/empresa-comprar-test">
          <FormattedMessage {...messages.menuEmpresaMisTestsComprar} />
        </Dropdown.Item>
      }
      <Dropdown.Item as={Link} external to="/empresa-tests/mis-tests">
        <FormattedMessage {...messages.menuEmpresaMisTestsCuentaCorrente} />
      </Dropdown.Item>
      <Dropdown.Item as={Link} external to="/empresa-tests/historial">
        <FormattedMessage {...messages.menuEmpresaMisTestsHistorial} />
      </Dropdown.Item>
      <Dropdown.Item as={Link} external to="/empresa-tests/landing">
        <FormattedMessage {...messages.menuEmpresaMisTestsInformacion} />
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
));

const EstadoCuenta = userHasPermission([ESTADO_CUENTA_VER])(() => (
  <Menu.Item className="navbar-item" as={Link} to="/empresa/estado-cuenta">
    <FormattedMessage {...messages.menuEmpresaEstadoCuenta} />
  </Menu.Item>
));

const EmpresaMenu = (props) => (
  <Menu navbar>
    <Menu.Item className="navbar-item" as={Link} to="/empresa/panel">
      <FormattedMessage {...messages.menuEmpresaMiEmpresa} />
    </Menu.Item>
    <Menu.Item className="navbar-item" as={Link} to="/empresa/ofertas">
      <FormattedMessage {...messages.menuEmpresaMisOfertas} />
    </Menu.Item>
    {settings.centralTest && <MisTests {...props} />}
    <EstadoCuenta />
  </Menu>
);

const PostulanteMenu = (props) => (
  <Menu navbar>
    <Menu.Item className="navbar-item" as={Link} to="/postulante/panel">
      <FormattedMessage {...messages.menuPostulanteMiPanel} />
    </Menu.Item>
    <Menu.Item className="navbar-item" as={Link} to="/postulante/mi-cv">
      <FormattedMessage {...messages.menuPostulanteMiCurriculum} />
    </Menu.Item>
    <Menu.Item className="navbar-item" as={Link} to="/postulante/mis-ofertas">
      <FormattedMessage {...messages.menuPostulanteOfertas} />
    </Menu.Item>
    {settings.danchiano ? (
      <Menu.Item className="navbar-item" as={Link} to="/postulante/test-danchiano">
        <FormattedMessage {...messages.menuPostulanteMisTests} />
      </Menu.Item>
    ) : null}
  </Menu>
);

PostulanteMenu.propTypes = {
  intl: intlShape.isRequired,
};

const OperadorMenu = (props) => (
  <Menu navbar>
    <Dropdown className="navbar-item" item pointing text="Admin.">
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/operador">
          <FormattedMessage {...messages.menuOperadorAdministracionPanel} />
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-administracion-avanzada">
          <FormattedMessage {...messages.menuOperadorAdministracionAvanzada} />
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} to="/operador/empresas">
          <FormattedMessage {...messages.menuOperadorAdministracionEmpresas} />
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/operador/ofertas">
          <FormattedMessage {...messages.menuOperadorAdministracionOfertas} />
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/operador/funcionarios">
          Administración de Funcionarios
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/operador/postulantes">
          <FormattedMessage {...messages.menuOperadorAdministracionPostulantes} />
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/operador/pagos">
          <FormattedMessage {...messages.menuOperadorAdministracionPagos} />
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/operador/paquetes">
          <FormattedMessage {...messages.menuOperadorAdministracionProductos} />
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/operador/empresapaquetes">
          <FormattedMessage {...messages.menuOperadorAdministracionEmpresaPaquetes} />
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/operador/feeds">
          Administración de Feeds
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} to="/operador/banners">
          Administración de Banners Alertas
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/operador/postulantes-liberados">
          Actualizar Postulantes Liberados
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/operador/imgs-s3">
          Subir imágenes a S3
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} external to="/operador-pagos">
          <FormattedMessage {...messages.menuOperadorPagos} />
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-forzar-acceso">
          <FormattedMessage {...messages.menuOperadorForzarAcceso} />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    <Dropdown className="navbar-item" item pointing text={props.intl.formatMessage(messages.menuOperadorReportes)}>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} external to="/operador-reportes-hdl">
          <FormattedMessage {...messages.menuOperadorReportesHDL} />
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} external to="/operador/estadisticas-danchiano">
          <FormattedMessage {...messages.menuOperadorReportesDA} />
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} to="/operador/enriquecer-datos-postulantes">
          Enriquecer Datos de Postulantes
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    <Dropdown className="navbar-item" item pointing text={props.intl.formatMessage(messages.menuOperadorListas)}>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} external to="/operador-conocimientos">
          Áreas, subáreas y conocimientos
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-paises">
          Países, provincias y ciudades
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} external to="/operador-canales">
          Canales y subcanales
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-regiones">
          Regiones y ubicaciones
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} external to="/operador-como-nos-conociste">
          Cómo nos conociste
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-contratos">
          Contratos
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-destinos">
          Destinos
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-estados-civil">
          Estados civiles
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-disponibilidades-cambiar-residencia">
          Disponibilidades para cambiar de residencia
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-disponibilidades-viaje">
          Disponibilidades para viajar
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-idiomas">
          Idiomas
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-jornadas-laboral">
          Jornadas laborales
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-niveles-idioma">
          Niveles de idioma
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown className="navbar-item" item pointing text="Estudios">
          <Dropdown.Menu>
            <Dropdown.Item as={Link} external to="/operador-tecnicos">
              Técnicos
            </Dropdown.Item>
            <Dropdown.Item as={Link} external to="/operador-titulos">
              Titulos
            </Dropdown.Item>
            <Dropdown.Item as={Link} external to="/operador-cursos">
              Cursos
            </Dropdown.Item>
            <Dropdown.Item as={Link} external to="/operador-postgrados">
              Postgrados
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown.Item as={Link} external to="/operador-tipos-documento">
          Tipos de documento
        </Dropdown.Item>
        <Dropdown.Item as={Link} external to="/operador-tipos-permiso-conducir">
          Tipos de permiso de conducir
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} external to="/operador-propiedades">
          Propiedades
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    <Dropdown className="navbar-item" item pointing text={props.intl.formatMessage(messages.menuOperadorProductos)}>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/operador/paquetes">
          <FormattedMessage {...messages.menuOperadorAdminPaquete} />
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/operador/paquetes/crear">
          <FormattedMessage {...messages.menuOperadorCrearPaquete} />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </Menu>
);

OperadorMenu.propTypes = {
  intl: intlShape.isRequired,
};

const HeaderMenu = (props) => {
  if (props.user.tipo === 'Postulante' && props.user.Postulante.PerfilCompleto) {
    return <PostulanteMenu {...props} />;
  } else if (props.user.tipo === 'Empresa' || props.user.tipo === 'Funcionario') {
    return <EmpresaMenu {...props} />;
  } else if (props.user.tipo === 'Operador') {
    return <OperadorMenu {...props} />;
  }
  return null;
};

HeaderMenu.propTypes = {
  user: PropTypes.object,
};

export default injectIntl(HeaderMenu);
