import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Image } from 'semantic-ui-react';
import { MOBILE } from 'styles/variables';

const ImageBj = ({ padded, logout, ...other }) => <Image {...other} />;

ImageBj.propTypes = {
  padded: PropTypes.bool,
  logout: PropTypes.bool,
};

const StyledImage = styled(ImageBj)`
  ${(props) => props.padded && css`
    padding-left: 10px !important;
    padding-right: 10px !important;
    vertical-align: middle !important;
  `}
  
  ${(props) => props.logout && css`
    position: absolute !important;
    top: 17px;
    right: 0px;

    @media(max-width: ${MOBILE.maxWidth}px) {
      display: none !important;
    }
  `}
`;

export default StyledImage;
