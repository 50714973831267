import settings from 'settings';

export const API_BASE_URL = settings.apiBaseUrl;
export const API_REST_URL = `${API_BASE_URL}/v1`;

export const API_MEDIA_TYPE = 'application/vnd.api+json';

export const API_REQUEST = 'BJ_API_REQUEST';
export const API_SUCCESS = 'BJ_API_SUCCESS';
export const API_FAILURE = 'BJ_API_FAILURE';
