/**
 * propsToJs.js
 * High Order Component que transforma props de tipo Immutable en objetos javascript planos.
 *
 * Es útil para transformar las props de los containers a las props de los componentes decorativos.
 * Por más detalles ver http://redux.js.org/docs/recipes/UsingImmutableJS.html
 */
import React from 'react';
import { Iterable } from 'immutable';

const toJS = (WrappedComponent) =>
  (wrappedComponentProps) => {
    const KEY = 0;
    const VALUE = 1;

    const propsJS = Object.entries(wrappedComponentProps)
    .reduce((acc, wrappedComponentProp) => {
      acc[wrappedComponentProp[KEY]] =
        Iterable.isIterable(wrappedComponentProp[VALUE])
          ? wrappedComponentProp[VALUE].toJS()
          : wrappedComponentProp[VALUE];
      return acc;
    }, {});

    return <WrappedComponent {...propsJS} />;
  };

export default toJS;
