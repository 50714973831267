import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button, Dropdown } from 'semantic-ui-react';
import { Popup } from 'components/Semantic';
import { WHITE, GRAY_4, DARK_BLUE } from 'styles/variables';
import { contrastColor, hoverBackgroundColor } from 'styles/utils';
import { BJLoaderSmall } from 'components/BJComponents/BJLoader';

const SemanticButton = ({ color, fontSize, fontColor, border, ...other }) => <Button {...other} />;

SemanticButton.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontColor: PropTypes.string,
  border: PropTypes.bool,
};

const StyledButton = styled(SemanticButton)`
  background-color: ${DARK_BLUE} !important;
  color: ${WHITE} !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
  ${(props) => props.fontSize && css`
    font-size: ${props.fontSize} !important;
  `}
  ${(props) => props.color && css`
    background-color: ${props.color} !important;
    color: ${contrastColor(props.color)} !important;
  `}
  ${(props) => props.fontColor && css`
    color: ${props.fontColor} !important;
  `}
  border-bottom-left-radius: 3px !important;
  border-top-left-radius: 3px !important;
  text-transform: uppercase !important;
  margin-right: 1px !important;
  ${(props) => props.border && css`
    margin-right: 0 !important;
    border: 1px solid ${GRAY_4} !important;
  `}
  :hover {
    background-color: ${(props) => hoverBackgroundColor(props)} !important;
  }

  // Loader styles
  &.loading:before {
    border: none !important;
  }
  &.loading:after {
    ${BJLoaderSmall}
  }
`;

const SemanticDropdown = ({ color, fontColor, border, ...other }) => <Dropdown {...other} />;

SemanticDropdown.propTypes = {
  color: PropTypes.string,
  fontColor: PropTypes.string,
  border: PropTypes.bool,
};

const StyledDropdown = styled(SemanticDropdown)`
  background-color: ${DARK_BLUE} !important;
  color: ${WHITE} !important;
  max-width: 36px !important;
  text-align: center !important;
  ${(props) => props.color && css`
    background-color: ${props.color} !important;
    color: ${contrastColor(props.color)} !important;
  `}
  ${(props) => props.fontColor && css`
    color: ${props.fontColor} !important;
  `}
  ${(props) => props.border && css`
    border: 1px solid ${GRAY_4} !important;
    border-left: none !important;
  `}
  :hover {
    background-color: ${(props) => hoverBackgroundColor(props)} !important;
  }
  &.ui.dropdown .menu > .header {
    color: ${GRAY_4};
  }
  &.ui.dropdown .menu > .item {
    padding-left: 24px !important;
  }
`;

const ButtonDropdown = (props) => {
  const { type, text, options, hint, onClick, onChange, color, fontSize, fontColor, border, disabled, loading,
    fluid, marginLeft, marginRight } = props;

  const [open, setOpen] = useState(false);

  const buttonDropdown = (
    <Button.Group fluid={fluid} style={{ marginLeft: marginLeft || 0, marginRight: marginRight || 0 }}>
      <StyledButton
        onClick={type === 'dropdown' ? () => setOpen((o) => !o) : onClick}
        // onBlur={type === 'dropdown' ? () => setOpen(false) : null} // Funciona solo si no hay hint, pero si hay se dispara onBlur y onClick a la vez
        color={color}
        fontSize={fontSize}
        fontColor={fontColor}
        border={border}
        disabled={disabled}
        loading={loading}
      >
        {text}
      </StyledButton>
      {type !== 'button' &&
        <StyledDropdown
          className="button icon"
          open={open}
          // onBlur={() => setOpen(false)} // Funciona solo si no hay hint, pero si hay se dispara onBlur y onClick a la vez
          onClick={() => setOpen((o) => !o)}
          color={color}
          fontColor={fontColor}
          floating
          border={border}
          disabled={disabled}
          trigger={<React.Fragment></React.Fragment>}
        >
          <Dropdown.Menu>
            {!!options && options.length > 0 && options.map((o) => (
              <React.Fragment key={o.key}>
                {!!o.header &&
                  <Dropdown.Header>{o.header}</Dropdown.Header>
                }
                {o.options.map((oo) => (
                  <Dropdown.Item key={oo.key} onClick={() => onChange(oo.value)}>
                    {oo.name}
                  </Dropdown.Item>
                ))}
              </React.Fragment>
            ))}
          </Dropdown.Menu>
        </StyledDropdown>
      }
    </Button.Group>
  );

  if (hint) {
    return (
      <Popup
        trigger={<div>{buttonDropdown}</div>}
        disabled={open}
        wide
        content={hint}
        bjstyle
        position="top right"
      />
    );
  }
  return buttonDropdown;
};

ButtonDropdown.propTypes = {
  type: PropTypes.oneOf(['button-dropdown', 'button', 'dropdown']),
  text: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  hint: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontColor: PropTypes.string,
  border: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fluid: PropTypes.bool,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
};

ButtonDropdown.defaultProps = {
  type: 'button-dropdown',
};

export default ButtonDropdown;
