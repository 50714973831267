import { getAsyncInjectors } from 'utils/asyncInjectors';
import { loadModules, renderNetworkError } from 'utils/moduleLoader';

export default function (store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  const panelRoute = {
    name: 'postulante-panel',
    path: 'postulante/panel',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Postulante/MiPanel'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const empresasCVRoute = {
    name: 'postulante-empresas-cv',
    path: 'postulante/empresas-cv',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Postulante/EmpresasCV'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const registroRoute = {
    name: 'postulante-registro',
    path: 'postulante/registro',
    getComponent(nextState, cb) {
      loadModules([
        import('containers/Postulante/Registro'),
        import('containers/Postulante/Registro/reducer'),
        import('containers/Postulante/Registro/sagas'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component, reducerRegistro, sagasRegistro] = modules;

          injectReducer(this.name, reducerRegistro.default);
          injectSagas(sagasRegistro.default);
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const miCVRoute = {
    name: 'postulante-micv',
    path: 'postulante/mi-cv',
    getComponent(nextState, cb) {
      loadModules([
        import('containers/Postulante/MiCV'),
        import('containers/Postulante/MiCV/reducer'),
        import('containers/Postulante/MiCV/sagas'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component, reducerMiCV, sagasMiCV] = modules;

          injectReducer(this.name, reducerMiCV.default);
          injectSagas(sagasMiCV.default);
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const alertasRoute = {
    name: 'postulante-alertas',
    path: 'postulante/alertas',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Postulante/Alertas'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        })
        .catch(err => renderNetworkError(err, cb));
    },
  };

  const misOfertasRoute = {
    name: 'postulante-ofertas',
    path: 'postulante/mis-ofertas(/:seccion)(/formularios-:idPostulacion)',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Postulante/MisOfertas'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const postulacionRoute = {
    name: 'postulante-postulacion',
    path: 'postulante/oferta-:nroOferta/postulacion',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Postulante/Postulacion'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const postulacionExternaRoute = {
    name: 'postulante-postulacion-externa',
    path: 'postulante/oferta-:nroOferta/postulacion-externa',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Postulante/Postulacion/Externa'),
      ], cb)
            .then(([modules, renderRoute]) => {
              const [component] = modules;
              renderRoute(component);
            })
            .catch(err => renderNetworkError(err, cb));
    },
  };

  const formularioRoute = {
    name: 'postulante-formulario',
    path: 'postulante/postulacion-:idPostulacion/formulario-:idFormulario',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Postulante/Formulario'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const testDanchianoRoute = {
    name: 'postulante-test-danchiano',
    path: 'postulante/test-danchiano',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Postulante/TestDanchiano'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  const confirmarTerminosRoute = {
    name: 'confirmar-terminos',
    path: 'confirmar-terminos',
    getComponent(nextState, cb) {
      loadModules([
        import('components/Postulante/ConfirmarTerminos'),
      ], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;
        renderRoute(component);
      })
      .catch(err => renderNetworkError(err, cb));
    },
  };

  return {
    childRoutes: [
      panelRoute,
      empresasCVRoute,
      registroRoute,
      miCVRoute,
      misOfertasRoute,
      alertasRoute,
      postulacionExternaRoute,
      postulacionRoute,
      formularioRoute,
      testDanchianoRoute,
      confirmarTerminosRoute,
    ],
  };
}
