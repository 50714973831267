import _ from 'lodash';
import { REPO_QUERY_REQUEST, REPO_QUERY_SUCCESS, REPO_QUERY_FAILURE, REPO_QUERY_TERMINATED, REPO_QUERY_COUNT,
  Operations } from './constants';

export function makeGet(name, req, meta = {}) {
  return {
    type: REPO_QUERY_REQUEST,
    payload: {
      name,
      operation: Operations.GET,
      req: { ...req, method: 'get' },
    },
    meta,
  };
}

export function makePost(name, req, meta = {}) {
  return {
    type: REPO_QUERY_REQUEST,
    payload: {
      name,
      operation: Operations.CREATE,
      req: { ...req, method: 'post' },
    },
    meta,
  };
}

export function makePatch(name, req, meta = {}) {
  return {
    type: REPO_QUERY_REQUEST,
    payload: {
      name,
      operation: Operations.UPDATE,
      req: { ...req, method: 'patch' },
    },
    meta,
  };
}

export function makePut(name, req, meta = {}) {
  return {
    type: REPO_QUERY_REQUEST,
    payload: {
      name,
      operation: Operations.UPDATE,
      req: { ...req, method: 'put' },
    },
    meta,
  };
}

export function makeDelete(name, req, meta = {}) {
  return {
    type: REPO_QUERY_REQUEST,
    payload: {
      name,
      operation: Operations.DELETE,
      req: { ...req, method: 'delete' },
    },
    meta,
  };
}

export function makeList(name, req) {
  return {
    type: REPO_QUERY_REQUEST,
    payload: {
      name,
      operation: Operations.LIST,
      req: { ...req, method: 'get' },
    },
  };
}

export function handleSuccess(name, data, meta = {}) {
  return {
    type: REPO_QUERY_SUCCESS,
    payload: { name, data },
    meta,
  };
}

export function handleFailure(name, error, meta = {}) {
  return {
    type: REPO_QUERY_FAILURE,
    payload: { name, error },
    meta,
  };
}

export function handleTerminated(name, meta = {}) {
  return {
    type: REPO_QUERY_TERMINATED,
    payload: { name },
    meta,
  };
}

export function handleCount(name, count) {
  return {
    type: REPO_QUERY_COUNT,
    payload: { name, count },
  };
}

export class DataRepository {

  constructor(entity, endpoint) {
    this.entity = entity;
    this.entityKey = entity.key;
    this.endpoint = endpoint;
  }

  query(name) {
    return new Query(this, name);
  }
}

class Query {

  constructor(repository, name) {
    this.repo = repository;
    this.name = name;
  }

  get(id, filter = {}) {
    return {
      type: REPO_QUERY_REQUEST,
      payload: {
        name: this.name,
        operation: Operations.GET,
        schema: this.repo.entityKey,
        req: {
          url: `${this.repo.endpoint}/${id}`,
          method: 'get',
          filter,
        },
      },
    };
  }

  create(data, meta = {}) {
    return {
      type: REPO_QUERY_REQUEST,
      payload: {
        name: this.name,
        operation: Operations.CREATE,
        schema: this.repo.entityKey,
        req: {
          url: this.repo.endpoint,
          method: 'post',
          body: data,
        },
      },
      meta,
    };
  }

  delete(id) {
    return {
      type: REPO_QUERY_REQUEST,
      payload: {
        name: this.name,
        operation: Operations.DELETE,
        schema: this.repo.entityKey,
        req: {
          url: `${this.repo.endpoint}/${id}`,
          method: 'delete',
        },
      },
    };
  }

  list(filter = {}, { page = 1, pageSize = 10 } = {}) {
    return {
      type: REPO_QUERY_REQUEST,
      payload: {
        name: this.name,
        operation: Operations.LIST,
        schema: [this.repo.entityKey],
        page,
        pageSize,
        req: {
          url: this.repo.endpoint,
          method: 'get',
          filter: {
            ...filter,
            limit: pageSize,
            skip: (page - 1) * pageSize,
          },
        },
      },
    };
  }

  put(id, updates, meta = {}) {
    return {
      type: REPO_QUERY_REQUEST,
      payload: {
        name: this.name,
        operation: Operations.UPDATE,
        schema: this.repo.entityKey,
        req: {
          url: `${this.repo.endpoint}/${id}`,
          method: 'put',
          body: updates,
        },
      },
      meta,
    };
  }

  patch(id, updates, meta = {}) {
    return {
      type: REPO_QUERY_REQUEST,
      payload: {
        name: this.name,
        operation: Operations.UPDATE,
        schema: this.repo.entityKey,
        req: {
          url: `${this.repo.endpoint}/${id}`,
          method: 'patch',
          body: updates,
        },
      },
      meta,
    };
  }

  count(filter = {}) {
    return {
      type: REPO_QUERY_REQUEST,
      payload: {
        name: this.name,
        operation: Operations.COUNT,
        req: {
          url: `${this.repo.endpoint}/count`,
          method: 'get',
          filter,
        },
      },
    };
  }

  getRelated(id, relation, filter = {}, { page = 1, pageSize = 10, meta = {} } = {}) {
    const related = this.repo.entity.schema[relation];
    const schema = _.isArray(related) ? [related[0].key] : related.key;

    return {
      type: REPO_QUERY_REQUEST,
      payload: {
        name: this.name,
        operation: _.isArray(related) ? Operations.LIST : Operations.GET,
        schema,
        page,
        pageSize,
        req: {
          url: `${this.repo.endpoint}/${id}/${relation}`,
          method: 'get',
          filter: {
            ...filter,
            limit: pageSize,
            skip: (page - 1) * pageSize,
          },
        },
      },
      meta,
    };
  }

  countRelated(id, relation, filter = {}) {
    return {
      type: REPO_QUERY_REQUEST,
      payload: {
        name: this.name,
        operation: Operations.COUNT,
        req: {
          url: `${this.repo.endpoint}/${id}/${relation}/count`,
          method: 'get',
          filter,
        },
      },
    };
  }

  updateRelated(id, relation, relatedId, updates, meta = {}) {
    const schema = this.repo.entity.schema[relation][0].key;

    return {
      type: REPO_QUERY_REQUEST,
      payload: {
        name: this.name,
        operation: Operations.UPDATE,
        schema,
        req: {
          url: `${this.repo.endpoint}/${id}/${relation}/${relatedId}`,
          method: 'put',
          body: updates,
        },
      },
      meta,
    };
  }
}
