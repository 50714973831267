import jwtDecode from 'jwt-decode';
import { fromJS, List } from 'immutable';
import createReducer from 'utils/createReducer';
import { AUTH_REQUEST, AUTH_SUCCESS, AUTH_FAILURE, AUTH_EXPIRED, AUTH_LOGOUT } from './constants';

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  token: null,
  user: null,
  expirationDate: null,
  errors: [],
};

const reducer = createReducer(initialState, {

  [AUTH_REQUEST]: (state) =>
    state
    .set('isAuthenticated', false)
    .set('isAuthenticating', true)
    .set('errors', new List()),

  [AUTH_SUCCESS]: (state, action) => {
    const decoded = jwtDecode(action.payload.token);
    const { exp, iat, iss, ...user } = decoded;

    return state
    .set('isAuthenticated', true)
    .set('isAuthenticating', false)
    .set('token', action.payload.token)
    .set('user', fromJS(user))
    .set('expirationDate', new Date(exp * 1000));
  },

  [AUTH_FAILURE]: (state, action) =>
    state
    .set('isAuthenticating', false)
    .set('isAuthenticated', false)
    .set('user', null)
    .set('token', null)
    .set('errors', fromJS(action.payload.errors)),

  [AUTH_EXPIRED]: (state) =>
    state
    .set('isAuthenticated', false)
    .set('isAuthenticating', true)
    .set('user', null)
    .set('errors', new List()),

  [AUTH_LOGOUT]: (state) =>
    state
    .set('isAuthenticated', false)
    .set('token', null)
    .set('user', null),

});

export default reducer;
