import jwtDecode from 'jwt-decode';
import moment from 'moment';
import settings from 'settings';

const audience = `${settings.country}.${settings.audience}`;

export function checkToken(token) {
  if (!token) { return false; }

  const decoded = jwtDecode(token);

  if (decoded.aud !== audience) { return false; }

  const expiration = moment(decoded.exp * 1000);
  const now = moment();

  if (now.isAfter(expiration)) { return false; }

  return true;
}
