import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/file';

const Video = (props) => {
  const { url } = props;

  return (
    <div
      style={{
        position: 'relative',
        paddingTop: '56.25%',
      }}
    >
      <ReactPlayer
        controls
        playing
        muted
        volume={1}
        url={url}
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
};

Video.propTypes = {
  url: PropTypes.string,
};


export default Video;
