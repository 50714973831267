import React from 'react';
import { Link } from 'components/Navigation';
import { Image } from 'semantic-ui-react';
import { Segment, Button } from 'components/Semantic';
import { DARK_BLUE, GRAY_EMPTY_LIST } from 'styles/variables';
import PropTypes from 'prop-types';

const EmptyList = ({ src, description, action, external, urlAction, color, colorAction, content, size, target }) => {
  const defaultContent = (
    <div>
      <span style={{ color: GRAY_EMPTY_LIST }}>
        {description}
      </span>
      <br />
      <span style={{ textAlign: 'center' }}>
        <Button as={Link} external={external} target={target || '_self'} to={urlAction} basic color={colorAction || DARK_BLUE}>
          {action}
        </Button>
      </span>
    </div>);

  return (
    <Segment.Group>
      <Segment textAlign="center" color={color}>
        <Image size={size} src={src} centered />
      </Segment>
      {content
        ? <Segment textAlign="center" color={color}>{content}</Segment>
        : <Segment textAlign="center" color={color}>{defaultContent}</Segment>
      }
    </Segment.Group>
  );
};

EmptyList.propTypes = {
  action: PropTypes.object,
  content: PropTypes.node,
  color: PropTypes.string,
  colorAction: PropTypes.string,
  external: PropTypes.bool,
  target: PropTypes.string,
  description: PropTypes.object,
  src: PropTypes.string.isRequired,
  size: PropTypes.string,
  urlAction: PropTypes.string,
};

export default EmptyList;
