import createReducer from 'utils/createReducer';
import settings from 'settings';

const initialState = {
  pais: settings.name,
  idPais: settings.paisId,
  apiVersion: settings.apiVersion,
};

const reducer = createReducer(initialState, {});

export default reducer;
