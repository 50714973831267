import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { pick } from 'lodash';
import { Container } from 'semantic-ui-react';
import { Responsive, Icon } from 'components/Semantic';
import { DARK_BLUE } from 'styles/variables';

import Logo from './Logo';
import HeaderMenu from './HeaderMenu';
import UserMenu from './UserMenu';
import LoginButtons from './LoginButtons';

import messages from './messages';

import './header.css';

const Header = (props) => {
  const { user, toggleSidebarVisibility, intl } = props;

  const menuProps = pick(props, 'auth', 'user', 'authenticate', 'logout');

  return (
    <>
      <div className="bjHeader" role="navigation">
        <Container>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Sidebar Menu */}
            {user ? (
              <Responsive
                as="a"
                maxWidth={Responsive.onlyTablet.maxWidth}
                aria-label={intl.formatMessage(messages.menu)}
                className="sidebarMenuButton"
                href="#"
                onClick={() => toggleSidebarVisibility()}
              >
                <Icon name="sidebar" size="big" color={DARK_BLUE} />
              </Responsive>
            ) : null}

            {/* Logo */}
            <Logo />
          </div>
          {user ? (
            <>
              {/* Header Menu */}
              <div className="headerMenu">
                <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                  <HeaderMenu horizontal {...menuProps} />
                </Responsive>
              </div>
              {/* user Menu */}
              <UserMenu {...menuProps} />
            </>
          ) : (
            <>
              {/* Login Buttons */}
              <LoginButtons />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

Header.propTypes = {
  user: PropTypes.object,
  toggleSidebarVisibility: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(Header);
