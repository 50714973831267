import { v4 as uuidv4 } from 'uuid';
import { startsWith } from 'lodash';
import { useMutation } from 'containers/Api/hooks';

export function useEventTracking() {
  const [callTrackEvent] = useMutation(() => '/eventsTrack', { method: 'post' });
  
  const force = document.cookie.split('; ').find((c) => startsWith(c, 'force'))?.split('=')[1] === "true";

  const trackEvent = (event) => {
    const uuid = uuidv4();

    callTrackEvent({
      values: {
        ...event,
        Uuid: event.Uuid ?? uuid,
        ForcedAccess: force ? 1 : 0,
      },
    });
  };

  return { trackEvent };
}
