import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/Navigation';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dropdown, Responsive } from 'components/Semantic';
import { DARK_BLUE } from 'styles/variables';
import settings from 'settings';
import { truncUserName, initialLetter } from './utils';
import messages from './messages';

import './header.css';

const UserMenu = (props) => {
  const { user } = props;

  let menu = null;
  let nombreUsuario = null;
  if (user.tipo === 'Empresa') {
    nombreUsuario = user.Empresa.Nombre;
    menu = (
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/mi-cuenta"><FormattedMessage {...messages.menuEmpresaMiCuenta} /></Dropdown.Item>
        {settings.blog ?
          <Dropdown.Item as={Link} external to="/blog/" target="_blank">Blog</Dropdown.Item>
        : null}
        <Dropdown.Item as={Link} external to="/contacto"><FormattedMessage {...messages.menuEmpresaContacto} /></Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} to="/logout"><FormattedMessage {...messages.logout} /></Dropdown.Item>
      </Dropdown.Menu>
    );
  } else if (user.tipo === 'Postulante') {
    if (user.Postulante.PrimerNombre) {
      nombreUsuario = `${user.Postulante.PrimerNombre}`;
    } else {
      nombreUsuario = `${user.username}`;
    }
    menu = (
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/mi-cuenta"><FormattedMessage {...messages.menuPostulanteMiCuenta} /></Dropdown.Item>
        {settings.blog ?
          <Dropdown.Item as={Link} external to="/blog/" target="_blank">Blog</Dropdown.Item>
        : null}
        <Dropdown.Item as={Link} external to="/contacto"><FormattedMessage {...messages.menuPostulanteContacto} /></Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} to="/logout"><FormattedMessage {...messages.logout} /></Dropdown.Item>
      </Dropdown.Menu>
    );
  } else if (user.tipo === 'Funcionario') {
    nombreUsuario = `${user.Funcionario.PrimerNombre}`;
    menu = (
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/mi-cuenta"><FormattedMessage {...messages.menuEmpresaMiCuenta} /></Dropdown.Item>
        {settings.blog ?
          <Dropdown.Item as={Link} external to="/blog/" target="_blank">Blog</Dropdown.Item>
        : null}
        <Dropdown.Item as={Link} external to="/contacto"><FormattedMessage {...messages.menuEmpresaContacto} /></Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} to="/logout"><FormattedMessage {...messages.logout} /></Dropdown.Item>
      </Dropdown.Menu>
    );
  } else if (user.tipo === 'Operador') {
    nombreUsuario = `${user.Operador.PrimerNombre} ${user.Operador.PrimerApellido}`;
    menu = (
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/logout"><FormattedMessage {...messages.logout} /></Dropdown.Item>
      </Dropdown.Menu>
    );
  }

  return (
    <div className="headerUserMenu bj_header_user_menu">
      <Responsive
        as={Dropdown}
        maxWidth={Responsive.onlyMobile.maxWidth}
        navbar
        userType={user.tipo}
        pointing
        direction="left"
        button
        color={DARK_BLUE}
        text={initialLetter(nombreUsuario)}
        title={nombreUsuario}
      >
        {menu}
      </Responsive>
      <Responsive
        as={Dropdown}
        minWidth={Responsive.onlyTablet.minWidth}
        navbar
        userType={user.tipo}
        pointing
        direction="left"
        button
        color={DARK_BLUE}
        text={truncUserName(nombreUsuario)}
        title={nombreUsuario}
      >
        {menu}
      </Responsive>
    </div>
  );
};

UserMenu.propTypes = {
  user: PropTypes.object,
};

export default injectIntl(UserMenu);
