import { defineMessages } from 'react-intl';

export default defineMessages({
  cerrar: {
    id: 'app.components.BJComponents.cerrar',
    defaultMessage: 'Cerrar',
  },
  si: {
    id: 'app.components.BJComponents.si',
    defaultMessage: 'Si',
  },
  no: {
    id: 'app.components.BJComponents.no',
    defaultMessage: 'No',
  },
  copiar: {
    id: 'app.components.BJComponents.copiar',
    defaultMessage: 'Copiar',
  },
  copiado: {
    id: 'app.components.BJComponents.copiado',
    defaultMessage: 'Copiado',
  },
});
