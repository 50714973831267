import React from 'react';
import PropTypes from 'prop-types';

const getColor = (id) => {
  switch (id % 13) {
    case 1:
      return 'red';
    case 2:
      return 'orange';
    case 3:
      return 'yellow';
    case 4:
      return 'olive';
    case 5:
      return 'darkblue';
    case 6:
      return 'green';
    case 7:
      return 'teal';
    case 8:
      return 'blue';
    case 9:
      return 'violet';
    case 10:
      return 'purple';
    case 11:
      return 'pink';
    case 12:
      return 'brown';
    default:
      return 'black';
  }
};

const CircularAvatar = ({ id, iniciales }) => (<div style={{ width: 50, height: 50, background: getColor(id), float: 'right', borderRadius: '50%', textAlign: 'center', color: 'white' }}>
  <h3 style={{ paddingTop: '25%' }}>{iniciales.toUpperCase()}</h3>
</div>);

CircularAvatar.propTypes = {
  id: PropTypes.number,
  iniciales: PropTypes.string,
};
export default CircularAvatar;
