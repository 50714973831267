import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button } from 'semantic-ui-react';
import * as variables from 'styles/variables';
import { hoverBackgroundColor, fontColor } from 'styles/utils';
import { BJLoaderSmall } from 'components/BJComponents/BJLoader';


// Button.Group

const ButtonGroupBJ = ({ divided, ...other }) => <Button.Group {...other} />;

ButtonGroupBJ.propTypes = {
  divided: PropTypes.bool,
};

const StyledButtonGroup = styled(ButtonGroupBJ)`
  ${(props) => props.divided && css`
    .button {
      &:not(:last-child) {
        position: relative !important;
        &:after {
          content: '' !important;
          border-left: 1px solid ${variables.GRAY_4} !important;
          position: absolute !important;
          right: 0 !important;
        }
      }
    }
  `}
`;


// Button

const ButtonBJ = ({ color, textColor, basic, simple, sticky, leftAligned, list, login, contacto, shadow,
  textTransform, rounded, border, fontSize, backgroundColor, mobile, toolbar, padding, ...other }) => <Button {...other} />;

ButtonBJ.Content = Button.Content;
ButtonBJ.Group = Button.Group;

ButtonBJ.propTypes = {
  color: PropTypes.string,
  textColor: PropTypes.string,
  basic: PropTypes.bool,
  simple: PropTypes.bool,
  sticky: PropTypes.bool,
  leftAligned: PropTypes.bool,
  list: PropTypes.bool,
  login: PropTypes.bool,
  contacto: PropTypes.bool,
  textTransform: PropTypes.string,
  rounded: PropTypes.bool,
  border: PropTypes.bool,
  fontSize: PropTypes.string,
  backgroundColor: PropTypes.string,
  mobile: PropTypes.bool,
  toolbar: PropTypes.bool,
  shadow: PropTypes.bool,
  padding: PropTypes.string,
};

const StyledButton = styled(ButtonBJ)`
  appearance: none !important;
  border-radius: ${(props) => props.rounded ? '20px' : '3px'} !important;
  ${(props) => props.circular && css`
    border-radius: 100% !important;
  `}
  background-color: ${(props) => props.color || variables.DARK_BLUE} !important;
  color: ${(props) => props.textColor ?? fontColor(props)} !important;
  ${(props) => props.fontSize && css`
    font-size: ${props.fontSize} !important;
  `}
  ${(props) => props.textTransform && css`text-transform: ${props.textTransform} !important;`}

  ${(props) => props.border && css`
    border: 1px solid ${variables.GRAY_4} !important;
  `}

  :hover {
    background-color: ${(props) => hoverBackgroundColor(props)} !important;
  }

  ${(props) => props.basic && css`
    background-color: ${props.backgroundColor || 'transparent'} !important;
    color: ${props.color} !important;
    font-size: ${props.fontSize || '1rem'} !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    &:hover {
      background-color: ${props.backgroundColor || 'transparent'} !important;
      color: ${hoverBackgroundColor(props)} !important;
    }
    ${props.border && css`
      border: solid 1px ${props.color} !important;
      padding-right: 10px !important;
      padding-left: 10px !important;
      &:hover {
        border: solid 1px ${hoverBackgroundColor(props)} !important;
      }
    `}
    ${props.mobile && css`
      @media only screen and (max-width: ${variables.MOBILE.maxWidth}px) {
        width: 100% !important;
      }
    `}
  `}

  ${(props) => props.simple && css`
    background-color: transparent !important;
    color: ${variables.DARK_GRAY} !important;
    border: solid 1px ${variables.DARK_GRAY} !important;
    &:hover {
      background-color: ${variables.DARK_BLUE} !important;
      color: ${variables.WHITE} !important;
      border: solid 1px ${variables.DARK_BLUE} !important;
    }
  `}

  ${(props) => props.sticky && css`
    border-radius: 100% !important;
    position: fixed !important;
    bottom: 80px !important;
    right: 50px !important;
    box-shadow: 1px 1px 10px 1px ${variables.GRAY_1} !important;
    z-index: 5 !important;
    @media only screen and (min-width: ${variables.TABLET.minWidth}px) {
      visibility: hidden !important;
    }
  `}

  ${(props) => props.leftAligned && css`
    text-align: left !important;
  `}

  ${(props) => props.list && css`
    padding: 0 !important;
  `}

  ${(props) => props.login && css`
    padding-top: 12px !important;
    font-family: 'Roboto', 'Arial', sans-serif !important;
    @media only screen and (max-width: ${variables.MOBILE.maxWidth}px) {
      font-size: 22.5px !important;
      padding: 8px !important;
      margin: 0 !important;
    }
    i {
      opacity: 1 !important;
      margin: 0 !important;
    }
  `}

  ${(props) => props.contacto && css`
    font-weight: 700 !important;
    line-height: 1.42857143 !important;
    font-size: 14px !important;
    display: inline-block;
    position: static;
    border: 1px ${props.textColor} solid !important;
    width: 100% !important;
    margin-bottom: 20px !important;
    margin-top: 6px !important;
    &:hover {
      background-color: ${props.color} !important;
      border: 1px ${hoverBackgroundColor(props.textColor)} solid !important;
      color: ${hoverBackgroundColor(props.textColor)} !important;
    }
  `}

  ${(props) => props.toolbar && css`
    background-color: ${variables.GRAY_5} !important;
    color: ${variables.DARK_GRAY} !important;
    padding: 0.25em !important;
    font-size: 12px !important;
    line-height: 18px !important;
    &:hover {
      background-color: ${variables.GRAY_10} !important;
    }
    &.button.toggle.active {
      background-color: ${variables.GRAY_8} !important;
      color: ${variables.DARK_BLUE} !important;
      &:hover {
        background-color: ${variables.GRAY_10} !important;
        color: ${variables.DARK_BLUE} !important;
      }
    }
    i {
      font-size: 16px !important;
    }
  `}

  ${(props) => props.shadow && css`
    box-shadow: 0px 2px 5px -1px ${variables.GRAY_1} !important;
  `}

  ${(props) => props.padding && css`
    padding: ${props.padding} !important;
  `}

  // Loader styles
  &.loading:before {
    border: none !important;
  }
  &.loading:after {
    ${BJLoaderSmall}
  }
`;

StyledButton.defaultProps = {
  textTransform: 'uppercase',
};

StyledButton.Content = ButtonBJ.Content;
StyledButton.Group = StyledButtonGroup;

export default StyledButton;
