import React from 'react';
import PropTypes from 'prop-types';
import { pickBy, isEmpty, isNil } from 'lodash';
import { DataLoaderShape } from '../constants';

/**
 * Optimizador de data loaders
 * Se usa principalmente para evitar renders innecesarios cuando se cargan datos en background.
 * Ignora los cambios de estado de las props recibidas en `loaders`, excepto el cambio final, cuando
 * el último loader termina de cargar.
 */
export default class DataFetcherOptimizer extends React.Component {

  static propTypes = {
    children: PropTypes.node,
    loaders: PropTypes.objectOf(DataLoaderShape),
  }

  shouldComponentUpdate(nextProps) {
    const wasLoadingProps = pickBy(this.props.loaders, (value) => this.isDataLoaderProp(value) && value.loading);
    const isLoadingProps = pickBy(nextProps.loaders, (value) => this.isDataLoaderProp(value) && value.loading);

    // Si el último loader acaba de terminar de cargar, entonces generamos una actualización de la UI.
    if (!isEmpty(wasLoadingProps) && isEmpty(isLoadingProps)) {
      return true;
    }

    // De lo contrario evitamos hacer re-render
    return isEmpty(wasLoadingProps) && isEmpty(isLoadingProps);
  }

  isDataLoaderProp = (prop) => !isNil(prop) && !isNil(prop.loading) && !isNil(prop.loaded);

  render() {
    return this.props.children;
  }

}
