/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { addLocaleData } from 'react-intl';
import { DEFAULT_LOCALE } from './containers/App/constants';
import settings from './settings';

const resolve = () => Promise.resolve();

export function loadTranslationsMessages() {
  const localeNames = [DEFAULT_LOCALE];

  // settings.defaultLocale: el locale por defecto del país actual
  // DEFAULT_LOCALE: el locale por defecto de la app
  if (settings.defaultLocale !== DEFAULT_LOCALE) {
    localeNames.push(settings.defaultLocale);
  }

  return Promise.all(localeNames.map((localeName) =>
    Promise.all([
      import(`./translations/${localeName}.json`).catch(resolve),
      import(`intl/locale-data/jsonp/${localeName}`).catch(resolve),
    ])
  ))
  .then((locales) => locales.reduce((acc, [localeData, intlData], idx) => {
    const localeName = localeNames[idx];

    if (intlData) {
      addLocaleData(intlData);
    } else {
      addLocaleData({ locale: localeName, pluralRuleFunction: () => {}, });
    }

    if (localeData) {
      acc[localeName] = formatTranslationMessages(localeName, localeData, locales[0]);
    }

    return acc;
  }, {}));
}

export const formatTranslationMessages = (locale, messages, defaultMessages) => {
  const defaultFormattedMessages = locale !== DEFAULT_LOCALE
    ? formatTranslationMessages(DEFAULT_LOCALE, defaultMessages)
    : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    let message = messages[key];
    if (!message && locale !== DEFAULT_LOCALE) {
      message = defaultFormattedMessages[key];
    }
    return Object.assign(formattedMessages, { [key]: message });
  }, {});
};
