import React, { useState } from 'react';
import { Visibility, Image } from 'semantic-ui-react';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const StyledImage = styled(Image)`
  animation: ${animation} 0.3s 0s both;
`;

const LazyImage = (props) => {
  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      {!show ?
        <Visibility
          as="span"
          fireOnMount
          onTopVisible={() => setShow(true)}
        />
      :
        <StyledImage {...props} />
      }
    </React.Fragment>
  );
};

export default LazyImage;
