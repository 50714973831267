import settings from 'settings';
import { getAsyncInjectors } from 'utils/asyncInjectors';
import { loadModules } from 'utils/moduleLoader';

// Import root app
import App from 'containers/App';
import { APPLICATION_PATH } from 'containers/App/constants';

export default function (store) {
  // Create reusable async injectors using getAsyncInjectors factory
  const { injectReducer, injectSagas } = getAsyncInjectors(store); // eslint-disable-line no-unused-vars
  const initChild = (module) => module.default(store);

  const homeRoute = {
    path: 'home',
    name: 'home',
    getComponent(nextState, cb) {
      loadModules([import('components/HomePage')], cb)
      .then(([modules, renderRoute]) => {
        const [component] = modules;

        if (settings.isDev) {
          renderRoute(component);
        } else {
          window.location = '/';
        }
      });
    },
  };

  const loginRoute = {
    path: 'login',
    name: 'login',
    getComponent(nextState, cb) {
      loadModules([import('containers/Login')], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;

          if (settings.isDev) {
            renderRoute(component);
          } else {
            window.location.pathname = '/login';
          }
        });
    },
  };

  const logoutRoute = {
    path: 'logout',
    name: 'logout',
    getComponent(nextState, cb) {
      loadModules([import('containers/Logout')], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;

          localStorage.removeItem('token');

          if (settings.isDev) {
            renderRoute(component);
          } else {
            window.location.pathname = '/logout';
          }
        });
    },
  };

  const miCuentaRoute = {
    name: 'mi-cuenta',
    path: 'mi-cuenta',
    getComponent(nextState, cb) {
      loadModules([
        import('components/MiCuenta'),
      ], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        });
    },
  };

  const redirectRoute = {
    path: 'redirect',
    name: 'redirect',
    getComponent(nextState, cb) {
      loadModules([import('components/Redirect')], cb)
        .then(([modules, renderRoute]) => {
          const [component] = modules;
          renderRoute(component);
        });
    },
  };

  const notFoundRoute = {
    path: '*',
    name: 'notfound',
    getComponent(nextState, cb) {
      loadModules([import('containers/NotFoundPage')], cb)
      .then(([[component], renderRoute]) => {
        renderRoute(component);
      });
    },
  };

  // Set up the router, wrapping all Routes in the App component
  return {
    component: App,
    childRoutes: [
      {
        path: APPLICATION_PATH,
        childRoutes: [
          homeRoute,
          loginRoute,
          logoutRoute,
          miCuentaRoute,
          redirectRoute,
          initChild(require('./empresa')),    // eslint-disable-line global-require
          initChild(require('./postulante')), // eslint-disable-line global-require
          initChild(require('./operador')),    // eslint-disable-line global-require
        ],
      },
      notFoundRoute,
    ],
  };
}
