import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { pick,omit, isEqual, isNil } from 'lodash';
import { Cookies } from 'react-cookie';
import { withRouter } from 'react-router';
import settings from 'settings';
import { PageLoader } from 'components/BJComponents';
import { ErrorBoundary } from 'components/Errors';
import { checkToken } from 'utils/jwt';
import { logMessage } from 'logger';

import AppHelmet from './Helmet';
import AppLayout from './Layout';
import { PageContainer } from './styledComponents';

export const UserContext = React.createContext(null);

window.locationMap = {};
window.navigateFromAppMobile = (nextLocationKey, navigate) => {
  const nextLocation = window.locationMap[nextLocationKey];
  delete window.locationMap[nextLocationKey];
  if (navigate && nextLocation) {
    nextLocation.state = nextLocation.state || {};
    nextLocation.state.routeFromAppMobile = true;
    window.reactRouterHistory.push(nextLocation);
  }
};
function App({ user, auth, globalState, cookies, handleAuthentication, getToken, location, children, router, route }) {
  const [userFetching, setUserFetching] = useState(true);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const routerWillLeave = (nextLocation) => {
      if (!nextLocation.state || !nextLocation.state.routeFromAppMobile) {
        window.locationMap[nextLocation.key] = nextLocation;
        window.ReactNativeWebView.postMessage(JSON.stringify({
          event: 'navigation',
          nextLocation,
        }));
        return false;
      }

      delete window.locationMap[nextLocation.key];
      return true;
    };

    const processRoutes = (iRoute) => {
      router.setRouteLeaveHook(iRoute, routerWillLeave);
      if (iRoute.childRoutes) {
        iRoute.childRoutes.forEach(childRoute => {
          processRoutes(childRoute);
        });
      }
    };

    if (window.ReactNativeWebView) {
      processRoutes(route);
    }
  }, [location]);


  const toggleVisibility = () => {
    setVisible(!visible);
    if (visible) {
      document.body.style.overflow = 'visible';
    } else {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    }
  };

  const authenticate = () => {
    let token;
    let hasSessionId;
    if (window.ReactNativeWebView && window.token) { // WebView desde la app mobile
      token = window.token;
      // callback que usa la app mobile para notificarnos de un token nuevo
      window.notifyNewToken = () => {
        handleAuthentication(window.token);
      }
    } else {
      // Check stored access token
      token = cookies.get('token') || localStorage.getItem('token');
      hasSessionId = document.cookie.indexOf('ASP.NET_SessionId') >= 0;
    }


    if (checkToken(token)) {
      handleAuthentication(token);
    } else if (hasSessionId) {
      getToken();
    } else {
      setUserFetching(false);
    }
  };

  const reload = (event) => {
    if (event.key === 'logout-event') {
      window.location = '/';
    }
  };

  const closeSidebar = () => {
    if (window.innerWidth > 991 && visible) {
      // toggleVisibility();
      setVisible(false);
      document.body.style.overflow = 'visible';
    }
  };

  useEffect(() => {
    authenticate();

    closeSidebar();
    window.addEventListener('storage', reload);
    window.addEventListener('resize', closeSidebar);

    return () => {
      window.removeEventListener('storage', reload);
      window.removeEventListener('resize', closeSidebar);
    };
  }, []);

  useEffect(() => {
    if (userFetching && (!isNil(user) || auth.errors.length > 0)) {
      setUserFetching(false);
    }
  }, [userFetching, user, auth]);

  useEffect(() => {
    // Cuando obtengo un token nuevo lo actualizo en las cookies
    if (!settings.isDev && auth.token && auth.token !== cookies.get('token')) {
      cookies.set('token', auth.token, { path: '/' });
    }
  }, [auth, cookies]);

  const loggingState = pick(globalState, ['app', 'auth', 'route', 'notification']); // No logueamos todo el globalState

  let Layout = AppLayout;

  if (window.ReactNativeWebView) { // WebView desde la app mobile
    Layout = SimpleLayout;
  }

  return (
    <>
      <AppHelmet />
      <Layout
        user={user}
        visible={visible}
        toggleVisibility={toggleVisibility}
      >
        <PageContainer id="pageContainer">
          <ErrorBoundary
            onCatch={(error, info) => {
              logMessage({ error, location, state: loggingState, info });
            }}
          >
            {userFetching ? <PageLoader /> : children}
          </ErrorBoundary>
        </PageContainer>
      </Layout>
    </>
  );
}

App.propTypes = {
  user: PropTypes.object,
  auth: PropTypes.object,
  globalState: PropTypes.object,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  handleAuthentication: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node,
  router: PropTypes.object,
  route: PropTypes.object,
};

const SimpleLayout = (props) => <React.Fragment {...props} />;

function areEqual(prevProps, nextProps) {
  const ignoredProps = ['globalState'];
  const prev = omit(prevProps, ignoredProps);
  const next = omit(nextProps, ignoredProps);
  return isEqual(prev, next);
}

export default withRouter(React.memo(App, areEqual));
