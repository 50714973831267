import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button } from 'semantic-ui-react';
import { Popup } from 'components/Semantic';
import { DARK_BLUE, WHITE, GRAY_1 } from 'styles/variables';

const ButtonInfo = ({ color, thinMargin, noMargin, jazzy, noShadow, borderWidth, marginTop, marginRight, marginBottom, marginLeft,
                      position, top, right, bottom, left, ...other }) => <Button {...other} />;

ButtonInfo.propTypes = {
  color: PropTypes.string,
  jazzy: PropTypes.bool,
  noShadow: PropTypes.bool,
  borderWidth: PropTypes.string,
  thinMargin: PropTypes.bool,
  marginTop: PropTypes.string,
  marginRight: PropTypes.string,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  noMargin: PropTypes.bool,
  position: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
};

const StyledButton = styled(ButtonInfo)`  
  color: ${(props) => props.color || DARK_BLUE} !important;
  border: ${(props) => props.borderWidth || '2px'} ${(props) => props.color || DARK_BLUE} solid !important;
  background-color: transparent !important;
  padding: 2px !important;
  margin: 10px !important;
  ${(props) => props.marginTop && css`
    margin-top: ${props.marginTop} !important;
  `}
  ${(props) => props.marginRight && css`
    margin-right: ${props.marginRight} !important;
  `}
  ${(props) => props.marginBottom && css`
    margin-bottom: ${props.marginBottom} !important;
  `}
  ${(props) => props.marginLeft && css`
    margin-left: ${props.marginLeft} !important;
  `}
  ${(props) => props.thinMargin && css`
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  `}
  ${(props) => props.noMargin && css`
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  `}
  ${(props) => props.jazzy && css`
    color: ${WHITE} !important;
    background-color: ${props.color || DARK_BLUE} !important;
    box-shadow: 2px 2px 10px ${GRAY_1} !important;
  `}
  ${(props) => props.noShadow && css`
    box-shadow: none !important;
  `}
  ${(props) => props.position && css`
    position: ${props.position} !important;
  `}
  ${(props) => props.top && css`
    top: ${props.top} !important;
  `}
  ${(props) => props.right && css`
    right: ${props.right} !important;
  `}
  ${(props) => props.bottom && css`
    bottom: ${props.bottom} !important;
  `}
  ${(props) => props.left && css`
    left: ${props.left} !important;
  `}
`;

const HintButton = (props) => {
  const button = (
    <StyledButton
      type="button"
      circular
      color={props.color}
      icon={props.icon}
      size={props.size}
      thinMargin={props.thinMargin}
      noMargin={props.noMargin}
      jazzy={props.jazzy}
      noShadow={props.noShadow}
      borderWidth={props.borderWidth}
      marginTop={props.marginTop}
      marginRight={props.marginRight}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      position={props.stylePosition}
      top={props.top}
      right={props.right}
      bottom={props.bottom}
      left={props.left}
      onClick={props.onClick}
      tabIndex={props.tabIndex}
    />
  );

  if (props.noPopup) {
    return button;
  }

  return (
    <Popup
      bjstyle
      trigger={button}
      content={props.content}
      position={props.position}
      wide={props.wide}
    />
  );
};

HintButton.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  content: PropTypes.any,
  noPopup: PropTypes.bool,
  position: PropTypes.string,
  wide: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  size: PropTypes.string,
  thinMargin: PropTypes.bool,
  noMargin: PropTypes.bool,
  jazzy: PropTypes.bool,
  noShadow: PropTypes.bool,
  borderWidth: PropTypes.string,
  marginTop: PropTypes.string,
  marginRight: PropTypes.string,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  stylePosition: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default HintButton;
