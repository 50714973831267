import { castArray } from 'lodash';
import { checkToken } from 'utils/jwt';
import settings from 'settings';
import { AUTH_REQUEST, AUTH_TOKEN, AUTH_SUCCESS, AUTH_FAILURE, AUTH_EXPIRED, AUTH_LOGOUT } from './constants';


export function authenticate(username, password) {
  return { type: AUTH_REQUEST, payload: { username, password } };
}

export function getToken() {
  return { type: AUTH_TOKEN };
}

export function logout() {
  return { type: AUTH_LOGOUT };
}

export function handleAuthentication(token) {
  if (!checkToken(token)) {
    localStorage.removeItem('token');
    return handleError({ title: 'Token expirado o inválido' });
  }

  if (settings.isDev) {
    localStorage.setItem('token', token);
  }
  return { type: AUTH_SUCCESS, payload: { token } };
}

export function handleError(err) {
  return { type: AUTH_FAILURE, payload: { errors: castArray(err) } };
}

export function refreshToken(token) {
  return { type: AUTH_EXPIRED, payload: { token } };
}
