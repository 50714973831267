export const EMPRESA_EDITAR = '[PF]Empresa.Editar';
export const EMPRESA_VER = '[PF]Empresa.Ver';

export const ESTADO_CUENTA_VER = '[PF]EstadoCuenta.Ver';

export const FUNCIONARIO_ACTIVAR_ADMINISTRADOR = '[PF]Funcionario.ActivarAdministrador';
export const FUNCIONARIO_ACTIVAR_ANALISTA = '[PF]Funcionario.ActivarAnalista';
export const FUNCIONARIO_ACTIVAR_ASISTENTE = '[PF]Funcionario.ActivarAsistente';
export const FUNCIONARIO_CREAR_ADMINISTRADOR = '[PF]Funcionario.CrearAdministrador';
export const FUNCIONARIO_CREAR_ANALISTA = '[PF]Funcionario.CrearAnalista';
export const FUNCIONARIO_CREAR_ASISTENTE = '[PF]Funcionario.CrearAsistente';
export const FUNCIONARIO_INACTIVAR_ADMINISTRADOR = '[PF]Funcionario.InactivarAdministrador';
export const FUNCIONARIO_INACTIVAR_ANALISTA = '[PF]Funcionario.InactivarAnalista';
export const FUNCIONARIO_INACTIVAR_ASISTENTE = '[PF]Funcionario.InactivarAsistente';
export const FUNCIONARIO_LISTAR_TODOS = '[PF]Funcionario.ListarTodos';
export const FUNCIONARIO_REINVITAR_ADMINISTRADOR = '[PF]Funcionario.ReinvitarAdministrador';
export const FUNCIONARIO_REINVITAR_ANALISTA = '[PF]Funcionario.ReinvitarAnalista';
export const FUNCIONARIO_REINVITAR_ASISTENTE = '[PF]Funcionario.ReinvitarAsistente';
export const FUNCIONARIO_ESTADISTICAS_VER = '[PF]FuncionarioEstadisticas.Ver';
export const FUNCIONARIO_OFERTA_LISTAR = '[PF]FuncionarioOferta.Listar';

export const OFERTA_ASIGNAR_ANALISTA = '[PF]Oferta.AsignarAnalista';
export const OFERTA_ASIGNAR_ASISTENTE = '[PF]Oferta.AsignarAsistente';
export const OFERTA_CREAR = '[PF]Oferta.Crear';
export const OFERTA_ASIGNADA_VER = '[PF]OfertaAsignada.Ver';
export const OFERTA_PROPIA_EDITAR = '[PF]OfertaPropia.Editar';
export const OFERTA_PROPIA_VER = '[PF]OfertaPropia.Ver';
export const OFERTA_TODAS_EDITAR = '[PF]OfertaTodas.Editar';
export const OFERTA_TODAS_VER = '[PF]OfertaTodas.Ver';
export const OFERTA_TODAS_REPUBLICAR = '[PF]OfertaTodas.Republicar';
export const OFERTA_PROPIA_REPUBLICAR = '[PF]OfertaPropia.Republicar';
export const OFERTA_ASIGNADA_REPUBLICAR = '[PF]OfertaAsignada.Republicar';
export const OFERTA_TODAS_EXTENDER = '[PF]OfertaTodas.Extender';
export const OFERTA_PROPIAS_EXTENDER = '[PF]OfertaPropias.Extender';

export const PAGOS = '[PF]Pagos';

export const POSTULACION_OFERTA_ASIGNADA_EDITAR = '[PF]PostulacionOfertaAsignada.Editar';
export const POSTULACION_OFERTA_ASIGNADA_VER = '[PF]PostulacionOfertaAsignada.Ver';
export const POSTULACION_OFERTA_PROPIA_EDITAR = '[PF]PostulacionOfertaPropia.Editar';
export const POSTULACION_OFERTA_PROPIA_VER = '[PF]PostulacionOfertaPropia.Ver';
export const POSTULACION_OFERTA_TODAS_EDITAR = '[PF]PostulacionOfertaTodas.Editar';
export const POSTULACION_OFERTA_TODAS_VER = '[PF]PostulacionOfertaTodas.Ver';
export const POSTULACION_OFERTA_TODAS_INVITAR = '[PF]PostulacionOfertaTodas.Invitar';
export const POSTULACION_OFERTA_PROPIA_INVITAR = '[PF]PostulacionOfertaPropia.Invitar';
export const POSTULACION_OFERTA_ASIGNADA_INVITAR = '[PF]PostulacionOfertaAsignada.Invitar';

export const TESTS = '[PF]Tests';

export const PROCESOS_SELECCION_ADMINISTRAR = '[PF]ProcesoSeleccion.Administrar';
