import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { Segment } from 'components/Semantic';
import settings from 'settings';

import { TEXT_FORMATS } from './constants';

import { serialize, deserialize } from './utils/serialization';
import { handleOnKeyDown, replaceText } from './utils/helpers';

import BlockElement from './BlockElement';
import MarkElement from './MarkElement';
import Toolbar from './Toolbar';
import BottomToolbar from './BottomToolbar';

import { Container } from './styledComponents';

const RichTextEditor = (props) => {
  const { initialValue, inputFormat, outputFormat, placeholder, rows, success, error,
    enhanceParams, onChange, onBlur } = props;

  const editor = useMemo(() => withReact(withHistory(createEditor())), []);

  const [value, setValue] = useState(null);
  const [output, setOutput] = useState('');
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    setOutput(initialValue);
    deserialize(initialValue, inputFormat).then((v) => {
      setValue(v);
    });
  }, []);

  const renderElement = useCallback((props) => {
    return <BlockElement {...props} />
  }, []);

  const renderLeaf = useCallback((props) => {
    return <MarkElement {...props} />
  }, []);

  return (
    <Container rows={rows} success={success} error={error} focus={focus}>
      {!value ? (
        <Segment loading style={{ padding: '30px' }} />
      ) : (
        <Slate
          editor={editor}
          value={value}
          onChange={(value) => {
            const isAstChange = editor.operations.some(op => 'set_selection' !== op.type);
            if (isAstChange) {
              const serialized = serialize(value, outputFormat);
              setOutput(serialized);
              onChange(serialized);
            }
          }}
        >
          <Toolbar />
          <div className="bj-editable">
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder}
              onKeyDown={(event) => handleOnKeyDown(event, editor)}
              onFocus={() => setFocus(true)}
              onBlur={() => {
                setFocus(false);
                onBlur();
              }}
            />
          </div>
          {settings.featuresGPT ?
            <BottomToolbar
              text={output}
              enhanceParams={enhanceParams}
              onReplace={(text) => {
                deserialize(text, TEXT_FORMATS.MARKDOWN).then((nodes) => {
                  replaceText(editor, nodes);
                });
              }}
            />
          : null}
        </Slate>
      )}
    </Container>
  );
};

RichTextEditor.propTypes = {
  initialValue: PropTypes.string.isRequired,
  inputFormat: PropTypes.string,
  outputFormat: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  success: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  enhanceParams: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    endpoint: PropTypes.string,
    uuid: PropTypes.string,
    chnl1: PropTypes.string,
    chnl2: PropTypes.string,
    chnl3: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  onBlur: PropTypes.func,
};

RichTextEditor.defaultProps = {
  rows: 10,
  onBlur: () => null,
};

export default RichTextEditor;
