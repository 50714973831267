import React from 'react';
import { NEUTRAL_FIELD_BORDER } from 'styles/variables';

const Divider = () => (
  <div
    style={{
      display: "inline",
      borderRight: `1px solid ${NEUTRAL_FIELD_BORDER}`,
      margin: "0 0.5em 0 0.25em",
    }}
  />
);

export default Divider;
