import { defineMessages } from 'react-intl';

export default defineMessages({
  menu: {
    id: 'app.components.Header.menu',
    defaultMessage: 'Menú',
  },
  menuEmpresaMiEmpresa: {
    id: 'app.components.Header.menuEmpresaMiEmpresa',
    defaultMessage: 'Mi Empresa',
  },
  menuEmpresaMisOfertas: {
    id: 'app.components.Header.menuEmpresaMisOfertas',
    defaultMessage: 'Mis Ofertas',
  },
  menuEmpresaMisTests: {
    id: 'app.components.Header.menuEmpresaMisTests',
    defaultMessage: 'Mis Tests',
  },
  menuEmpresaMisTestsComprar: {
    id: 'app.components.Header.menuEmpresaMisTestsComprar',
    defaultMessage: 'Comprar tests',
  },
  menuEmpresaMisTestsCuentaCorrente: {
    id: 'app.components.Header.menuEmpresaMisTestsCuentaCorrente',
    defaultMessage: 'Ver cuenta corriente',
  },
  menuEmpresaMisTestsHistorial: {
    id: 'app.components.Header.menuEmpresaMisTestsHistorial',
    defaultMessage: 'Ver historial',
  },
  menuEmpresaMisTestsInformacion: {
    id: 'app.components.Header.menuEmpresaMisTestsInformacion',
    defaultMessage: 'Información',
  },
  menuEmpresaEstadoCuenta: {
    id: 'app.components.Header.menuEmpresaEstadoCuenta',
    defaultMessage: 'Estado de Cuenta',
  },
  menuPostulanteMiPanel: {
    id: 'app.components.Header.menuPostulanteMiPanel',
    defaultMessage: 'Mi Panel',
  },
  menuPostulanteMiPanelCambiarUsuario: {
    id: 'app.components.Header.menuPostulanteMiPanelCambiarUsuario',
    defaultMessage: 'Cambiar usuario',
  },
  menuPostulanteMiPanelCambiarPass: {
    id: 'app.components.Header.menuPostulanteMiPanelCambiarPass',
    defaultMessage: 'Cambiar Contraseña',
  },
  menuPostulanteMiPanelEditarPrivacidad: {
    id: 'app.components.Header.menuPostulanteMiPanelEditarPrivacidad',
    defaultMessage: 'Editar Privacidad',
  },
  menuPostulanteMiPanelConfigAlertas: {
    id: 'app.components.Header.menuPostulanteMiPanelConfigAlertas',
    defaultMessage: 'Configurar ofertas por e-mail',
  },
  menuPostulanteMiPanelDarBajaCuenta: {
    id: 'app.components.Header.menuPostulanteMiPanelDarBajaCuenta',
    defaultMessage: 'Dar de baja mi cuenta',
  },
  menuPostulanteMiCurriculum: {
    id: 'app.components.Header.menuPostulanteMiCurriculum',
    defaultMessage: 'Mi Currículum',
  },
  menuPostulanteMiCurriculumVer: {
    id: 'app.components.Header.menuPostulanteMiCurriculumVer',
    defaultMessage: 'Ver mi currículum',
  },
  menuPostulanteMiCurriculumDatos: {
    id: 'app.components.Header.menuPostulanteMiCurriculumDatos',
    defaultMessage: 'Datos Personales',
  },
  menuPostulanteMiCurriculumOtrosDatos: {
    id: 'app.components.Header.menuPostulanteMiCurriculumOtrosDatos',
    defaultMessage: 'Otros Datos Personales',
  },
  menuPostulanteMiCurriculumFoto: {
    id: 'app.components.Header.menuPostulanteMiCurriculumFoto',
    defaultMessage: 'Foto',
  },
  menuPostulanteMiCurriculumEstudios: {
    id: 'app.components.Header.menuPostulanteMiCurriculumEstudios',
    defaultMessage: 'Estudios',
  },
  menuPostulanteMiCurriculumIdiomas: {
    id: 'app.components.Header.menuPostulanteMiCurriculumIdiomas',
    defaultMessage: 'Idiomas',
  },
  menuPostulanteMiCurriculumExperiencias: {
    id: 'app.components.Header.menuPostulanteMiCurriculumExperiencias',
    defaultMessage: 'Experiencias',
  },
  menuPostulanteMiCurriculumFuturoEmpleo: {
    id: 'app.components.Header.menuPostulanteMiCurriculumFuturoEmpleo',
    defaultMessage: 'Futuro Empleo',
  },
  menuPostulanteMiCurriculumCVAdjunto: {
    id: 'app.components.Header.menuPostulanteMiCurriculumCVAdjunto',
    defaultMessage: 'Currículum en Texto o Adjunto',
  },
  menuPostulanteMiCurriculumCartaPresentacion: {
    id: 'app.components.Header.menuPostulanteMiCurriculumCartaPresentacion',
    defaultMessage: 'Carta de Presentación',
  },
  menuPostulanteMiCurriculumEditar: {
    id: 'app.components.Header.menuPostulanteMiCurriculumEditar',
    defaultMessage: 'Editar mi currículum',
  },
  menuPostulanteMiCurriculumEnviarMail: {
    id: 'app.components.Header.menuPostulanteMiCurriculumEnviarMail',
    defaultMessage: 'Enviar por Email',
  },
  menuPostulanteMiCurriculumDesactivar: {
    id: 'app.components.Header.menuPostulanteMiCurriculumDesactivar',
    defaultMessage: 'Desactivar currículum',
  },
  menuPostulanteOfertas: {
    id: 'app.components.Header.menuPostulanteOfertas',
    defaultMessage: 'Mis Ofertas',
  },
  menuPostulanteOfertasMisOfertas: {
    id: 'app.components.Header.menuPostulanteOfertasMisOfertas',
    defaultMessage: 'Mis Ofertas',
  },
  menuPostulanteOfertasBuscar: {
    id: 'app.components.Header.menuPostulanteOfertasBuscar',
    defaultMessage: 'Buscar ofertas',
  },
  menuPostulanteOfertasInvitaciones: {
    id: 'app.components.Header.menuPostulanteOfertasInvitaciones',
    defaultMessage: 'Mis invitaciones',
  },
  menuPostulanteMisTests: {
    id: 'app.components.Header.menuPostulanteMisTests',
    defaultMessage: 'Perfil Psicográfico',
  },
  menuPostulanteMisTestsInvitaciones: {
    id: 'app.components.Header.menuPostulanteMisTestsInvitaciones',
    defaultMessage: 'Mis Invitaciones',
  },
  menuPostulanteMisTestsCompetencias: {
    id: 'app.components.Header.menuPostulanteMisTestsCompetencias',
    defaultMessage: 'Test de Competencias',
  },
  menuPostulanteMisTestsPerfilLaboral: {
    id: 'app.components.Header.menuPostulanteMisTestsPerfilLaboral',
    defaultMessage: 'Test Perfil Laboral',
  },
  menuOperadorAdministracion: {
    id: 'app.components.Header.menuOperadorAdministracion',
    defaultMessage: 'Administración',
  },
  menuOperadorAdministracionAvanzada: {
    id: 'app.components.Header.menuOperadorAdministracionAvanzada',
    defaultMessage: 'Administración de Postulantes, Empresas y Ofertas',
  },
  menuOperadorAdministracionPanel: {
    id: 'app.components.Header.menuOperadorAdministracionPanel',
    defaultMessage: 'Panel de Administración',
  },
  menuOperadorAdministracionEmpresas: {
    id: 'app.components.Header.menuOperadorAdministracionEmpresas',
    defaultMessage: 'Administración de Empresas',
  },
  menuOperadorAdministracionOfertas: {
    id: 'app.components.Header.menuOperadorAdministracionOfertas',
    defaultMessage: 'Administración de Ofertas',
  },
  menuOperadorAdministracionPostulantes: {
    id: 'app.components.Header.menuOperadorAdministracionPostulantes',
    defaultMessage: 'Administración de Postulantes',
  },
  menuOperadorAdministracionPagos: {
    id: 'app.components.Header.menuOperadorAdministracionPagos',
    defaultMessage: 'Administración de Pagos',
  },
  menuOperadorAdministracionProductos: {
    id: 'app.components.Header.menuOperadorAdministracionProductos',
    defaultMessage: 'Administración de Productos',
  },
  menuOperadorAdministracionEmpresaPaquetes: {
    id: 'app.components.Header.menuOperadorAdministracionEmpresaPaquetes',
    defaultMessage: 'Administración de EmpresaPaquetes'
  },
  menuOperadorPagos: {
    id: 'app.components.Header.menuOperadorPagos',
    defaultMessage: 'Pagos',
  },
  menuOperadorForzarAcceso: {
    id: 'app.components.Header.menuOperadorForzarAcceso',
    defaultMessage: 'Forzar Acceso',
  },
  menuOperadorCambiarPassword: {
    id: 'app.components.Header.menuOperadorCambiarPassword',
    defaultMessage: 'Cambiar Contraseña',
  },
  menuOperadorReportes: {
    id: 'app.components.Header.menuOperadorReportes',
    defaultMessage: 'Reportes',
  },
  menuOperadorReportesHDL: {
    id: 'app.components.Header.menuOperadorReportesHDL',
    defaultMessage: 'Reportes HDL',
  },
  menuOperadorReportesDA: {
    id: 'app.components.Header.menuOperadorReportesDA',
    defaultMessage: 'Estadísticas D\'Anchiano',
  },
  menuOperadorListas: {
    id: 'app.components.Header.menuOperadorListas',
    defaultMessage: 'Listas',
  },
  menuOperadorProductos: {
    id: 'app.components.Header.menuOperadorProductos',
    defaultMessage: 'Productos',
  },
  menuOperadorAdminPaquete: {
    id: 'app.components.Header.menuOperadorAdminPaquete',
    defaultMessage: 'Administración de Paquetes',
  },
  menuOperadorCrearPaquete: {
    id: 'app.components.Header.menuOperadorCrearPaquete',
    defaultMessage: 'Crear Nuevo Paquete',
  },
  es: {
    id: 'app.components.Header.locales.es',
    defaultMessage: 'Español',
  },
  pt: {
    id: 'app.components.Header.locales.pt',
    defaultMessage: 'Portugués',
  },
  appBannerTitle: {
    id: 'app.components.Header.appBannerTitle',
    defaultMessage: 'Buscojobs',
  },
  appBannerText: {
    id: 'app.components.Header.appBannerText',
    defaultMessage: '¡Las ofertas laborales de {pais} en el celular!',
  },
  appBannerInstall: {
    id: 'app.components.Header.appBannerInstall',
    defaultMessage: 'Instalar',
  },
  appBannerClose: {
    id: 'app.components.Header.appBannerClose',
    defaultMessage: 'Cerrar',
  },
  login: {
    id: 'app.components.UserMenu.login',
    defaultMessage: 'Iniciar Sesión',
  },
  ingresoCandidatos: {
    id: 'app.components.UserMenu.ingresoCandidatos',
    defaultMessage: 'Ingreso candidatos',
  },
  publicarOferta: {
    id: 'app.components.UserMenu.publicarOferta',
    defaultMessage: 'Publica tu oferta',
  },
  logout: {
    id: 'app.components.UserMenu.logout',
    defaultMessage: 'Cerrar Sesión',
  },
  menuEmpresaMiCuenta: {
    id: 'app.components.UserMenu.menuEmpresaMiCuenta',
    defaultMessage: 'Mi Cuenta',
  },
  menuEmpresaContacto: {
    id: 'app.components.UserMenu.menuEmpresaContacto',
    defaultMessage: 'Contacto',
  },
  menuPostulanteMiCuenta: {
    id: 'app.components.UserMenu.menuPostulanteMiCuenta',
    defaultMessage: 'Mi Cuenta',
  },
  menuPostulanteContacto: {
    id: 'app.components.UserMenu.menuPostulanteContacto',
    defaultMessage: 'Contacto',
  },
  menuPostulanteAyuda: {
    id: 'app.components.UserMenu.menuPostulanteAyuda',
    defaultMessage: 'Ayuda',
  },
});
